import {getGoogleAnalytics} from '../utilities/google-analytics-api';

export const registerClientSideHistoryChangesWithGoogle = (history, pageTitle) => {
    history.listen(async (location) => {
        const googleAnalytics = await getGoogleAnalytics();

        googleAnalytics('send', {
            hitType: 'pageview',
            page: location.pathname,
            title: pageTitle
        });
    });
};
