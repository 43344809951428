import React from 'react';

import fractionType from '../../../../../../constants/fraction-type';
import {slugify} from '../../../../../../services/string-services';

const DECIMAL_PLACES = 3;

class CulinaryRecipeDetailIngredientsRow extends React.Component {
    getCheckedState(ingredientSelections, checkboxId) {
        let checked = false;

        if (ingredientSelections) {
            ingredientSelections.forEach((object) => {
                if (object.id === checkboxId) {
                    checked = true;
                }
            });
        }

        return checked;
    }

    getQuantityDisplay(quantity, unit) {
        const integer = Math.floor(quantity);
        const decimal = (quantity % 1).toFixed(DECIMAL_PLACES);
        const unitText = unit ? ` ${unit}` : '';
        let quantityDisplay;

        if (decimal > 0) {
            const fraction = fractionType[`${decimal}`];

            if (integer > 0) {
                quantityDisplay = `${integer} ${fraction}${unitText} `;
            } else {
                quantityDisplay = `${fraction}${unitText} `;
            }

            return (
                /* eslint-disable-next-line react/no-danger */
                <span dangerouslySetInnerHTML={{__html: quantityDisplay}} />
            );
        }

        if (integer === 0) {
            return null;
        }

        return <span>{`${integer}${unitText} `}</span>;
    }

    render() {
        let selectedVariationIndex = '',
            checkboxId = '',
            isChecked = false;

        if (!this.props.isPrinting) {
            selectedVariationIndex = this.props.ingredientsVariationReducer.selectedVariationIndex;
            checkboxId = slugify(
                `${selectedVariationIndex}-${this.props.ingredientIndex}-${this.props.groupIndex}-${this.props.ingredient.ingredient}`
            );
            isChecked = this.getCheckedState(
                this.props.ingredientsReducer.ingredientSelections[selectedVariationIndex],
                checkboxId
            );
        }

        const {ingredient, prepStep, quantity, unit} = this.props.ingredient;

        const prepText = prepStep ? `, ${prepStep}` : '';
        const quantityDisplay = this.getQuantityDisplay(quantity, unit);
        const fullItemDisplay = `${ingredient}${prepText}`;
        const {updateCheckedIngredient} = this.props.actions;
        const onCheck = () =>
            updateCheckedIngredient({
                displayName: `${quantity || ''}${unit || ''} ${ingredient}`,
                id: checkboxId,
                title: ingredient
            });

        return (
            <tr className="culinary-recipe-detail-ingredients-row">
                <td className="action">
                    <div className="checkbox">
                        <input
                            checked={isChecked}
                            className="checkbox-function"
                            data-testid={`ingredient-${this.props.groupIndex}-${this.props.ingredientIndex}`}
                            id={checkboxId}
                            onChange={onCheck}
                            type="checkbox"
                        />
                        <label className="checkbox-presentation" htmlFor={checkboxId}>
                            <span className="checkbox-label">{`Add ${ingredient}${prepText}`}</span>
                            <span className="checkbox-icon" />
                        </label>
                    </div>
                </td>
                {
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                    <td className="item" onClick={onCheck}>
                        {quantityDisplay}
                        {fullItemDisplay}
                    </td>
                }
            </tr>
        );
    }
}

export default CulinaryRecipeDetailIngredientsRow;
