import React from 'react';

import CulinaryRecipeDetailNutritionAmountItem from './culinary-recipe-detail-nutrition-amount-item';

class CulinaryRecipeDetailNutritionAmount extends React.Component {
    validateValue(value) {
        return value === '0' ? '0g' : value;
    }

    getSubNutrientMarkup(subNutrient, index) {
        if (subNutrient.value === '' || subNutrient.value === null || subNutrient.value === undefined) {
            return null;
        }

        return <CulinaryRecipeDetailNutritionAmountItem index={index} key={index} subNutrient={subNutrient} />;
    }

    render() {
        const {nutrient} = this.props;

        if (nutrient.value === '' || nutrient.value === null || nutrient.value === undefined) {
            return null;
        }

        if (nutrient.subNutrients && nutrient.subNutrients.length > 0) {
            return (
                <li className="culinary-recipe-detail-nutrition-amount">
                    {`${nutrient.key}: ${this.validateValue(nutrient.value)}`}
                    <ul className="related">
                        {nutrient.subNutrients.map((subNutrient, index) =>
                            this.getSubNutrientMarkup(subNutrient, index)
                        )}
                    </ul>
                </li>
            );
        }

        return (
            <li className="culinary-recipe-detail-nutrition-amount">
                {`${nutrient.key}: ${this.validateValue(nutrient.value)}`}
            </li>
        );
    }
}

export default CulinaryRecipeDetailNutritionAmount;
