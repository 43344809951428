import React from 'react';

import CulinaryFeaturePreviewList from '../modules/feature/culinary-feature-preview-list';
import CulinaryFeature2up from '../modules/feature/culinary-feature-2up';
import CulinaryFeatureCardMedia from '../modules/feature/culinary-feature-card-media';
import CulinaryFeatureCardGuide from '../modules/feature/cards/culinary-feature-card-guide';
import CulinaryEditorialFooter from '../modules/editorial/culinary-editorial-footer';
import FeatureSearchBar from '../../feature/feature-search-bar';
import CulinaryFeatureCardCarousel from '../modules/feature/cards/culinary-feature-card-carousel';
import CulinaryFeatureHeaderBlock from '../modules/feature/culinary-feature-header-block';
import CulinaryFeatureStory from '../modules/feature/culinary-feature-story';
import CulinaryFeatureEditorialCategoryBlock from '../modules/feature/culinary-feature-editorial-category-block';
import CulinaryFeatureRelatedFooter from '../modules/feature/related-content/culinary-feature-related-footer';
import ApiQueryService from '../../../services/api/api-query-service';

class CulinaryEditorialLandingTemplate extends React.Component {
    constructor(props) {
        super(props);

        this.hasSearchQuery = ApiQueryService.hasSearchQuery(this.props);
    }

    componentWillMount() {
        if (this.hasSearchQuery) {
            this.props.actions.syncSearchQueryWithStore(this.props.router.location.query);
        }
    }

    componentWillReceiveProps(nextProps) {
        this.hasSearchQuery = ApiQueryService.hasSearchQuery(nextProps);
    }

    render() {
        const {content} = this.props.contentReducer.urlContent;
        const {primaryFeature, secondaryFeatures} = content.featuredEditorialBlock;
        const editorialFeatures = content.recentGuideContent && {
            featureOne: content.recentGuideContent[0],
            featureTwo: content.recentGuideContent[1]
        };

        return (
            <main className="culinary-editorial-landing-template">
                <div className="intro">
                    <h2 className="page-title">{content.heading}</h2>
                    <div className="search">
                        <h3 className="heading">{'Search Editorial Content'}</h3>
                        <FeatureSearchBar
                            autocompleteResults={this.props.autocompleteReducer.results}
                            classExt={'editorial'}
                            formAction="/recipes-ideas/advice-how-tos/search"
                            hasSearchQuery={this.hasSearchQuery}
                            placeholder={'I want to learn about...'}
                            searchType={'editorial'}
                        />
                    </div>
                </div>
                <div className="container">
                    <section className="main">
                        <div className="primary">
                            {primaryFeature.type === 'Guide' ? (
                                <CulinaryFeatureCardGuide cardGuide={primaryFeature} isWide />
                            ) : (
                                <CulinaryFeatureCardMedia
                                    content={primaryFeature}
                                    imageHeight={'825'}
                                    imageWidth={'1100'}
                                    isStacked
                                    layout={'feature-primary'}
                                    showDescription
                                />
                            )}
                        </div>
                        <div className="supporting-content">
                            <CulinaryFeaturePreviewList
                                featuredContentList={secondaryFeatures}
                                layout="small"
                                listStyle="column"
                            />
                        </div>
                    </section>
                    <hr className="divider" />
                </div>

                <section className="how-tos">
                    <CulinaryFeatureHeaderBlock
                        constrained
                        contentLabel={'how-tos'}
                        heading={'Featured How-Tos'}
                        linkUrl={content.recentHowTosSearchUrl}
                    />
                    <CulinaryFeatureCardCarousel
                        actions={this.props.actions}
                        alertReducer={this.props.alertReducer}
                        cardList={content.recentHowTos}
                        carouselState={this.props.carouselReducer.carousels}
                        favoritesReducer={this.props.favoritesReducer}
                        id="featured-recipes"
                        isAuthenticated={this.props.isAuthenticated}
                        isOptionalCarousel
                        modalReducer={this.props.modalReducer}
                        styleConfig="primary"
                    />
                </section>

                <div className="story">
                    <CulinaryFeatureStory data={content.featuredCulinaryStory} />
                </div>

                <section className="section">
                    <h3 className="heading">{`${content.featuredCategory.title} content`}</h3>
                    <CulinaryFeatureEditorialCategoryBlock editorialCategoryBlock={content.featuredCategory} />
                </section>

                <section className="related-list">
                    <h3 className="heading">{'Related Editorial Content'}</h3>
                    <div className="blocks">
                        <CulinaryFeaturePreviewList featuredContentList={content.recentEditorialContent} />
                    </div>
                    <section className="features-double">
                        <CulinaryFeature2up feature2up={editorialFeatures} />
                    </section>
                </section>
                <CulinaryEditorialFooter editorialCategories={content.editorialNavigationLinks} />
                {content.promoContent && (
                    <CulinaryFeatureRelatedFooter
                        condensedLayout
                        environmentConfigReducer={this.props.environmentConfigReducer}
                        noChildren={false}
                        promoContent={content.promoContent}
                    />
                )}
            </main>
        );
    }
}

export default CulinaryEditorialLandingTemplate;
