import React from 'react';

import CulinaryMealIdeasActionItem from './culinary-meal-ideas-action-item';

const CulinaryMealIdeasActionBlock = ({content, modalUpdate}) => {
    if (!content) {
        return null;
    }

    return (
        <div className="culinary-meal-ideas-action-block">
            {content.map((promo) => (
                <CulinaryMealIdeasActionItem
                    image={promo.image}
                    key={promo.heading}
                    link={promo.link}
                    message={promo.heading}
                    modalUpdate={modalUpdate}
                />
            ))}
        </div>
    );
};

export default CulinaryMealIdeasActionBlock;
