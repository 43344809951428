import React from 'react';

import {isMicrosoftBrowser} from '../../../../services/user-agent-service';

class CulinaryLoadingCart extends React.Component {
    constructor(props) {
        super(props);

        this.label = this.props.label ? this.props.label : 'Loading...';
    }

    getComponentClass() {
        const baseClass = 'culinary-loading-cart';

        return isMicrosoftBrowser() ? `${baseClass} ms` : baseClass;
    }

    render() {
        return (
            <div className={this.getComponentClass()}>
                <div className="icon">
                    <div className="cart">
                        <div className="cart-frame">
                            <svg height="162" viewBox="0 0 120 162" width="120">
                                <path
                                    className="line frame"
                                    d="M7.2 53.9s10.2-1.1 12 4c1.3 3.6 18.7 61.8 18.7 61.8s-7.7 11.9-8.4 12.9c-1.3 2-.7 3.8 2.5 3.8h67s4.5 1.7 4.5 4.3"
                                />
                            </svg>
                        </div>
                        <div className="cart-basket">
                            <svg height="162" viewBox="0 0 120 162" width="120">
                                <path
                                    className="line basket"
                                    d="M22.1 67.2s84.2 5.1 86.7 5.1 4.5 1.5 3.8 5-6.3 33.3-6.7 35.3c-.3 2-1.3 4-5.3 4.2s-62.8 3-62.8 3"
                                />
                            </svg>
                        </div>
                        <div className="items">
                            <div className="item-small">
                                <div className="item">
                                    <svg height="162" viewBox="0 0 120 162" width="120">
                                        <path
                                            className="line small"
                                            d="M29.966 13.617L48 7.027l10.433 28.555-18.034 6.59z"
                                        />
                                    </svg>
                                </div>
                            </div>
                            <div className="item-large">
                                <div className="item">
                                    <svg height="162" viewBox="0 0 120 162" width="120">
                                        <path
                                            className="line large"
                                            d="M75.878 36.718L86.205 5.692l24.195 8.053-10.327 31.026z"
                                        />
                                    </svg>
                                </div>
                            </div>
                            <div className="item-round">
                                <div className="item">
                                    <svg height="162" viewBox="0 0 120 162" width="120">
                                        <circle className="line round" cx="67.4" cy="18.5" r="13.7" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wheels">
                        <div className="wheel-back">
                            <svg height="162" viewBox="0 0 120 162" width="120">
                                <circle className="wheel back" cx="22.1" cy="150.3" r="4.5" />
                            </svg>
                        </div>
                        <div className="wheel-front">
                            <svg height="162" viewBox="0 0 120 162" width="120">
                                <circle className="wheel front" cx="89.4" cy="150.3" r="4.5" />
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="label">{this.label}</div>
            </div>
        );
    }
}

export default CulinaryLoadingCart;
