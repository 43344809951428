import React from 'react';
import {Link} from 'react-router';

import eventKeys from '../../constants/event-keys';
import IconPlus from '../global/icon/icon-plus';
import IconMinus from '../global/icon/icon-minus';

class FeatureCategorySection extends React.Component {
    constructor(props) {
        super(props);

        this.categoryId = `category-${this.props.index}`;

        this.onClickHandler = () => this.onClick();
        this.onKeyDownHandler = (event) => this.onKeyDown(event);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.visibleCategoryId === this.categoryId || nextProps.isMobileBreakpoint === false) {
            this.isOpen = true;
        }

        if (nextProps.visibleCategoryId !== this.categoryId && nextProps.isMobileBreakpoint === true) {
            this.isOpen = false;
        }
    }

    onKeyDown(event) {
        if (event.keyCode === eventKeys.ESC && this.isOpen) {
            this.toggle();
        }
    }

    onClick() {
        this.toggle();
    }

    toggle() {
        const activeCategoryId = this.isOpen ? null : this.categoryId;

        this.props.updateVisibleCategoryIdHandler(activeCategoryId);
    }

    checkForActivePageClass(url) {
        let activeClass = '';
        const activeUrl = this.props.urlContentPath && this.props.urlContentPath.replace(/\/$/u, '');

        if (url === activeUrl && this.props.showPageState) {
            activeClass = 'is-active';
        }

        return activeClass;
    }

    render() {
        const {category} = this.props;
        const hiddenClass = this.props.isMobileBreakpoint && !this.isOpen ? 'is-hidden' : '';
        const collapsedClass = this.props.isMobileBreakpoint && !this.isOpen ? 'is-collapsed' : 'is-expanded';
        const categoryActiveClass = this.checkForActivePageClass(category.url);

        return (
            <section className="section" id={this.categoryId} onKeyDown={this.onKeyDownHandler}>
                <div className="menu">
                    <h4 className="heading">{`${category.title} Menu`}</h4>
                    <button
                        aria-expanded={this.isOpen}
                        className={`feature-toggle-btn ${collapsedClass}`}
                        onClick={this.onClickHandler}
                        type="button"
                    >
                        {category.title}
                        <span className="icon icon-collapsed">
                            <IconPlus />
                        </span>
                        <span className="icon icon-expanded">
                            <IconMinus />
                        </span>
                    </button>
                    <Link className={`category ${categoryActiveClass}`} to={category.url}>
                        <span className="link-text">{category.title}</span>
                    </Link>
                </div>

                <ul
                    aria-hidden={this.props.isMobileBreakpoint && !this.isOpen}
                    className={`feature-subcategories ${hiddenClass}`}
                >
                    <li className="subcategory subcategory-view-all">
                        <Link className={`link ${categoryActiveClass}`} to={category.url}>
                            <span className="link-text">{`All ideas in ${category.title}`}</span>
                        </Link>
                    </li>
                    {category.subLinks.map((subcategory, subcategoryIndex) => (
                        <li className="subcategory" key={subcategoryIndex}>
                            <Link
                                className={`link ${this.checkForActivePageClass(subcategory.url)}`}
                                to={subcategory.url}
                            >
                                <span className="link-text">{subcategory.title}</span>
                            </Link>
                        </li>
                    ))}
                </ul>
            </section>
        );
    }
}

export default FeatureCategorySection;
