import ContentApiService from '../services/api/content-api-service';
import EnterpriseApiService from '../services/api/enterprise-api-service';
import {
    CLEAR_URL_CONTENT,
    CONTENT_SET_LOADING,
    LOAD_STORE_CONTENT,
    LOAD_URL_CONTENT,
    LOAD_URL_CONTENT_SUCCESS,
    SET_EDITORIAL_LIST_IN_STORE
} from '../action-types';

export const loadContentForUrl = (url, domainUrl, headers = null) => async (dispatch) => {
    dispatch({
        data: true,
        type: CONTENT_SET_LOADING
    });

    dispatch({
        data: url,
        type: LOAD_URL_CONTENT
    });

    const responseModel = await ContentApiService.fetchUrlContent(url, domainUrl, headers);

    if (responseModel.data) {
        dispatch({
            data: responseModel.data,
            type: LOAD_URL_CONTENT_SUCCESS
        });
    } else {
        dispatch({
            data: false,
            type: CONTENT_SET_LOADING
        });
    }

    if (responseModel.data && responseModel.data.content && responseModel.data.content.categoryContent) {
        dispatch({
            data: responseModel.data.content.categoryContent,
            type: SET_EDITORIAL_LIST_IN_STORE
        });
    }
};

export const loadStoreContent = (storeId) => async (dispatch, getState) => {
    const {url, enterprise} = getState().environmentConfigReducer;
    const endpoint = `${url.local}${enterprise.storeApiUrl}`;
    const responseModel = await EnterpriseApiService.fetchStoreContent(endpoint, storeId);

    dispatch({
        data: responseModel.data,
        type: LOAD_STORE_CONTENT
    });
};

export const clearContent = () => (dispatch) => {
    dispatch({
        type: CLEAR_URL_CONTENT
    });
};
