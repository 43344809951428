import React from 'react';

class CulinaryFeatureGuideHeroSectionsBackground extends React.Component {
    constructor(props) {
        super(props);

        this.onImageLoadHandler = () => this.onImageLoad();

        if (this.props.content.hero && this.props.content.hero.backgroundImage1) {
            this.backgroundImage = this.props.content.hero.backgroundImage1;

            this.style = {
                image1: {
                    backgroundImage: `url(${this.backgroundImage.cropUrl})`
                }
            };
        }

        this.state = {
            imageLoaded: false
        };
    }

    componentDidMount() {
        if (this.backgroundImageElement && this.backgroundImageElement.complete) {
            this.onImageLoad();
        }
    }

    onImageLoad() {
        this.setState({
            imageLoaded: true
        });
    }

    render() {
        if (this.props.content.hero.backgroundImage1) {
            return (
                <div
                    className={`culinary-feature-guide-hero-sections-background${
                        this.state.imageLoaded ? ' culinary-feature-guide-hero-sections-background-loaded' : ''
                    }`}
                >
                    <div className="background" style={this.style.image1} />
                    <img
                        alt={this.backgroundImage.alt}
                        className="background-image"
                        onLoad={this.onImageLoadHandler}
                        ref={(node) => {
                            this.backgroundImageElement = node;
                        }}
                        src={this.backgroundImage.cropUrl}
                    />
                </div>
            );
        }

        return null;
    }
}

export default CulinaryFeatureGuideHeroSectionsBackground;
