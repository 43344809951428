export const GA_EVENT = 'gaEvent';
export const LIST_INTERACTION = 'List Interaction';

export const setGtmSharingPlatform = (platform) => {
    global.window.dataLayer.push({
        event: GA_EVENT,
        eventAction: 'Share',
        eventCategory: 'Recipe Interaction',
        eventLabel: platform
    });
};

export const setGtmFilterSort = (filterName) => {
    global.window.dataLayer.push({
        event: GA_EVENT,
        eventAction: 'Filter/Sort',
        eventCategory: 'Recipe Interaction',
        eventLabel: filterName
    });
};

export const reportListEvent = (action, eventLabel = 'Culinary') => {
    let windowDataLayer = global.window.dataLayer;

    try {
        if (global.window.parentIFrame) {
            windowDataLayer = global.window.parent.window.dataLayer;
        }
    } catch (error) {}

    if (windowDataLayer) {
        windowDataLayer.push({
            event: GA_EVENT,
            eventAction: action,
            eventCategory: LIST_INTERACTION,
            eventLabel
        });
    }
};
