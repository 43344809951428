export const textCustomizationClasses = (textContrast, textInverse) => {
    const contrastClass = textContrast ? ' needs-contrast' : '';
    const invertClass = textInverse ? ' is-inverted' : '';

    return `${contrastClass}${invertClass}`;
};

export const backgroundStyle = (backgroundColor, backgroundImage) => {
    const elementStyle = {};

    if (backgroundColor) {
        elementStyle.backgroundColor = `#${backgroundColor}`;
    }

    if (backgroundImage) {
        elementStyle.backgroundImage = `url(${backgroundImage.url})`;
    }

    return elementStyle;
};
