import React from 'react';

const getListMarkup = (data) => {
    if (data.list) {
        return (
            <ul className="culinary-feature-card-info-content">
                {data.list.map((item, index) => (
                    <li key={index}>
                        <div className="header">{item.heading}</div>

                        <div
                            /* eslint-disable-next-line react/no-danger */
                            dangerouslySetInnerHTML={{__html: item.body}}
                        />
                    </li>
                ))}
            </ul>
        );
    }

    return null;
};

const getBodyMarkup = (data) => {
    if (data.body) {
        return (
            <div
                className="culinary-feature-card-info-content"
                /* eslint-disable-next-line react/no-danger */
                dangerouslySetInnerHTML={{__html: data.body}}
            />
        );
    }

    return null;
};

const CulinaryFeatureCardInfo = ({data}) => {
    const listMarkup = getListMarkup(data);
    const bodyMarkup = getBodyMarkup(data);

    return (
        <div className="culinary-feature-card-info">
            <div className="inner-wrap">
                <h4 className="header">{data.heading}</h4>
                {listMarkup}
                {bodyMarkup}
            </div>
        </div>
    );
};

export default CulinaryFeatureCardInfo;
