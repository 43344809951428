import React from 'react';

import CulinaryEditorialRow from '../modules/culinary-editorial-row';
import CulinaryLoadingRecipe from '../modules/loading/culinary-loading-recipe';
import CulinarySearchActiveRefinementList from '../modules/search/culinary-search-active-refinement-list';
import CulinarySearchFilterBar from '../modules/search/culinary-search-filter-bar';
import CulinarySearchFilters from '../modules/search/culinary-search-filters';
import CulinarySearchHero from '../modules/search/culinary-search-hero';
import CulinarySearchInfoBar from '../modules/search/culinary-search-info-bar';
import CulinarySearchNoResults from '../modules/search/culinary-search-no-results';
import editorialSearchSortByMap from '../../../constants/editorial-search-sort-by-map';
import withMountAnimation from '../../global/hoc/with-mount-animation';
import CulinarySearchViewMoreBtn from '../modules/search/culinary-search-view-more-btn';
import CulinaryFeatureHeaderBlock from '../modules/feature/culinary-feature-header-block';
import CulinaryFeatureCardCarousel from '../modules/feature/cards/culinary-feature-card-carousel';
import CulinaryFeatureEditorialCategoryBlock from '../modules/feature/culinary-feature-editorial-category-block';
import CulinaryEditorialFooter from '../modules/editorial/culinary-editorial-footer';
import CulinaryFeatureRelatedFooter from '../modules/feature/related-content/culinary-feature-related-footer';
import CulinaryFeaturePreviewList from '../modules/feature/culinary-feature-preview-list';
import CulinaryFeatureRelatedContent from '../modules/feature/related-content/culinary-feature-related-content';
import ApiQueryService from '../../../services/api/api-query-service';

const CulinaryEditorialRowWithMountAnimation = withMountAnimation(CulinaryEditorialRow);

class CulinaryEditorialSearchTemplate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            searchQuery: {}
        };

        this.hasSearchQuery = ApiQueryService.hasSearchQuery(this.props);
    }

    componentWillMount() {
        const dropdowns = this.props.contentReducer.urlContent.content.dropdowns;

        if (dropdowns) {
            dropdowns.forEach((dropdown) => this.props.actions.addFilterGroup({fieldName: dropdown.fieldName}));
        }

        if (this.hasSearchQuery) {
            this.props.actions.syncSearchQueryWithStore(this.props.router.location.query);
        }
    }

    componentWillReceiveProps(nextProps) {
        const location = this.props.router.location;
        const dropdowns = this.props.contentReducer.urlContent.content.dropdowns;
        const isViewMore = nextProps.searchReducer.currentPage > 0;

        if (Object.keys(nextProps.searchReducer.filters).length === 0) {
            dropdowns.forEach((dropdown) => this.props.actions.addFilterGroup({fieldName: dropdown.fieldName}));
        }

        if (nextProps.router.location.search !== this.state.searchQuery) {
            this.hasSearchQuery = ApiQueryService.hasSearchQuery(nextProps);

            if (this.hasSearchQuery) {
                const locationQuery = {
                    currentPage: nextProps.searchReducer.currentPage,
                    pageSize: nextProps.searchReducer.pageSize,
                    sortBy: nextProps.searchReducer.sortBy ? nextProps.searchReducer.sortBy : 'relevance',
                    ...location.query
                };

                this.props.actions.syncSearchQueryWithStore(this.props.router.location.query);
                this.props.actions.setSearchQuery(
                    locationQuery,
                    this.props.environmentConfigReducer.cms.editorialSearchApiUrl,
                    isViewMore
                );
            } else if (nextProps.searchReducer.results.length) {
                this.props.actions.clearSearchResults();
            }
        }

        this.setState({searchQuery: location.search});
    }

    componentWillUnmount() {
        this.props.actions.resetSearch();
    }

    render() {
        const content = this.props.contentReducer.urlContent.content;
        const {actions, autocompleteReducer, renderReducer, location, searchReducer} = this.props;

        const shouldDisplayViewMoreButton =
            searchReducer.searchIsLoadingMore ||
            (searchReducer.totalPages - searchReducer.currentPage > 1 &&
                this.hasSearchQuery &&
                !searchReducer.searchIsLoading);
        const focusIndex = searchReducer.pageSize * searchReducer.currentPage;
        const animationDelayOffset = 140;
        const animationTransitionDuration = 700;
        const animationTransitionTimeout =
            animationDelayOffset * searchReducer.displayResults.length + animationTransitionDuration;
        const {editorialLandingContent} = content;

        const noSearchResults =
            !searchReducer.searchIsLoading && this.hasSearchQuery && searchReducer.totalResultCount === 0;
        const hasSearchResults =
            !searchReducer.searchIsLoading && this.hasSearchQuery && searchReducer.totalResultCount > 0;
        const searching = searchReducer.searchIsLoading && this.hasSearchQuery;
        const noSearchQuery = !this.hasSearchQuery;

        const showSearchPageContent = noSearchResults || searching || noSearchQuery;

        return (
            <main className="culinary-editorial-search-template">
                <CulinarySearchHero
                    autocompleteResults={autocompleteReducer.results}
                    clearAutocompleteResults={actions.clearAutocompleteResults}
                    hasSearchQuery={this.hasSearchQuery}
                    isEditorial
                    isServerSide={renderReducer.isServerSide}
                    media={content.primaryMedia}
                    searchTerm={location.query.q}
                    setQueryString={actions.setQueryString}
                />
                {content.dropdowns && (
                    <CulinarySearchFilters>
                        <CulinarySearchFilterBar
                            addFilter={actions.addFilter}
                            dropdowns={content.dropdowns}
                            filters={searchReducer.filters}
                            removeFilter={actions.removeFilter}
                        />
                    </CulinarySearchFilters>
                )}
                {content.dropdowns && (
                    <CulinarySearchActiveRefinementList
                        addFilter={actions.addFilter}
                        dropdowns={content.dropdowns}
                        filters={searchReducer.filters}
                        hasSearchQuery={this.hasSearchQuery}
                        removeAllFilters={actions.removeAllFilters}
                        removeFilter={actions.removeFilter}
                    />
                )}
                <div className="page-content">
                    {hasSearchResults && (
                        <CulinarySearchInfoBar
                            displayCount={searchReducer.displayResults.length}
                            isLoading={searchReducer.searchIsLoading}
                            searchTerm={location.query.q}
                            setSortBy={actions.setSortBy}
                            sortBy={searchReducer.sortBy}
                            sortByMap={editorialSearchSortByMap}
                            sortFilters={content.sortByOptions}
                            totalResultCount={searchReducer.totalResultCount}
                        />
                    )}
                    {searching && <CulinaryLoadingRecipe label="Loading" />}
                    {hasSearchResults && (
                        <CulinaryEditorialRowWithMountAnimation
                            cards={searchReducer.displayResults}
                            focusFirstAllowed
                            focusIndex={focusIndex}
                            withMountAnimationProps={{
                                offset: animationDelayOffset,
                                pageSize: searchReducer.pageSize,
                                transitionGroup: {
                                    className: 'culinary-editorial-row',
                                    classNames: {
                                        enter: 'kf-slide-in'
                                    },
                                    component: 'div',
                                    enter: true,
                                    exit: false,
                                    timeout: animationTransitionTimeout
                                }
                            }}
                        />
                    )}
                    {shouldDisplayViewMoreButton && (
                        <CulinarySearchViewMoreBtn
                            actions={this.props.actions}
                            endpoint={this.props.environmentConfigReducer.cms.editorialSearchApiUrl}
                            label={''}
                            location={this.props.router.location}
                            searchReducer={searchReducer}
                        />
                    )}
                    {noSearchResults && (
                        <CulinarySearchNoResults
                            content={content.noSearchResultsContent}
                            heading={content.noSearchResultsHeading}
                            searchTerm={location.query.q}
                        />
                    )}
                </div>
                <div className="additional-page-content">
                    {showSearchPageContent && [
                        <section className="how-tos" key="1">
                            <CulinaryFeatureHeaderBlock
                                constrained
                                contentLabel={'how-tos'}
                                heading={'Featured How-Tos'}
                                linkUrl={editorialLandingContent.recentHowTosSearchUrl}
                            />
                            <CulinaryFeatureCardCarousel
                                actions={this.props.actions}
                                alertReducer={this.props.alertReducer}
                                cardList={editorialLandingContent.recentHowTos}
                                carouselState={this.props.carouselReducer.carousels}
                                favoritesReducer={this.props.favoritesReducer}
                                id="featured-recipes"
                                isAuthenticated={this.props.isAuthenticated}
                                isOptionalCarousel
                                key="1"
                                modalReducer={this.props.modalReducer}
                                styleConfig="primary"
                            />
                        </section>,
                        <section className="section" key="2">
                            <h3 className="heading">{`${editorialLandingContent.featuredCategory.title} content`}</h3>
                            <CulinaryFeatureEditorialCategoryBlock
                                editorialCategoryBlock={editorialLandingContent.featuredCategory}
                            />
                        </section>,
                        <section className="related-list" key="3">
                            <h3 className="heading">{'Related Editorial Content'}</h3>
                            <div className="blocks">
                                <CulinaryFeaturePreviewList
                                    featuredContentList={editorialLandingContent.recentEditorialContent}
                                />
                            </div>
                        </section>,
                        <CulinaryEditorialFooter
                            editorialCategories={editorialLandingContent.editorialNavigationLinks}
                            key="4"
                        />
                    ]}
                    {editorialLandingContent.promoContent && (
                        <CulinaryFeatureRelatedFooter
                            environmentConfigReducer={this.props.environmentConfigReducer}
                            noChildren={!searchReducer.relatedRecipeContent}
                            promoContent={editorialLandingContent.promoContent}
                        >
                            {hasSearchResults && (
                                <CulinaryFeatureRelatedContent
                                    actions={this.props.actions}
                                    alertReducer={this.props.alertReducer}
                                    carouselState={this.props.carouselReducer.carousels}
                                    favoritesReducer={this.props.favoritesReducer}
                                    isAuthenticated={this.props.isAuthenticated}
                                    modalReducer={this.props.modalReducer}
                                    relatedContent={this.props.searchReducer.relatedRecipeContent}
                                    searchReducer={searchReducer}
                                    sectionTitle={'You might also like'}
                                />
                            )}
                        </CulinaryFeatureRelatedFooter>
                    )}
                </div>
            </main>
        );
    }
}

export default CulinaryEditorialSearchTemplate;
