import React from 'react';
import parser from 'html-react-parser';

import CulinaryFeatureCard from '../culinary/modules/feature/cards/culinary-feature-card';
import CulinaryFeatureCardGuide from '../culinary/modules/feature/cards/culinary-feature-card-guide';
import CulinaryFeatureExpertTip from '../culinary/modules/feature/culinary-feature-expert-tip';

import FeaturePrimaryImage from './feature-primary-image';

class FeatureWysiwygMixed extends React.Component {
    inlineCard(component) {
        return (
            <div className="component">
                <CulinaryFeatureCard
                    actions={this.props.actions}
                    alertReducer={this.props.alertReducer}
                    card={component}
                    favoritesReducer={this.props.favoritesReducer}
                    id={'wysiwyg-inline'}
                    isAuthenticated={this.props.isAuthenticated}
                    layoutType="inline"
                />
            </div>
        );
    }

    expertTip(component) {
        return (
            <div className="component">
                <CulinaryFeatureExpertTip data={component} layoutType="direction" />
            </div>
        );
    }

    image(component, alignment = 'full') {
        const imageWidth = alignment ? 600 : 1000;

        return (
            <div className={`component component-${alignment}`}>
                <FeaturePrimaryImage cropWidth={imageWidth} data={component} />
            </div>
        );
    }

    referenceCard(component, alignment = 'full') {
        let cardMarkup;

        if (component.type === 'Guide') {
            cardMarkup = (
                <div className={`component component-guide component-${alignment}`}>
                    <CulinaryFeatureCardGuide
                        cardGuide={component}
                        isLargeCard
                        isWide={alignment === 'full'}
                        isWideXSmall
                    />
                </div>
            );
        } else {
            cardMarkup = (
                <div className={`component component-card component-${alignment}`}>
                    <CulinaryFeatureCard
                        actions={this.props.actions}
                        alertReducer={this.props.alertReducer}
                        card={component}
                        favoritesReducer={this.props.favoritesReducer}
                        id={'wysiwyg-reference'}
                        isAuthenticated={this.props.isAuthenticated}
                    />
                </div>
            );
        }

        return cardMarkup;
    }

    getComponentMarkup(id, alias, alignment) {
        const filteredArray = this.props.content.components.filter((component) => component.id === id);
        const component = filteredArray[0].data;

        switch (alias) {
            case 'inlineCardReference':
                return this.inlineCard(component, alignment);
            case 'expertTip':
                return this.expertTip(component, alignment);
            case 'cardReference':
                return this.referenceCard(component, alignment);
            case 'image':
                return this.image(component, alignment);
            default:
                return null;
        }
    }

    render() {
        const {body} = this.props.content;

        if (!body) {
            return null;
        }

        return (
            <div className="feature-wysiwyg-mixed">
                {parser(body, {
                    replace: (domNode) => {
                        if (domNode.name === 'component') {
                            const alias = domNode.attribs['hv-alias'];
                            const {id, alignment} = domNode.attribs;

                            return this.getComponentMarkup(id, alias, alignment);
                        }

                        return domNode;
                    }
                })}
            </div>
        );
    }
}

export default FeatureWysiwygMixed;
