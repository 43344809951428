import thunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension/developmentOnly';
import {createStore, applyMiddleware} from 'redux';
import {routerMiddleware} from 'react-router-redux';
import {autoRehydrate} from 'redux-persist';

import {combine} from '../reducers/root-reducers';

function getMiddleware(history) {
    if (history) {
        return applyMiddleware(routerMiddleware(history), thunk);
    }

    return applyMiddleware(thunk);
}

export function createProviderStore(initialState, history = null) {
    const store = createStore(combine(), initialState, composeWithDevTools(getMiddleware(history), autoRehydrate()));

    return store;
}
