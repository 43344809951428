import React from 'react';

import {slugify} from '../../../../services/string-services';

import CulinaryFeatureCard from './cards/culinary-feature-card';
import CulinaryFeatureCardGuide from './cards/culinary-feature-card-guide';
import CulinaryFeatureCardCarousel from './cards/culinary-feature-card-carousel';

class CulinaryFeatureBasicCardListing extends React.Component {
    componentDidMount() {
        const {rowId, columnId, data} = this.props;

        if (rowId !== undefined && columnId !== undefined) {
            let isValid = false;

            if (data) {
                isValid = true;
            }

            this.props.actions.pageBuilderSetValid({
                columnId,
                isValid,
                rowId
            });
        }
    }

    render() {
        const {
            actions,
            data,
            alertReducer,
            isAuthenticated,
            modalReducer,
            layoutType,
            favoritesReducer,
            carouselReducer
        } = this.props;
        let cardMarkup;

        if (!data) {
            return null;
        }

        const cards = data.cards ? data.cards : data;

        return (
            <div className={`culinary-feature-basic-card-listing ${layoutType}`}>
                {data.heading && <h2 className="heading">{data.heading}</h2>}
                {layoutType !== 'full' ? (
                    <div className="list">
                        {cards.map((card, cardIndex) => {
                            if (card.type === 'Guide') {
                                cardMarkup = <CulinaryFeatureCardGuide cardGuide={card} key={cardIndex} />;
                            } else {
                                cardMarkup = (
                                    <CulinaryFeatureCard
                                        actions={this.props.actions}
                                        alertReducer={this.props.alertReducer}
                                        card={card}
                                        cardIndex={cardIndex}
                                        favoritesReducer={favoritesReducer}
                                        id={`card-blocks-${cardIndex}`}
                                        isAuthenticated={isAuthenticated}
                                        key={cardIndex}
                                        modalReducer={modalReducer}
                                    />
                                );
                            }

                            return cardMarkup;
                        })}
                    </div>
                ) : (
                    <CulinaryFeatureCardCarousel
                        actions={actions}
                        alertReducer={alertReducer}
                        cardList={data}
                        carouselState={carouselReducer.carousels}
                        favoritesReducer={favoritesReducer}
                        id={`basic-card-carousel-${slugify(data.heading)}`}
                        isAuthenticated={isAuthenticated}
                        isOptionalCarousel
                        modalReducer={modalReducer}
                        styleConfig="primary"
                    />
                )}
            </div>
        );
    }
}

export default CulinaryFeatureBasicCardListing;
