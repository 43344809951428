import React from 'react';

import CulinaryFeatureTabsPanelGroup from './culinary-feature-tabs-panel-group';

class CulinaryFeatureTabs extends React.Component {
    constructor(props) {
        super(props);

        this.onClickHandler = (event) => this.onClickTab(event);

        this.state = {
            activeTabIndex: 0
        };
    }

    componentDidMount() {
        const {rowId, columnId, data} = this.props;

        if (rowId !== undefined && columnId !== undefined) {
            let isValid = false;

            if (data && data.length > 0) {
                isValid = true;
            }

            this.props.actions.pageBuilderSetValid({
                columnId,
                isValid,
                rowId
            });
        }
    }

    getComponentClass() {
        const baseClass = 'culinary-feature-tabs';

        return `${baseClass} ${baseClass}-${this.props.layoutType}`;
    }

    onClickTab(event) {
        const tabIndex = Number.parseInt(event.currentTarget.dataset.tabIndex);

        if (tabIndex !== this.state.activeTabIndex) {
            this.setState({
                activeTabIndex: tabIndex
            });
        }
    }

    getButtonStateClass(index) {
        return this.state.activeTabIndex === index ? 'button button-selected' : 'button';
    }

    render() {
        return (
            <div className={this.getComponentClass()}>
                <ul className="tabs">
                    {this.props.data.map((tab, index) => (
                        <li className="item" key={index}>
                            <button
                                className={this.getButtonStateClass(index)}
                                data-tab-index={index}
                                onClick={this.onClickHandler}
                                type="button"
                            >
                                {tab.tabText}
                            </button>
                        </li>
                    ))}
                </ul>
                <CulinaryFeatureTabsPanelGroup
                    actions={this.props.actions}
                    activeTabIndex={this.state.activeTabIndex}
                    alertReducer={this.props.alertReducer}
                    favoritesReducer={this.props.favoritesReducer}
                    isAuthenticated={this.props.isAuthenticated}
                    layoutType={this.props.layoutType}
                    modalReducer={this.props.modalReducer}
                    onClickHandler={this.onClickHandler}
                    tabs={this.props.data}
                />
            </div>
        );
    }
}

export default CulinaryFeatureTabs;
