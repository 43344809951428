import React from 'react';

class CulinaryFeatureCardGuideBackground extends React.Component {
    constructor(props) {
        super(props);

        this.onImageLoadHandler = () => this.onImageLoad();
        this.backgroundData = this.getBackgroundData();

        this.backgroundColorStyle = {};
        this.backgroundImageStyle = {};

        const {backgroundColor, backgroundImage} = this.backgroundData;

        if (backgroundColor) {
            this.backgroundColorStyle.backgroundColor = `#${backgroundColor}`;
        }

        if (backgroundImage) {
            this.backgroundImageStyle.backgroundImage = `url(${backgroundImage.cropUrl}&width=900&height=900)`;
        }

        this.state = {
            imageLoaded: false
        };
    }

    componentDidMount() {
        if (this.backgroundImage && this.backgroundImage.complete) {
            this.onImageLoad();
        }
    }

    onImageLoad() {
        this.setState({
            imageLoaded: true
        });
    }

    getBackgroundData() {
        const {cardGuide, isWide} = this.props;
        const {backgroundColor, backgroundImage, backgroundImageLarge} = cardGuide;

        return {
            backgroundColor,
            backgroundImage: isWide && backgroundImageLarge ? backgroundImageLarge : backgroundImage
        };
    }

    render() {
        const {backgroundImage} = this.backgroundData;

        return (
            <div className="culinary-feature-card-guide-background" style={this.backgroundColorStyle}>
                <div
                    className={`background${this.state.imageLoaded ? ' background-loaded' : ''}`}
                    style={this.backgroundImageStyle}
                />
                {backgroundImage && (
                    <img
                        alt={backgroundImage.alt}
                        className="background-image"
                        onLoad={this.onImageLoadHandler}
                        ref={(node) => {
                            this.backgroundImage = node;
                        }}
                        src={backgroundImage.url}
                    />
                )}
            </div>
        );
    }
}

export default CulinaryFeatureCardGuideBackground;
