import React from 'react';
import {Link} from 'react-router';

import CulinaryFeatureCardCarousel from '../cards/culinary-feature-card-carousel';

const CulinaryFeatureRelatedGuide = ({
    actions,
    alertReducer,
    carouselState,
    isAuthenticated,
    isRecipeDetail,
    modalReducer,
    favoritesReducer,
    relatedGuideContent
}) => {
    if (!relatedGuideContent || !relatedGuideContent.references || !carouselState) {
        return null;
    }

    const headingIntro = isRecipeDetail ? 'More' : 'More ideas you may like';

    return (
        <section className="culinary-feature-related-guide">
            <h4 className="section-header">
                {`${headingIntro} from the `}
                {relatedGuideContent.title && <Link to={relatedGuideContent.url}>{relatedGuideContent.title}</Link>}
                {' food guide'}
            </h4>
            <CulinaryFeatureCardCarousel
                actions={actions}
                alertReducer={alertReducer}
                cardList={relatedGuideContent.references}
                carouselState={carouselState}
                favoritesReducer={favoritesReducer}
                id="carousel-feature-related-guide"
                isAuthenticated={isAuthenticated}
                modalReducer={modalReducer}
                styleConfig="secondary"
            />
        </section>
    );
};

export default CulinaryFeatureRelatedGuide;
