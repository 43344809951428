import React from 'react';
import uuid from 'uuid';
import debounce from 'lodash.debounce';

import {getYouTubePlayer} from '../../../../services/youtube-iframe-api-service';

class FeaturePrimaryVideoPlayer extends React.Component {
    constructor(props) {
        super(props);

        this.uuid = uuid.v4();
        this.videoIdentifier = `${this.props.videoId}${this.uuid}`;
        this.onResizeWindowHandler = debounce((event) => this.onResizeWindow(event), 250);
        this.body = null;
    }

    componentDidMount() {
        this.loadYouTubeVideo();

        this.props.setActiveVideoId(this.videoIdentifier);
        global.window.addEventListener('resize', this.onResizeWindowHandler);
        this.body = global.document.querySelector('body');
    }

    componentDidUpdate() {
        if (this.player && this.props.activeVideoId !== this.videoIdentifier) {
            this.pause();
        }
    }

    componentWillUnmount() {
        if (this.player) {
            this.player.destroy();
            this.player = null;
        }

        global.window.removeEventListener('resize', this.onResizeWindowHandler);
    }

    async loadYouTubeVideo() {
        this.player = await getYouTubePlayer(this.props.videoId, this.uuid, this.onPlayerReady);
        this.player.addEventListener('onStateChange', this.onStateChange);
    }

    onPlayerReady = () => {
        this.play();
    };

    onStateChange = (event) => {
        if (event.data === 1) {
            this.props.setActiveVideoId(this.videoIdentifier);
        }
    };

    onResizeWindow() {
        const videoIFrameDimensions = this.player.getIframe().getBoundingClientRect();

        if (
            videoIFrameDimensions.width === global.window.innerWidth &&
            videoIFrameDimensions.height === global.window.innerHeight
        ) {
            this.body.classList.add('is-fullscreen');
        } else {
            this.body.classList.remove('is-fullscreen');
        }
    }

    play() {
        this.player.playVideo();
    }

    pause() {
        this.player.pauseVideo();
    }

    render() {
        return (
            <div className="player">
                <div id={this.videoIdentifier} />
            </div>
        );
    }
}

export default FeaturePrimaryVideoPlayer;
