import React from 'react';

import SwapLink from '../../global/hoc/swap-link';

import FeatureFlexibleListItem from './feature-flexible-list-item';

class FeatureFlexibleList extends React.Component {
    componentDidMount() {
        const {rowId, columnId, data} = this.props;

        if (rowId !== undefined && columnId !== undefined) {
            let isValid = false;

            if (data && data.list && data.list.length > 0) {
                isValid = true;
            }

            this.props.actions.pageBuilderSetValid({
                columnId,
                isValid,
                rowId
            });
        }
    }

    render() {
        const {data, layoutType} = this.props;

        if (!data || !data.list || !data.list.length) {
            return null;
        }

        const imageClass = data.list[0].image ? 'has-images' : 'no-images';
        const listTypeClass = data.ordered ? 'ordered' : 'unordered';

        return (
            <div className={`feature-flexible-list ${listTypeClass} ${layoutType} ${imageClass}`}>
                {data.heading && <h3 className="header">{data.heading}</h3>}
                {!data.ordered && (
                    <ul className={`list unordered ${imageClass}`}>
                        {data.list.map((listItem, index) => (
                            <FeatureFlexibleListItem
                                classes={`${imageClass}`}
                                key={index}
                                layoutType={layoutType}
                                listItem={listItem}
                                listItemType={'unordered'}
                            />
                        ))}
                    </ul>
                )}

                {data.ordered && (
                    <ol className="list ordered">
                        {data.list.map((listItem, index) => (
                            <FeatureFlexibleListItem
                                classes={`${imageClass}`}
                                key={index}
                                layoutType={layoutType}
                                listItem={listItem}
                                listItemType={'ordered'}
                            />
                        ))}
                    </ol>
                )}
                {data.link && (
                    <SwapLink className={'link'} href={data.link.url} target={data.link.target}>
                        {data.link.title}
                    </SwapLink>
                )}
            </div>
        );
    }
}

export default FeatureFlexibleList;
