const nutritionKeyMap = {
    Calories: 'calories',
    Cholesterol: 'cholesterolContent',
    'Dietary Fiber': 'fiberContent',
    Protein: 'proteinContent',
    'Saturated Fat': 'saturatedFatContent',
    Sodium: 'sodiumContent',
    'Total Carbohydrates': 'carbohydrateContent',
    'Total Fat': 'fatContent',
    'Total Sugars': 'sugarContent',
    'Trans Fat': 'transFatContent'
};

export default nutritionKeyMap;
