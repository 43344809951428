import React from 'react';

import CulinaryFeatureCard from '../cards/culinary-feature-card';
import CulinaryFeatureCardInfo from '../cards/culinary-feature-card-info';
import CulinaryFeatureExpertTip from '../culinary-feature-expert-tip';

class CulinaryFeatureFeaturedIngredientsPanel extends React.Component {
    constructor(props) {
        super(props);

        this.mediaMarkup = null;
        this.relatedContentMarkup = null;
        this.supplementaryContentMarkup = null;
        this.selected = this.props.selectedTabIndex === this.props.index;
        this.activeClass = this.selected ? ' active' : '';
    }

    ariaHidden(selected) {
        return !selected;
    }

    getMediaMarkup() {
        if (this.props.ingredient.contentImage) {
            return (
                <div className="media">
                    <img
                        alt={this.props.ingredient.contentImage.alt}
                        className="image"
                        src={`${this.props.ingredient.contentImage.cropUrl}&width=400`}
                    />
                </div>
            );
        }

        return null;
    }

    getRelatedContentMarkup() {
        if (this.props.ingredient.inlineReference) {
            return (
                <div className="footer">
                    <CulinaryFeatureCard
                        actions={this.props.actions}
                        alertReducer={this.props.alertReducer}
                        card={this.props.ingredient.inlineReference}
                        favoritesReducer={this.props.favoritesReducer}
                        id={`featured-ingredients-inline-${this.props.index}`}
                        isAuthenticated={this.props.isAuthenticated}
                        layoutType="inline"
                        modalReducer={this.props.modalReducer}
                    />
                </div>
            );
        }

        return null;
    }

    getSupplementaryContentMarkup() {
        if (this.props.ingredient.contentReference) {
            return (
                <aside className="supplementary">
                    <span className="heading" />
                    <CulinaryFeatureCard
                        actions={this.props.actions}
                        alertReducer={this.props.alertReducer}
                        card={this.props.ingredient.contentReference}
                        favoritesReducer={this.props.favoritesReducer}
                        id={`featured-ingredients-${this.props.index}`}
                        isAuthenticated={this.props.isAuthenticated}
                        modalReducer={this.props.modalReducer}
                    />
                </aside>
            );
        }

        if (this.props.ingredient.expertTip) {
            return (
                <aside className="supplementary">
                    <span className="heading" />
                    <CulinaryFeatureExpertTip data={this.props.ingredient.expertTip} layoutType="feature-stacked" />
                </aside>
            );
        }

        if (this.props.ingredient.funFact) {
            return (
                <aside className="supplementary">
                    <span className="heading" />
                    <CulinaryFeatureCardInfo data={this.props.ingredient.funFact} />
                </aside>
            );
        }

        if (this.props.ingredient.richList) {
            return (
                <aside className="supplementary">
                    <span className="heading" />
                    <CulinaryFeatureCardInfo data={this.props.ingredient.richList} />
                </aside>
            );
        }

        return null;
    }

    render() {
        const baseClass = 'culinary-feature-featured-ingredients-panel';

        this.selected = this.props.selectedTabIndex === this.props.index;
        this.activeClass = this.selected ? ` ${baseClass}-active` : '';
        this.withMediaClass = !this.props.ingredient.contentImage ? ` ${baseClass}-no-media` : '';

        return (
            <section
                aria-hidden={this.ariaHidden(this.selected)}
                aria-labelledby={`panel${this.props.index}-tab`}
                className={`${baseClass}${this.activeClass}${this.withMediaClass}`}
                id={`panel${this.props.index}`}
                role="tabpanel"
            >
                <div className="inner">
                    <h4 className="heading">{this.props.ingredient.heading}</h4>
                    {this.getMediaMarkup()}
                    <div className="content">
                        <div className="primary">
                            <div className="header">{this.props.ingredient.heading}</div>
                            <div
                                className="description"
                                /* eslint-disable-next-line react/no-danger */
                                dangerouslySetInnerHTML={{__html: this.props.ingredient.body}}
                            />
                            {this.getRelatedContentMarkup()}
                        </div>
                        {this.getSupplementaryContentMarkup()}
                    </div>
                </div>
            </section>
        );
    }
}

export default CulinaryFeatureFeaturedIngredientsPanel;
