import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import * as ActionCreators from '../action-creators/root-actions';

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(ActionCreators, dispatch)
    };
}

function mapStateToProps(state) {
    return state;
}

export function createReduxHigherOrderComponent(component) {
    return connect(mapStateToProps, mapDispatchToProps)(component);
}
