import React from 'react';

import ComponentFactory from '../../factories/component-factory';

import PageBuilderRow from './page-builder-row';

class PageBuilder extends React.Component {
    componentWillMount() {
        this.props.actions.pageBuilderClearValid();
    }

    checkRowForValidColumn(rowId) {
        let hasValidColumn = null;

        const currentRow = this.props.pageBuilderReducer.pageBuilderRows[rowId];

        if (currentRow) {
            Object.keys(currentRow).forEach((column) => {
                if (hasValidColumn === true && currentRow[column] === false) {
                    hasValidColumn = true;
                } else {
                    hasValidColumn = currentRow[column];
                }
            });
        }

        return hasValidColumn;
    }

    makeRow(row, rowIndex) {
        const isValidRow = this.checkRowForValidColumn(rowIndex);

        if (isValidRow === false) {
            return null;
        }

        return (
            <PageBuilderRow key={rowIndex} {...row}>
                {row.columns.map((column, index) => this.makeColumn(column, index, rowIndex))}
            </PageBuilderRow>
        );
    }

    makeColumn(column, columnIndex, rowIndex) {
        const columnId = `${rowIndex}-${columnIndex}`;

        return (
            <div className={`column column-${column.width}of12`} key={columnIndex}>
                {this.makeComponent(column, rowIndex, columnId)}
            </div>
        );
    }

    makeComponent(column, rowId, columnId) {
        return (
            <ComponentFactory
                columnId={columnId}
                component={column.component}
                rowId={rowId}
                templateData={this.props}
                width={column.width}
            />
        );
    }

    render() {
        const rows = this.props.grid.rows;

        return <div className="page-builder">{rows && rows.map((row, index) => this.makeRow(row, index))}</div>;
    }
}

export default PageBuilder;
