import React from 'react';
import debounce from 'lodash.debounce';
import {checkElementsInViewport} from 'react-lazy';

import {getBreakpoint} from '../../../../../services/breakpoint-service';

import CulinaryFeatureCardCarouselNavigation from './culinary-feature-card-carousel-navigation';
import CulinaryFeatureCardList from './culinary-feature-card-list';

const DURATION = 500;

const ROOT_CLASS = 'culinary-feature-card-carousel';

/* eslint-disable sort-keys */
const CAROUSEL_MARGIN = {
    base: 0,
    xs: 0,
    sm: 0,
    md: 50,
    lg: 70,
    xl: 100
};

const CARD_COUNT = {
    base: 2,
    xs: 3,
    sm: 3,
    md: 4,
    lg: 4,
    xl: 4
};
/* eslint-enable sort-keys */

class CulinaryFeatureCardCarousel extends React.Component {
    constructor(props) {
        super(props);

        this.blockWidth = null;
        this.carouselState = [];
        this.id = '';
        this.isAnimating = false;
        this.itemCount = null;
        this.maxScrollPosition = 0;
        this.scrollAmount = 0;
        this.timeoutHandler = 0;

        this.state = {
            scrollPosition: 0
        };

        this.setupHandlers();
    }

    componentDidMount() {
        require('velocity-animate');
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.carouselState && nextProps.carouselState[this.id] !== undefined) {
            const nextBlockWidth = nextProps.carouselState[this.id].blockWidth;

            if (nextBlockWidth !== this.blockWidth) {
                this.blockWidth = nextBlockWidth;

                this.updateCarousel();
            }
        }
    }

    setupHandlers() {
        this.onScrollContainerHandler = debounce((event) => this.onScrollContainer(event), 250);
        this.onClickNextHandler = (event) => this.onClickNext(event);
        this.onClickPreviousHandler = (event) => this.onClickPrevious(event);
    }

    setScrollPosition() {
        if (this.isAnimating) {
            return;
        }

        const currentPosition = this.carouselContainer.scrollLeft;

        this.setState({
            scrollPosition: currentPosition
        });
    }

    updateCarousel() {
        const breakpoint = getBreakpoint();
        const carouselWidth = this.carouselContainer.getBoundingClientRect().width;
        const carouselMargin = CAROUSEL_MARGIN[breakpoint];
        const listWidth = this.blockWidth * this.getListLength() + carouselMargin;

        this.scrollAmount = this.blockWidth * CARD_COUNT[breakpoint];
        this.maxScrollPosition = listWidth - carouselWidth;
    }

    advanceCarousel() {
        this.animateCarousel(false);
    }

    rewindCarousel() {
        this.animateCarousel(true);
    }

    animateCarousel(isReverse) {
        let offset = this.scrollAmount;

        if (isReverse) {
            offset = offset * -1;
        }

        /* eslint-disable-next-line no-undef, new-cap */
        Velocity(this.carouselContainer, 'scroll', {
            axis: 'x',
            container: this.carouselContainer,
            duration: DURATION,
            offset: `${offset}px`
        });

        this.isAnimating = true;

        clearTimeout(this.timeoutHandler);

        this.timeoutHandler = setTimeout(() => {
            this.isAnimating = false;
            this.setScrollPosition();
        }, DURATION);
    }

    checkDisableClickNext() {
        return this.state.scrollPosition >= this.maxScrollPosition;
    }

    checkDisableClickPrevious() {
        return this.state.scrollPosition <= 0;
    }

    onClickNext() {
        this.advanceCarousel();
    }

    onClickPrevious() {
        this.rewindCarousel();
    }

    onScrollContainer() {
        this.setScrollPosition();
        checkElementsInViewport();
    }

    getListLength() {
        const cardData = this.props.cardList;

        if (!cardData) {
            return 0;
        }

        if (cardData.cards && cardData.cards.length > 0) {
            return cardData.cards.length;
        } else if (cardData && cardData.length > 0) {
            return cardData.length;
        }

        return 0;
    }

    render() {
        const listLength = this.getListLength();
        const {cardList, id, styleConfig} = this.props;
        let rootClasses = ROOT_CLASS;

        if (!cardList || listLength === 0 || !this.props.carouselState) {
            return null;
        }

        if (styleConfig) {
            rootClasses = `${ROOT_CLASS} ${styleConfig}`;
        }

        this.itemCount = listLength;
        this.carouselState = this.props.carouselState;
        this.id = id;

        return (
            <div className={rootClasses}>
                <CulinaryFeatureCardCarouselNavigation
                    disableClickNext={this.checkDisableClickNext()}
                    disableClickPrevious={this.checkDisableClickPrevious()}
                    onClickNext={this.onClickNextHandler}
                    onClickPrevious={this.onClickPreviousHandler}
                />
                <div className="container">
                    <div
                        className="scroll-container"
                        onScroll={this.onScrollContainerHandler}
                        ref={(carouselContainer) => {
                            this.carouselContainer = carouselContainer;
                        }}
                    >
                        <CulinaryFeatureCardList
                            actions={this.props.actions}
                            alertReducer={this.props.alertReducer}
                            cardList={this.props.cardList}
                            favoritesReducer={this.props.favoritesReducer}
                            id={this.props.id}
                            isAuthenticated={this.props.isAuthenticated}
                            isMobileOnlyCarousel={this.props.isOptionalCarousel && listLength <= 4}
                            modalReducer={this.props.modalReducer}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default CulinaryFeatureCardCarousel;
