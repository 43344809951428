import React from 'react';

import SwapLink from '../../../global/hoc/swap-link';

/* eslint-disable react/no-danger */

class CulinaryFeatureFullWidthIntro extends React.Component {
    componentDidMount() {
        const {rowId, columnId, data} = this.props;

        if (rowId !== undefined && columnId !== undefined) {
            let isValid = false;

            if (data && (data.heading || data.description)) {
                isValid = true;
            }

            this.props.actions.pageBuilderSetValid({
                columnId,
                isValid,
                rowId
            });
        }
    }

    render() {
        const data = this.props.data;

        let styles = {},
            ctaMarkup = null;

        if (data.backgroundImage) {
            styles = {
                backgroundImage: `url(${data.backgroundImage.url})`
            };
        }

        if (data.ctaLink) {
            ctaMarkup = (
                <SwapLink className="content-link" href={data.ctaLink.url} target={data.ctaLink.target}>
                    {data.ctaLink.title}
                </SwapLink>
            );
        }

        return (
            <div className="culinary-feature-full-width-intro">
                <div className="background" style={styles} />
                <div className="intro-content">
                    <h4 className="heading">
                        <span className="content-header">{data.heading}</span>
                    </h4>
                    <div className="content-body" dangerouslySetInnerHTML={{__html: data.description}} />
                    {ctaMarkup}
                </div>
            </div>
        );
    }
}
/* eslint-enable react/no-danger */

export default CulinaryFeatureFullWidthIntro;
