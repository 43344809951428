import React from 'react';
import {Link} from 'react-router';
import Highlighter from 'react-highlight-words';

const FeatureSearchAutocomplete = ({
    autocompleteResults,
    isActive,
    searchTerm,
    selectedAutocompleteResultIndex,
    showLabel
}) => (
    <ul
        className={`feature-search-autocomplete${isActive ? ' feature-search-autocomplete-active' : ''}${
            showLabel ? ' feature-search-autocomplete-label' : ''
        }`}
    >
        {autocompleteResults.map((autocompleteResult, autocompleteResultIndex) => (
            <li
                className={`${selectedAutocompleteResultIndex === autocompleteResultIndex ? 'active' : ''}`}
                key={autocompleteResultIndex}
            >
                <Link to={autocompleteResult.url}>
                    <Highlighter
                        highlightClassName={'highlight'}
                        searchWords={searchTerm.split(' ')}
                        textToHighlight={autocompleteResult.title}
                    />
                </Link>
            </li>
        ))}
    </ul>
);

export default FeatureSearchAutocomplete;
