import React from 'react';
import {Link} from 'react-router';

import {getVideoMetadata} from '../../../../services/youtube-data-api-service';
import CulinaryFavoriteBtn from '../favorite/culinary-favorite-btn';
import IconVideo from '../../../global/icon/icon-video';

class CulinaryFeatureSplitContent extends React.Component {
    constructor(props) {
        super(props);

        this.recipes = [];

        this.state = {
            mediaUrl0: '',
            mediaUrl1: ''
        };
    }

    componentDidMount() {
        const {rowId, columnId, data} = this.props;

        if (rowId !== undefined && columnId !== undefined) {
            let isValid = false;

            if (data && (data.firstRecipe || data.secondRecipe)) {
                isValid = true;
            }

            this.props.actions.pageBuilderSetValid({
                columnId,
                isValid,
                rowId
            });
        }

        if (this.props.data) {
            if (this.props.data.firstRecipe) {
                this.recipes.push(this.props.data.firstRecipe);
            }

            if (this.props.data.secondRecipe && this.props.layoutType === 'full') {
                this.recipes.push(this.props.data.secondRecipe);
            }

            this.setImage();
        }
    }

    setImage() {
        this.recipes.forEach((recipe, index) => {
            const media = recipe.media;

            if (media.type === 'Video' && !media.previewImageUrl) {
                getVideoMetadata(media.videoId).then((mediaUrl) => {
                    this.setState({
                        [`mediaUrl${index}`]: mediaUrl.fallbackThumbnailUrl
                    });
                });
            } else if (media.type === 'Image') {
                this.setState({
                    [`mediaUrl${index}`]: media.url
                });
            } else {
                this.setState({
                    [`mediaUrl${index}`]: media.previewImageUrl
                });
            }
        });
    }

    createBackgroundImage(index) {
        return {
            backgroundImage: `url('${this.state[`mediaUrl${index}`]}')`
        };
    }

    render() {
        if (!this.props.data) {
            return null;
        }

        return (
            <section className="culinary-feature-split-content">
                {this.recipes.map((recipe, index) => (
                    <div
                        className={`container ${this.props.layoutType === 'full' ? '' : 'half'}`}
                        key={recipe.contentId}
                    >
                        <div className="background" style={this.createBackgroundImage(index)}>
                            {recipe.media !== null && recipe.media.type === 'Video' && <IconVideo hover={false} />}
                        </div>
                        <div className="content">
                            <h4 className="title">{recipe.title}</h4>
                            <div className="cook-time">{recipe.cookTime}</div>
                            <div className="cta">
                                <Link className="button" to={recipe.url}>
                                    {'View Recipe'}
                                </Link>
                                <CulinaryFavoriteBtn
                                    actions={this.props.actions}
                                    alertReducer={this.props.alertReducer}
                                    favoritesReducer={this.props.favoritesReducer}
                                    id={recipe.contentId}
                                    isAuthenticated={this.props.isAuthenticated}
                                    modalReducer={this.props.modalReducer}
                                    modalTriggerId={`split-content-favorite-button-${recipe.contentId}`}
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </section>
        );
    }
}

export default CulinaryFeatureSplitContent;
