import React from 'react';

import CulinaryFeatureGuideHeroSectionsBackground from './culinary-feature-guide-hero-sections-background';
import CulinaryFeatureGuideHeroSectionsHeader from './culinary-feature-guide-hero-sections-header';
import CulinaryFeatureGuideHeroSectionsList from './culinary-feature-guide-hero-sections-list';
import CulinaryFeatureGuideHeroSectionsNavbar from './culinary-feature-guide-hero-sections-navbar';

class CulinaryFeatureGuideHeroSections extends React.Component {
    constructor(props) {
        super(props);

        this.dropLinks = this.props.dropLinks ? this.props.dropLinks : [];
        this.scrollTop = 0;
        this.isScrolledPastSubnav = false;

        if (this.props.content.hero && this.props.content.hero.backgroundImage1) {
            this.style = {
                image1: {
                    backgroundImage: `url(${this.props.content.hero.backgroundImage1.cropUrl})`
                }
            };
        }

        this.onScrollHandler = () => this.onScroll();

        this.state = {
            isScrolled: false
        };
    }

    componentDidMount() {
        global.addEventListener('scroll', this.onScrollHandler);
    }

    componentWillUnmount() {
        global.removeEventListener('scroll', this.onScrollHandler);
    }

    posTop() {
        let scrollPosition = 0;

        if (global.window.pageYOffset !== undefined) {
            scrollPosition = global.window.pageYOffset;
        }

        if (global.document.documentElement.scrollTop) {
            scrollPosition = global.document.documentElement.scrollTop;
        }

        if (global.document.body.scrollTop) {
            scrollPosition = global.document.body.scrollTop;
        }

        return scrollPosition;
    }

    onScroll() {
        const scrollTop = this.posTop();
        const scrolled = scrollTop > 10;

        if (this.state.isScrolled !== scrolled) {
            this.setState({
                isScrolled: scrolled
            });
        }
    }

    getComponentClass() {
        const baseClass = 'culinary-feature-guide-hero-sections';

        return this.state.isScrolled ? `${baseClass} is-scrolled` : baseClass;
    }

    render() {
        return (
            <div className={this.getComponentClass()}>
                <CulinaryFeatureGuideHeroSectionsBackground {...this.props} />
                <div className="container">
                    <div className="intro">
                        <CulinaryFeatureGuideHeroSectionsHeader {...this.props} />
                    </div>
                    <div className="links">
                        <CulinaryFeatureGuideHeroSectionsList
                            dropLinks={this.dropLinks}
                            isScrolled={this.state.isScrolled}
                        />
                    </div>
                </div>
                <div className="lattice" />
                <CulinaryFeatureGuideHeroSectionsNavbar
                    content={this.props.content}
                    currentSectionIndex={this.props.currentSectionIndex}
                    dropLinks={this.dropLinks}
                    isScrolled={this.state.isScrolled}
                    showNavBar={this.props.inPageBuidlerBody}
                />
            </div>
        );
    }
}

export default CulinaryFeatureGuideHeroSections;
