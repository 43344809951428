import React from 'react';
import {Link} from 'react-router';

const CulinaryRelatedTags = ({featuredTags, label}) => {
    if (!featuredTags) {
        return null;
    }

    return (
        <div className="culinary-related-tags">
            {label && <div className="label">{`${label}:`}</div>}
            <ul className="list">
                {featuredTags.map((tag) => (
                    <li key={tag.title}>
                        <Link className="tag-link" to={tag.url}>
                            {tag.title}
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default CulinaryRelatedTags;
