import React from 'react';

import FeatureWysiwyg from '../../../feature/feature-wysiwyg';
import SwapLink from '../../../global/hoc/swap-link';

const CulinaryFeatureMealIdeasPromo = ({mealIdeasPromo}) => {
    if (!mealIdeasPromo) {
        return null;
    }

    const {leftImage, rightImage, ctaLink} = mealIdeasPromo;

    return (
        <section className="culinary-feature-meal-ideas-promo">
            <h3 className="header">{`Learn about ${mealIdeasPromo.heading}`}</h3>
            {leftImage && (
                <img
                    alt={leftImage.alt ? leftImage.alt : ''}
                    className="image-one"
                    src={`${leftImage.cropUrl}&width=450`}
                    width="307"
                />
            )}
            <div className="content">
                <div className="heading">
                    {mealIdeasPromo.heading}
                    {mealIdeasPromo.indicateAsNew && <span className="new">{'New'}</span>}
                </div>
                <div className="description">
                    <FeatureWysiwyg data={mealIdeasPromo.description} />
                </div>
                <SwapLink className={'button'} href={ctaLink.url} target={ctaLink.target}>
                    {ctaLink.title}
                </SwapLink>
            </div>
            {rightImage && (
                <img
                    alt={rightImage.alt ? rightImage.alt : ''}
                    className="image-two"
                    src={`${rightImage.cropUrl}&width=450`}
                    width="263"
                />
            )}
        </section>
    );
};

export default CulinaryFeatureMealIdeasPromo;
