import React from 'react';

import CulinaryCardBlocks from '../modules/culinary-card-blocks';
import CulinaryFeatureEditorialBlock from '../modules/feature/culinary-feature-editorial-block';
import CulinaryFeatureRelatedFooter from '../modules/feature/related-content/culinary-feature-related-footer';
import CulinaryLoadingRecipe from '../modules/loading/culinary-loading-recipe';
import CulinarySearchActiveRefinementList from '../modules/search/culinary-search-active-refinement-list';
import CulinarySearchFilterBar from '../modules/search/culinary-search-filter-bar';
import CulinarySearchFilters from '../modules/search/culinary-search-filters';
import CulinarySearchHero from '../modules/search/culinary-search-hero';
import CulinarySearchInfoBar from '../modules/search/culinary-search-info-bar';
import CulinarySearchNoResults from '../modules/search/culinary-search-no-results';
import CulinarySearchRefinementGroups from '../modules/search/culinary-search-refinement-groups';
import CulinarySearchRefinementList from '../modules/search/culinary-search-refinement-list';
import recipeSearchSortByMap from '../../../constants/recipe-search-sort-by-map';
import withMountAnimation from '../../global/hoc/with-mount-animation';
import CulinarySearchViewMoreBtn from '../modules/search/culinary-search-view-more-btn';
import CulinaryFeatureRelatedContent from '../modules/feature/related-content/culinary-feature-related-content';
import ApiQueryService from '../../../services/api/api-query-service';

const CulinaryCardBlocksWithMountAnimation = withMountAnimation(CulinaryCardBlocks);

class CulinaryRecipesTemplate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            searchQuery: {}
        };

        this.lastLocationQuery = null;

        this.hasSearchQuery = ApiQueryService.hasSearchQuery(this.props);
    }

    componentWillMount() {
        const dropdowns = this.props.contentReducer.urlContent.content.dropdowns;

        if (dropdowns) {
            dropdowns.forEach((dropdown) => this.props.actions.addFilterGroup({fieldName: dropdown.fieldName}));
        }
    }

    componentWillReceiveProps(nextProps) {
        const location = nextProps.router.location;
        const dropdowns = nextProps.contentReducer.urlContent.content.dropdowns;
        const isViewMore = nextProps.searchReducer.currentPage > 0;
        const isDifferentSearch = location.search !== this.state.searchQuery;
        const defaultSortBy = location.query && location.query.q ? 'relevance' : 'newest';

        const locationQuery = {
            currentPage: nextProps.searchReducer.currentPage,
            pageSize: nextProps.searchReducer.pageSize,
            sortBy: nextProps.searchReducer.sortBy ? nextProps.searchReducer.sortBy : defaultSortBy,
            ...location.query
        };

        if (isDifferentSearch && !isViewMore) {
            this.props.actions.resetSearch();
            this.lastLocationQuery = null;
        } else if (locationQuery.toString() !== this.lastLocationQuery) {
            this.lastLocationQuery = locationQuery.toString();

            if (Object.keys(nextProps.searchReducer.filters).length === 0) {
                dropdowns.forEach((dropdown) => nextProps.actions.addFilterGroup({fieldName: dropdown.fieldName}));
            }

            this.hasSearchQuery = ApiQueryService.hasSearchQuery(nextProps);

            nextProps.actions.syncSearchQueryWithStore(nextProps.router.location.query);

            if (this.hasSearchQuery) {
                nextProps.actions.setSearchQuery(
                    locationQuery,
                    this.props.environmentConfigReducer.cms.searchApiUrl,
                    isViewMore
                );
            }
        }

        const {coursetype} = location.query;
        const {tag, refinement} = nextProps.searchReducer.metaData;

        this.activeRefinements = [...dropdowns];

        if (tag) {
            this.activeRefinements.push({
                fieldName: 'tagId',
                options: [
                    {
                        color: tag.badgeColor,
                        id: tag.id,
                        title: tag.title
                    }
                ]
            });
        }

        if (refinement) {
            this.activeRefinements.push({
                fieldName: 'refinementId',
                options: [
                    {
                        id: refinement.id,
                        title: refinement.title
                    }
                ]
            });
        }

        if (coursetype) {
            this.activeRefinements.push({
                fieldName: 'coursetype',
                options: [
                    {
                        id: coursetype,
                        title: coursetype
                    }
                ]
            });
        }

        this.setState({searchQuery: location.search});
    }

    componentWillUnmount() {
        this.props.actions.resetSearch();
    }

    render() {
        const content = this.props.contentReducer.urlContent.content;

        const {
            actions,
            alertReducer,
            autocompleteReducer,
            carouselReducer,
            environmentConfigReducer,
            isAuthenticated,
            location,
            modalReducer,
            favoritesReducer,
            renderReducer,
            router,
            searchReducer
        } = this.props;

        const shouldDisplayViewMoreButton = searchReducer.totalResultCount !== searchReducer.displayResults.length;
        const focusIndex = searchReducer.pageSize * searchReducer.currentPage;
        const animationDelayOffset = 140;
        const animationTransitionDuration = 700;
        const animationTransitionTimeout =
            animationDelayOffset * searchReducer.displayResults.length + animationTransitionDuration;
        const relatedGuidesAndHowTos = this.props.searchReducer.relatedGuidesAndHowTos;
        const showRefinementGroups =
            !this.hasSearchQuery ||
            (!searchReducer.searchIsLoading && this.hasSearchQuery && searchReducer.totalResultCount === 0);

        return (
            <main className="culinary-recipes-template">
                {content.primaryMedia && (
                    <CulinarySearchHero
                        autocompleteResults={autocompleteReducer.results}
                        clearAutocompleteResults={actions.clearAutocompleteResults}
                        hasSearchQuery={this.hasSearchQuery}
                        isServerSide={renderReducer.isServerSide}
                        media={content.primaryMedia}
                        router={router}
                        searchTerm={location.query.q}
                        setQueryString={actions.setQueryString}
                    />
                )}
                {content.dropdowns && (
                    <CulinarySearchFilters>
                        <CulinarySearchFilterBar
                            addFilter={actions.addFilter}
                            dropdowns={content.dropdowns}
                            filters={searchReducer.filters}
                            removeFilter={actions.removeFilter}
                        />
                    </CulinarySearchFilters>
                )}

                {content.dropdowns && (
                    <CulinarySearchActiveRefinementList
                        addFilter={actions.addFilter}
                        dropdowns={this.activeRefinements}
                        filters={searchReducer.filters}
                        hasSearchQuery={this.hasSearchQuery}
                        removeAllFilters={actions.removeAllFilters}
                        removeFilter={actions.removeFilter}
                    />
                )}
                <div className="page-content">
                    {!this.hasSearchQuery && <hr className="hr hr-hidden-mobile" />}

                    {!this.hasSearchQuery && (
                        <CulinarySearchRefinementList
                            addFilter={actions.addFilter}
                            data={content.featuredRefinements}
                            dropdowns={content.dropdowns}
                            filters={searchReducer.filters}
                            removeFilter={actions.removeFilter}
                            sortBy={searchReducer.sortBy}
                        />
                    )}
                    {!searchReducer.searchIsLoading && this.hasSearchQuery && searchReducer.totalResultCount > 0 && (
                        <CulinarySearchInfoBar
                            displayCount={searchReducer.displayResults.length}
                            isLoading={searchReducer.searchIsLoading}
                            searchTerm={location.query.q}
                            setSortBy={actions.setSortBy}
                            sortBy={searchReducer.sortBy}
                            sortByMap={recipeSearchSortByMap}
                            sortFilters={content.sortFilters}
                            totalResultCount={searchReducer.totalResultCount}
                        />
                    )}

                    {this.hasSearchQuery && searchReducer.searchIsLoading && searchReducer.currentPage === 0 && (
                        <CulinaryLoadingRecipe />
                    )}

                    {!searchReducer.searchIsLoading && this.hasSearchQuery && searchReducer.totalResultCount === 0 && (
                        <CulinarySearchNoResults
                            content={content.noSearchResultsContent}
                            heading={content.noSearchResultsHeading}
                            searchTerm={location.query.q}
                        />
                    )}

                    {!searchReducer.searchIsLoading && this.hasSearchQuery && searchReducer.displayResults.length > 0 && (
                        <CulinaryCardBlocksWithMountAnimation
                            actions={actions}
                            alertReducer={alertReducer}
                            cards={searchReducer.displayResults}
                            favoritesReducer={favoritesReducer}
                            focusIndex={focusIndex}
                            isAuthenticated={isAuthenticated}
                            modalReducer={modalReducer}
                            showRatings={searchReducer.sortBy === 'rating'}
                            withMountAnimationProps={{
                                offset: animationDelayOffset,
                                pageSize: searchReducer.pageSize,
                                transitionGroup: {
                                    appear: true,
                                    className: 'culinary-card-blocks culinary-card-blocks-search',
                                    classNames: {
                                        appear: 'kf-slide-in',
                                        enter: 'kf-slide-in'
                                    },
                                    component: 'div',
                                    enter: true,
                                    exit: false,
                                    timeout: animationTransitionTimeout
                                }
                            }}
                        />
                    )}
                </div>
                {showRefinementGroups && (
                    <CulinarySearchRefinementGroups
                        actions={actions}
                        alertReducer={alertReducer}
                        carouselReducer={carouselReducer}
                        content={content}
                        favoritesReducer={favoritesReducer}
                        focusIndex={focusIndex}
                        isAuthenticated={isAuthenticated}
                        modalReducer={modalReducer}
                    />
                )}
                {shouldDisplayViewMoreButton && (
                    <CulinarySearchViewMoreBtn
                        actions={this.props.actions}
                        endpoint={environmentConfigReducer.cms.searchApiUrl}
                        label={'Recipes'}
                        location={this.props.router.location}
                        searchReducer={searchReducer}
                    />
                )}
                <CulinaryFeatureRelatedFooter
                    environmentConfigReducer={this.props.environmentConfigReducer}
                    noChildren={
                        (!content.featuredEditorialContent && !relatedGuidesAndHowTos) ||
                        (!showRefinementGroups && !relatedGuidesAndHowTos) ||
                        (!showRefinementGroups && relatedGuidesAndHowTos && relatedGuidesAndHowTos.length === 0)
                    }
                    promoContent={content.promoContent}
                >
                    {!showRefinementGroups && (
                        <CulinaryFeatureRelatedContent
                            actions={this.props.actions}
                            alertReducer={this.props.alertReducer}
                            carouselState={this.props.carouselReducer.carousels}
                            favoritesReducer={this.props.favoritesReducer}
                            isAuthenticated={this.props.isAuthenticated}
                            modalReducer={this.props.modalReducer}
                            relatedContent={relatedGuidesAndHowTos}
                            searchReducer={searchReducer}
                            sectionTitle={'You might also like'}
                        />
                    )}
                    {showRefinementGroups && <CulinaryFeatureEditorialBlock data={content.featuredEditorialContent} />}
                </CulinaryFeatureRelatedFooter>
            </main>
        );
    }
}

export default CulinaryRecipesTemplate;
