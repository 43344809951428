import * as qs from 'qs';

class ApiQueryService {
    static toQueryString(obj) {
        return qs.stringify(obj, {arrayFormat: 'repeat'});
    }

    static queryStringToObject(str) {
        return qs.parse(str);
    }

    static removeQueryString(url) {
        return url.split('?')[0];
    }

    static removeTrailingSlash(url) {
        return url.replace(/\/$/u, '');
    }

    static hasSearchQuery(props) {
        /* remove l param from location query to prevent checksum error on login redirect */
        const {l, ...rest} = props.router.location.query;
        const queryKeys = Object.keys(rest);

        return queryKeys.length > 0;
    }
}

export default ApiQueryService;
