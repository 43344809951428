import logger from '@hy-vee/logger';

const crossFetch = require('cross-fetch');

export const fetch = (url, options) =>
    crossFetch(url, options).then((response) => {
        if (!response.ok) {
            logger.error(`Not ok response from ${url}`, {response});

            return Promise.reject(response);
        }

        return Promise.resolve(response);
    });

export const post = async (url, endpoint, payload) => {
    const fullUrl = `${url}${endpoint}`;

    const response = await crossFetch(fullUrl, {
        ...payload,
        method: 'POST'
    });

    if (!response.ok) {
        throw new Error('Post error');
    }

    return response.json();
};
