import React from 'react';
import {Link} from 'react-router';

import CulinaryFeatureCardMedia from './culinary-feature-card-media';

const scrollToTopOfPage = () => {
    setTimeout(() => {
        global.window.scrollTo(0, 0);
    }, 0);
};

const CulinaryFeatureEditorialBlock = ({data}) => {
    if (!data || (!data.featureOne && !data.featureTwo)) {
        return null;
    }

    const blocks = [data.featureOne, data.featureTwo].filter(Boolean);

    return (
        <div className="culinary-feature-editorial-block">
            {data.category && (
                <h3 className="heading-more">
                    {'More from '}
                    <Link className="link" to={data.categoryUrl}>
                        {data.category}
                    </Link>
                </h3>
            )}
            {data.heading && <h3 className="heading">{data.heading}</h3>}
            <div className="editorial-blocks">
                {blocks.map((block, index) => (
                    <div className="block" key={index}>
                        <CulinaryFeatureCardMedia content={block} showDescription />
                    </div>
                ))}
            </div>
            {data.editorialLandingUrl && (
                <div className="cta">
                    <Link className="cta-button" onClick={scrollToTopOfPage} to={data.editorialLandingUrl}>
                        {'View more'}
                    </Link>
                </div>
            )}
        </div>
    );
};

export default CulinaryFeatureEditorialBlock;
