import React from 'react';

const FOCUS_DELAY_FOR_ACCESSIBILITY = 250;

class SkipLink extends React.Component {
    constructor(props) {
        super(props);

        this.shouldFocus = false;
        this.timeoutHandler = 0;
    }

    componentWillReceiveProps(nextProps) {
        this.shouldFocus = nextProps.pathname !== this.props.pathname;
    }

    componentDidUpdate() {
        if (this.shouldFocus) {
            clearTimeout(this.timeoutHandler);
            this.timeoutHandler = setTimeout(() => {
                this.skipLink.focus();
                this.shouldFocus = false;
            }, FOCUS_DELAY_FOR_ACCESSIBILITY);
        }
    }

    render() {
        const title = this.props.title ? this.props.title : 'main';

        return (
            <a
                aria-label={`Skip to ${title} content`}
                className="skip-link"
                href="#main-content"
                ref={(skipLink) => {
                    this.skipLink = skipLink;
                }}
            >
                {'Skip to '}
                <span className="title">{`${title} `}</span>
                {'content'}
            </a>
        );
    }
}

export default SkipLink;
