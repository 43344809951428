import React from 'react';

import {sectionSlug} from '../../services/string-services';
import {fullWidthComponents} from '../../constants/page-builder-components';
import {initWaypoints, removeWaypoints} from '../../services/waypoint-animation-service';

import PageBuilderRowBackground from './page-builder-row-background';

class PageBuilderRow extends React.Component {
    constructor(props) {
        super(props);

        this.dropLinkId = this.props.dropLinkText ? sectionSlug(this.props.dropLinkText) : null;
        this.componentClass = this.getComponentClass();
    }

    componentDidMount() {
        if (this.props.backgroundImage) {
            initWaypoints();
        }
    }

    componentWillUnmount() {
        if (this.props.backgroundImage) {
            removeWaypoints();
        }
    }

    getComponentClass() {
        const row = this.props;
        const baseClass = 'page-builder-row';

        return `${baseClass}${this.hasBackground(row) ? ' has-background' : ''}${
            this.fullWidth(row) ? ' is-full-width' : ''
        }`;
    }

    hasBackground(row) {
        return row.backgroundImage || row.leftAnimationImage || row.rightAnimationImage;
    }

    fullWidth(row) {
        const alias = row.columns[0].component && row.columns[0].component.alias; // only need to check 1st one if full width component

        return fullWidthComponents.find((item) => item === alias && row.columns.length === 1) ? ' is-full-width' : '';
    }

    getBackgroundProps() {
        const hasAnimatedBackground =
            this.props.leftAnimationImage || (this.props.rightAnimationImage && this.props.animationStyle);
        const hasBackground =
            this.props.backgroundImage || this.props.leftAnimationImage || this.props.rightAnimationImage;

        return {
            hasAnimatedBackground,
            hasBackground
        };
    }

    render() {
        const {hasBackground, hasAnimatedBackground} = this.getBackgroundProps();

        return (
            <div
                className={this.componentClass}
                data-wpa={hasAnimatedBackground ? 'waypoint' : null}
                id={this.dropLinkId}
            >
                {hasBackground && <PageBuilderRowBackground {...this.props} />}
                <div className="inner">
                    <div className="page-builder-grid-row">{this.props.children}</div>
                </div>
            </div>
        );
    }
}

export default PageBuilderRow;
