import {CLEAR_AUTOCOMPLETE_RESULTS, SET_AUTOCOMPLETE_RESULTS} from '../action-types';

const getInitialState = () => ({
    results: []
});

const clearAutocompleteResults = (state) => ({
    ...state,
    results: []
});

const setAutocompleteResults = (state, results) => ({
    ...state,
    results: [
        ...results // new results overwrite old results
    ]
});

const reducerMap = {
    [CLEAR_AUTOCOMPLETE_RESULTS]: clearAutocompleteResults,
    [SET_AUTOCOMPLETE_RESULTS]: setAutocompleteResults
};

export default (state = getInitialState(), {data, type}) => {
    if (reducerMap[type]) {
        return reducerMap[type](state, data);
    }

    return state;
};

export {getInitialState};
