import React from 'react';
import {Link} from 'react-router';

const Culinary404 = ({environmentConfigReducer}) => (
    <div className="culinary-error">
        <div className="content">
            <h3 className="heading">{'404'}</h3>
            <h4 className="subheading">
                {"That's no "} <span>{'Gouda!'}</span>
            </h4>
            <p className="message">
                {"We can't seem to locate the page you're looking for. You can head straight to the "}
                <Link to="/">{'Home Page'}</Link>
                {', check out our '}
                <a href={environmentConfigReducer.url.contact}>{'Help & FAQ'}</a>
                {'.'}
            </p>
        </div>
        <div className="media">
            <img
                alt="Can't find page"
                className="image"
                height="1082"
                src="/recipes-ideas/static/images/gouda-404-2x.jpg"
                width="1104"
            />
        </div>
    </div>
);

export default Culinary404;
