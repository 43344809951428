import {ADD_VIDEO, SET_ACTIVE_VIDEO_ID, SET_VIDEO_PROP} from '../action-types';

const getInitialState = () => ({
    activeVideoId: '',
    videos: {}
});

const addVideo = (state, {videoId}) => ({
    ...state,
    videos: {
        ...state.videos,
        [videoId]: {}
    }
});

const setActiveVideoId = (state, data) => ({
    ...state,
    activeVideoId: data
});

const setVideoProp = (state, data) => {
    const {videoId, ...prop} = data;

    return {
        ...state,
        videos: {
            ...state.videos,
            [videoId]: {
                ...state.videos[videoId],
                ...prop
            }
        }
    };
};

const reducerMap = {
    [ADD_VIDEO]: addVideo,
    [SET_ACTIVE_VIDEO_ID]: setActiveVideoId,
    [SET_VIDEO_PROP]: setVideoProp
};

export default (state = getInitialState(), {data, type}) => {
    if (reducerMap[type]) {
        return reducerMap[type](state, data);
    }

    return state;
};

export {getInitialState};
