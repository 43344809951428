import React from 'react';
import {Link} from 'react-router';

const CulinaryFavoritesNoResults = ({recipeFavorites}) => {
    const activeCourseTypeData =
        recipeFavorites.courseTypes &&
        recipeFavorites.courseTypes.filter((courseType) => courseType.title === recipeFavorites.activeCourseType);
    const searchRecipesUrl =
        activeCourseTypeData && activeCourseTypeData.length
            ? activeCourseTypeData[0].searchUrl
            : recipeFavorites.recipeSearchLanding;
    const emptyDisplayLabel =
        activeCourseTypeData && activeCourseTypeData.length ? recipeFavorites.activeCourseType.toLowerCase() : 'Hy-vee';

    return (
        <div className="culinary-favorites-no-results">
            <h3 className="heading">{'Save Your Favorites'}</h3>
            <p className="description">{`Save ${emptyDisplayLabel} recipes to access later.`}</p>
            {searchRecipesUrl && (
                <Link className="button" to={searchRecipesUrl}>
                    {`Browse ${emptyDisplayLabel} recipes`}
                </Link>
            )}
        </div>
    );
};

export default CulinaryFavoritesNoResults;
