import React from 'react';

const IconClose = () => (
    <svg className="icon-close" focusable={false} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.06 10l8.47-8.469c.292-.292.292-.768 0-1.06-.293-.293-.768-.293-1.061 0L10 8.94 1.531.47C1.239.178.763.178.471.47c-.293.293-.293.769 0 1.061L8.94 10 .47 18.469c-.292.293-.292.768 0 1.06.147.148.34.22.532.22s.383-.074.53-.22l8.47-8.469 8.469 8.47c.146.146.338.218.53.218s.384-.073.531-.219c.293-.292.293-.767 0-1.06L11.063 10h-.003z"
            fill="#3F3F3F"
        />
    </svg>
);

export default IconClose;
