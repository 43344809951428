import React from 'react';

import CulinaryFeatureArticleAuthor from '../../culinary-feature-article-author';
import CulinaryFeatureContentSharing from '../../culinary-feature-content-sharing';

class CulinaryFeatureGuideHeroSectionsHeader extends React.PureComponent {
    render() {
        const {content, metaData, router} = this.props;

        return (
            <div className="culinary-feature-guide-hero-sections-header">
                <h3 className="share-heading">{'Share Options'}</h3>
                <CulinaryFeatureContentSharing hidePrint metaData={metaData} router={router} />
                <h3 className="intro-heading">{'Introduction'}</h3>
                <div className="category">{`Hy-Vee ${content.contentType}`}</div>
                <div className="title">{content.title}</div>
                <CulinaryFeatureArticleAuthor author={content.author} />
                <div
                    className="excerpt"
                    /* eslint-disable-next-line react/no-danger */
                    dangerouslySetInnerHTML={{__html: content.description}}
                />
            </div>
        );
    }
}

export default CulinaryFeatureGuideHeroSectionsHeader;
