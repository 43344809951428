import React from 'react';

import CulinaryMealIdeasCard from './culinary-meal-ideas-card';

/* eslint-disable react/prefer-stateless-function */
class CulinaryMealIdeasCardBlocks extends React.Component {
    render() {
        const {meals} = this.props.contentReducer.urlContent.content;

        return (
            <div className="culinary-meal-ideas-card-blocks">
                {meals.map((meal, index) => (
                    <CulinaryMealIdeasCard
                        {...this.props}
                        cardIndex={index}
                        key={index}
                        meal={meal}
                        selectedMeals={this.props.selectedMeals}
                    />
                ))}
            </div>
        );
    }
}
/* eslint-enable react/prefer-stateless-function */

export default CulinaryMealIdeasCardBlocks;
