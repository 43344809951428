import React from 'react';

const CulinaryFavoritesModalConfirmDeleteFail = ({onClickCancelHandler}) => (
    <div className="culinary-favorites-modal-confirm-delete">
        <div className="content">
            <h3 className="heading">{'Unsave recipe fail'}</h3>
            <p>{'There was an issue removing this recipe from your saved recipes. Please try again.'}</p>
        </div>
        <div className="actions">
            <button className="primary-button" onClick={onClickCancelHandler} type="button">
                {'Close'}
            </button>
        </div>
    </div>
);

export default CulinaryFavoritesModalConfirmDeleteFail;
