export const slugify = (string = '') => {
    const a = 'àáäâèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;';
    const b = 'aaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------';
    const p = new RegExp(a.split('').join('|'), 'gu');

    return string
        .toString()
        .toLowerCase()
        .replace(/\s+/gu, '-')
        .replace(p, (c) => b.charAt(a.indexOf(c)))
        .replace(/&/gu, '-and-')
        .replace(/[^\w-]+/gu, '')
        .replace(/--+/gu, '-')
        .replace(/^-+/u, '')
        .replace(/-+$/u, '');
};

export const truncateString = (string, characterCount) => {
    if (string.length <= characterCount) {
        return string;
    }

    const hEllip = '\u2026';
    const trimmedString = string.substring(0, characterCount);
    const stringAtSpace = Math.min(trimmedString.length, trimmedString.lastIndexOf(' '));

    return `${trimmedString.substring(0, stringAtSpace)}${hEllip}`;
};

export const stripWhiteSpaceAndLowercase = (string) => string.replace(/\s/gu, '').toLowerCase();

export const sectionSlug = (string) => `section-link-${slugify(string)}`;
