import React from 'react';
import ReactDOM from 'react-dom';
import {Link} from 'react-router';
import {Lazy} from 'react-lazy';

import IconVideo from '../../global/icon/icon-video';
import {getPreviewImageState} from '../../../services/preview-image-service';

class CulinaryEditorialRowItem extends React.Component {
    constructor(props) {
        super(props);

        this.onLoadMediaHandler = () => this.onLoadMedia();

        this.state = {
            imageLoaded: false,
            mediaAlt: '',
            mediaUrl: '',
            shouldCrop: true
        };
    }

    componentDidMount() {
        this.setPreviewImage(this.props.result);

        if (this.props.shouldFocus && this.categoryLink) {
            // eslint-disable-next-line react/no-find-dom-node
            const categoryLinkElement = ReactDOM.findDOMNode(this.categoryLink);

            categoryLinkElement.focus();
        }

        if (this.image && this.image.complete) {
            this.onLoadMedia();
        }
    }

    onLoadMedia() {
        this.setState({
            imageLoaded: true
        });
    }

    async setPreviewImage(result) {
        const previewImageState = await getPreviewImageState(result.primaryMedia);

        this.setState(previewImageState);
    }

    getVideoIconMarkup(media) {
        if (media !== null && media.type === 'Video') {
            return <IconVideo hover={false} />;
        }

        return null;
    }

    getImageClass() {
        return this.state.imageLoaded ? 'image image-loaded' : 'image';
    }

    getImageUrl() {
        if (this.state.mediaUrl) {
            if (this.state.shouldCrop) {
                return `${this.state.mediaUrl}&width=200&height=150`;
            }

            return this.state.mediaUrl;
        }

        return '';
    }

    render() {
        const {
            author,
            category,
            categoryUrl,
            contentId,
            contentType,
            contentTypeUrl,
            dateCreatedFormated,
            description,
            primaryMedia,
            title,
            url
        } = this.props.result;

        const imageUrl = this.getImageUrl();

        return (
            <div className="culinary-editorial-row-item" key={contentId} style={this.props.styles}>
                <div className="content">
                    <Link
                        className="category"
                        ref={(node) => {
                            this.categoryLink = node;
                        }}
                        to={categoryUrl || contentTypeUrl}
                    >
                        {category || contentType}
                    </Link>
                    <div className="title">
                        <Link className="title-link" to={url}>
                            {title}
                        </Link>
                    </div>
                    <p className="excerpt">{description}</p>
                    <div className="meta">
                        <span>{dateCreatedFormated}</span>
                        {author && <span>{author.name}</span>}
                    </div>
                </div>
                {imageUrl && (
                    <Lazy className="media-container" component="div" cushion={500}>
                        <Link className="media" to={url}>
                            <div className="border">
                                <div className="top-left" />
                                <div className="bottom-right" />
                            </div>
                            <img
                                alt={this.state.mediaAlt}
                                className={this.getImageClass()}
                                onLoad={this.onLoadMediaHandler}
                                ref={(node) => {
                                    this.image = node;
                                }}
                                src={imageUrl}
                            />
                            {this.getVideoIconMarkup(primaryMedia)}
                        </Link>
                    </Lazy>
                )}
            </div>
        );
    }
}

export default CulinaryEditorialRowItem;
