import React from 'react';

import DropdownMenu from './dropdown-menu';
import DropdownRadio from './dropdown-radio';

class DropdownSelect extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeDropdownIndex: null
        };

        /* istanbul ignore next */
        this.onClickWindowHandler = (event) => this.onClickWindow(event);
        this.onToggleDropdownHandler = (toggle, dropdownId) => this.onToggleDropdown(toggle, dropdownId);
    }

    componentDidMount() {
        global.addEventListener('click', this.onClickWindowHandler);
    }

    componentWillUnmount() {
        global.removeEventListener('click', this.onClickWindowHandler);
    }

    onToggleDropdown(toggle, dropdownId) {
        const toggleButton = toggle;
        const shouldExpand = toggleButton.getAttribute('aria-expanded') === 'false';

        toggleButton.setAttribute('aria-expanded', shouldExpand);
        toggleButton.setAttribute('aria-selected', shouldExpand);

        /* istanbul ignore next */
        const activeDropdownIndex = shouldExpand ? dropdownId : null;

        this.setState({activeDropdownIndex});
    }

    /* istanbul ignore next */
    onClickWindow(event) {
        if (!this.dropdownWrapper.contains(event.target)) {
            this.setState({activeDropdownIndex: null});
        }
    }

    render() {
        return (
            <div
                className="dropdown-select-div"
                ref={(element) => {
                    this.dropdownWrapper = element;
                }}
            >
                <DropdownMenu
                    activeDropdownIndex={this.state.activeDropdownIndex}
                    dropdownId={this.props.dropdownId}
                    errorStyle={this.props.errorStyle}
                    onToggleDropdown={this.onToggleDropdownHandler}
                    title={this.props.checkedValue || 'Select an Existing List'}
                >
                    <DropdownRadio
                        actions={this.props.actions}
                        checkedValue={this.props.checkedValue}
                        dropdownId={this.props.dropdownId}
                        invalid={this.props.invalid}
                        options={this.props.options}
                    />
                </DropdownMenu>
            </div>
        );
    }
}

export default DropdownSelect;
