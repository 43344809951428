import {browserHistory} from 'react-router';
import React from 'react';

const ACTIVE_CLASS = ' active';

class CulinaryRecipeDetailVariationsTab extends React.Component {
    constructor(props) {
        super(props);

        this.onClickTabHandler = () => this.onClickTab();
    }

    onClickTab() {
        this.props.actions.setSelectedVariationIndex(this.props.tabIndex);

        browserHistory.replace({
            ...this.props.location,
            query: {
                ...this.props.location.query,
                variation: this.props.tabIndex
            }
        });
    }

    render() {
        const isSelected = this.props.selectedVariationIndex === this.props.tabIndex;

        return (
            <li className="item" role="presentation">
                <button
                    aria-selected={isSelected}
                    className={`button${isSelected ? ACTIVE_CLASS : ''}`}
                    onClick={this.onClickTabHandler}
                    role="tab"
                    type="button"
                >
                    <span className="label">{this.props.variation.title}</span>
                </button>
            </li>
        );
    }
}

export default CulinaryRecipeDetailVariationsTab;
