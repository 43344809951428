import React from 'react';

import CulinaryRecipeDetailNutritionAmount from './culinary-recipe-detail-nutrition-amount';
import CulinaryRecipeDetailNutritionChart from './culinary-recipe-detail-nutrition-chart';

class CulinaryRecipeDetailNutritionInfo extends React.Component {
    constructor(props) {
        super(props);

        this.waypointTriggeredOnce = false;

        this.state = {
            waypointInView: false
        };
    }

    componentDidMount() {
        if (this.charts !== undefined) {
            global.window.addEventListener('scroll', this.waypoint);

            this.waypoint();
        }
    }

    componentWillUnmount() {
        global.window.removeEventListener('scroll', this.waypoint);
    }

    scrolledIntoView() {
        if (this.charts) {
            const chartsRectTop = this.charts.getBoundingClientRect().top;
            const viewPortHeight = global.window.innerHeight;
            const offset = 50;

            return chartsRectTop + offset - viewPortHeight < 0;
        }

        return false;
    }

    waypoint = () => {
        const inView = this.scrolledIntoView();

        if (inView) {
            this.setWaypointState(this.waypointTriggeredOnce);
        }
    };

    setWaypointState(triggeredOnce) {
        if (triggeredOnce === false) {
            this.waypointTriggeredOnce = true;

            this.setState({
                waypointInView: true
            });
        }
    }

    render() {
        if (!this.props.nutritionInfo) {
            return null;
        }

        const {singleServingDescription, nutrients, dailyValues} = this.props.nutritionInfo;
        const nutrientData = nutrients.filter((nutrient) => nutrient.key !== 'Calories');
        const calorieData = nutrients.filter((nutrient) => nutrient.key === 'Calories');
        const hasNutrientData =
            nutrientData.length > 0 &&
            nutrientData[0].value !== '0' &&
            nutrientData[0].value !== null &&
            nutrientData[0].value !== 'null';
        const hasDailyValues = dailyValues && dailyValues.length > 0;
        const hasCaloriesData =
            calorieData.length > 0 &&
            calorieData[0].value !== '0' &&
            calorieData[0].value !== null &&
            calorieData[0].value !== 'null';
        const modifierClass = this.props.isRecipeDetail ? 'culinary-recipe-detail-nutrition-info-below-steps' : '';

        if (!hasDailyValues && !hasCaloriesData && !hasNutrientData) {
            return null;
        }

        if (!dailyValues || !nutrients) {
            return null;
        }

        return (
            <section className={`culinary-recipe-detail-nutrition-info ${modifierClass}`}>
                <h3 className="heading">{'Nutrition facts'}</h3>
                <div className="servings">
                    <h4 className="heading">{'Servings'}</h4>
                    {hasCaloriesData && (
                        <div className="calories">{`${calorieData[0].value} ${calorieData[0].key} per serving`}</div>
                    )}
                    <div className="info">{singleServingDescription}</div>
                </div>
                <div className="values">
                    <h4 className="heading">{'Amounts Per Serving'}</h4>
                    {hasNutrientData && (
                        <ul className="amounts">
                            {nutrientData.map((nutrient, index) => (
                                <CulinaryRecipeDetailNutritionAmount key={index} nutrient={nutrient} />
                            ))}
                        </ul>
                    )}
                    {hasDailyValues && (
                        <div className="daily">
                            <h4 className="heading">{'Daily Values'}</h4>
                            <div
                                className="charts"
                                ref={(charts) => {
                                    this.charts = charts;
                                }}
                            >
                                {dailyValues.map((dailyValue, index) => (
                                    <CulinaryRecipeDetailNutritionChart
                                        dailyValue={dailyValue}
                                        key={index}
                                        waypointInView={this.state.waypointInView}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </section>
        );
    }
}

export default CulinaryRecipeDetailNutritionInfo;
