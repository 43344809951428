import React from 'react';

import FeatureDate from '../../../feature/feature-date';
import CulinaryFavoriteBtn from '../favorite/culinary-favorite-btn';

import CulinaryFeatureArticleAuthor from './culinary-feature-article-author';
import CulinaryFeatureContentSharing from './culinary-feature-content-sharing';

const CulinaryFeatureActionBar = (props) => {
    const authorStackedClass = props.stackAuthorOnMobile ? 'author-block-stacked-small' : '';
    const simpleLayoutClass = props.simpleLayout ? 'culinary-feature-action-bar-simple' : '';

    return (
        <div className={`culinary-feature-action-bar ${simpleLayoutClass}`}>
            <div className="primary">
                <section className={`author-block ${authorStackedClass}`}>
                    <h4 className="header">{'Author'}</h4>
                    <div className={`culinary-feature-action-bar-publish-info ${authorStackedClass}`}>
                        <CulinaryFeatureArticleAuthor
                            author={props.author}
                            stackAuthorOnMobile={props.stackAuthorOnMobile}
                        />
                        <FeatureDate publishDate={props.publishDate} />
                    </div>
                </section>
                <section className="sharing-block">
                    <h4 className="header">{'Share Options'}</h4>
                    <CulinaryFeatureContentSharing
                        locationQuery={props.locationQuery}
                        metaData={props.metaData}
                        router={props.router}
                    />
                </section>
            </div>
            {!props.isPrinting && !props.simpleLayout && (
                <section className="secondary">
                    <h4 className="header">{'Save Options'}</h4>
                    <div className="favoriting-block">
                        <CulinaryFavoriteBtn
                            actions={props.actions}
                            alertReducer={props.alertReducer}
                            favoritesReducer={props.favoritesReducer}
                            id={props.id}
                            isAuthenticated={props.isAuthenticated}
                            layoutType="large"
                            modalReducer={props.modalReducer}
                            modalTriggerId={`action-bar-favorite-button-${props.id}`}
                        />
                    </div>
                </section>
            )}
        </div>
    );
};

export default CulinaryFeatureActionBar;
