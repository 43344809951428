import React from 'react';

class CulinaryAddToListModalLogin extends React.Component {
    static ID = 'culinary-add-to-list-modal-login';

    render() {
        const urls = this.props.environmentConfigReducer.url;

        return (
            <div className="culinary-add-to-list-modal-login">
                <div className="content">
                    <h3 className="heading">{'Add To List'}</h3>
                    <p>{'Please log in or create an account in order to add items to list'}</p>
                </div>
                <div className="actions">
                    <a
                        className="secondary-button"
                        href={`${urls.accountLogin}${urls.base}${this.props.contentReducer.urlContentPath}`}
                    >
                        {'Log In'}
                    </a>
                    <a
                        className="primary-button"
                        href={`${urls.accountSignUp}${urls.base}${this.props.contentReducer.urlContentPath}`}
                    >
                        {'Create Account'}
                    </a>
                </div>
            </div>
        );
    }
}

export default CulinaryAddToListModalLogin;
