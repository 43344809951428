import React from 'react';
import debounce from 'lodash.debounce';

import {slugify} from '../../../../../services/string-services';

import CulinaryFeatureRecipeCarouselList from './culinary-feature-recipe-carousel-list';
import CulinaryFeatureRecipeCarouselNavigation from './culinary-feature-recipe-carousel-navigation';

const DURATION = 500;

class CulinaryFeatureRecipeCarousel extends React.Component {
    constructor(props) {
        super(props);

        this.recipes = this.props.data.recipes;
        this.blockWidth = null;
        this.carouselState = [];
        this.id =
            this.recipes && this.recipes.length > 1
                ? `recipe-carousel-${slugify(this.recipes[0].title)}`
                : 'featured-recipes';
        this.isAnimating = false;
        this.isLooping = false;
        this.itemCount = null;
        this.timeoutHandler = 0;

        this.state = {
            activeIndex: null
        };

        this.setupHandlers();
    }

    componentDidMount() {
        const {rowId, columnId, data} = this.props;

        if (rowId !== undefined && columnId !== undefined) {
            let isValid = false;

            if (data && data.recipes && data.recipes.length > 0) {
                isValid = true;
            }

            this.props.actions.pageBuilderSetValid({
                columnId,
                isValid,
                rowId
            });
        }

        if (this.recipes && this.recipes.length > 0) {
            require('velocity-animate');

            global.window.addEventListener('resize', this.onResizeWindowHandler);

            this.resetActiveIndex();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.recipes && this.recipes.length > 0) {
            if (nextProps.carouselReducer.carousels[this.id] !== undefined) {
                const nextBlockWidth = nextProps.carouselReducer.carousels[this.id].blockWidth;

                if (nextBlockWidth !== this.blockWidth) {
                    this.blockWidth = nextBlockWidth;

                    this.updateCarousel();
                    this.resetActiveIndex();
                }
            }
        }
    }

    componentWillUnmount() {
        global.window.removeEventListener('resize', this.onResizeWindowHandler);
    }

    setupHandlers() {
        this.onScrollContainerHandler = debounce((event) => this.onScrollContainer(event), 250);
        this.onClickRecipeHandler = (event) => this.onClickRecipe(event);
        this.onClickNextHandler = (event) => this.onClickNext(event);
        this.onClickPreviousHandler = (event) => this.onClickPrevious(event);
        this.onResizeWindowHandler = debounce((event) => this.onResizeWindow(event), 250);
    }

    resetActiveIndex() {
        this.setActiveIndex(this.isLooping ? 1 : 0);
        this.carouselContainer.scrollLeft = this.blockWidth * 0.75;
    }

    setScrollPosition() {
        if (this.isAnimating) {
            return;
        }

        const currentPosition = this.carouselContainer.scrollLeft;

        this.updateLoopedPosition(currentPosition);
    }

    setActiveIndex(index) {
        this.setState({
            activeIndex: index
        });
    }

    updateLoopedPosition(currentPosition) {
        const scrollOffsetMax = this.maxScrollPosition - currentPosition;
        const scrollOffsetMin = currentPosition - this.minScrollPosition;

        if (currentPosition > this.maxScrollPosition) {
            this.carouselContainer.scrollLeft = this.minScrollPosition + scrollOffsetMax * -1;
        } else if (currentPosition < this.minScrollPosition) {
            this.carouselContainer.scrollLeft = this.maxScrollPosition - scrollOffsetMin * -1;
        }
    }

    updateCarousel() {
        if (this.carouselContainer) {
            const carouselWidth = this.carouselContainer.getBoundingClientRect().width;
            const listWidth = this.blockWidth * this.itemCount;

            this.scrollAmount = this.blockWidth;
            this.minScrollPosition = listWidth;
            this.maxScrollPosition = this.minScrollPosition * 2;
            this.isLooping = listWidth > carouselWidth;
        }
    }

    advanceCarousel() {
        if (!this.isAnimating) {
            const newActiveIndex = this.state.activeIndex === this.itemCount - 1 ? 0 : this.state.activeIndex + 1;

            this.setActiveIndex(newActiveIndex);

            this.animateCarousel(false);
        }
    }

    rewindCarousel() {
        if (!this.isAnimating) {
            const newActiveIndex = this.state.activeIndex === 0 ? this.itemCount - 1 : this.state.activeIndex - 1;

            this.setActiveIndex(newActiveIndex);

            this.animateCarousel(true);
        }
    }

    animateCarousel(isReverse) {
        this.isAnimating = true;

        let offset = this.scrollAmount;

        if (isReverse) {
            offset = offset * -1;
        }

        /* eslint-disable-next-line no-undef, new-cap */
        Velocity(this.carouselContainer, 'scroll', {
            axis: 'x',
            container: this.carouselContainer,
            duration: DURATION,
            offset: `${offset}px`
        });

        clearTimeout(this.timeoutHandler);

        this.timeoutHandler = setTimeout(() => {
            this.isAnimating = false;
            this.setScrollPosition();
        }, DURATION + 100);
    }

    onResizeWindow() {
        this.updateCarousel();
        this.resetActiveIndex();
    }

    onClickRecipe(event) {
        this.setActiveIndex(Number.parseInt(event.currentTarget.dataset.carouselIndex));
    }

    onClickNext() {
        this.advanceCarousel();
    }

    onClickPrevious() {
        this.rewindCarousel();
    }

    onScrollContainer() {
        this.setScrollPosition();
    }

    render() {
        if (!this.recipes || this.recipes.length < 1) {
            return null;
        }

        let carouselStyles = {},
            navigationMarkup = null;

        if (this.props.data.backgroundImage) {
            carouselStyles = {
                backgroundImage: `url(${this.props.data.backgroundImage.url})`
            };
        }

        this.itemCount = this.props.data.recipes.length;
        this.carouselState = this.props.carouselReducer.carousels;

        if (this.isLooping) {
            navigationMarkup = (
                <CulinaryFeatureRecipeCarouselNavigation
                    onClickNext={this.onClickNextHandler}
                    onClickPrevious={this.onClickPreviousHandler}
                />
            );
        }

        return (
            <div
                className={`culinary-feature-recipe-carousel ${this.isLooping ? 'is-looping' : 'is-not-looping'}`}
                style={carouselStyles}
            >
                <h3 className="heading">{'Featured Recipe Carousel'}</h3>
                {navigationMarkup}
                <div className="container">
                    <div
                        className="scroll-container"
                        onScroll={this.onScrollContainerHandler}
                        ref={(carouselContainer) => {
                            this.carouselContainer = carouselContainer;
                        }}
                    >
                        <CulinaryFeatureRecipeCarouselList
                            actions={this.props.actions}
                            activeIndex={this.state.activeIndex}
                            alertReducer={this.props.alertReducer}
                            favoritesReducer={this.props.favoritesReducer}
                            id={this.id}
                            isAuthenticated={this.props.isAuthenticated}
                            isLooping={this.isLooping}
                            modalReducer={this.props.modalReducer}
                            onClickRecipe={this.onClickRecipeHandler}
                            recipes={this.recipes}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default CulinaryFeatureRecipeCarousel;
