import React from 'react';
import {Route, IndexRoute} from 'react-router';

import {createReduxHigherOrderComponent} from '../factories/view-factory';
import {isAuthRequiredRoute, getLoginRedirectUrl} from '../services/auth-required-route-service';
import AuthApiService from '../services/api/auth-api-service';

import PageLayout from './page-layout';
import TemplateRenderer from './template-renderer';
import CulinaryFavoritesTemplate from './culinary/templates/culinary-favorites-template';

const PageLayoutHOC = createReduxHigherOrderComponent(PageLayout);

export const getRoutes = (store = null) => {
    const validateRoute = (nextState, replace, callback) => {
        if (!store) {
            return callback();
        }

        const storeState = store.getState();

        if (!storeState.renderReducer.isServerSide && storeState.environmentConfigReducer.url) {
            AuthApiService.updateAuth(storeState.environmentConfigReducer.url.updateAuth);
        }

        const isAuthenticated = storeState.isAuthenticated;
        const {pathname, search} = nextState.location;
        const isAuthRequired = isAuthRequiredRoute(pathname);

        if (!isAuthenticated && isAuthRequired) {
            const accountLoginUrl = storeState.environmentConfigReducer.url.accountLogin;
            const redirectUrl = getLoginRedirectUrl(pathname, search, accountLoginUrl);

            return global.window.location.replace(redirectUrl);
        }

        return callback();
    };

    return [
        <Route component={PageLayoutHOC} key="favorites" onEnter={validateRoute} path="/recipes-ideas/favorites">
            <IndexRoute component={CulinaryFavoritesTemplate} />
        </Route>,
        <Route component={PageLayoutHOC} key="content-api" onEnter={validateRoute} path="*">
            <IndexRoute component={TemplateRenderer} />
        </Route>
    ];
};
