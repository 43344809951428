import React from 'react';

import CulinaryFeatureCardMedia from './culinary-feature-card-media';

const CulinaryFeaturePreviewList = ({content, layout, shouldFocus, hasStyles, styles}) => {
    const styleProperties = hasStyles ? styles : {};

    return (
        <li style={styleProperties}>
            <CulinaryFeatureCardMedia content={content} layout={layout} shouldFocus={shouldFocus} />
        </li>
    );
};

export default CulinaryFeaturePreviewList;
