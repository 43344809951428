import React from 'react';

import CulinaryFeatureIntro from '../../modules/feature/culinary-feature-intro';
import CulinaryListicleList from '../../modules/listicle/culinary-listicle-list';

import CulinaryArticleListicleTemplateBase from './culinary-article-listicle-template-base';

const CulinaryListicleTemplateBody = (props) => {
    const {content} = props.contentReducer.urlContent;

    return (
        <CulinaryArticleListicleTemplateBase {...props}>
            <CulinaryFeatureIntro description={content.description} layout={'divided'} />
            <CulinaryListicleList
                actions={props.actions}
                alertReducer={props.alertReducer}
                favoritesReducer={props.favoritesReducer}
                isAuthenticated={props.isAuthenticated}
                listItems={content.listItems}
                modalReducer={props.modalReducer}
                videoPlayerReducer={props.videoPlayerReducer}
            />
        </CulinaryArticleListicleTemplateBase>
    );
};

export default CulinaryListicleTemplateBody;
