import React from 'react';

import FeaturePrimaryImage from '../../../feature/feature-primary-image';

import FeaturePrimaryVideo from './feature-primary-video';

const CulinaryFeaturePrimaryMedia = ({
    activeVideoId,
    addVideo,
    media,
    setActiveVideoId,
    setVideoProp,
    videoPlayerReducer,
    layoutType,
    hideCaption
}) => {
    if (!media) {
        return null;
    }

    const mediaTypeClass = media.type.toLowerCase();

    return (
        <div className={`media media-${mediaTypeClass}`}>
            {media.type === 'Image' ? (
                <FeaturePrimaryImage data={media} hideCaption={hideCaption} layoutType={layoutType} />
            ) : (
                <FeaturePrimaryVideo
                    activeVideoId={activeVideoId}
                    addVideo={addVideo}
                    media={media}
                    setActiveVideoId={setActiveVideoId}
                    setVideoProp={setVideoProp}
                    videoPlayerReducer={videoPlayerReducer}
                />
            )}
        </div>
    );
};

export default CulinaryFeaturePrimaryMedia;
