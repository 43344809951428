import React from 'react';

import SwapLink from '../global/hoc/swap-link';

import FeatureInfoBlock from './feature-info-block';

class FeatureMultiColumnLeadIn extends React.Component {
    componentDidMount() {
        const {rowId, columnId, data} = this.props;

        if (rowId !== undefined && columnId !== undefined) {
            let isValid = false;

            if (data && data.featuredLeadItems && data.featuredLeadItems.length > 0) {
                isValid = true;
            }

            this.props.actions.pageBuilderSetValid({
                columnId,
                isValid,
                rowId
            });
        }
    }

    render() {
        const {data, layoutType} = this.props;

        if (!data || !data.featuredLeadItems) {
            return null;
        }

        return (
            <section className={`feature-multi-column-lead-in feature-multi-column-lead-in-${layoutType}`}>
                <div className="content">
                    <div className="header">
                        <h2 className="heading">{data.heading}</h2>
                        {data.description && (
                            <div
                                className="description"
                                /* eslint-disable-next-line react/no-danger */
                                dangerouslySetInnerHTML={{__html: data.description}}
                            />
                        )}
                    </div>
                    <div
                        className={`feature-multi-column-lead-in-blocks feature-multi-column-lead-in-blocks-${data.featuredLeadItems.length}up`}
                    >
                        <ul className="list">
                            {data.featuredLeadItems.map((block, index) => (
                                <li key={index}>
                                    <FeatureInfoBlock
                                        description={block.description}
                                        heading={block.heading}
                                        image={block.image}
                                        layoutType={layoutType}
                                        link={block.link}
                                    />
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="actions">
                        {data.ctaLink && (
                            <SwapLink className={'actions-btn'} href={data.ctaLink.url} target={data.ctaLink.target}>
                                {data.ctaLink.title}
                            </SwapLink>
                        )}
                    </div>
                </div>
            </section>
        );
    }
}

export default FeatureMultiColumnLeadIn;
