import React from 'react';
import ReactDOM from 'react-dom';
import {Link} from 'react-router';
import {Lazy} from 'react-lazy';

import IconVideo from '../../../global/icon/icon-video';
import {getContentCategorizationData} from '../../../../services/content-categorization-service';
import {truncateString} from '../../../../services/string-services';

// @params layout = large, feature, feature-primary
class CulinaryFeatureCardMedia extends React.Component {
    constructor(props) {
        super(props);

        this.onImageLoadHandler = () => this.onImageLoad();

        this.state = {
            imageLoaded: false
        };
    }

    componentDidMount() {
        if (this.props.shouldFocus && this.imageLink) {
            /* eslint-disable-next-line react/no-find-dom-node */
            const imageLinkElement = ReactDOM.findDOMNode(this.imageLink);

            imageLinkElement.focus();
        }

        if (this.mediaImage && this.mediaImage.complete) {
            this.onImageLoad();
        }
    }

    onImageLoad() {
        this.setState({
            imageLoaded: true
        });
    }

    render() {
        const {
            content,
            showDescription,
            layout = 'large',
            isStacked,
            imageWidth = '300',
            imageHeight = '225'
        } = this.props;

        if (!content) {
            return null;
        }

        const isVideo = content.media.type === 'Video';
        const source = isVideo ? content.media.previewImageUrl : content.media.cropUrl;
        const contentCategorization = getContentCategorizationData(content);
        const imageSize = `width=${imageWidth}&height=${imageHeight}`;
        const layoutDirectionClass = isStacked ? 'culinary-feature-card-media-stacked' : '';
        const truncatedDescription = content.description
            ? truncateString(content.description, 140)
            : content.description;

        return (
            <div
                className={`culinary-feature-card-media culinary-feature-card-media-${layout} ${layoutDirectionClass}`}
            >
                {source && (
                    <Link
                        aria-label={content.title ? content.title : ''}
                        className={`media media-${layout}`}
                        ref={(node) => {
                            this.imageLink = node;
                        }}
                        to={content.url}
                    >
                        <div className="background">
                            <Lazy className="container" component="div" cushion={500}>
                                <img
                                    alt={content.media.alt}
                                    className={`media-img${this.state.imageLoaded ? ' media-img-loaded' : ''}`}
                                    height={imageHeight}
                                    onLoad={this.onImageLoadHandler}
                                    ref={(node) => {
                                        this.mediaImage = node;
                                    }}
                                    src={`${source}&${imageSize}`}
                                    width={imageWidth}
                                />
                            </Lazy>
                        </div>
                        <div className="border">
                            <div className="top-left" />
                            <div className="bottom-right" />
                        </div>
                        {isVideo && <IconVideo hover={false} />}
                    </Link>
                )}
                <div className="content">
                    <Link className="header" to={contentCategorization.url}>
                        <span className="text">{contentCategorization.label}</span>
                    </Link>
                    <Link className={`title title-${layout}`} to={content.url}>
                        <span className="title-text">{content.title}</span>
                    </Link>

                    {showDescription && truncatedDescription && <div className="body">{truncatedDescription}</div>}
                </div>
            </div>
        );
    }
}

export default CulinaryFeatureCardMedia;
