import {MODAL_CLOSE, MODAL_UPDATE} from '../action-types';

const getInitialState = () => ({
    isActive: false,
    isRecentlyInactive: false,
    modalId: '',
    triggerId: ''
});

const modalUpdate = (state, modalData) => {
    const isRecentlyInactive =
        modalData.isRecentlyInactive === undefined ? !modalData.isActive : modalData.isRecentlyInactive;

    return {
        ...state,
        ...modalData,
        isRecentlyInactive
    };
};

const modalClose = (state) => ({
    ...state,
    isActive: false,
    isRecentlyInactive: true
});

const reducerMap = {
    [MODAL_CLOSE]: modalClose,
    [MODAL_UPDATE]: modalUpdate
};

export default (state = getInitialState(), {data, type}) => {
    if (reducerMap[type]) {
        return reducerMap[type](state, data);
    }

    return state;
};

export {getInitialState};
