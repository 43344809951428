import React from 'react';

import CulinaryFeatureCardMedia from './culinary-feature-card-media';
import CulinaryFeatureEditorialCategoryCard from './culinary-feature-editorial-category-card';

const CulinaryFeatureEditorialCategoryBlock = ({editorialCategoryBlock}) => {
    if (!editorialCategoryBlock) {
        return null;
    }

    return (
        <section className="culinary-feature-editorial-category-block">
            <h3 className="header">{'Editorial Content'}</h3>
            <div className="list">
                {editorialCategoryBlock.content.map((card, index) => (
                    <div className="item" key={index}>
                        <CulinaryFeatureCardMedia
                            content={card}
                            imageHeight={'600'}
                            imageWidth={'800'}
                            isStacked
                            layout={'small'}
                        />
                    </div>
                ))}
                <div className="item">
                    <CulinaryFeatureEditorialCategoryCard
                        cardBackgroundColor={editorialCategoryBlock.cardBackgroundColor}
                        cardBackgroundImage={editorialCategoryBlock.cardBackgroundImage}
                        cardInverseText={editorialCategoryBlock.cardInverseText}
                        categoryUrl={editorialCategoryBlock.categoryUrl}
                        title={editorialCategoryBlock.title}
                    />
                </div>
            </div>
        </section>
    );
};

export default CulinaryFeatureEditorialCategoryBlock;
