import React from 'react';

const IconPinterest = ({height, width}) => (
    <svg className="icon-pinterest" focusable={false} height={height} viewBox="0 0 31 31" width={width}>
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
            <g fill="#AA252A" transform="translate(-766.000000, -607.000000)">
                <g transform="translate(720.000000, 607.000000)">
                    <g transform="translate(46.000000, 0.000000)">
                        <path d="M15.5004752,0 C6.94009994,0 0,6.93914963 0,15.4995248 C0,22.0661537 4.08537445,27.674872 9.8518439,29.932804 C9.71594985,28.7069066 9.59336011,26.8252966 9.90506116,25.4872628 C10.1873027,24.2775206 11.7230005,17.7821649 11.7230005,17.7821649 C11.7230005,17.7821649 11.2592502,16.8537139 11.2592502,15.481469 C11.2592502,13.3261703 12.507955,11.7172987 14.0636093,11.7172987 C15.3864382,11.7172987 16.0250452,12.7094203 16.0250452,13.9001563 C16.0250452,15.2305877 15.1783207,17.2186322 14.741179,19.0612795 C14.3762607,20.6036296 15.5147298,21.8618375 17.036173,21.8618375 C19.7911162,21.8618375 21.9093529,18.9567457 21.9093529,14.7639864 C21.9093529,11.0530333 19.2427884,8.45774195 15.4349039,8.45774195 C11.0245241,8.45774195 8.43588486,11.7657644 8.43588486,15.1849729 C8.43588486,16.5163545 8.94905122,17.9456179 9.58955887,18.7220196 C9.71594985,18.8750192 9.73495601,19.0099629 9.69694369,19.1667637 C9.57910548,19.6561724 9.31777076,20.7091138 9.26645412,20.9238834 C9.19898225,21.2080255 9.04123111,21.2678949 8.74758591,21.1320009 C6.81180834,20.2301585 5.60206615,17.400141 5.60206615,15.1270041 C5.60206615,10.237669 9.15431777,5.74746329 15.8435364,5.74746329 C21.2194292,5.74746329 25.3988842,9.57910548 25.3988842,14.6993654 C25.3988842,20.0410472 22.030042,24.3402409 17.3554765,24.3402409 C15.7846173,24.3402409 14.3087888,23.5239263 13.8032249,22.5603139 C13.8032249,22.5603139 13.0258729,25.5195733 12.8377119,26.2446583 C12.4870482,27.5912449 11.542442,29.2789921 10.9104871,30.3081757 C12.3606572,30.7567211 13.902057,30.9990497 15.5004752,30.9990497 C24.0599001,30.9990497 31,24.0599001 31,15.4995248 C31,6.93914963 24.0599001,0 15.5004752,0" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default IconPinterest;
