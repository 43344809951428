import ContentApiService from '../services/api/content-api-service';
import {CLEAR_AUTOCOMPLETE_RESULTS, SET_AUTOCOMPLETE_RESULTS} from '../action-types';

export const clearAutocompleteResults = () => ({
    type: CLEAR_AUTOCOMPLETE_RESULTS
});

export const setAutocompleteSearchTerm = (searchTerm, autocompleteApiUrl) => async (dispatch) => {
    const responseModel = await ContentApiService.getAutocompleteResults(searchTerm, autocompleteApiUrl);

    if (responseModel.success) {
        const autocompleteResults = responseModel.data.data.results;

        dispatch({
            data: autocompleteResults,
            type: SET_AUTOCOMPLETE_RESULTS
        });
    }
};

export const setAutocompleteEditorialSearchTerm = (searchTerm, editorialSearchAutoCompleteApiUrl) => async (
    dispatch
) => {
    const responseModel = await ContentApiService.getAutocompleteResults(searchTerm, editorialSearchAutoCompleteApiUrl);

    if (responseModel.success) {
        const autocompleteResults = responseModel.data.data.results;

        dispatch({
            data: autocompleteResults,
            type: SET_AUTOCOMPLETE_RESULTS
        });
    }
};
