import {SET_EDITORIAL_LIST_IN_STORE, GET_PAGED_RESULTS} from '../action-types';

export const setEditorialListInStore = (editorialList = []) => ({
    data: editorialList,
    type: SET_EDITORIAL_LIST_IN_STORE
});

export const getPagedResults = (pageNumber) => ({
    data: pageNumber,
    type: GET_PAGED_RESULTS
});
