const pinterestSdkPromiseWrapper = async (appId) => {
    const pinterestSdkPromise = global.PDK
        ? global.PDK
        : await new Promise((resolve) => {
              global.pAsyncInit = () => {
                  resolve(global.PDK);
              };
          });

    global.PDK.init({
        appId,
        cookie: true
    });

    return pinterestSdkPromise;
};

// eslint-disable-next-line no-return-await
export const getPinterestSdk = async (appId) => await pinterestSdkPromiseWrapper(appId);
