import React from 'react';
import {Link} from 'react-router';
import {LinkButton} from '@hy-vee/web-core/lib/components/button';
import {Icon} from '@hy-vee/web-core';
import styled from 'styled-components';
import {hyvee, spacing} from '@hy-vee/themes';

import CulinaryFeaturePrimaryMedia from '../../modules/feature/culinary-feature-primary-media';
import IconHyVeeLogo from '../../../global/icon/icon-hy-vee-logo';
import CulinaryFeatureBadges from '../../modules/feature/badges/culinary-feature-badges';
import {getContentCategorizationData} from '../../../../services/content-categorization-service';

function getLayoutClass(template) {
    if (template === 'how-to') {
        return 'culinary-detail-template-hd culinary-detail-template-hd-how-to';
    }

    if (template === 'article-listicle') {
        return 'culinary-detail-template-hd culinary-detail-template-hd-center';
    }

    return 'culinary-detail-template-hd';
}

const StyledLinkButton = styled(LinkButton)`
    color: ${hyvee.primary};
    fill: ${hyvee.primary};
    font-weight: 400;
    text-align: center;

    &:hover {
        color: ${hyvee.primaryHover};
        fill: ${hyvee.primaryHover};
        text-decoration: none;
    }
`;

const CulinaryDetailTemplateHd = (props) => {
    const {content} = props.contentReducer.urlContent;
    const layoutClass = getLayoutClass(props.template);
    const mediaClass = content.primaryMedia ? ' culinary-detail-template-hd-media' : '';
    const heroClass = content.displayPrimaryMediaAsHero ? 'culinary-detail-template-hd-hero' : '';
    const contentCategorization = getContentCategorizationData(content);

    return (
        <div className={`${layoutClass}${mediaClass} ${heroClass}`}>
            {content.primaryMedia && content.displayPrimaryMediaAsHero && (
                <div className="hero">
                    <img
                        alt={content.primaryMedia.alt}
                        className="image"
                        height="525"
                        src={`${content.primaryMedia.cropUrl}&width=1500&height=525`}
                        width="1500"
                    />
                </div>
            )}
            <div className="header">
                <div className="print-logo">
                    <IconHyVeeLogo />
                </div>
                <Link className="topic" to={contentCategorization.url}>
                    {contentCategorization.label}
                </Link>
                <div className="hdg">{content.title}</div>
            </div>
            <h4 className="media-hdg">{'Primary Media'}</h4>
            {content.primaryMedia && !content.displayPrimaryMediaAsHero && (
                <CulinaryFeaturePrimaryMedia
                    activeVideoId={props.videoPlayerReducer.activeVideoId}
                    addVideo={props.actions.addVideo}
                    hideCaption
                    layoutType={'primary'}
                    media={content.primaryMedia}
                    setActiveVideoId={props.actions.setActiveVideoId}
                    setVideoProp={props.actions.setVideoProp}
                    videoPlayerReducer={props.videoPlayerReducer}
                />
            )}
            {!props.ignoreChildren && (
                <div className="meta">
                    {props.children}
                    <CulinaryFeatureBadges badges={content.badges} />
                </div>
            )}
            {props.showJumpLink && (
                <div className="meta" style={{textAlign: 'center'}}>
                    <StyledLinkButton as="a" href="#recipe-ingredients">
                        {'Select & Shop Ingredients'}
                        <Icon color="inherit" name="caret-right" style={{marginLeft: spacing.small}} />
                    </StyledLinkButton>
                </div>
            )}
        </div>
    );
};

export default CulinaryDetailTemplateHd;
