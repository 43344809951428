import React from 'react';

const CulinarySearchNoResults = ({content, heading, searchTerm}) => {
    let displayString = '',
        headingString = `${heading} `;

    if (searchTerm) {
        displayString = `\u201C${searchTerm}\u201D`;
        headingString = `${heading} for `;
    }

    return (
        <section className="culinary-search-no-results">
            <h2 className="heading">
                {headingString}
                {searchTerm && <em className="search-term">{displayString}</em>}
            </h2>
            <div
                className="user-content"
                /* eslint-disable-next-line react/no-danger */
                dangerouslySetInnerHTML={{__html: content}}
            />
        </section>
    );
};

export default CulinarySearchNoResults;
