import React from 'react';

import CulinaryRecipeDetailNutritionInfo from '../../recipe/detail/nutrition/culinary-recipe-detail-nutrition-info';

class CulinaryMealIdeasNutritionModal extends React.Component {
    static ID = 'culinary-meal-ideas-nutrition';

    render() {
        const {mealIdeaKey} = this.props.mealIdeasNutritionReducer;
        const meals = this.props.contentReducer.urlContent.content.meals;
        const {nutritionalInfo} = meals.find((meal) => meal.title === mealIdeaKey);

        return (
            <div className="culinary-meal-ideas-nutrition-modal">
                <div className="content">
                    <CulinaryRecipeDetailNutritionInfo nutritionInfo={nutritionalInfo} />
                </div>
            </div>
        );
    }
}

export default CulinaryMealIdeasNutritionModal;
