import React, {useRef, useState} from 'react';
import {sizing} from '@hy-vee/themes';
import {Alert, Button, Collapse, H5, OutlineButton, Radio, Textarea} from '@hy-vee/web-core';
import {Small} from '@hy-vee/web-core/lib/components/typography';
import styled from 'styled-components';
import ReCAPTCHA from 'react-google-recaptcha';
import RadioGroup from '@hy-vee/web-core/lib/components/radio/radio-group';

import {postFeedback} from '../../../../../services/feedback-service';

const CulinaryHowToDetailFeedback = ({content: {title}, environmentConfigReducer: {recaptcha: {sitekey}}}) => {
    const type = 'How-To';
    const recaptcha = useRef(null);
    const [wasThisHelpful, setWasThisHelpful] = useState(undefined);
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isSent, setIsSent] = useState(false);
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [forceRerenderKey, setForceRerenderKey] = useState(1);
    const disabled = isSent || isLoading;
    const cancelOnClick = () => {
        setForceRerenderKey(forceRerenderKey + 1);
        setWasThisHelpful(undefined);
        setMessage('');
    };
    const recaptchaOnChange = async (recaptchaValue) => {
        if (recaptchaValue) {
            setIsLoading(true);

            try {
                await postFeedback({
                    feedback: {
                        message,
                        title,
                        type,
                        wasThisHelpful
                    },
                    recaptcha: recaptcha.current.getValue()
                });
                setIsSent(true);
            } catch (error) {
                setIsSent(false);
                setErrorMessage('Sorry, this message failed to send. Please try again later.');
                recaptcha.current.reset();
            }

            setIsLoading(false);
        }
    };
    const Heading = styled(H5)`
        text-align: ${isSent ? 'center' : 'left'};
    `;
    const AlertContainer = styled.div`
        margin-bottom: 20px;
    `;
    const ButtonContainer = styled.div`
        button {
            margin: 10px;
        }
    `;
    const GoogleDisclaimerP = styled.p`
        font-size: ${sizing[12]};
        line-height: 1.4;
        text-align: left;
    `;

    return (
        <div className='culinary-how-to-detail-feedback'>
            {
                errorMessage && <AlertContainer>
                    <Alert
                        onDismiss={() => {setErrorMessage(undefined)}}
                        status="error"
                    >
                        {errorMessage}
                    </Alert>
                </AlertContainer>
            }
            <Heading className='heading'>{isSent ? 'Thanks for your feedback!' : 'Help us make this page better!' }</Heading>
            <Collapse isOpen={!isSent}>
                <RadioGroup
                    disabled={disabled}
                    key={forceRerenderKey}
                    label='Was this helpful?'
                    onChange={({target: {value}}) => {setWasThisHelpful(value)}}
                    textTransform='none'
                >
                    <Radio label='Yes' value='Yes'/>
                    <Radio label='No' value='No'/>
                </RadioGroup>
            </Collapse>
            <Collapse isOpen={wasThisHelpful && !isSent}>
                <Textarea
                    disabled={disabled}
                    id='feedback-textarea'
                    label={{
                        'No': 'Sorry about that. What can we do better?',
                        'Yes': 'Great! What was the most helpful?',
                    }[wasThisHelpful]}
                    onChange={({target: {value}}) => {setMessage(value)}}
                    placeholder='Tell us here'
                    textTransform='none'
                    value={message}
                />
                <ButtonContainer>
                    <OutlineButton
                        disabled={disabled}
                        onClick={cancelOnClick}
                    >
                        {'Cancel'}
                    </OutlineButton>
                    <Button
                        disabled={isSent}
                        isLoading={isLoading}
                        onClick={() => {
                            if (wasThisHelpful) {
                                recaptcha.current.execute()
                            } else {
                                setErrorMessage('Please select "Yes" or "No".');
                            }
                        }}
                    >
                        {isSent ? 'Sent' : 'Send Feedback'}
                    </Button>
                </ButtonContainer>
                <GoogleDisclaimerP>
                    <Small>
                        {'This site is protected by reCAPTCHA and the Google '}
                        <a href={'https://policies.google.com/privacy'}>{'Privacy Policy'}</a>
                        {' and '}
                        <a href={'https://policies.google.com/terms'}>{'Terms of Service'}</a>
                        {' apply.'}
                    </Small>
                </GoogleDisclaimerP>
                <ReCAPTCHA
                    onChange={recaptchaOnChange}
                    ref={recaptcha}
                    sitekey={sitekey}
                    size={'invisible'}
                />
            </Collapse>
        </div>
    )
};

export default CulinaryHowToDetailFeedback;
