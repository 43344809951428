import React from 'react';

const CulinaryFavoritesModalConfirmDeleteDefault = (props) => (
    <div className="culinary-favorites-modal-confirm-delete">
        <div className="content">
            <h3 className="heading">{'Remove Recipe'}</h3>
            <p>{`Are you sure you want to remove ${props.recipeName} from your saved recipes?`}</p>
        </div>
        <div className="actions">
            <button className="secondary-button" onClick={props.onClickCancelHandler} type="button">
                {'No'}
            </button>
            <button className="primary-button" onClick={props.onClickConfirmHandler} type="button">
                {'Yes'}
            </button>
        </div>
    </div>
);

export default CulinaryFavoritesModalConfirmDeleteDefault;
