import React from 'react';
import {Link} from 'react-router';

class CulinarySearchRefinementGroupsHeading extends React.Component {
    constructor(props) {
        super(props);

        this.onClickViewMoreHandler = () => this.onClickViewMore();
    }

    onClickViewMore() {
        global.window.scrollTo(0, 0);
    }

    render() {
        const {refinement, results, totalResults} = this.props.refinementBlock;

        return (
            <div className="culinary-search-refinement-groups-heading">
                <h3 className="hdg">{refinement.title}</h3>
                {results && totalResults > 4 && (
                    <Link className="link" onClick={this.onClickViewMoreHandler} to={refinement.url}>
                        {'View More'}
                    </Link>
                )}
            </div>
        );
    }
}

export default CulinarySearchRefinementGroupsHeading;
