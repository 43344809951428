import {PAGE_BUILDER_SET_VALID, PAGE_BUILDER_CLEAR_VALID} from '../action-types';

const getInitialState = () => ({
    pageBuilderRows: {}
});

const pageBuilderSetValid = (state, componentData) => ({
    pageBuilderRows: {
        ...state.pageBuilderRows,
        [componentData.rowId]: {
            ...state.pageBuilderRows[componentData.rowId],
            [componentData.columnId]: componentData.isValid
        }
    }
});

const pageBuilderClearValid = () => ({
    pageBuilderRows: {}
});

const reducerMap = {
    [PAGE_BUILDER_CLEAR_VALID]: pageBuilderClearValid,
    [PAGE_BUILDER_SET_VALID]: pageBuilderSetValid
};

export default (state = getInitialState(), {data, type}) => {
    if (reducerMap[type]) {
        return reducerMap[type](state, data);
    }

    return state;
};

export {getInitialState};
