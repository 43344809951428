import {convertToMilliseconds} from '../services/timing-service';
import {
    LOAD_RECIPE_FAVORITES,
    RECIPE_FAVORITES_IS_LOADING,
    SET_ACTIVE_COURSE_TYPE,
    SET_PAGED_RECIPE_FAVORITES,
    CLEAR_RECIPE_FAVORITES,
    SORT_RECIPE_FAVORITES,
    RECIPE_FAVORITES_ERROR,
    SET_SAVED_RECIPE_IDS,
    RECIPE_SAVE_SUCCESSFUL,
    RECIPE_SAVE_ERROR,
    SET_DELETE_DATA,
    RECIPE_DELETE_SUCCESSFUL,
    RECIPE_DELETE_ON_FAVORITES_PAGE_ERROR,
    RECIPE_DELETE_ON_FAVORITES_PAGE_SUCCESSFUL,
    RECIPE_DELETE_ERROR,
    CLEAR_FAVORITING_ERRORS
} from '../action-types';

const DEFAULT_COURSE_TYPE = 'Saved Recipes';
const DEFAULT_SORT_KEY = 'defaultOrder';

const DEFAULT_RECIPE_FAVORITES = {
    activeCourseType: DEFAULT_COURSE_TYPE,
    activeDeletion: null,
    activeSortKey: DEFAULT_SORT_KEY,
    courseTypes: null,
    currentPage: 0,
    deleteError: false,
    error: false,
    focusFirstAllowed: false,
    focusIndex: null,
    loading: true,
    paginationAmount: 20,
    recipes: null,
    recipesByActiveCategory: null,
    recipeSearchLanding: '',
    totalResults: 0,
    totalResultsInActiveCategory: 0,
    visibleRecipes: null
};

const getInitialState = () => ({
    activeFavoriteId: null,
    deleteRecipeError: false,
    recipeFavorites: {
        ...DEFAULT_RECIPE_FAVORITES
    },
    recipeIdsRequested: false,
    savedRecipeIds: [],
    saveRecipeError: null,
    savingRecipe: null
});

const clearRecipeFavorites = (state) => ({
    ...state,
    activeFavoriteId: null,
    deleteRecipeError: false,
    recipeFavorites: {
        ...DEFAULT_RECIPE_FAVORITES
    },
    recipeIdsRequested: false,
    savedRecipeIds: [],
    saveRecipeError: false
});

const setError = (state, data) => ({
    recipeFavorites: {
        ...state.recipeFavorites,
        error: data
    }
});

const getRecipesByCourseType = (recipes, activeCourseType) => {
    const recipeGroup = {
        recipes,
        totalResults: recipes.length
    };

    if (activeCourseType !== DEFAULT_COURSE_TYPE) {
        recipeGroup.recipes = recipeGroup.recipes.filter((recipe) => recipe.courseType === activeCourseType);
        recipeGroup.totalResults = recipeGroup.recipes.length;
    }

    return recipeGroup;
};

const setActiveCourseType = (state, data) => {
    const activeCourseType = data;
    const recipeGroup = getRecipesByCourseType(state.recipeFavorites.recipes, activeCourseType);

    return {
        ...state,
        recipeFavorites: {
            ...state.recipeFavorites,
            activeCourseType,
            currentPage: 0,
            focusFirstAllowed: false,
            focusIndex: null,
            recipesByActiveCategory: recipeGroup.recipes,
            totalResultsInActiveCategory: recipeGroup.totalResults
        }
    };
};

const setLoading = (state, data) => {
    const loading = data;

    return {
        ...state,
        recipeFavorites: {
            ...state.recipeFavorites,
            loading
        },
        saveRecipeError: false
    };
};

const loadRecipeFavorites = (state, data) => {
    const newData = data || {};
    const courseTypes = newData.courseTypes || [];
    const recipes = newData.recipes || [];
    const totalResults = newData.totalResults || 0;
    const recipeSearchLanding = newData.recipeSearchLanding || '';

    const recipesWithOrder = recipes.map((recipe, index) => {
        recipe.defaultOrder = index; // eslint-disable-line no-param-reassign

        return recipe;
    });

    const visibleRecipes = recipesWithOrder.slice(0, state.recipeFavorites.paginationAmount);

    return {
        ...state,
        recipeFavorites: {
            ...state.recipeFavorites,
            courseTypes,
            error: false,
            recipes: recipesWithOrder,
            recipesByActiveCategory: recipes,
            recipeSearchLanding,
            totalResults,
            totalResultsInActiveCategory: totalResults,
            visibleRecipes
        }
    };
};

const setPagedRecipeFavorites = (state, pageNumber) => {
    const {recipeFavorites} = state;
    const startIndex = pageNumber * recipeFavorites.paginationAmount;
    const endIndex = startIndex + recipeFavorites.paginationAmount;
    const visibleRecipes = recipeFavorites.recipesByActiveCategory.slice(startIndex, endIndex);

    return {
        ...state,
        recipeFavorites: {
            ...recipeFavorites,
            currentPage: pageNumber,
            focusIndex: startIndex,
            visibleRecipes: [...recipeFavorites.visibleRecipes, ...visibleRecipes]
        }
    };
};

const compareSortValues = (key, sortOrder) =>
    function(a, b) {
        const varA = key === 'totalTime' ? convertToMilliseconds(a[key]) : a[key];
        const varB = key === 'totalTime' ? convertToMilliseconds(b[key]) : b[key];
        let comparison;

        if (varA === null) {
            comparison = 1;
        } else if (varB === null) {
            comparison = -1;
        } else if (varA === varB) {
            comparison = 0;
        } else if (sortOrder === 'asc') {
            comparison = varA < varB ? -1 : 1;
        } else {
            comparison = varA < varB ? 1 : -1;
        }

        return comparison;
    };

const sortRecipeFavorites = (state, sortKey) => {
    const sortOrder = sortKey === 'rating' ? 'desc' : 'asc';
    const sortedRecipes = state.recipeFavorites.recipesByActiveCategory.sort(compareSortValues(sortKey, sortOrder));
    const visibleRecipes = sortedRecipes.slice(0, state.recipeFavorites.paginationAmount);

    return {
        ...state,
        recipeFavorites: {
            ...state.recipeFavorites,
            activeSortKey: sortKey,
            currentPage: 0,
            visibleRecipes
        }
    };
};

const setSavedRecipeIds = (state, recipeIds) => {
    const savedRecipeIds = recipeIds.map((id) => id.recipe_id);

    return {
        ...state,
        recipeIdsRequested: true,
        savedRecipeIds,
        saveRecipeError: false
    };
};

const getSavedRecipeIds = (recipes, id) => {
    const idIndex = recipes.findIndex((selection) => selection === id);

    if (idIndex >= 0) {
        return recipes.filter((selection) => selection !== id);
    }

    return [...recipes, id];
};

const setSavedRecipeId = (state, id) => {
    const savedRecipeIds = getSavedRecipeIds(state.savedRecipeIds, id);

    return {
        ...state,
        savedRecipeIds,
        savingRecipe: false
    };
};

const deleteSavedRecipeIdOnFavoritesPage = (state, id) => {
    let {visibleRecipes} = state.recipeFavorites;
    const removedRecipe = visibleRecipes.filter((recipe) => recipe.contentId === id);
    const removedRecipeCategory = removedRecipe[0].courseType;
    const savedRecipeIds = state.savedRecipeIds.filter((recipeId) => recipeId !== id);
    const recipesByActiveCategory = state.recipeFavorites.recipesByActiveCategory.filter(
        (recipe) => recipe.contentId !== id
    );
    const courseTypes = state.recipeFavorites.courseTypes;
    const recipes = state.recipeFavorites.recipes.filter((recipe) => recipe.contentId !== id);
    const endIndex = (state.recipeFavorites.currentPage + 1) * state.recipeFavorites.paginationAmount;

    visibleRecipes = recipesByActiveCategory.slice(0, endIndex);

    const foundIndex = courseTypes.findIndex((courseType) => courseType.title === removedRecipeCategory);

    if (foundIndex >= 0) {
        const foundCourse = courseTypes[foundIndex];

        courseTypes[foundIndex] = {
            ...foundCourse,
            count: foundCourse.count - 1
        };
    }

    return {
        ...state,
        deleteRecipeError: false,
        recipeFavorites: {
            ...state.recipeFavorites,
            focusIndex: state.recipeFavorites.activeDeletion.nextFocusIndex,
            recipes,
            recipesByActiveCategory,
            totalResults: state.recipeFavorites.totalResults - 1,
            totalResultsInActiveCategory: recipesByActiveCategory.length,
            visibleRecipes
        },
        savedRecipeIds
    };
};

const deleteSavedRecipeId = (state, id) => {
    const savedRecipeIds = state.savedRecipeIds.filter((recipeId) => recipeId !== id);

    return {
        ...state,
        deleteRecipeError: false,
        savedRecipeIds
    };
};

const setDeleteData = (state, data) => {
    const nextFocusIndex = data.index === state.recipeFavorites.totalResults - 1 ? data.index - 1 : data.index;

    return {
        ...state,
        activeFavoriteId: data.id,
        recipeFavorites: {
            ...state.recipeFavorites,
            activeDeletion: {
                nextFocusIndex,
                title: data.title
            },
            focusFirstAllowed: nextFocusIndex === 0
        }
    };
};

const setDeleteRecipeError = (state, id) => ({
    ...state,
    activeFavoriteId: id,
    deleteRecipeError: true,
    recipeFavorites: {
        ...state.recipeFavorites
    }
});

const setSaveRecipeError = (state, id) => ({
    ...state,
    activeFavoriteId: id,
    saveRecipeError: true,
    savingRecipe: false
});

const clearFavoritingErrors = (state) => ({
    ...state,
    deleteRecipeError: false,
    saveRecipeError: false
});

const setDeleteRecipeErrorOnFavoritesPage = (state) => ({
    ...state,
    recipeFavorites: {
        ...state.recipeFavorites,
        deleteError: true
    }
});

const reducerMap = {
    [CLEAR_FAVORITING_ERRORS]: clearFavoritingErrors,
    [CLEAR_RECIPE_FAVORITES]: clearRecipeFavorites,
    [LOAD_RECIPE_FAVORITES]: loadRecipeFavorites,
    [RECIPE_DELETE_ERROR]: setDeleteRecipeError,
    [RECIPE_DELETE_ON_FAVORITES_PAGE_ERROR]: setDeleteRecipeErrorOnFavoritesPage,
    [RECIPE_DELETE_ON_FAVORITES_PAGE_SUCCESSFUL]: deleteSavedRecipeIdOnFavoritesPage,
    [RECIPE_DELETE_SUCCESSFUL]: deleteSavedRecipeId,
    [RECIPE_FAVORITES_ERROR]: setError,
    [RECIPE_FAVORITES_IS_LOADING]: setLoading,
    [RECIPE_SAVE_ERROR]: setSaveRecipeError,
    [RECIPE_SAVE_SUCCESSFUL]: setSavedRecipeId,
    [SET_ACTIVE_COURSE_TYPE]: setActiveCourseType,
    [SET_DELETE_DATA]: setDeleteData,
    [SET_PAGED_RECIPE_FAVORITES]: setPagedRecipeFavorites,
    [SET_SAVED_RECIPE_IDS]: setSavedRecipeIds,
    [SORT_RECIPE_FAVORITES]: sortRecipeFavorites
};

export default (state = getInitialState(), {data, type}) => {
    if (reducerMap[type]) {
        return reducerMap[type](state, data);
    }

    return state;
};

export {getInitialState, DEFAULT_RECIPE_FAVORITES, DEFAULT_COURSE_TYPE};
