import React from 'react';

const CulinaryEditorialCategoryCount = ({subsetResults, allResults, featuredContentCount}) => {
    let subsetCount = subsetResults ? subsetResults.length : 0,
        allCount = allResults ? allResults.length : 0;

    subsetCount += featuredContentCount;
    allCount += featuredContentCount;

    return (
        <div aria-live="polite" className="culinary-editorial-category-count">
            {`Showing ${subsetCount} of ${allCount} items`}
        </div>
    );
};

export default CulinaryEditorialCategoryCount;
