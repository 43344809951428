import React from 'react';
import {Link} from 'react-router';

import CulinaryFeaturePreviewList from '../feature/culinary-feature-preview-list';

const CulinaryLandingSecondaryFeatures = ({secondaryFeatures, editorialLandingUrl}) => (
    <div className="culinary-landing-secondary-features">
        <div className="list">
            <CulinaryFeaturePreviewList featuredContentList={secondaryFeatures} layout="small" listStyle="column" />
        </div>
        <Link className="link" to={editorialLandingUrl}>
            {'View more advice & how-tos'}
        </Link>
    </div>
);

export default CulinaryLandingSecondaryFeatures;
