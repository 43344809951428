import React from 'react';
import {CSSTransition, TransitionGroup} from 'react-transition-group';

import CulinaryRecipeDetailRatingsIcon from './culinary-recipe-detail-ratings-icon';

class CulinaryRecipeDetailRatings extends React.Component {
    constructor(props) {
        super(props);

        this.rating = this.props.contentReducer.urlContent.content.rating;
        this.actions = this.props.actions;
        this.recipeId = this.rating.recipeId;
        this.starNumber = 0;

        this.roundedStarValue = this.roundNumber(this.rating.value, 2);
        this.iconData = this.starPercents(this.roundedStarValue);

        this.messageTimeout = null;

        this.state = {
            hoveredStarNum: 0,
            isHovered: false,
            showMessage: false
        };
    }

    componentWillUnmount() {
        clearTimeout(this.messageTimeout);
    }

    roundNumber = (value, decimal) => Number(value.toFixed(decimal));

    getStarNumber = (event) => event.currentTarget.dataset.starNum;

    starPercents(rating) {
        const percentArray = [];

        for (let i = 0; i < 5; i++) {
            const remainder = this.roundNumber(rating - i, 2);
            let data = 0;

            if (remainder >= 0) {
                data = remainder >= 1 ? 100 : remainder * 100;
            }

            percentArray.push(data);
        }

        return percentArray;
    }

    clickRating = (event) => {
        const ratingStored = this.props.recipeRatingReducer.recipeRating !== null;
        const hasRating = ratingStored && this.props.recipeRatingReducer.recipeRating[this.recipeId];

        const id = hasRating ? this.props.recipeRatingReducer.recipeRating[this.recipeId].ratingId : null;
        const rating = parseInt(event.currentTarget.dataset.starNum);
        const recipeId = this.props.contentReducer.urlContent.content.rating.recipeId;

        const setRatingObject = {
            id,
            rating,
            recipeId
        };

        if (id === null) {
            this.rating.count = this.rating.count + 1;
        }

        this.setState({
            showMessage: true
        });

        this.actions.setRecipeRating(setRatingObject, this.props.environmentConfigReducer.cms.rateRecipeApiUrl);
    };

    hoverOn = (event) => {
        this.starNumber = this.getStarNumber(event);

        this.setState({
            hoveredStarNum: this.starNumber > 0 ? this.starNumber : 0,
            isHovered: this.starNumber > 0
        });
    };

    hoverOff = () => {
        this.setState({
            hoveredStarNum: 0,
            isHovered: false
        });
    };

    getSuccessMessage(showMessage) {
        if (showMessage) {
            clearTimeout(this.messageTimeout);

            this.messageTimeout = setTimeout(() => {
                this.setState({
                    showMessage: false
                });
            }, 5000);

            return <div className="message">{'Thanks for rating!'}</div>;
        }

        return null;
    }

    render() {
        const successMessage = this.getSuccessMessage(this.state.showMessage);
        const message = successMessage ? (
            <CSSTransition classNames="message" timeout={300}>
                {successMessage}
            </CSSTransition>
        ) : null;

        return (
            <div className="culinary-recipe-detail-ratings">
                <h4 className="header">{'User Rating'}</h4>
                <span className="rating-text">{`${this.roundedStarValue} out of 5 stars`}</span>
                <div className="label">{'Rate it:'}</div>
                <div className="rating">
                    <ul className="list">
                        {this.iconData.map((data, index) => (
                            <li key={index}>
                                <CulinaryRecipeDetailRatingsIcon
                                    clickRatingHandler={this.clickRating}
                                    hoveredStarNum={this.state.hoveredStarNum}
                                    index={index}
                                    isHovered={this.state.isHovered}
                                    mouseEnterRatingHandler={this.hoverOn}
                                    mouseLeaveRatingHandler={this.hoverOff}
                                    rating={this.rating}
                                    userRating={this.props.recipeRatingReducer.recipeRating}
                                    value={data}
                                />
                            </li>
                        ))}
                    </ul>
                    <TransitionGroup aria-live="polite" component="span">
                        {message}
                    </TransitionGroup>
                </div>
                <div className="meta">
                    <span>{this.rating.count}</span> {'ratings'}
                </div>
            </div>
        );
    }
}

export default CulinaryRecipeDetailRatings;
