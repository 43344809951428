import React from 'react';
import {Link, browserHistory} from 'react-router';

import {setGtmFilterSort} from '../../../../utilities/gtm-utilities';

class CulinarySearchRefinementListItem extends React.Component {
    constructor(props) {
        super(props);

        this.onClickRefinementItemHandler = () => this.onClickRefinementItem();
    }

    onClickRefinementItem() {
        const {
            addFilter,
            dropdowns,
            featuredRefinementId,
            filters,
            removeFilter,
            sortBy,
            featuredRefinement
        } = this.props;

        setGtmFilterSort(featuredRefinement.refinement.title);

        const foundDropdown = dropdowns.find((group) =>
            group.options.find((option) => option.id === Math.floor(featuredRefinementId))
        );

        const sortProp = sortBy ? `&sortBy=${sortBy}` : '';

        if (!foundDropdown) {
            browserHistory.push(`${global.window.location.pathname}?refinementId=${featuredRefinementId}${sortProp}`);

            return;
        }

        const fieldName = foundDropdown.fieldName;
        const filterIndex = filters[fieldName].indexOf(featuredRefinementId);
        const hasFilterId = filterIndex > -1;

        if (hasFilterId) {
            removeFilter({
                fieldName,
                filterIndex
            });
        } else {
            addFilter({
                fieldName,
                filterId: featuredRefinementId
            });
        }
    }

    render() {
        const {featuredRefinement, setLinks} = this.props;
        const refinement = featuredRefinement.refinement;
        const linkColor = featuredRefinement.linkColor;
        const colorStyle = {};

        if (linkColor) {
            colorStyle.color = `#${linkColor}`;
        }

        return (
            <li className="list-item">
                {setLinks ? (
                    <Link
                        className="button"
                        onClick={this.onClickRefinementItemHandler}
                        style={colorStyle}
                        to={refinement.url}
                    >
                        <span className="button-label">{refinement.title}</span>
                    </Link>
                ) : (
                    <button
                        className="button"
                        onClick={this.onClickRefinementItemHandler}
                        style={colorStyle}
                        type="button"
                    >
                        <span className="button-label">{refinement.title}</span>
                    </button>
                )}
            </li>
        );
    }
}

export default CulinarySearchRefinementListItem;
