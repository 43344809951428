import React from 'react';

class CulinaryFeatureCardRating extends React.Component {
    constructor(props) {
        super(props);

        this.starValues = this.getStarValues(this.props.rating);
    }

    roundNumber = (value, decimal) => Number(value.toFixed(decimal));

    starPercents(rating) {
        const percentArray = [];

        for (let i = 0; i < 5; i++) {
            const remainder = this.roundNumber(rating - i, 2);
            let data = 0;

            if (remainder >= 0) {
                data = remainder >= 1 ? 1 : remainder;
            }

            percentArray.push(data);
        }

        return percentArray;
    }

    getStarValues(rating) {
        if (rating) {
            return this.starPercents(rating);
        }

        return [];
    }

    render() {
        if (this.props.rating !== 0) {
            return (
                <div className="culinary-feature-card-rating">
                    {this.starValues.map((value, index) => (
                        <svg className="star" focusable={false} key={index} viewBox="0 0 16.2 16.1">
                            <linearGradient
                                gradientUnits="userSpaceOnUse"
                                id={`shape-${this.props.contentId}-${index}`}
                                x1="1.046"
                                x2="15.202"
                                y1="8.165"
                                y2="8.165"
                            >
                                <stop offset={value} stopColor="#757575" />
                                <stop offset={value} stopColor="#fff" />
                            </linearGradient>
                            <path
                                d="M10.3 5.8L8.1 1.2 5.9 5.8 1 6.5l3.6 3.6-.9 5.1 4.4-2.4 4.4 2.4-.8-5.1 3.5-3.6z"
                                fill={`url(#shape-${this.props.contentId}-${index})`}
                                stroke="#757575"
                            />
                        </svg>
                    ))}
                </div>
            );
        }

        return (
            <div className="culinary-feature-card-rating">
                <div className="info">{'No Ratings'}</div>
            </div>
        );
    }
}

export default CulinaryFeatureCardRating;
