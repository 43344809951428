import React from 'react';

import IconCheck from '../../../global/icon/icon-check';
import IconUndo from '../../../global/icon/icon-undo';

class CulinaryDirectionsCheckbox extends React.Component {
    constructor(props) {
        super(props);

        this.animatingTimeout = null;

        this.state = {
            animating: false,
            isChecked: this.getCheckedState(this.props.reducers.uiReducer.directionSelections, this.props.checkboxId)
        };
    }

    componentWillReceiveProps(nextProps) {
        const {reducers, checkboxId} = nextProps;
        const selections = reducers.uiReducer.directionSelections;
        const isChecked = this.getCheckedState(selections, checkboxId);

        if (this.state.isChecked !== isChecked) {
            this.setState({
                animating: isChecked,
                isChecked
            });

            if (isChecked) {
                this.animatingTimeout = setTimeout(() => {
                    this.setState({animating: false});
                }, 2000);
            }
        }
    }

    componentWillUnmount() {
        clearTimeout(this.animatingTimeout);
    }

    getCheckedState(selections, checkboxId) {
        let checked = false;

        selections.forEach((key) => {
            if (key === checkboxId) {
                checked = true;
            }
        });

        return checked;
    }

    getAnimClass() {
        return this.state.animating ? ' culinary-directions-checkbox-animating' : '';
    }

    render() {
        const {actions, checkboxId, index} = this.props;

        return (
            <div className={`culinary-directions-checkbox${this.getAnimClass()}`}>
                <input
                    checked={this.state.isChecked}
                    className="input"
                    id={checkboxId}
                    onChange={() => actions.updateCheckedDirection(checkboxId)}
                    type="checkbox"
                    value={index}
                />
                <label className="label" htmlFor={checkboxId}>
                    <span className="header">{'Step'}</span>
                    <span className="number">{index}</span>
                    {this.state.isChecked && <span className="footer">{'Complete'}</span>}
                    <IconUndo />
                    <IconCheck />
                </label>
            </div>
        );
    }
}

export default CulinaryDirectionsCheckbox;
