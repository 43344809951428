import React from 'react';

import CulinaryDirectionsList from '../modules/directions/culinary-directions-list';
import CulinaryFeatureIntro from '../modules/feature/culinary-feature-intro';
import CulinaryFeatureActionBar from '../modules/feature/culinary-feature-action-bar';
import CulinaryHowToDetailFeedback from '../modules/how-to/detail/culinary-how-to-detail-feedback';
import CulinaryFeatureThingsToGrab from '../modules/feature/culinary-feature-things-to-grab';
import CulinaryFeatureTags from '../modules/feature/culinary-feature-tags';
import CulinaryFeatureRelatedGuide from '../modules/feature/related-content/culinary-feature-related-guide';
import CulinaryFeatureRelatedContent from '../modules/feature/related-content/culinary-feature-related-content';
import CulinaryFeatureRelatedFooter from '../modules/feature/related-content/culinary-feature-related-footer';

import CulinaryDetailTemplateBase from './culinary-detail-template/culinary-detail-template-base';
import CulinaryDetailTemplateHd from './culinary-detail-template/culinary-detail-template-hd';

const CulinaryHowToDetailTemplate = (props) => {
    const {content, id} = props.contentReducer.urlContent;

    const reducers = {
        uiReducer: props.uiReducer,
        videoPlayerReducer: props.videoPlayerReducer
    };

    const hasTags = content.badges && content.tags;

    return (
        <CulinaryDetailTemplateBase {...props}>
            <article className="container">
                <h3 className="hdg">{'How-to Article'}</h3>
                <CulinaryDetailTemplateHd template={'how-to'} {...props}>
                    {content.thingsToGrab.length > 0 && (
                        <div className="section">
                            <CulinaryFeatureThingsToGrab displayTypeDouble thingsToGrab={content.thingsToGrab} />
                        </div>
                    )}
                </CulinaryDetailTemplateHd>
                <CulinaryFeatureActionBar
                    actions={props.actions}
                    alertReducer={props.alertReducer}
                    author={content.author}
                    id={id}
                    locationQuery={props.location.query}
                    metaData={content.metaData}
                    router={props.router}
                    simpleLayout
                />
                <CulinaryFeatureIntro
                    description={content.description}
                    layout={`${hasTags ? 'full' : 'full-no-tags'}`}
                />
                <CulinaryFeatureTags badges={content.badges} description={content.description} tags={content.tags} />
                <div className="culinary-detail-template-bd">
                    <div className={`directions${!content.description ? ' directions-no-divider' : ''}`}>
                        <h4 className="header">{'Directions'}</h4>
                        <CulinaryDirectionsList
                            actions={props.actions}
                            alertReducer={props.alertReducer}
                            directions={content.directions}
                            favoritesReducer={props.favoritesReducer}
                            id={id}
                            ingredientsVariationReducer={props.ingredientsVariationReducer}
                            isAuthenticated={props.isAuthenticated}
                            modalReducer={props.modalReducer}
                            reducers={reducers}
                        />
                    </div>
                    <div
                        className={`supporting-content${!content.description ? ' supporting-content-no-divider' : ''}`}
                    >
                        <CulinaryHowToDetailFeedback
                            actions={props.actions}
                            content={props.contentReducer.urlContent.content}
                            environmentConfigReducer={props.environmentConfigReducer}
                            feedbackReducer={props.feedbackReducer}
                            id={props.contentReducer.urlContent.id}
                        />
                    </div>
                </div>
            </article>
            <CulinaryFeatureRelatedFooter
                environmentConfigReducer={props.environmentConfigReducer}
                noChildren={!content.relatedGuideContent && !content.relatedHowToContent}
                promoContent={content.promoContent}
            >
                <CulinaryFeatureRelatedGuide
                    actions={props.actions}
                    alertReducer={props.alertReducer}
                    carouselState={props.carouselReducer.carousels}
                    favoritesReducer={props.favoritesReducer}
                    isAuthenticated={props.isAuthenticated}
                    modalReducer={props.modalReducer}
                    relatedGuideContent={content.relatedGuideContent}
                />

                <CulinaryFeatureRelatedContent
                    actions={props.actions}
                    alertReducer={props.alertReducer}
                    carouselState={props.carouselReducer.carousels}
                    favoritesReducer={props.favoritesReducer}
                    isAuthenticated={props.isAuthenticated}
                    label={'how-tos'}
                    modalReducer={props.modalReducer}
                    relatedContent={content.relatedHowToContent}
                />
            </CulinaryFeatureRelatedFooter>
        </CulinaryDetailTemplateBase>
    );
};

export default CulinaryHowToDetailTemplate;
