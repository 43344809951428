import React from 'react';

import CulinaryMealIdeasPrintModal from '../modules/meal-ideas/modals/culinary-meal-ideas-print-modal';
import CulinaryFeatureRelatedFooter from '../modules/feature/related-content/culinary-feature-related-footer';
import CulinaryFeatureEditorialBlock from '../modules/feature/culinary-feature-editorial-block';
import CulinaryMealIdeasActionBlock from '../modules/meal-ideas/actions/culinary-meal-ideas-action-block';
import CulinaryMealIdeasActionCta from '../modules/meal-ideas/actions/culinary-meal-ideas-action-cta';
import CulinaryMealIdeasCardBlocks from '../modules/meal-ideas/card/culinary-meal-ideas-card-blocks';
import CulinaryMealIdeasHero from '../modules/meal-ideas/hero/culinary-meal-ideas-hero';
import CulinaryMealIdeasPrintView from '../modules/meal-ideas/print/culinary-meal-ideas-print-view';
import CulinaryPrintButton from '../modules/culinary-print-button';
import withMountAnimation from '../../global/hoc/with-mount-animation';
import {setGtmSharingPlatform} from '../../../utilities/gtm-utilities';
import Platforms from '../../../constants/platforms';

const CulinaryMealIdeasCardsWithMountAnimation = withMountAnimation(CulinaryMealIdeasCardBlocks);

class CulinaryMealIdeasTemplate extends React.Component {
    constructor(props) {
        super(props);

        let selectedMealIdeas = props.location.query.meals;

        selectedMealIdeas = selectedMealIdeas ? selectedMealIdeas.split(',') : [];

        this.meals = props.contentReducer.urlContent.content.meals;
        this.selectedMeals = this.meals.filter((meal) => selectedMealIdeas.includes(`${meal.id}`));

        this.isPrinting = props.location.query.print === 'true';
        this.shouldPrintRecipes = props.location.query.printRecipes === 'true';
        this.shouldPrintRecipes = false;

        this.onClickPrintHandler = (event) => this.onClickPrint(event);
    }

    componentWillUnmount() {
        this.props.actions.modalClose();
    }

    onClickPrint(event) {
        setGtmSharingPlatform(Platforms.Print);
        event.preventDefault();
        this.props.actions.modalUpdate({
            isActive: true,
            modalId: CulinaryMealIdeasPrintModal.ID
        });
    }

    render() {
        const {content} = this.props.contentReducer.urlContent;
        const {router} = this.props;

        return (
            <main className="culinary-meal-ideas-template">
                {this.isPrinting && <CulinaryPrintButton />}
                <CulinaryMealIdeasHero
                    content={content}
                    onClickPrintHandler={this.onClickPrintHandler}
                    router={router}
                />
                <div className="container container-main">
                    <CulinaryMealIdeasCardsWithMountAnimation
                        {...this.props}
                        selectedMeals={this.selectedMeals}
                        withMountAnimationProps={{
                            offset: 140,
                            pageSize: 6,
                            transitionGroup: {
                                appear: true,
                                className: 'culinary-meal-ideas-card-blocks',
                                classNames: {
                                    appear: 'kf-slide-in'
                                },
                                component: 'div',
                                enter: false,
                                exit: false,
                                timeout: 1400
                            }
                        }}
                    />
                    <CulinaryMealIdeasActionBlock
                        content={content.promoBlock}
                        modalUpdate={this.props.actions.modalUpdate}
                    />
                    <CulinaryMealIdeasActionCta content={content.lookingForMoreBlock} />
                </div>
                {this.isPrinting && (
                    <CulinaryMealIdeasPrintView
                        ingredientsReducer={this.props.ingredientsReducer}
                        ingredientsVariationReducer={this.props.ingredientsVariationReducer}
                        selectedMeals={this.selectedMeals}
                        shouldPrintRecipes={this.shouldPrintRecipes}
                        shouldPrintShoppingList={false}
                    />
                )}
                <CulinaryFeatureRelatedFooter
                    environmentConfigReducer={this.props.environmentConfigReducer}
                    noChildren={!content.latestFeaturedCategoryContent}
                    promoContent={content.promoContent}
                >
                    <CulinaryFeatureEditorialBlock data={content.latestFeaturedCategoryContent} />
                </CulinaryFeatureRelatedFooter>
            </main>
        );
    }
}

export default CulinaryMealIdeasTemplate;
