import React from 'react';

const IconVideo = ({hover}) => {
    const hoverClass = hover === false ? ' icon-video-no-hover' : '';

    return (
        <div className={`icon-video${hoverClass}`}>
            <div className="circle">
                <div className="triangle" />
            </div>
        </div>
    );
};

export default IconVideo;
