import React from 'react';

const IconFlame = () => (
    <svg
        className="icon-flame"
        focusable={false}
        height="29.1"
        viewBox="0 0 24 29.1"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M12 9.1c-2.4 2.4-6.5 5.7-6.5 9.2 0 3.5 2.9 6.3 6.5 6.3s6.5-2.8 6.5-6.3-4-6.7-6.5-9.2zm0 13.7c-2.5 0-4.6-2.2-4.6-4.8s2.8-4.7 4.6-6.6c1.8 1.9 4.6 3.9 4.6 6.6s-2.1 4.8-4.6 4.8z" />
        <path d="M12 0C7.5 4.5 0 10.8 0 17.3s5.4 11.8 12 11.8 12-5.3 12-11.8S16.7 4.6 12 0zm0 27.3c-5.6 0-10.2-4.5-10.2-10S8.2 6.5 12 2.7c4 3.9 10.2 9.1 10.2 14.6s-4.6 10-10.2 10z" />
    </svg>
);

export default IconFlame;
