import {FORM_INPUT_CLEAR, FORM_INPUT_UPDATE} from '../action-types';

export const formInputClear = () => ({
    type: FORM_INPUT_CLEAR
});

export const formInputUpdate = (inputData) => ({
    data: inputData,
    type: FORM_INPUT_UPDATE
});
