import React from 'react';

const IconTwitter = ({height, width}) => (
    <svg className="icon-twitter" focusable={false} height={height} viewBox="0 0 31 31" width={width}>
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
            <g transform="translate(-812.000000, -607.000000)">
                <g transform="translate(720.000000, 607.000000)">
                    <g transform="translate(92.000000, 0.000000)">
                        <path
                            d="M31,15.75 C31,24.172 24.172,31 15.75,31 C7.328,31 0.5,24.172 0.5,15.75 C0.5,7.328 7.328,0.5 15.75,0.5 C24.172,0.5 31,7.328 31,15.75"
                            fill="#1DA1F2"
                        />
                        <path
                            d="M21.3359,13.0645 C21.3449,13.1895 21.3449,13.3145 21.3449,13.4395 C21.3449,17.2515 18.4429,21.6425 13.1419,21.6425 C11.5079,21.6425 9.9899,21.1685 8.7139,20.3475 C8.9459,20.3755 9.1689,20.3835 9.4099,20.3835 C10.7579,20.3835 11.9989,19.9285 12.9899,19.1525 C11.7229,19.1255 10.6599,18.2945 10.2939,17.1525 C10.4729,17.1785 10.6509,17.1975 10.8389,17.1975 C11.0979,17.1975 11.3569,17.1615 11.5979,17.0985 C10.2759,16.8315 9.2849,15.6705 9.2849,14.2695 L9.2849,14.2335 C9.6689,14.4475 10.1149,14.5825 10.5889,14.5995 C9.8119,14.0825 9.3029,13.1985 9.3029,12.1985 C9.3029,11.6635 9.4459,11.1715 9.6959,10.7435 C11.1149,12.4935 13.2489,13.6355 15.6409,13.7605 C15.5969,13.5455 15.5689,13.3235 15.5689,13.0995 C15.5689,11.5105 16.8549,10.2165 18.4529,10.2165 C19.2829,10.2165 20.0319,10.5645 20.5599,11.1275 C21.2109,11.0015 21.8359,10.7605 22.3899,10.4305 C22.1749,11.1005 21.7199,11.6635 21.1219,12.0195 C21.7019,11.9575 22.2649,11.7965 22.7819,11.5735 C22.3899,12.1445 21.8979,12.6535 21.3359,13.0645"
                            fill="#FFFFFF"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default IconTwitter;
