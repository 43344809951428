import React from 'react';

import FeatureSearchBar from '../../../feature/feature-search-bar';

import CulinarySearchHeroBackground from './culinary-search-hero-background';

class CulinarySearchHero extends React.Component {
    constructor(props) {
        super(props);

        this.onLoadImageHandler = () => this.onLoadImage();
        this.onSubmitSearchHandler = () => this.onSubmitSearch();

        this.state = {
            // eslint-disable-next-line react/no-unused-state
            heroImageLoaded: false,
            isActive: this.props.hasSearchQuery || this.props.isServerSide
        };
    }

    onSubmitSearch() {
        this.setState({isActive: true});
    }

    onLoadImage() {
        this.setState({
            // eslint-disable-next-line react/no-unused-state
            heroImageLoaded: true
        });
    }

    render() {
        const searchType = this.props.isEditorial ? 'editorial' : 'recipe';
        const heading = this.props.isEditorial ? 'Search Advice & How-Tos' : 'Find a Recipe';
        const placeholder = this.props.isEditorial ? 'I want to learn about\u2026' : 'I want to make\u2026';
        const labelStyle = this.props.isEditorial ? 'compact' : '';

        return (
            <section className={`culinary-search-hero${this.state.isActive ? ' active' : ''}`}>
                <CulinarySearchHeroBackground {...this.props} />
                <div className="hero-container">
                    <h2 className="heading">{heading}</h2>
                    <FeatureSearchBar
                        autocompleteResults={this.props.autocompleteResults}
                        classExt="editorial-search"
                        hasSearchQuery={this.props.hasSearchQuery}
                        label={heading}
                        labelStyle={labelStyle}
                        placeholder={placeholder}
                        searchTerm={this.props.searchTerm}
                        searchType={searchType}
                        submitSearchHandler={this.onSubmitSearchHandler}
                    />
                </div>
            </section>
        );
    }
}

export default CulinarySearchHero;
