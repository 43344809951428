import React from 'react';

import CulinaryEditorialRowItem from './culinary-editorial-row-item';

class CulinaryEditorialRow extends React.Component {
    getStyles = () => {
        return this.props.hasStyles ? this.props.styles : {};
    };

    shouldFocus = (cardIndex) => {
        return this.props.focusIndex === cardIndex && this.props.focusIndex > 0;
    };

    render() {
        return (
            <div className="culinary-editorial-row">
                {this.props.cards.map((card, cardIndex) => {
                    return (
                        <CulinaryEditorialRowItem
                            key={cardIndex}
                            result={card}
                            shouldFocus={this.shouldFocus(cardIndex)}
                            styles={this.getStyles()}
                        />
                    );
                })}
            </div>
        );
    }
}

export default CulinaryEditorialRow;
