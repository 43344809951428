import logger from '@hy-vee/logger';

import RequestModel from '../../models/request-model';

import BaseApiService from './base-api-service';

const logResponseIfUnsuccessful = (endpoint, method) => (body) => {
    if (body && body.success === false) {
        const message = body.message || 'No error message on response';

        logger.error(`Error during request ${method} ${endpoint}, ${message}`);
    }

    return body;
};

class ContentApiService extends BaseApiService {
    create() {
        return new this.constructor(this);
    }

    fetchUrlContent(url, domainUrl, headers = null) {
        const endpoint = domainUrl + url;
        const requestModel = new RequestModel(endpoint);

        requestModel.headers = headers;

        return this._getRequest(requestModel);
    }

    submitHowToFeedback(id, response, endpoint) {
        const requestModel = new RequestModel(endpoint);

        requestModel.data = {
            ContentId: id,
            Id: null,
            IsHelpful: response
        };

        return this._postRequest(requestModel);
    }

    getAutocompleteResults(searchTerm, endpoint) {
        const requestModel = new RequestModel(endpoint);

        requestModel.data = {q: searchTerm};

        return this._getRequest(requestModel);
    }

    getSearchResults(searchQuery, endpoint) {
        const requestModel = new RequestModel(endpoint);

        requestModel.data = searchQuery;

        return this._getRequest(requestModel);
    }

    getRecipeFavorites(recipeIds, endpoint) {
        const requestModel = new RequestModel(endpoint);

        requestModel.data = {
            Ids: recipeIds
        };

        return this._getRequest(requestModel);
    }

    _getRequest(requestModel) {
        return super.getRequest(requestModel).then(logResponseIfUnsuccessful(requestModel.endpoint, 'GET'));
    }

    _postRequest(requestModel) {
        return super.postRequest(requestModel).then(logResponseIfUnsuccessful(requestModel.endpoint, 'POST'));
    }
}

export default new ContentApiService();
