import React from 'react';

class CulinaryLoadingRecipe extends React.Component {
    constructor(props) {
        super(props);

        this.label = this.props.label ? this.props.label : 'Loading Recipes...';
    }

    render() {
        return (
            <div className="culinary-loading-recipe">
                <div className="mask">
                    <div className="pot">
                        <svg height="125" viewBox="0 0 110 125" width="110">
                            <path
                                className="handle-left"
                                d="M17 87.2c-.7.2-1.3.3-2.1.3-3.9 0-7.1-3.2-7.1-7.1s3.2-7.1 7.1-7.1c.8 0 1.5.1 2.2.4"
                            />
                            <path
                                className="handle-right"
                                d="M90.2 73.5c.5-.1 1.1-.2 1.7-.2 3.9 0 7.1 3.2 7.1 7.1s-3.2 7.1-7.1 7.1c-.7 0-1.3-.1-1.9-.3"
                            />
                            <path
                                className="bowl"
                                d="M17.6 81.5v34.6c0 3.2 2.5 6.2 5.9 6.2h59.4c3.7 0 6.4-2.4 6.4-6.2V71.9H17.6v9.6z"
                            />
                        </svg>
                        <div className="lid">
                            <svg height="125" viewBox="0 0 110 125" width="110">
                                <path
                                    className="handle"
                                    d="M45.7 53.1v-1.9c0-2 2.2-3.7 4.8-3.7h5.8c2.6 0 4.8 1.7 4.8 3.7v1.9"
                                />
                                <path className="cover" d="M85.1 65c-3-4.5-15.9-11-31.4-11-15.6 0-28.6 6.6-31.4 11.1" />
                                <path
                                    className="rim"
                                    d="M14.8 71.9H92l2.4-2.4v-1s-.2-2.4-2.4-2.4H14.8s-2.4 0-2.4 2.4v1s0 2.4 2.4 2.4z"
                                />
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="shadow">
                    <svg height="125" viewBox="0 0 110 125" width="110">
                        <path className="shadow-long" d="M93.5 122.3h-91" />
                        <path className="shadow-short" d="M98.5 122.3h9" />
                    </svg>
                </div>
                <div className="label">{this.label}</div>
            </div>
        );
    }
}

export default CulinaryLoadingRecipe;
