import {MEAL_IDEAS_KEY_UPDATE} from '../action-types';

const getInitialState = () => ({
    mealIdeaKey: null
});

const mealIdeasModalUpdate = (state, mealIdeaKey) => ({
    ...state.mealIdeaKey,
    ...mealIdeaKey
});

const reducerMap = {
    [MEAL_IDEAS_KEY_UPDATE]: mealIdeasModalUpdate
};

export default (state = getInitialState(), {data, type}) => {
    if (reducerMap[type]) {
        return reducerMap[type](state, data);
    }

    return state;
};

export {getInitialState};
