import React from 'react';

const IconDismiss = () => (
    <svg className="icon-dismiss" focusable={false} viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd" transform="translate(1.053 1)">
            <circle cx="13" cy="13" r="13" stroke="#3F3F3F" />
            <polygon
                fill="#3F3F3F"
                points="18.64 8.18 14 12.82 18.64 17.46 17.46 18.64 12.82 14 8.18 18.64 7 17.46 11.64 12.82 7 8.18 8.18 7 12.82 11.64 17.46 7"
            />
        </g>
    </svg>
);

export default IconDismiss;
