import React from 'react';
import ReactDOM from 'react-dom';
import {Link} from 'react-router';
import {Lazy} from 'react-lazy';

import IconVideo from '../../../../global/icon/icon-video';
import CulinaryFavoriteBtn from '../../favorite/culinary-favorite-btn';
import {getContentCategorizationData} from '../../../../../services/content-categorization-service';
import {getPreviewImageState} from '../../../../../services/preview-image-service';
import CulinaryTotalTime from '../culinary-feature-total-time';

import CulinaryFeatureCardRating from './culinary-feature-card-rating';

class CulinaryFeatureCard extends React.Component {
    constructor(props) {
        super(props);

        this.onImageLoadHandler = () => this.onImageLoad();
        this.scrollToTopOfPageHandler = (event) => this.scrollToTopOfPage(event);

        this.isNotHowTo = this.props.card.contentType !== 'How-To';

        this.state = {
            imageLoaded: false,
            mediaUrl: '',
            shouldCrop: true
        };
    }

    componentDidMount() {
        this.setPreviewImage(this.props.card);

        if (this.image && this.image.complete) {
            this.onImageLoad();
        }
    }

    componentDidUpdate() {
        if (this.props.shouldFocus && this.mediaLink) {
            /* eslint-disable-next-line react/no-find-dom-node */
            const mediaLinkElement = ReactDOM.findDOMNode(this.mediaLink);

            mediaLinkElement.focus();
        }
    }

    scrollToTopOfPage() {
        setTimeout(() => {
            global.window.scrollTo(0, 0);
        }, 0);
    }

    onImageLoad() {
        this.setState({
            imageLoaded: true
        });
    }

    generateMarkup(props) {
        const {card} = props;

        this.componentClass = this.getComponentClass(this.props.layoutType);
        this.videoIconMarkup = this.getVideoIconMarkup(card);
        this.cardTitleMarkup = this.getCardTitleMarkup(card);
        this.ratingMarkup = this.getRatingMarkup();
    }

    async setPreviewImage(card) {
        const previewImageState = await getPreviewImageState(card.media);

        this.setState(previewImageState);
    }

    getComponentClass(layoutType) {
        const baseClass = 'culinary-feature-card';

        switch (layoutType) {
            case 'inline':
                return `${baseClass} ${baseClass}-inline`;
            case 'big':
                return `${baseClass} ${baseClass}-big`;
            case 'rating':
                return `${baseClass} ${baseClass}-rating`;
            default:
                return baseClass;
        }
    }

    getVideoIconMarkup(card) {
        if (card.media !== null && card.media.type === 'Video') {
            return <IconVideo hover={false} />;
        }

        return null;
    }

    getCardTitleMarkup(card) {
        if (card.title !== null) {
            return (
                <Link className="title-link" to={card.url}>
                    <span className="link-text">{card.title}</span>
                </Link>
            );
        }

        return null;
    }

    getRatingMarkup() {
        if (this.props.layoutType === 'rating' && this.props.card.rating !== null) {
            return <CulinaryFeatureCardRating contentId={this.props.card.contentId} rating={this.props.card.rating} />;
        }

        return null;
    }

    getHeadingMarkup(layoutType, category) {
        if (layoutType === 'inline' && category !== null) {
            return <h5 className="main-heading">{`Related Content, ${category.label}`}</h5>;
        }

        return null;
    }

    render() {
        let imageUrl = this.state.mediaUrl || '';

        if (imageUrl && this.state.shouldCrop) {
            imageUrl = `${imageUrl}&width=875&height=657`;
        }

        const backgroundImage = {
            backgroundImage: imageUrl ? `url(${imageUrl})` : 'none'
        };

        const card = this.props.card;
        const contentCategorization = getContentCategorizationData(card);
        const styles = this.props.hasStyles ? this.props.styles : {};

        this.generateMarkup(this.props);

        return (
            <div className={this.componentClass} role="group" style={styles}>
                {this.getHeadingMarkup(this.props.layoutType, contentCategorization)}

                {this.props.layoutType === 'inline' ? (
                    <Link className="image-link" style={backgroundImage} to={card.url}>
                        <div className="link-label">{card.title}</div>
                        <div className="border">
                            <div className="top-left" />
                            <div className="bottom-right" />
                        </div>
                        {this.videoIconMarkup}
                    </Link>
                ) : (
                    <Link
                        aria-label={card.title ? card.title : ''}
                        className="image-link"
                        ref={(element) => {
                            this.mediaLink = element;
                        }}
                        to={card.url}
                    >
                        <div className="link-label">{card.title}</div>
                        {imageUrl && (
                            <Lazy className="container" component="div" cushion={500}>
                                <img
                                    alt={card.media.alt}
                                    className={`image${this.state.imageLoaded ? ' image-loaded' : ''}`}
                                    onLoad={this.onImageLoadHandler}
                                    ref={(node) => {
                                        this.image = node;
                                    }}
                                    src={imageUrl}
                                />
                            </Lazy>
                        )}
                        <div className="border">
                            <div className="top-left" />
                            <div className="bottom-right" />
                        </div>
                        {this.videoIconMarkup}
                    </Link>
                )}

                <div className="main-content">
                    <div className="body">
                        {contentCategorization && contentCategorization.label && contentCategorization.url && (
                            <Link
                                className="category-link"
                                onClick={this.scrollToTopOfPageHandler}
                                to={contentCategorization.url}
                            >
                                <span className="text">{contentCategorization.label}</span>
                            </Link>
                        )}
                        {this.cardTitleMarkup}
                    </div>

                    {this.isNotHowTo && (
                        <div className="footer">
                            {this.ratingMarkup}
                            <div className="split">
                                <CulinaryTotalTime card={this.props.card} />
                                <CulinaryFavoriteBtn
                                    actions={this.props.actions}
                                    alertReducer={this.props.alertReducer}
                                    cardIndex={this.props.cardIndex}
                                    favoritesReducer={this.props.favoritesReducer}
                                    id={this.props.card.contentId}
                                    isAuthenticated={this.props.isAuthenticated}
                                    modalReducer={this.props.modalReducer}
                                    modalTriggerId={`card-favorite-button-${this.props.id}-${this.props.card.contentId}`}
                                    recipeTitle={card.title}
                                    showConfirmation={this.props.showConfirmation}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default CulinaryFeatureCard;
