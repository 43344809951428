import React from 'react';
import {Link} from 'react-router';

import {getPreviewImageState} from '../../../../../services/preview-image-service';
import CulinaryAddToListBtn from '../../add-to-list/culinary-add-to-list-btn';

import CulinaryMealIdeasCardNutritionBtn from './culinary-meal-ideas-card-nutrition-btn';

class CulinaryMealIdeasCard extends React.Component {
    constructor(props) {
        super(props);

        this.subMediaMarkup = this.getSubMediaMarkup();
        this.itemsMarkup = this.getItemsMarkup();
        this.ingredientsToAdd = this.getIngredientsToAdd();

        this.state = {
            mediaUrl: '',
            shouldCrop: true
        };
    }

    componentDidMount() {
        this.setPreviewImage(this.props.meal.primaryImage);
    }

    getLinkMarkup(recipe) {
        if (recipe && recipe.url) {
            return (
                <Link className="link" to={recipe.url}>
                    {'Recipe'}
                </Link>
            );
        }

        return null;
    }

    getSubMediaMarkup() {
        const {mealItems} = this.props.meal;
        const subMedia = mealItems.find((mealItem) => {
            const primaryImageUrl = this.props.meal.primaryImage.url;
            const itemMediaUrl = mealItem.primaryMedia ? mealItem.primaryMedia.url : '';

            if (mealItem.primaryMedia && primaryImageUrl !== itemMediaUrl) {
                return true;
            }

            return false;
        });

        if (subMedia) {
            return (
                <img
                    alt={subMedia.primaryMedia.alt}
                    className="sub-media"
                    src={`${subMedia.primaryMedia.cropUrl}&width=80&height=80`}
                />
            );
        }

        return null;
    }

    getItemsMarkup() {
        const {mealItems} = this.props.meal;
        const cardItems = mealItems.map((mealItem, index) => (
            <div className={index === 0 ? 'entree' : 'side'} key={index}>
                <span className="text">{mealItem.title}</span>
                {this.getLinkMarkup(mealItem.recipe)}
            </div>
        ));

        return cardItems;
    }

    getIngredientsToAdd() {
        const allIngredients = this.props.meal.ingredients.reduce(
            (ingredients, ingredientGroup) => ingredients.concat(ingredientGroup.ingredients),
            []
        );

        return allIngredients.map((ingredient) => ({displayName: ingredient}));
    }

    async setPreviewImage(primaryImage) {
        const previewImageState = await getPreviewImageState(primaryImage);

        this.setState(previewImageState);
    }

    render() {
        const styles = this.props.hasStyles ? this.props.styles : {};
        const {primaryImage, id} = this.props.meal;
        const nutrientCheck = this.props.meal.nutritionalInfo.nutrients.find(
            (nutrient) => nutrient.value !== null && nutrient.value !== '0'
        );
        const shouldPrint =
            this.props.selectedMeals && this.props.selectedMeals.find((mealToPrint) => mealToPrint.id === id);

        const printClass = shouldPrint ? '' : 'hide-print';

        let imageUrl = null;

        if (this.state.mediaUrl && this.state.shouldCrop) {
            imageUrl = `${this.state.mediaUrl}&width=875&height=657`;
        } else {
            imageUrl = this.state.mediaUrl;
        }

        return (
            <section className={`culinary-meal-ideas-card ${printClass}`} style={styles}>
                <div className="media">
                    <div className="nutrition">
                        {imageUrl && <img alt={primaryImage.alt} className="main" src={imageUrl} />}
                        {this.props.meal.nutritionalInfo.dailyValues && nutrientCheck && (
                            <CulinaryMealIdeasCardNutritionBtn
                                actions={this.props.actions}
                                mealId={this.props.meal.title}
                                modalReducer={this.props.modalReducer}
                            />
                        )}
                    </div>
                    {this.subMediaMarkup}
                </div>
                <div className="body">{this.itemsMarkup}</div>
                <div className="footer">
                    <CulinaryAddToListBtn
                        actions={this.props.actions}
                        environmentConfigReducer={this.props.environmentConfigReducer}
                        ingredientsToAdd={this.ingredientsToAdd}
                        isAuthenticated={this.props.isAuthenticated}
                        layoutType="inline-block"
                        modalReducer={this.props.modalReducer}
                        modalTriggerId={`addToListButton_${id}`}
                    />
                </div>
            </section>
        );
    }
}

export default CulinaryMealIdeasCard;
