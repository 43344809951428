import fetch from 'cross-fetch';

import ResponseModel from '../../models/response-model';

import ApiQueryService from './api-query-service';

class BaseApiService {
    _abortPromise = null;

    _abortResolver = null;

    async abort() {
        if (this._abortPromise) {
            const response = {
                ok: false,
                status: 409,
                statusText: 'Abort Fetch',
                text: () => null
            };

            await this._abortResolver(response);

            return true;
        }

        return false;
    }

    getRequest(requestModel, cacheBuster = null) {
        const data = Object.assign(cacheBuster ? {cachebuster: cacheBuster} : {}, requestModel.data);

        const queryString = Object.keys(data).length ? `?${ApiQueryService.toQueryString(data)}` : '';
        const endpoint = `${requestModel.endpoint}${queryString}`;

        return this._fetch(endpoint, {
            credentials: 'include',
            headers: requestModel.headers,
            method: 'GET'
        });
    }

    postRequest(requestModel) {
        return this._fetch(requestModel.endpoint, {
            body: JSON.stringify(requestModel.data),
            credentials: 'include',
            headers: requestModel.headers,
            method: 'POST'
        });
    }

    putRequest(requestModel) {
        return this._fetch(requestModel.endpoint, {
            body: JSON.stringify(requestModel.data),
            credentials: 'include',
            headers: requestModel.headers,
            method: 'PUT'
        });
    }

    patchRequest(requestModel) {
        return this._fetch(requestModel.endpoint, {
            body: JSON.stringify(requestModel.data),
            credentials: 'include',
            headers: requestModel.headers,
            method: 'PATCH'
        });
    }

    deleteRequest(requestModel) {
        return this._fetch(requestModel.endpoint, {
            body: JSON.stringify(requestModel.data),
            credentials: 'include',
            headers: requestModel.headers,
            method: 'DELETE'
        });
    }

    async _fetch(endpoint, options) {
        this._setupAbort();

        let response;

        try {
            response = await Promise.race([fetch(endpoint, options), this._abortPromise, this._fetchTimeout(90000)]);

            this._abortPromise = null;
        } catch (error) {
            response = null;
        }

        const body = await this._parseResponse(response);

        const responseModel = this._createResponse(response, body);

        return responseModel;
    }

    async _parseResponse(response) {
        if (!response) {
            return null;
        }

        let body;

        const bodyText = await response.text();

        try {
            body = JSON.parse(bodyText);
        } catch (error) {
            body = bodyText === '' ? null : bodyText;
        }

        return body;
    }

    _createResponse(response, body = null) {
        const responseModel = new ResponseModel();

        responseModel.data = body;
        responseModel.statusCode = response ? response.status : null;
        responseModel.statusText = response ? response.statusText : null;
        responseModel.success = response ? response.ok : false;

        return responseModel;
    }

    _fetchTimeout(requestTimeout) {
        const response = {
            ok: false,
            status: 408,
            statusText: 'Fetch request timed out.',
            text: () => null
        };

        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(response);
            }, requestTimeout);
        });
    }

    _setupAbort() {
        this._abortPromise = new Promise((resolve) => {
            this._abortResolver = resolve;
        });
    }
}

export default BaseApiService;
