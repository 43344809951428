import React from 'react';

import CulinaryDirectionsList from '../modules/directions/culinary-directions-list';
import CulinaryFeatureIntro from '../modules/feature/culinary-feature-intro';
import CulinaryFeatureRecommendedPairing from '../modules/feature/culinary-feature-recommended-pairing';
import CulinaryFeatureThingsToGrab from '../modules/feature/culinary-feature-things-to-grab';
import CulinaryRecipeDetailIngredients from '../modules/recipe/detail/ingredients/culinary-recipe-detail-ingredients';
import CulinaryRecipeDetailNutritionInfo from '../modules/recipe/detail/nutrition/culinary-recipe-detail-nutrition-info';
import CulinaryRecipeDetailVariations from '../modules/recipe/detail/variations/culinary-recipe-detail-variations';
import FeatureArticleSource from '../../feature/feature-article-source';
import CulinaryFeatureActionBar from '../modules/feature/culinary-feature-action-bar';
import CulinaryRecipeDetailPreStep from '../modules/recipe/detail/culinary-recipe-detail-pre-step';
import CulinaryRecipeDetailTimingSummary from '../modules/recipe/detail/timing/culinary-recipe-detail-timing-summary';
import CulinaryRecipeDetailRatings from '../modules/recipe/detail/ratings/culinary-recipe-detail-ratings';
import CulinaryFeatureTags from '../modules/feature/culinary-feature-tags';
import CulinaryFeatureRelatedGuide from '../modules/feature/related-content/culinary-feature-related-guide';
import CulinaryFeatureRelatedContent from '../modules/feature/related-content/culinary-feature-related-content';
import CulinaryFeatureRelatedFooter from '../modules/feature/related-content/culinary-feature-related-footer';
import RecipeJsonLd from '../modules/recipe/detail/recipe-json-ld';
import {initWaypoints, removeWaypoints} from '../../../services/waypoint-animation-service';

import CulinaryDetailTemplateBase from './culinary-detail-template/culinary-detail-template-base';
import CulinaryDetailTemplateHd from './culinary-detail-template/culinary-detail-template-hd';

class CulinaryRecipeDetailTemplate extends React.Component {
    constructor(props) {
        super(props);

        const {content, id} = this.props.contentReducer.urlContent;

        this.content = content;
        this.id = id;
        this.urlContentPath = this.props.contentReducer.urlContentPath;
        this.selectedVariationIndex = this.getVariationIndex();
        this.activeVariation = this.content.variations[this.selectedVariationIndex];
        this.articleSource = this.content.source;
        this.recommendedPairingData = this.content.recommendedPairing;
        this.thingsToGrab = this.activeVariation.thingsToGrab;

        this.reducers = {
            contentReducer: this.props.contentReducer,
            recipeDetailIngredientsReducer: this.props.recipeDetailIngredientsReducer,
            uiReducer: this.props.uiReducer,
            videoPlayerReducer: this.props.videoPlayerReducer
        };
    }

    componentDidMount() {
        this.props.actions.setSelectedVariationIndex(this.selectedVariationIndex);

        initWaypoints();
    }

    componentWillReceiveProps(nextProps) {
        const {content, id} = nextProps.contentReducer.urlContent;

        this.content = content;
        this.id = id;
        this.urlContentPath = this.props.contentReducer.urlContentPath;
        this.selectedVariationIndex = nextProps.ingredientsVariationReducer.selectedVariationIndex;
        this.activeVariation = content.variations[this.selectedVariationIndex];
        this.articleSource = content.source;
        this.recommendedPairingData = content.recommendedPairing;
        this.thingsToGrab = this.activeVariation.thingsToGrab;

        this.reducers = {
            contentReducer: nextProps.contentReducer,
            recipeDetailIngredientsReducer: nextProps.recipeDetailIngredientsReducer,
            uiReducer: nextProps.uiReducer,
            videoPlayerReducer: nextProps.videoPlayerReducer
        };
    }

    componentWillUnmount() {
        this.props.actions.resetSelectedIngredients();

        removeWaypoints();
    }

    getVariationIndex() {
        const defaultIndex = this.content.variations.findIndex((variation) => variation.isDefault === true);
        const serverSideQuery = this.props.serverSideLocation.query ? this.props.serverSideLocation.query : {};
        const clientSideQuery = this.props.location.query ? this.props.location.query : {};

        if (clientSideQuery.variation === undefined && serverSideQuery.variation !== undefined) {
            return this.getValidVariationIndex(serverSideQuery.variation);
        } else if (clientSideQuery.variation !== undefined) {
            return this.getValidVariationIndex(clientSideQuery.variation);
        }

        return this.getValidVariationIndex(defaultIndex);
    }

    getValidVariationIndex(variationIndex) {
        let tabIndex = 0;

        if (variationIndex < this.content.variations.length) {
            tabIndex = variationIndex > 0 ? variationIndex : 0;
        }

        return Number(tabIndex);
    }

    render() {
        const hasTags = this.content.badges && this.content.tags;

        return (
            <CulinaryDetailTemplateBase {...this.props}>
                <article className="container">
                    <h3 className="hdg">{'Recipe'}</h3>
                    <CulinaryDetailTemplateHd showJumpLink {...this.props}>
                        <CulinaryRecipeDetailRatings {...this.props} />
                        <CulinaryRecipeDetailTimingSummary {...this.props} />
                    </CulinaryDetailTemplateHd>
                    {this.content && this.content.author && this.content.metaData && (
                        <CulinaryFeatureActionBar
                            actions={this.props.actions}
                            alertReducer={this.props.alertReducer}
                            author={this.content.author}
                            favoritesReducer={this.props.favoritesReducer}
                            id={this.id}
                            isAuthenticated={this.props.isAuthenticated}
                            locationQuery={this.props.location.query}
                            metaData={this.content.metaData}
                            modalReducer={this.props.modalReducer}
                            router={this.props.router}
                        />
                    )}
                    {this.content && this.content.description && (
                        <CulinaryFeatureIntro
                            description={this.content.description}
                            layout={`${hasTags ? 'full' : 'full-no-tags'}`}
                        />
                    )}
                    {this.content && this.content.tags && this.content.badges && (
                        <CulinaryFeatureTags
                            badges={this.content.badges}
                            description={this.content.description}
                            tags={this.content.tags}
                        />
                    )}
                    <div className="culinary-detail-template-bd">
                        <div
                            className={`info-supplies ${!this.content.description ? 'info-supplies-no-divider' : ''} `}
                        >
                            <CulinaryRecipeDetailVariations
                                {...this.props}
                                selectedVariationIndex={this.selectedVariationIndex}
                            />
                            {this.activeVariation.ingredientGroups && (
                                <CulinaryRecipeDetailIngredients
                                    actions={this.props.actions}
                                    description={this.content.description}
                                    environmentConfigReducer={this.props.environmentConfigReducer}
                                    ingredientGroups={this.activeVariation.ingredientGroups}
                                    ingredientsReducer={this.props.ingredientsReducer}
                                    ingredientsVariationReducer={this.props.ingredientsVariationReducer}
                                    isAuthenticated={this.props.isAuthenticated}
                                    modalReducer={this.props.modalReducer}
                                    recipeTitle={this.content.title}
                                    recipeUrl={this.urlContentPath}
                                    servings={this.activeVariation.servings}
                                />
                            )}
                            {this.thingsToGrab && (
                                <div className="things-to-grab">
                                    <CulinaryFeatureThingsToGrab
                                        displayTypeDouble={false}
                                        thingsToGrab={this.thingsToGrab}
                                    />
                                </div>
                            )}
                        </div>

                        <div
                            className={`directions ${!this.content.description ? 'directions-recipe-no-divider' : ''} `}
                        >
                            <h4 className="header">{'Directions'}</h4>
                            {this.activeVariation.prepStep && (
                                <CulinaryRecipeDetailPreStep preStep={this.activeVariation.prepStep} />
                            )}
                            {this.activeVariation.directions && (
                                <CulinaryDirectionsList
                                    actions={this.props.actions}
                                    alertReducer={this.props.alertReducer}
                                    directions={this.activeVariation.directions}
                                    favoritesReducer={this.props.favoritesReducer}
                                    id={this.id}
                                    ingredientsVariationReducer={this.props.ingredientsVariationReducer}
                                    isAuthenticated={this.props.isAuthenticated}
                                    modalReducer={this.props.modalReducer}
                                    reducers={this.reducers}
                                />
                            )}
                            {this.content.nutritionalInfo && (
                                <CulinaryRecipeDetailNutritionInfo
                                    isRecipeDetail
                                    nutritionInfo={this.content.nutritionalInfo}
                                />
                            )}
                        </div>
                        <div className="supporting-content">
                            {this.recommendedPairingData && (
                                <CulinaryFeatureRecommendedPairing {...this.recommendedPairingData} />
                            )}
                            {this.articleSource && <FeatureArticleSource articleSource={this.articleSource} />}
                        </div>
                    </div>
                    {this.content && (
                        <RecipeJsonLd content={this.content} selectedVariationIndex={this.selectedVariationIndex} />
                    )}
                </article>
                {this.content && this.content.promoContent && (
                    <CulinaryFeatureRelatedFooter
                        environmentConfigReducer={this.props.environmentConfigReducer}
                        noChildren={!this.content.relatedGuideContent && !this.content.relatedRecipeContent}
                        promoContent={this.content.promoContent}
                    >
                        <CulinaryFeatureRelatedGuide
                            actions={this.props.actions}
                            alertReducer={this.props.alertReducer}
                            carouselState={this.props.carouselReducer.carousels}
                            favoritesReducer={this.props.favoritesReducer}
                            isAuthenticated={this.props.isAuthenticated}
                            isRecipeDetail
                            modalReducer={this.props.modalReducer}
                            relatedGuideContent={this.content.relatedGuideContent}
                        />
                        <CulinaryFeatureRelatedContent
                            actions={this.props.actions}
                            alertReducer={this.props.alertReducer}
                            carouselState={this.props.carouselReducer.carousels}
                            favoritesReducer={this.props.favoritesReducer}
                            isAuthenticated={this.props.isAuthenticated}
                            label={'recipes'}
                            modalReducer={this.props.modalReducer}
                            relatedContent={this.content.relatedRecipeContent}
                        />
                    </CulinaryFeatureRelatedFooter>
                )}
            </CulinaryDetailTemplateBase>
        );
    }
}

export default CulinaryRecipeDetailTemplate;
