import React from 'react';

class CulinaryFeatureGuideHeroSectionsNavbar extends React.Component {
    constructor(props) {
        super(props);

        this.onClickNextHandler = () => this.onClickNext();
    }

    componentDidMount() {
        require('velocity-animate');
    }

    onClickNext = () => {
        const newSectionIndex = this.props.currentSectionIndex + 1;

        this.scrollToTarget(this.props.dropLinks[newSectionIndex].id);
    };

    scrollToTarget(id) {
        const scrollTarget = global.document.querySelector(`#${id}`);

        if (scrollTarget) {
            /* eslint-disable-next-line no-undef, new-cap */
            Velocity(scrollTarget, 'scroll', {
                complete: () => {
                    const pageYOffset = global.window.pageYOffset;

                    scrollTarget.setAttribute('tabindex', '-1');
                    scrollTarget.focus();

                    global.window.scrollTo(0, pageYOffset);
                },
                duration: 1000,
                offset: this.props.isScrolled ? -64 : -164
            });
        }
    }

    getStateClass() {
        const navBarClass = 'culinary-feature-guide-hero-sections-navbar';

        return this.props.showNavBar ? `${navBarClass} ${navBarClass}-active` : navBarClass;
    }

    getNextButtonMarkup() {
        if (this.props.currentSectionIndex < this.props.dropLinks.length - 1) {
            return (
                <button aria-label="next" className="button" onClick={this.onClickNextHandler} type="button">
                    <span className="label">{'Next'}</span>
                    <span className="icon" />
                </button>
            );
        }

        return null;
    }

    getLabelMarkup() {
        const label = this.props.dropLinks[this.props.currentSectionIndex]
            ? this.props.dropLinks[this.props.currentSectionIndex].label
            : null;
        let labelMarkup = null;

        if (label) {
            labelMarkup = <div className="section">{label}</div>;
        }

        return labelMarkup;
    }

    render() {
        if (!this.props.dropLinks.length) {
            return null;
        }

        return (
            <div className={this.getStateClass()}>
                <div className="container">
                    <div className="content">
                        <div className="title">{this.props.content.shortTitle}</div>
                        {this.getLabelMarkup()}
                    </div>
                    <div className="action">{this.getNextButtonMarkup()}</div>
                </div>
            </div>
        );
    }
}

export default CulinaryFeatureGuideHeroSectionsNavbar;
