import React from 'react';

class PageBuilderRowBackground extends React.PureComponent {
    constructor(props) {
        super(props);

        this.animClass = this.getComponentClass();
        this.backgroundImageStyle = this.getBackgroundImage();
        this.leftAnimationImageStyle = this.getLeftAnimationImage();
        this.rightAnimationImageStyle = this.getRightAnimationImage();
    }

    getComponentClass() {
        const baseClass = 'page-builder-row-background';

        return this.props.animationStyle ? `${baseClass} ${baseClass}-${this.props.animationStyle}` : baseClass;
    }

    getBackgroundImage() {
        return {
            backgroundImage: this.props.backgroundImage ? `url(${this.props.backgroundImage})` : 'none'
        };
    }

    getLeftAnimationImage() {
        return {
            backgroundImage: this.props.leftAnimationImage ? `url(${this.props.leftAnimationImage})` : 'none'
        };
    }

    getRightAnimationImage() {
        return {
            backgroundImage: this.props.rightAnimationImage ? `url(${this.props.rightAnimationImage})` : 'none'
        };
    }

    render() {
        return (
            <div className={this.animClass}>
                {this.props.backgroundImage && <div className="background" style={this.backgroundImageStyle} />}
                {this.props.leftAnimationImage && (
                    <div className="background-left" style={this.leftAnimationImageStyle} />
                )}
                {this.props.rightAnimationImage && (
                    <div className="background-right" style={this.rightAnimationImageStyle} />
                )}
            </div>
        );
    }
}

export default PageBuilderRowBackground;
