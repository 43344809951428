import React from 'react';

const CulinaryMealIdeasPrintViewListItem = ({ingredient}) => (
    <li className="culinary-meal-ideas-print-view-list-item">
        <div className="ingredient">
            <span className="checkbox" />
            <span className="label">{ingredient}</span>
        </div>
    </li>
);

export default CulinaryMealIdeasPrintViewListItem;
