import RequestModel from '../../models/request-model';

import BaseApiService from './base-api-service';

class EnterpriseApiService extends BaseApiService {
    create() {
        return new this.constructor(this);
    }

    fetchStoreContent(endpoint, storeId) {
        const requestModel = new RequestModel(endpoint);

        requestModel.data = {storeId};

        return super.getRequest(requestModel);
    }

    fetchUserRecipes(endpoint) {
        const requestModel = new RequestModel(endpoint);

        return super.getRequest(requestModel);
    }

    addToCartText(endpoint, data) {
        const requestModel = new RequestModel(endpoint);

        requestModel.data = data;

        return super.postRequest(requestModel);
    }

    addToListText(addToListEndpoint, data) {
        const endpoint = addToListEndpoint.replace('{shopping-list-id}', data.listId);
        const requestModel = new RequestModel(endpoint);

        requestModel.data = {
            quantity: 1,
            text: data.ingredientTitle
        };

        return super.postRequest(requestModel);
    }

    fetchUserLists(endpoint) {
        const requestModel = new RequestModel(endpoint);

        return super.getRequest(requestModel);
    }

    createUserList(endpoint, name) {
        const requestModel = new RequestModel(endpoint);

        requestModel.data = {
            name
        };

        return super.postRequest(requestModel);
    }

    saveRecipeById(saveRecipeEndpoint, recipeId) {
        const endpoint = saveRecipeEndpoint.replace('{recipe-id}', recipeId);
        const requestModel = new RequestModel(endpoint);

        return super.postRequest(requestModel);
    }

    deleteRecipeById(deleteRecipeEndpoint, recipeId) {
        const endpoint = deleteRecipeEndpoint.replace('{recipe-id}', recipeId);
        const requestModel = new RequestModel(endpoint);

        return super.deleteRequest(requestModel);
    }
}

export default new EnterpriseApiService();
