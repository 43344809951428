import React from 'react';

import CulinaryFeatureBadgesLink from './culinary-feature-badges-link';

const DISPLAY_COUNT = 3;

const CulinaryFeatureBadges = ({badges}) => {
    if (!badges) {
        return null;
    }

    return (
        <section className="culinary-feature-badges">
            <h4 className="header">{'Recipe Wellness Badges'}</h4>
            <ul className="list">
                {badges.slice(0, DISPLAY_COUNT).map((badge) => (
                    <li className="badge" key={badge.title}>
                        <CulinaryFeatureBadgesLink badge={badge} />
                    </li>
                ))}
            </ul>
        </section>
    );
};

export default CulinaryFeatureBadges;
