import React from 'react';

import CulinaryAddToListModal from '../culinary/modules/add-to-list/culinary-add-to-list-modal';
import CulinaryAddToListModalLogin from '../culinary/modules/add-to-list/culinary-add-to-list-modal-login';
import CulinaryFavoriteModalLogin from '../culinary/modules/favorite/culinary-favorite-modal-login';
import CulinaryFavoritesModalConfirmDelete from '../culinary/modules/favorites/culinary-favorites-modal-confirm-delete/culinary-favorites-modal-confirm-delete';
import CulinaryMealIdeasNutritionModal from '../culinary/modules/meal-ideas/modals/culinary-meal-ideas-nutrition-modal';
import CulinaryMealIdeasPrintModal from '../culinary/modules/meal-ideas/modals/culinary-meal-ideas-print-modal';
import eventKeys from '../../constants/event-keys';

import IconClose from './icon/icon-close';

const isActiveModalClass = 'is-active-modal';

class ModalContainer extends React.Component {
    constructor(props) {
        super(props);

        this.html = null;
        this.MODAL_MAP = {};

        this.onClickCloseHandler = (event) => this.onClickClose(event);
        this.onKeyDownModalHandler = (event) => this.onKeyDownModal(event);
        this.onFocusDocumentHandler = (event) => this.onFocusDocument(event);

        this.isRecentlyActive = false;
    }

    componentDidMount() {
        this.html = global.document.querySelector('html');

        global.document.addEventListener('focus', this.onFocusDocumentHandler, true);
    }

    componentWillReceiveProps(newProps) {
        this.isRecentlyActive = newProps.modalReducer.isActive && !this.props.modalReducer.isActive;
    }

    componentDidUpdate() {
        if (this.html) {
            if (this.props.modalReducer.isActive) {
                this.html.classList.add(isActiveModalClass);

                if (this.isRecentlyActive) {
                    this.closeButton.focus();
                }
            } else {
                this.html.classList.remove(isActiveModalClass);
            }
        }
    }

    componentWillUnmount() {
        global.document.removeEventListener('focus', this.onFocusDocumentHandler, true);
    }

    setModalMap() {
        this.MODAL_MAP = {
            [CulinaryAddToListModal.ID]: {
                component: <CulinaryAddToListModal {...this.props} />,
                style: 'standard'
            },
            [CulinaryAddToListModalLogin.ID]: {
                component: <CulinaryAddToListModalLogin {...this.props} />,
                style: 'standard'
            },
            [CulinaryFavoriteModalLogin.ID]: {
                component: <CulinaryFavoriteModalLogin {...this.props} />,
                style: 'standard'
            },
            [CulinaryMealIdeasNutritionModal.ID]: {
                component: <CulinaryMealIdeasNutritionModal {...this.props} />,
                style: 'standard'
            },
            [CulinaryMealIdeasPrintModal.ID]: {
                component: <CulinaryMealIdeasPrintModal {...this.props} />,
                style: 'standard'
            },
            [CulinaryFavoritesModalConfirmDelete.ID]: {
                component: <CulinaryFavoritesModalConfirmDelete {...this.props} />,
                style: 'standard'
            }
        };
    }

    onClickClose() {
        this.closeModal();
    }

    onKeyDownModal(event) {
        if (event.keyCode === eventKeys.ESC) {
            event.preventDefault();
            this.closeModal();
        }
    }

    onFocusDocument(event) {
        if (this.modalContainer) {
            if (this.props.modalReducer.isActive && !this.modalContainer.contains(event.target)) {
                event.stopPropagation();
                this.closeButton.focus();
            }
        }
    }

    closeModal() {
        this.props.actions.modalClose();
    }

    /* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
    render() {
        this.setModalMap();

        const isActive = this.props.modalReducer.isActive;
        const modalId = this.props.modalReducer.modalId;

        if (isActive && modalId) {
            const innerModalComponent = this.MODAL_MAP[modalId].component;
            const modalStyle = this.MODAL_MAP[modalId].style;

            return (
                <div
                    className={`modal-container ${modalStyle}`}
                    ref={(modalContainer) => {
                        this.modalContainer = modalContainer;
                    }}
                >
                    <div className="background" onClick={this.onClickCloseHandler} />
                    <div className="content">
                        <button
                            aria-label={'Close Modal'}
                            className="close-button"
                            onClick={this.onClickCloseHandler}
                            onKeyDown={this.onKeyDownModalHandler}
                            ref={(closeButton) => {
                                this.closeButton = closeButton;
                            }}
                            type="button"
                        >
                            <span className="label">{'Close'}</span>
                            <IconClose />
                        </button>
                        <div className="inner-content">{innerModalComponent}</div>
                    </div>
                </div>
            );
        }

        return null;
    }
    /* eslint-enable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
}

export default ModalContainer;
