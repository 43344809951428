import React from 'react';

import DropdownMenu from '../../../global/dropdown/dropdown-menu';
import DropdownOptions from '../../../global/dropdown/dropdown-options';

class CulinarySearchInfoBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeDropdownIndex: Object.keys(this.props.sortByMap).indexOf(this.props.sortBy) || null,
            optionLabel: this.props.sortByMap['relevance']
        };

        this.onClickOptionHandler = (event) => this.onClickOption(event);
        this.onClickWindowHandler = (event) => this.onClickWindow(event);
        this.onToggleDropdownHandler = (toggle, refinementIndex) => this.onToggleDropdown(toggle, refinementIndex);
    }

    componentDidMount() {
        global.addEventListener('click', this.onClickWindowHandler);
    }

    componentWillUnmount() {
        global.removeEventListener('click', this.onClickWindowHandler);
    }

    onToggleDropdown(toggle, refinementIndex) {
        const refinementButton = toggle;
        const shouldExpand = refinementButton.getAttribute('aria-expanded') === 'false';

        refinementButton.setAttribute('aria-expanded', shouldExpand);
        refinementButton.setAttribute('aria-selected', shouldExpand);

        const activeDropdownIndex = shouldExpand ? refinementIndex : null;

        this.setState({activeDropdownIndex});
    }

    onClickWindow(event) {
        if (!this.culinaryRecipeResultsInfoBar.contains(event.target)) {
            this.setState({activeDropdownIndex: null});
        }
    }

    onClickOption(event) {
        const optionValue = event.target.getAttribute('data-value');

        if (optionValue !== this.props.sortBy) {
            this.props.setSortBy(optionValue);
        }

        this.setState({activeDropdownIndex: null});
    }

    render() {
        const {displayCount, totalResultCount} = this.props;

        return (
            <div
                className="culinary-search-info-bar kf-fade-in"
                ref={(element) => {
                    this.culinaryRecipeResultsInfoBar = element;
                }}
            >
                <div aria-live="polite">
                    {!this.props.isLoading && (
                        <span>
                            <span>{`${displayCount} `}</span>
                            <span>{'of '}</span>
                            <span>{`${totalResultCount} results `}</span>
                        </span>
                    )}
                    {this.props.searchTerm && (
                        <span>
                            <span>{'for '}</span>
                            <span>{this.props.searchTerm}</span>
                        </span>
                    )}
                </div>
                <div className="info-bar-controls">
                    <div>{'Sort By'}</div>
                    <DropdownMenu
                        activeDropdownIndex={this.state.activeDropdownIndex}
                        dropdownId={'sortBy'}
                        onToggleDropdown={this.onToggleDropdownHandler}
                        title={this.props.sortByMap[this.props.sortBy] || this.state.optionLabel}
                    >
                        <DropdownOptions onClickOption={this.onClickOptionHandler} options={this.props.sortByMap} />
                    </DropdownMenu>
                </div>
            </div>
        );
    }
}

export default CulinarySearchInfoBar;
