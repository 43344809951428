const getFacebookSdkPromiseWrapper = async (appId) => {
    const facebookSdkPromise = global.FB
        ? global.FB
        : await new Promise((resolve) => {
              global.fbAsyncInit = () => {
                  resolve(global.FB);
              };
          });

    global.FB.init({
        appId,
        version: 'v2.9',
        xfbml: true
    });

    return facebookSdkPromise;
};

// eslint-disable-next-line no-return-await
export const getFacebookSdk = async (appId) => await getFacebookSdkPromiseWrapper(appId);
