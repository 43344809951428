import React from 'react';

class CulinaryFavoritesNavItem extends React.Component {
    componentIsActive() {
        return `${this.props.categoryIndex}` === `${this.props.itemIndex}`;
    }

    getComponentStateClass() {
        const baseClass =
            this.props.layoutType === 'primary'
                ? 'culinary-favorites-nav-item culinary-favorites-nav-item-primary'
                : 'culinary-favorites-nav-item';

        return this.componentIsActive() ? `${baseClass} is-active` : baseClass;
    }

    render() {
        return (
            <button
                className={this.getComponentStateClass()}
                data-index={this.props.itemIndex}
                onClick={(event) => this.props.onClickSectionHandler(event, this.props.title)}
                type="button"
            >
                {`${this.props.title} (${this.props.count})`}
            </button>
        );
    }
}

export default CulinaryFavoritesNavItem;
