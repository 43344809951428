import {
    ADD_TO_CART_CLEAR_STATUS,
    ADD_TO_CART_INGREDIENTS_TO_ADD,
    ADD_TO_CART_RECIPE,
    ADD_TO_CART_SET_FAIL,
    ADD_TO_CART_SET_LOADING,
    ADD_TO_CART_SET_SUCCESS
} from '../action-types';

const getInitialState = () => ({
    importedItems: null,
    ingredientsToAdd: [],
    isFail: false,
    isLoading: false,
    isSuccess: false,
    recipeTitle: '',
    recipeUrl: ''
});

const addToCartSetFail = (state) => ({
    ...state,
    isFail: true,
    isLoading: false,
    isSuccess: false
});

const addToCartSetLoading = (state) => ({
    ...state,
    isFail: false,
    isLoading: true,
    isSuccess: false
});

const addToCartSetSuccess = (state, data) => ({
    ...state,
    importedItems: data,
    isFail: false,
    isLoading: false,
    isSuccess: true
});

const addToCartIngredientsToAdd = (state, ingredientsToAdd) => ({
    ...state,
    ingredientsToAdd
});

const addToCartRecipe = (state, data) => ({
    ...state,
    recipeTitle: data.title,
    recipeUrl: data.url
});

const addToCartClearStatus = (state) => ({
    ...state,
    isFail: false,
    isLoading: false,
    isSuccess: false
});

const reducerMap = {
    [ADD_TO_CART_CLEAR_STATUS]: addToCartClearStatus,
    [ADD_TO_CART_INGREDIENTS_TO_ADD]: addToCartIngredientsToAdd,
    [ADD_TO_CART_RECIPE]: addToCartRecipe,
    [ADD_TO_CART_SET_FAIL]: addToCartSetFail,
    [ADD_TO_CART_SET_LOADING]: addToCartSetLoading,
    [ADD_TO_CART_SET_SUCCESS]: addToCartSetSuccess
};

export default (state = getInitialState(), {data, type}) => {
    if (reducerMap[type]) {
        return reducerMap[type](state, data);
    }

    return state;
};

export {getInitialState};
