import {UI_UPDATE_CHECKED_DIRECTION} from '../action-types';

const getInitialState = () => ({
    carousels: [],
    directionSelections: []
});

const getCheckedSelections = (selections = [], key) => {
    const isChecked = selections.find((selection) => selection === key);

    if (isChecked) {
        return selections.filter((checkedItem) => checkedItem !== key);
    }

    return selections.concat(key);
};

const uiUpdateCheckedDirection = (state, key) => {
    const directionSelections = getCheckedSelections(state.directionSelections, key);

    return {
        ...state,
        directionSelections
    };
};

const reducerMap = {
    [UI_UPDATE_CHECKED_DIRECTION]: uiUpdateCheckedDirection
};

export default (state = getInitialState(), {data, type}) => {
    if (reducerMap[type]) {
        return reducerMap[type](state, data);
    }

    return state;
};

export {getInitialState};
