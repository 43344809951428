import React from 'react';

import FeaturePromotional from '../../feature/feature-promotional';
import CulinaryFeatureRelatedFooter from '../modules/feature/related-content/culinary-feature-related-footer';
import CulinaryFeatureEditorialBlock from '../modules/feature/culinary-feature-editorial-block';
import CulinaryFeatureCardMedia from '../modules/feature/culinary-feature-card-media';
import CulinaryFeatureCardGuide from '../modules/feature/cards/culinary-feature-card-guide';
import CulinaryFeatureCardCarousel from '../modules/feature/cards/culinary-feature-card-carousel';
import CulinaryFeatureHeaderBlock from '../modules/feature/culinary-feature-header-block';
import CulinaryLandingSecondaryFeatures from '../modules/landing/culinary-landing-secondary-features';
import CulinaryFeatureFeaturedIngredients from '../modules/feature/featured-ingredients/culinary-feature-featured-ingredients';
import CulinarySearchRefinementList from '../modules/search/culinary-search-refinement-list';
import CulinaryRelatedTags from '../modules/culinary-related-tags';
import CulinaryFeatureHeroBanner from '../modules/feature/culinary-feature-hero-banner';
import FeatureSearchBar from '../../feature/feature-search-bar';
import CulinaryFeatureMealIdeasPromo from '../modules/feature/culinary-feature-meal-ideas-promo';
import ApiQueryService from '../../../services/api/api-query-service';

class CulinaryRecipesIdeasTemplate extends React.Component {
    constructor(props) {
        super(props);

        this.hasSearchQuery = ApiQueryService.hasSearchQuery(this.props);

        this.previousLocationQuery = null;
    }

    componentWillReceiveProps(nextProps) {
        const {query} = nextProps.router.location;
        const queryString = JSON.stringify(query);

        this.hasSearchQuery = ApiQueryService.hasSearchQuery(nextProps);

        if (this.hasSearchQuery && this.previousLocationQuery !== queryString) {
            nextProps.actions.syncSearchQueryWithStore(query);
        }

        this.previousLocationQuery = queryString;
    }

    getPromotionalContent(content) {
        let promotional = content.loggedOutPromo;

        if (this.props.isAuthenticated && content.loggedInPromo) {
            promotional = content.loggedInPromo;
        }

        return promotional;
    }

    getBackgroundImages(content) {
        const backgroundImageLeft = content.backgroundImageLeft ? `url(${content.backgroundImageLeft.url})` : 'none';
        const backgroundImageRight = content.backgroundImageRight ? `url(${content.backgroundImageRight.url})` : 'none';

        const backgroundImage = {
            backgroundImage:
                content.backgroundImageLeft || content.backgroundImageRight
                    ? `${backgroundImageLeft}, ${backgroundImageRight}`
                    : 'none'
        };

        return backgroundImage;
    }

    render() {
        const {content} = this.props.contentReducer.urlContent;
        const {primaryFeature, secondaryFeatures, heading, editorialLandingUrl} = content.featuredEditorialBlock;
        const backgroundImages = this.getBackgroundImages(content);

        return (
            <main className="culinary-recipes-ideas-template">
                <h2 className="page-title">{'All Recipes and Ideas'}</h2>

                <CulinaryFeatureHeroBanner hero={content.hero} />

                <section className="search">
                    <h3 className="heading">{'Search Recipe Content'}</h3>
                    <FeatureSearchBar
                        autocompleteResults={this.props.autocompleteReducer.results}
                        formAction="/recipes-ideas/recipes"
                        hasSearchQuery={this.hasSearchQuery}
                        label={'Find a Recipe'}
                        placeholder={'What do you want to make?'}
                        searchType={'recipe'}
                        showLabel
                    />
                </section>

                <div className="refinements-container">
                    <div className="refinements">
                        <CulinarySearchRefinementList
                            addFilter={this.props.actions.addFilter}
                            data={content.featuredRefinements}
                            filters={this.props.searchReducer.filters}
                            removeFilter={this.props.actions.removeFilter}
                            setLinks
                            sortBy={this.props.searchReducer.sortBy}
                        />
                        <hr />
                    </div>
                </div>
                <div className="top" style={backgroundImages}>
                    <h3 className="heading">{heading}</h3>
                    <div className="container">
                        <div className="main">
                            <div className="primary">
                                {primaryFeature.type === 'Guide' ? (
                                    <CulinaryFeatureCardGuide cardGuide={primaryFeature} isWide />
                                ) : (
                                    <CulinaryFeatureCardMedia
                                        content={primaryFeature}
                                        imageHeight={'825'}
                                        imageWidth={'1100'}
                                        isStacked
                                        layout={'feature-primary'}
                                        showDescription
                                    />
                                )}
                            </div>
                            <div className="supporting-content">
                                <CulinaryLandingSecondaryFeatures
                                    editorialLandingUrl={editorialLandingUrl}
                                    secondaryFeatures={secondaryFeatures}
                                />
                            </div>
                        </div>
                        {content.featuredIngredients && (
                            <div className="featured-ingredients">
                                <CulinaryFeatureFeaturedIngredients
                                    actions={this.props.actions}
                                    alertReducer={this.props.alertReducer}
                                    data={content.featuredIngredients}
                                    favoritesReducer={this.props.favoritesReducer}
                                    isAuthenticated={this.props.isAuthenticated}
                                    modalReducer={this.props.modalReducer}
                                />
                            </div>
                        )}
                    </div>
                    {content.featuredRecipes && (
                        <section className="blocks">
                            <div className="container">
                                <hr className="hr" />
                                <CulinaryFeatureHeaderBlock
                                    contentLabel={'recipes'}
                                    heading={content.featuredRecipes.heading}
                                    linkUrl={content.featuredRecipes.recipesLandingUrl}
                                />
                            </div>
                            <div className="cards">
                                <CulinaryFeatureCardCarousel
                                    actions={this.props.actions}
                                    alertReducer={this.props.alertReducer}
                                    cardList={content.featuredRecipes.recipes}
                                    carouselState={this.props.carouselReducer.carousels}
                                    favoritesReducer={this.props.favoritesReducer}
                                    id="featured-recipes"
                                    isAuthenticated={this.props.isAuthenticated}
                                    isOptionalCarousel
                                    modalReducer={this.props.modalReducer}
                                    styleConfig="primary"
                                />
                            </div>
                        </section>
                    )}
                    <div className="container">
                        <div className="tags">
                            <CulinaryRelatedTags
                                featuredTags={content.featuredTags}
                                label={'Browse other popular tags'}
                            />
                        </div>
                    </div>
                </div>

                <div className="promotional">
                    <FeaturePromotional promotional={this.getPromotionalContent(content)} />
                </div>

                <CulinaryFeatureMealIdeasPromo mealIdeasPromo={content.mealIdeasPromo} />

                <CulinaryFeatureRelatedFooter
                    environmentConfigReducer={this.props.environmentConfigReducer}
                    noChildren={!content.featuredEditorialContent}
                    promoContent={content.promoContent}
                >
                    <CulinaryFeatureEditorialBlock data={content.featuredEditorialContent} />
                </CulinaryFeatureRelatedFooter>
            </main>
        );
    }
}

export default CulinaryRecipesIdeasTemplate;
