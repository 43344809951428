import BaseApiService from '../services/api/base-api-service';
import RequestModel from '../models/request-model';
import {SET_RECIPE_RATING} from '../action-types';

export const setRecipeRating = (recipeRating, rateRecipeApiUrl) => async (dispatch) => {
    const requestModel = new RequestModel(rateRecipeApiUrl);

    requestModel.data = {
        Id: recipeRating.id,
        Rating: recipeRating.rating,
        RecipeId: recipeRating.recipeId
    };

    const apiService = new BaseApiService();
    const response = await apiService.postRequest(requestModel);

    dispatch({
        data: {
            rating: recipeRating.rating,
            ratingId: response.data.data.id,
            recipeId: recipeRating.recipeId
        },
        type: SET_RECIPE_RATING
    });
};
