import {SET_FEEDBACK_RESPONSE_SELECTION, CLEAR_FEEDBACK_RESPONSE} from '../action-types';

export const setFeedbackResponseSelection = (selectedOptionValue) => ({
    data: selectedOptionValue,
    type: SET_FEEDBACK_RESPONSE_SELECTION
});

export const clearFeedbackResponse = () => ({
    type: CLEAR_FEEDBACK_RESPONSE
});
