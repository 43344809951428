import EnterpriseApiService from '../services/api/enterprise-api-service';
import {
    ADD_TO_LIST_ADD_ERRORS,
    ADD_TO_LIST_CLEAR_ERRORS,
    ADD_TO_LIST_CLEAR_STATUS,
    ADD_TO_LIST_INGREDIENTS_TO_ADD,
    ADD_TO_LIST_RECENTLY_VALIDATED,
    ADD_TO_LIST_SET_BUSY_ADDING,
    ADD_TO_LIST_SET_BUSY_LOADING,
    ADD_TO_LIST_SET_FAIL,
    ADD_TO_LIST_SET_LIST_TITLE,
    ADD_TO_LIST_SET_SUCCESS_ADDED,
    ADD_TO_LIST_SET_SUCCESS_LIST_CREATED,
    ADD_TO_LIST_SET_SUCCESS_LOADED,
    ADD_TO_LIST_SET_SUCCESS_RELOADED
} from '../action-types';

export const addToListGetLists = () => async (dispatch, getState) => {
    dispatch({
        type: ADD_TO_LIST_SET_BUSY_LOADING
    });

    const endpoint = getState().environmentConfigReducer.enterprise.lists;
    const responseModel = await EnterpriseApiService.fetchUserLists(endpoint);
    const type = responseModel.success && responseModel.data ? ADD_TO_LIST_SET_SUCCESS_LOADED : ADD_TO_LIST_SET_FAIL;
    const data = responseModel.success && responseModel.data ? responseModel.data.data : {};

    dispatch({
        data,
        type
    });
};

export const addToListGetNewLists = () => async (dispatch, getState) => {
    dispatch({
        type: ADD_TO_LIST_SET_BUSY_ADDING
    });

    const endpoint = getState().environmentConfigReducer.enterprise.lists;
    const responseModel = await EnterpriseApiService.fetchUserLists(endpoint);
    const type = responseModel.success ? ADD_TO_LIST_SET_SUCCESS_RELOADED : ADD_TO_LIST_SET_FAIL;
    const data = responseModel.success ? responseModel.data.data : {};

    dispatch({
        data,
        type
    });
};

export const addToListCreateList = (name) => async (dispatch, getState) => {
    dispatch({
        type: ADD_TO_LIST_SET_BUSY_ADDING
    });

    const endpoint = getState().environmentConfigReducer.enterprise.lists;
    const responseModel = await EnterpriseApiService.createUserList(endpoint, name);
    const type = responseModel.success ? ADD_TO_LIST_SET_SUCCESS_LIST_CREATED : ADD_TO_LIST_SET_FAIL;

    dispatch({
        type
    });
};

export const addToListAddIngredient = (listId, ingredient) => async (dispatch, getState) => {
    dispatch({
        type: ADD_TO_LIST_SET_BUSY_ADDING
    });

    const data = {
        ingredientTitle: ingredient.title,
        listId
    };

    const endpoint = getState().environmentConfigReducer.enterprise.addToList;
    const responseModel = await EnterpriseApiService.addToListText(endpoint, data);
    const type = responseModel.success ? ADD_TO_LIST_SET_SUCCESS_ADDED : ADD_TO_LIST_SET_FAIL;

    dispatch({
        type
    });
};

export const addToListIngredientsToAdd = (ingredients) => ({
    data: ingredients,
    type: ADD_TO_LIST_INGREDIENTS_TO_ADD
});

export const addToListAddErrors = (inputData) => ({
    data: inputData,
    type: ADD_TO_LIST_ADD_ERRORS
});

export const addToListClearErrors = () => ({
    type: ADD_TO_LIST_CLEAR_ERRORS
});

export const addToListClearStatus = () => ({
    type: ADD_TO_LIST_CLEAR_STATUS
});

export const addToListRecentlyValidated = (status) => ({
    data: status,
    type: ADD_TO_LIST_RECENTLY_VALIDATED
});

export const addToListSetListTitle = (listTitle) => ({
    data: listTitle,
    type: ADD_TO_LIST_SET_LIST_TITLE
});
