import React from 'react';

import CulinaryFeatureCardGuide from './cards/culinary-feature-card-guide';
import CulinaryFeatureCardMedia from './culinary-feature-card-media';

class CulinaryFeature2up extends React.Component {
    getFeatureMarkup = (feature, guidesOnly) => {
        if (!feature) {
            return null;
        }

        return (
            <div className="feature">
                {feature.type === 'Guide' ? (
                    <CulinaryFeatureCardGuide cardGuide={feature} isWide={guidesOnly} noAspectRatio={!guidesOnly} />
                ) : (
                    <CulinaryFeatureCardMedia
                        content={feature}
                        imageHeight={'600'}
                        imageWidth={'800'}
                        isStacked
                        layout={'feature'}
                    />
                )}
            </div>
        );
    };

    render() {
        const {feature2up} = this.props;

        if (!feature2up || !feature2up.featureOne) {
            return null;
        }

        const {featureOne, featureTwo} = feature2up;
        const guidesOnly =
            (featureOne.type === 'Guide' && featureTwo && featureTwo.type === 'Guide') ||
            (featureOne.type === 'Guide' && !featureTwo);

        const featureOneMarkup = this.getFeatureMarkup(featureOne, guidesOnly);
        const featureTwoMarkup = this.getFeatureMarkup(featureTwo, guidesOnly);

        return (
            <div className="culinary-feature-2up">
                {featureOneMarkup}
                {featureTwoMarkup}
            </div>
        );
    }
}

export default CulinaryFeature2up;
