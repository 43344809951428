import React from 'react';

import {isMicrosoftBrowser} from '../../../../services/user-agent-service';

class CulinaryLoadingList extends React.Component {
    constructor(props) {
        super(props);

        this.label = this.props.label ? this.props.label : 'Loading...';
    }

    getComponentClass() {
        const baseClass = 'culinary-loading-list';

        return isMicrosoftBrowser() ? `${baseClass} ms` : baseClass;
    }

    render() {
        return (
            <div className={this.getComponentClass()}>
                <div className="icon">
                    <div className="outline">
                        <svg height="110" viewBox="0 0 90 110" width="90">
                            <path
                                className="path"
                                d="M39.2 84.4H13c-3.6 0-6.5-3-6.5-6.5V22.2c0-3.6 2.9-6.5 6.5-6.5h42.6c3.6 0 6.5 3 6.5 6.5v36"
                            />
                        </svg>
                    </div>
                    <div className="lines">
                        <svg height="110" viewBox="0 0 90 110" width="90">
                            <path className="path ring-one" d="M19.4 10.7v10.1" />
                            <path className="path ring-two" d="M34.3 10.7v10.1" />
                            <path className="path ring-three" d="M49.2 10.7v10.1" />
                            <path className="path line-one" d="M44.9 36.3H25.3" />
                            <path className="path line-two" d="M51.6 51H25.1" />
                            <path className="path line-three" d="M44.9 65.8H25.3" />
                        </svg>
                    </div>
                    <div className="heart">
                        <svg height="110" viewBox="0 0 90 110" width="90">
                            <path
                                className="path"
                                d="M83.5 78.3c.1-5.4-4.4-10.2-9.8-10.2-3.2 0-6.1 1.9-7.8 4.3-1.7-2.4-4.4-4.1-7.6-4.3-5.4-.3-10.1 3.9-10.3 9.3v.6c0 2.1.9 4.2 2.4 5.7l13.1 14.6c1.1 1.3 3.1 1.3 4.3.1l13.3-14c1.4-1.5 2.3-3.4 2.5-5.4-.1-.2-.1-.5-.1-.7z"
                            />
                        </svg>
                    </div>
                    <div className="icon-one">
                        <svg height="110" viewBox="0 0 90 110" width="90">
                            <path className="path" d="M15.5 36l1.9 1.9 3.1-3.1" />
                        </svg>
                    </div>
                    <div className="icon-two">
                        <svg height="110" viewBox="0 0 90 110" width="90">
                            <path
                                className="shape"
                                d="M21.5 50.6c0-1.1-.9-2-1.9-2-.6 0-1.2.4-1.6.9-.3-.5-.9-.8-1.5-.8-1.1-.1-2 .8-2.1 1.8v.1c0 .4.2.8.5 1.1l2.6 2.9c.2.3.6.3.8 0l2.6-2.8c.3-.3.5-.7.5-1.1.1 0 .1-.1.1-.1z"
                                fill="#3F3F3F"
                                stroke="none"
                            />
                        </svg>
                    </div>
                    <div className="icon-three">
                        <svg height="110" viewBox="0 0 90 110" width="90">
                            <path className="path" d="M15.5 65.5l1.9 1.8 3.1-3.1" />
                        </svg>
                    </div>
                </div>
                <div className="label">{this.label}</div>
            </div>
        );
    }
}

export default CulinaryLoadingList;
