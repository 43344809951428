/* istanbul ignore file */

import React from 'react';
import {TransitionGroup, CSSTransition} from 'react-transition-group';

const withMountAnimation = (Component) =>
    class _withMountAnimation extends Component {
        constructor(props) {
            super(props);

            this.multiplier = 0;
        }

        getAnimationDelay(childIndex) {
            if (childIndex % this.props.withMountAnimationProps.pageSize === 0) {
                this.multiplier = 0;
            } else {
                this.multiplier++;
            }

            return `${this.multiplier * this.props.withMountAnimationProps.offset}ms`;
        }

        render() {
            this.renderedComponent = super.render();
            const {className, component} = this.props.withMountAnimationProps.transitionGroup;

            const items = this.renderedComponent.props.children.map((child, childIndex) => (
                <CSSTransition {...this.props.withMountAnimationProps.transitionGroup} key={childIndex}>
                    {React.cloneElement(child, {
                        hasStyles: true,
                        key: child.key,
                        styles: {
                            animationDelay: this.getAnimationDelay(childIndex)
                        }
                    })}
                </CSSTransition>
            ));

            return (
                <TransitionGroup className={className} component={component}>
                    {items}
                </TransitionGroup>
            );
        }
    };

export default withMountAnimation;
