import React from 'react';

import CulinaryFeaturePromoBlock from '../culinary-feature-promo-block';

const CulinaryFeatureRelatedFooter = ({
    environmentConfigReducer,
    promoContent,
    children,
    noChildren,
    condensedLayout
}) => {
    if (!promoContent && noChildren) {
        return null;
    }

    const promoOnlyClass = noChildren ? ' culinary-feature-related-footer-promo-only' : '';
    const condensedLayoutClass = condensedLayout ? ' culinary-feature-related-footer-condensed' : '';

    return (
        <aside className={`culinary-feature-related-footer${promoOnlyClass}${condensedLayoutClass}`}>
            <h3 className="header">{'Featured Ideas'}</h3>
            <div className="content">
                {children}

                {promoContent && (
                    <CulinaryFeaturePromoBlock
                        condensedLayout={condensedLayout}
                        data={promoContent}
                        environmentConfigReducer={environmentConfigReducer}
                        hasDivider={!noChildren}
                    />
                )}
            </div>
        </aside>
    );
};

export default CulinaryFeatureRelatedFooter;
