import React from 'react';

const CulinaryAddToListSuccess = (props) => (
    <div className="culinary-add-to-list-success">
        <div className="content">
            <h3 className="heading">{'Add To List'}</h3>
            <p>{`Items were added to your list: ${props.listTitle}`}</p>
        </div>
        <div className="actions">
            <a className="secondary-button" href="/deals/shopping-list.aspx">
                {'View Lists'}
            </a>
            <button className="primary-button" onClick={props.onClickCancel} type="button">
                {'Dismiss'}
            </button>
        </div>
    </div>
);

export default CulinaryAddToListSuccess;
