const clamp = (numberToClamp, min, max = Number.MAX_SAFE_INTEGER) => {
    if (numberToClamp > max) {
        return max;
    } else if (numberToClamp < min) {
        return min;
    }

    return numberToClamp;
};

export default clamp;
