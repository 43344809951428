import React from 'react';
import moment from 'moment';
import 'moment-duration-format';

import {getVideoMetadata} from '../../../../services/youtube-data-api-service';

import FeaturePrimaryVideoPlayer from './feature-primary-video-player';

class FeaturePrimaryVideo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hasPlayed: false
        };
    }

    componentWillMount() {
        this.buildState(this.props.media.videoId);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.media.videoId !== this.props.media.videoId) {
            this.props.setVideoProp({
                videoId: this.props.media.videoId
            });

            this.buildState(nextProps.media.videoId);
        }
    }

    async buildState(videoId) {
        await this.setVideoMetadata(videoId);
    }

    async setVideoMetadata(videoId) {
        const {duration, fallbackThumbnailUrl, title} = await getVideoMetadata(videoId);
        const seconds = moment.duration(duration).asSeconds();
        const format = seconds < 3600 ? 'mm:ss' : 'h:mm:ss';
        const readableDuration = moment.duration(duration, 'seconds').format(format, {trim: true});
        const videoMetaData = {
            duration: readableDuration,
            title
        };

        this.props.addVideo({
            videoId: this.props.media.videoId
        });

        this.props.setVideoProp({
            ...videoMetaData,
            videoId: this.props.media.videoId
        });

        this.setThumbnailUrl(fallbackThumbnailUrl);
    }

    setThumbnailUrl(fallbackThumbnailUrl) {
        const {previewImageUrl} = this.props.media;

        const cropSettings = {
            height: 450,
            mode: 'crop',
            width: 800
        };

        const thumbnailOptions = Object.keys(cropSettings)
            .map((key) => `${key}=${cropSettings[key]}`)
            .join('&');
        const thumbnailUrl =
            previewImageUrl.length > 0 ? `${previewImageUrl}&${thumbnailOptions}` : fallbackThumbnailUrl;

        this.props.setVideoProp({
            thumbnailUrl,
            videoId: this.props.media.videoId
        });
    }

    onClickPlayButton = () => {
        this.props.setVideoProp({
            videoId: this.props.media.videoId
        });

        this.setState({hasPlayed: true});
    };

    render() {
        const activeVideoId = this.props.activeVideoId;
        const videoProps = this.props.videoPlayerReducer.videos[this.props.media.videoId];

        if (!videoProps) {
            return null;
        }

        const {duration, thumbnailUrl, title} = videoProps;
        const {hasPlayed} = this.state;

        return (
            <section className="feature-primary-video">
                {!hasPlayed && <img alt={title} src={`${thumbnailUrl}`} />}
                {hasPlayed && (
                    <FeaturePrimaryVideoPlayer
                        activeVideoId={activeVideoId}
                        setActiveVideoId={this.props.setActiveVideoId}
                        videoId={this.props.media.videoId}
                    />
                )}
                {!hasPlayed && (
                    <div>
                        <div className="length">{duration}</div>
                        <button
                            aria-label="Play video"
                            className="play-btn"
                            onClick={this.onClickPlayButton}
                            type="button"
                        >
                            <div className="culinary-video-icon">
                                <div className="circle" />
                            </div>
                            <span className="btn-text">{'Play Video'}</span>
                        </button>
                    </div>
                )}
            </section>
        );
    }
}

export default FeaturePrimaryVideo;
