import React from 'react';

import {isMaxBreakpoint} from '../../../../../services/breakpoint-service';
import CulinaryFeatureCard from '../cards/culinary-feature-card';

const DURATION = 400;

class CulinaryFeatureTabsPanelGroup extends React.Component {
    constructor(props) {
        super(props);

        this.breakpoint = '';
        this.transitionDirection = 'right';
        this.panel = [];
        this.content = [];
    }

    componentDidMount() {
        require('velocity-animate');
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.activeTabIndex !== nextProps.activeTabIndex) {
            const isMobile = isMaxBreakpoint('xs');
            const hideIndex = this.props.activeTabIndex;
            const showIndex = nextProps.activeTabIndex;

            if (isMobile) {
                this.slideTransition(hideIndex, showIndex);
            } else {
                this.fadeTransition(hideIndex, showIndex);
            }

            this.panel[hideIndex].classList.remove('culinary-feature-tabs-panel-active');
            this.panel[showIndex].classList.add('culinary-feature-tabs-panel-active');
        }
    }

    shouldComponentUpdate() {
        return false;
    }

    slideTransition(hideIndex, showIndex) {
        /* eslint-disable new-cap, no-undef */
        Velocity(this.content[hideIndex], 'slideUp', {
            duration: DURATION
        });

        Velocity(this.content[showIndex], 'slideDown', {
            duration: DURATION
        });
        /* eslint-enable new-cap, no-undef */

        this.removeDriftClasses(hideIndex);
        this.removeDriftClasses(showIndex);
    }

    fadeTransition(hideIndex, showIndex) {
        this.transitionDirection = hideIndex < showIndex ? 'right' : 'left';

        this.tabOutAnimation(hideIndex);
        this.tabInAnimation(showIndex);
    }

    tabOutAnimation(tabIndex) {
        this.removeDriftClasses(tabIndex);
        this.content[tabIndex].classList.add(`drift-out-${this.transitionDirection}`);

        setTimeout(() => {
            this.content[tabIndex].style.display = 'none';
        }, DURATION);
    }

    tabInAnimation(tabIndex) {
        setTimeout(() => {
            this.removeDriftClasses(tabIndex);
            this.content[tabIndex].style.display = 'block';
            this.content[tabIndex].classList.add(`drift-in-${this.transitionDirection}`);
        }, DURATION);
    }

    removeDriftClasses(tabIndex) {
        this.content[tabIndex].classList.remove('drift-in-left', 'drift-out-left', 'drift-in-right', 'drift-out-right');
    }

    getInitialStyle(index) {
        return {
            display: index === this.props.activeTabIndex ? 'block' : 'none'
        };
    }

    getComponentClasses(index) {
        const baseClass = 'culinary-feature-tabs-panel';
        const layoutClass = this.props.layoutType ? this.props.layoutType : '';

        return index === this.props.activeTabIndex
            ? `${baseClass} ${baseClass}-${layoutClass} ${baseClass}-active`
            : `${baseClass} ${baseClass}-${layoutClass}`;
    }

    getImageMarkup(tab) {
        let imageMarkup = null;

        if (tab.image) {
            imageMarkup = (
                <div className="image-container">
                    <img alt={tab.image.alt} className="image" src={tab.image.cropUrl} />
                </div>
            );
        }

        return imageMarkup;
    }

    getRelatedCardMarkup(tab, index) {
        if (!tab.inlineReference) {
            return null;
        }

        return (
            <div className="inline-card">
                <CulinaryFeatureCard
                    actions={this.props.actions}
                    alertReducer={this.props.alertReducer}
                    card={tab.inlineReference}
                    favoritesReducer={this.props.favoritesReducer}
                    id={`tabs-card-${index}`}
                    isAuthenticated={this.props.isAuthenticated}
                    layoutType="inline"
                    modalReducer={this.props.modalReducer}
                />
            </div>
        );
    }

    render() {
        const tabs = this.props.tabs;

        return (
            <div className="culinary-feature-tabs-panel-group">
                {tabs.map((tab, index) => (
                    <div
                        className={this.getComponentClasses(index)}
                        key={index}
                        ref={(panel) => {
                            this.panel[index] = panel;
                        }}
                    >
                        <button
                            className="button"
                            data-tab-index={index}
                            onClick={this.props.onClickHandler}
                            type="button"
                        >
                            <span className="label">{tab.tabText}</span>
                            <svg className="icon" focusable={false} height="10" viewBox="0 0 10 10" width="10">
                                <path className="plus" d="M10 4.3H5.6V0H4.3v4.3H0v1.3h4.3V10h1.3V5.6H10" />
                            </svg>
                        </button>
                        <div
                            ref={(content) => {
                                this.content[index] = content;
                            }}
                            style={this.getInitialStyle(index)}
                        >
                            <div className="media">
                                {this.getImageMarkup(tab, index)}
                                <div className="content">
                                    <div
                                        className="body"
                                        /* eslint-disable-next-line react/no-danger */
                                        dangerouslySetInnerHTML={{__html: tab.content}}
                                    />
                                    {this.getRelatedCardMarkup(tab)}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                <div
                    className="breakpoint-tracker"
                    ref={(breakpointTracker) => {
                        this.breakpointTracker = breakpointTracker;
                    }}
                />
            </div>
        );
    }
}

export default CulinaryFeatureTabsPanelGroup;
