import React from 'react';

class CulinaryMealIdeasPrintModalMealItem extends React.Component {
    constructor(props) {
        super(props);

        this.mealIdea = this.props.mealIdea;
        this.state = {
            isChecked: true
        };

        this.onCheckboxChangeHandler = () => this.onCheckboxChange();
    }

    componentDidUpdate() {
        if (this.props.shouldFocus) {
            this.mealItem.focus();
        }
    }

    mealItems() {
        if (this.mealIdea.mealItems && this.mealIdea.mealItems.length > 0) {
            return this.mealIdea.mealItems
                .map((item) => item.title)
                .filter((text) => text && text.length > 0)
                .join(', ');
        }

        return '';
    }

    onCheckboxChange() {
        const isChecked = this.state.isChecked;

        this.props.onChangeInputHandler(this.mealIdea.id, isChecked);
        this.setState({isChecked: !isChecked});
    }

    render() {
        return (
            <li className="culinary-meal-ideas-print-modal-meal-item">
                <div className="input">
                    <input
                        aria-invalid={this.props.mealIdeasToPrintReducer.hasError}
                        checked={this.state.isChecked}
                        className="checkbox-function"
                        id={`mealPrint-${this.props.mealIdeaIndex}`}
                        name="meal-idea"
                        onChange={this.onCheckboxChangeHandler}
                        ref={(node) => {
                            this.mealItem = node;
                        }}
                        required
                        type="checkbox"
                    />
                    <label className="checkbox-presentation" htmlFor={`mealPrint-${this.props.mealIdeaIndex}`}>
                        <div className="title">{this.mealIdea.title}</div>
                        <div className="items">{this.mealItems()}</div>
                    </label>
                </div>
            </li>
        );
    }
}

export default CulinaryMealIdeasPrintModalMealItem;
