import React from 'react';

import CulinaryFeatureEditorialBlock from './culinary-feature-editorial-block';

class CulinaryFeatureRelatedStandalone extends React.Component {
    componentDidMount() {
        const {rowId, columnId, data} = this.props;

        if (rowId !== undefined && columnId !== undefined) {
            let isValid = false;

            if (data) {
                isValid = true;
            }

            this.props.actions.pageBuilderSetValid({
                columnId,
                isValid,
                rowId
            });
        }
    }

    render() {
        const data = this.props.data;

        if (!data) {
            return null;
        }

        return (
            <aside className="culinary-feature-related-standalone">
                <h2 className="header">{'Promotional Content'}</h2>
                <CulinaryFeatureEditorialBlock data={data} />
            </aside>
        );
    }
}

export default CulinaryFeatureRelatedStandalone;
