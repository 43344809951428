import React from 'react';

import CulinaryFeatureCard from './feature/cards/culinary-feature-card';
import CulinaryFeatureCardGuide from './feature/cards/culinary-feature-card-guide';

class CulinaryCardBlocks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            forceKeyChanges: false
        };
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.shouldReanimate && !nextProps.shouldReanimate) {
            this.setState({
                forceKeyChanges: true
            });
        }
    }

    shouldFocus(cardIndex) {
        const cardMatchFocusCheck = this.props.focusIndex === cardIndex;

        return (
            (cardMatchFocusCheck && this.props.focusIndex > 0) || (cardMatchFocusCheck && this.props.focusFirstAllowed)
        );
    }

    setKey(contentIdString, cardIndex) {
        return !this.props.shouldReanimate && !this.state.forceKeyChanges
            ? contentIdString
            : `${contentIdString}-${cardIndex}`;
    }

    getCardMarkup(card, cardIndex) {
        const contentIdString = card.contentId.toString();

        if (card.type === 'Guide') {
            return (
                <CulinaryFeatureCardGuide
                    cardGuide={card}
                    key={contentIdString}
                    shouldFocus={this.shouldFocus(cardIndex)}
                />
            );
        }

        return (
            <CulinaryFeatureCard
                actions={this.props.actions}
                alertReducer={this.props.alertReducer}
                card={card}
                cardIndex={cardIndex}
                favoritesReducer={this.props.favoritesReducer}
                focusIndex={this.props.focusIndex}
                id={`card-blocks-${cardIndex}`}
                isAuthenticated={this.props.isAuthenticated}
                key={this.setKey(contentIdString, cardIndex)}
                layoutType={this.props.showRatings ? 'rating' : ''}
                modalReducer={this.props.modalReducer}
                shouldFocus={this.shouldFocus(cardIndex)}
                showConfirmation={this.props.showConfirmation}
            />
        );
    }

    render() {
        const {cards} = this.props;

        return (
            <div className="culinary-card-blocks">
                {cards.map((card, cardIndex) => {
                    return this.getCardMarkup(card, cardIndex);
                })}
            </div>
        );
    }
}

export default CulinaryCardBlocks;
