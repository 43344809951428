import React from 'react';
import {connect} from 'react-redux';

import IconFacebook from '../../../global/icon/icon-facebook';
import IconPinterest from '../../../global/icon/icon-pinterest';
import IconTwitter from '../../../global/icon/icon-twitter';
import IconPrint from '../../../global/icon/icon-print';
import ApiQueryService from '../../../../services/api/api-query-service';
import {getFacebookSdk} from '../../../../utilities/facebook-sdk';
import {getPinterestSdk} from '../../../../utilities/pinterest-sdk';
import twitterLimits from '../../../../constants/twitter-limits';
import {setGtmSharingPlatform} from '../../../../utilities/gtm-utilities';
import Platforms from '../../../../constants/platforms';

import CulinaryFeatureEmail from './culinary-feature-email';

const mapStateToProps = (state) => ({
    serverSideLocation: state.serverSideLocation,
    sharing: state.environmentConfigReducer.sharing
});

export class CulinaryFeatureContentSharing extends React.Component {
    getUrl() {
        if (this.props.shareUrl) {
            const origin = global.window ? global.window.location.origin : this.props.serverSideLocation.origin;
            const shareUrl = `${origin}${this.props.shareUrl}`;

            return shareUrl;
        }

        return global.window ? global.window.location.href : this.props.serverSideLocation.href;
    }

    onClickShareToFacebook = async () => {
        const fb = await getFacebookSdk(this.props.sharing.facebookAppId);

        fb.ui(
            {
                href: this.getUrl(),
                method: 'share'
            },
            // eslint-disable-next-line no-unused-vars,no-empty-function
            function(response) {}
        );

        setGtmSharingPlatform(Platforms.Facebook);
    };

    onClickShareToPinterest = async () => {
        const pdk = await getPinterestSdk(this.props.sharing.pinterestAppId);

        pdk.pin(this.props.metaData.media.cropUrl, this.props.metaData.description, this.getUrl());

        setGtmSharingPlatform(Platforms.Pinterest);
    };

    onClickShareToTwitter = () => {
        setGtmSharingPlatform(Platforms.Twitter);
    };

    onClickPrint = () => {
        setGtmSharingPlatform(Platforms.Print);
    };

    getTwitterText(title, description) {
        const hEllip = '\u2026';
        const tweet = `${title} ${description} `;
        const availableCharacterCount = twitterLimits.MAX_LENGTH - twitterLimits.URL_LENGTH;

        if (tweet.length > availableCharacterCount) {
            const indexEnd = availableCharacterCount - `${hEllip} `.length;

            return `${tweet.substring(0, indexEnd)}${hEllip}`;
        }

        return tweet;
    }

    updatePrintUrl() {
        /* remove l param from location query to prevent checksum error on login redirect */
        if (this.props.locationQuery) {
            const {l, ...restOfQueries} = this.props.locationQuery;

            const queryString = ApiQueryService.toQueryString({
                ...restOfQueries,
                print: true
            });

            return `?${queryString}`;
        }

        return '';
    }

    render() {
        if (!this.props.metaData) {
            return null;
        }

        const twitterQuery = ApiQueryService.toQueryString({
            text: this.getTwitterText(this.props.metaData.title, this.props.metaData.description),
            url: this.getUrl()
        });

        return (
            <ul className="culinary-content-sharing">
                <li className="list-item">
                    <button className="share-button" onClick={this.onClickShareToFacebook} type="button">
                        <span className="sharing-label">{'Share This Recipe on Facebook'}</span>
                        <IconFacebook height="31" width="31" />
                    </button>
                </li>
                <li className="list-item">
                    <button className="share-button" onClick={this.onClickShareToPinterest} type="button">
                        <span className="sharing-label">{'Pin This Recipe on Pinterest'}</span>
                        <IconPinterest height="31" width="31" />
                    </button>
                </li>
                <li className="list-item">
                    <a
                        className="share-button"
                        href={`https://twitter.com/intent/tweet?${twitterQuery}`}
                        onClick={this.onClickShareToTwitter}
                    >
                        <span className="sharing-label">{'Share This Recipe on Twitter'}</span>
                        <IconTwitter height="31" width="31" />
                    </a>
                </li>
                <li className="list-item">
                    <CulinaryFeatureEmail
                        metaData={this.props.metaData}
                        shareUrl={this.props.shareUrl}
                        subTitle={this.props.subTitle}
                    />
                </li>
                {!this.props.hidePrint && (
                    <li className="list-item">
                        <a
                            className="share-button"
                            href={this.updatePrintUrl()}
                            onClick={
                                this.props.onClickPrintHandler ? this.props.onClickPrintHandler : this.onClickPrint
                            }
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <span className="sharing-label">{'Print This Recipe'}</span>
                            <IconPrint height="31" width="31" />
                        </a>
                    </li>
                )}
            </ul>
        );
    }
}

export default connect(mapStateToProps)(CulinaryFeatureContentSharing);
