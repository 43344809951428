import RequestModel from '../../models/request-model';

import BaseApiService from './base-api-service';

class AuthApiService extends BaseApiService {
    updateAuth(endpoint) {
        const requestModel = new RequestModel(endpoint);

        return super.getRequest(requestModel);
    }
}

export default new AuthApiService();
