const getUserAgent = () => {
    try {
        return global.window.navigator.userAgent;
    } catch (error) {
        return null;
    }
};

export const isMicrosoftBrowser = () => {
    const ua = getUserAgent();

    if (!ua) {
        return false;
    }

    const msie = ua.indexOf('MSIE ');

    if (msie > 0) {
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)));
    }

    const trident = ua.indexOf('Trident/');

    if (trident > 0) {
        const rv = ua.indexOf('rv:');

        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)));
    }

    const edge = ua.indexOf('Edge/');

    if (edge > 0) {
        return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)));
    }

    return false;
};
