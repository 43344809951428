import React from 'react';

import SwapLink from '../global/hoc/swap-link';

const FeatureArticleSource = ({articleSource}) => {
    const {magazineSource, sourceText} = articleSource;

    let contentSource = null;

    if (magazineSource) {
        const magazineLink = magazineSource.link;

        contentSource = (
            <SwapLink className={'link'} href={`${magazineLink.url}`} target={magazineLink.target}>
                {magazineLink.title}
            </SwapLink>
        );
    }

    if (sourceText && sourceText !== '' && magazineSource === null) {
        contentSource = (
            <div
                className="user-content"
                /* eslint-disable-next-line react/no-danger */
                dangerouslySetInnerHTML={{__html: sourceText}}
            />
        );
    }

    if (contentSource) {
        return (
            <div className="feature-article-source">
                <h3 className="header">{'Recipe Source:'}</h3>
                {contentSource}
            </div>
        );
    }

    return null;
};

export default FeatureArticleSource;
