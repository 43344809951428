import React from 'react';

import CulinaryFeatureContentSharing from '../../culinary-feature-content-sharing';

class CulinaryFeatureGuideHeroMain extends React.PureComponent {
    constructor(props) {
        super(props);

        if (this.props.content.hero.backgroundImage1) {
            this.styleImage1 = {
                backgroundImage: `url(${this.props.content.hero.backgroundImage1.cropUrl})`
            };
        }

        if (this.props.content.hero.backgroundImage2) {
            this.styleImage2 = {
                backgroundImage: `url(${this.props.content.hero.backgroundImage2.cropUrl})`
            };
        }
    }

    render() {
        return (
            <div className="culinary-feature-guide-hero-main">
                <div className="container">
                    <div className="image" style={this.styleImage1} />
                    <div className="header">
                        <h3 className="share-heading">{'Share Options'}</h3>
                        <CulinaryFeatureContentSharing
                            hidePrint
                            metaData={this.props.metaData}
                            router={this.props.router}
                        />
                        <h3 className="intro-heading">{'Introduction'}</h3>
                        <div className="topic">{`Hy-Vee ${this.props.content.contentType}`}</div>
                        <div className="title">{this.props.content.title}</div>
                        <div
                            className="body"
                            /* eslint-disable-next-line react/no-danger */
                            dangerouslySetInnerHTML={{__html: this.props.content.description}}
                        />
                    </div>
                    <div className="image" style={this.styleImage2} />
                </div>
                <div className="lattice" />
            </div>
        );
    }
}

export default CulinaryFeatureGuideHeroMain;
