import React from 'react';

class CulinaryPrintButton extends React.Component {
    onClickPrintButton = () => {
        global.window.print();
    };

    render() {
        return (
            <div className="culinary-print-button">
                <button className="button" onClick={this.onClickPrintButton} type="button">
                    {'Print'}
                </button>
            </div>
        );
    }
}

export default CulinaryPrintButton;
