import {SET_RECIPE_RATING} from '../action-types';

const getInitialState = () => ({
    recipeRating: null
});

const setRecipeRating = (state, data) => {
    const recipeRating = state.recipeRating !== null ? state.recipeRating : {};

    recipeRating[data.recipeId] = data;

    return {
        ...state,
        recipeRating
    };
};

const reducerMap = {
    [SET_RECIPE_RATING]: setRecipeRating
};

export default (state = getInitialState(), {data, type}) => {
    if (reducerMap[type]) {
        return reducerMap[type](state, data);
    }

    return state;
};

export {getInitialState};
