import React from 'react';

import {getPreviewImageState} from '../../../../../services/preview-image-service';

import CulinaryFeatureRecipeCarouselItemDetails from './culinary-feature-recipe-carousel-item-details';

class CulinaryFeatureRecipeCarouselItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            mediaAlt: '',
            mediaUrl: ''
        };
    }

    componentDidMount() {
        this.setPreviewImage(this.props.recipe.recipe);
    }

    getActiveClass() {
        return this.props.isActive ? 'is-active' : '';
    }

    async setPreviewImage(recipe) {
        const previewImageState = await getPreviewImageState(recipe.media);

        this.setState(previewImageState);
    }

    getImageMarkup(recipe) {
        const imageUrl = recipe.recipeCarouselImage ? recipe.recipeCarouselImage.url : this.state.mediaUrl;
        const imageAlt = recipe.recipeCarouselImage ? recipe.recipeCarouselImage.alt : this.state.mediaAlt;

        if (imageUrl) {
            return <img alt={imageAlt} className="image" src={imageUrl} />;
        }

        return null;
    }

    render() {
        return (
            <div
                aria-hidden={this.props.isRepeat}
                className={`culinary-feature-recipe-carousel-item ${this.getActiveClass()}`}
            >
                <button
                    className="button"
                    data-carousel-index={this.props.index}
                    onClick={this.props.onClickRecipe}
                    tabIndex={this.props.isRepeat ? -1 : 0}
                    type="button"
                >
                    <span className="label">{`Select ${this.props.recipe.recipe.title}`}</span>
                    {this.getImageMarkup(this.props.recipe)}
                </button>
                <div className="details">
                    <CulinaryFeatureRecipeCarouselItemDetails
                        actions={this.props.actions}
                        alertReducer={this.props.alertReducer}
                        favoritesReducer={this.props.favoritesReducer}
                        id={this.props.id}
                        isAuthenticated={this.props.isAuthenticated}
                        isRepeat={this.props.isRepeat}
                        modalReducer={this.props.modalReducer}
                        onClickRecipe={this.props.onClickRecipe}
                        recipe={this.props.recipe.recipe}
                    />
                </div>
            </div>
        );
    }
}

export default CulinaryFeatureRecipeCarouselItem;
