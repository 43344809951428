import React from 'react';

import CulinaryFeatureCardCarousel from '../feature/cards/culinary-feature-card-carousel';

import CulinarySearchRefinementGroupsHeading from './culinary-search-refinement-groups-heading';

const CulinarySearchRefinementGroups = ({
    actions,
    alertReducer,
    carouselReducer,
    content,
    isAuthenticated,
    modalReducer,
    favoritesReducer
}) => (
    <div className="culinary-search-refinement-groups">
        <div className="container">
            {content.recipeRefinementBlock1 && (
                <CulinarySearchRefinementGroupsHeading refinementBlock={content.recipeRefinementBlock1} />
            )}
        </div>
        <div className="carousel">
            <CulinaryFeatureCardCarousel
                actions={actions}
                alertReducer={alertReducer}
                cardList={content.recipeRefinementBlock1.results}
                carouselState={carouselReducer.carousels}
                favoritesReducer={favoritesReducer}
                id="refinement-group-1-recipes"
                isAuthenticated={isAuthenticated}
                isOptionalCarousel
                modalReducer={modalReducer}
                showRatings={false}
                styleConfig="primary"
            />
        </div>
        <div className="container">
            <hr className="hr" />
            {content.recipeRefinementBlock2 && (
                <CulinarySearchRefinementGroupsHeading refinementBlock={content.recipeRefinementBlock2} />
            )}
        </div>
        <div className="carousel">
            <CulinaryFeatureCardCarousel
                actions={actions}
                alertReducer={alertReducer}
                cardList={content.recipeRefinementBlock2.results}
                carouselState={carouselReducer.carousels}
                favoritesReducer={favoritesReducer}
                id="refinement-group-2-recipes"
                isAuthenticated={isAuthenticated}
                isOptionalCarousel
                modalReducer={modalReducer}
                showRatings={false}
                styleConfig="primary"
            />
        </div>
        <div className="container">
            {content.displayPopularRecipesBlock && <hr className="hr" />}
            {content.displayPopularRecipesBlock && <h3 className="hdg">{'Popular Now'}</h3>}
        </div>
        <div className="carousel">
            <CulinaryFeatureCardCarousel
                actions={actions}
                alertReducer={alertReducer}
                cardList={content.topRatedRecipes}
                carouselState={carouselReducer.carousels}
                favoritesReducer={favoritesReducer}
                id="popular-now-recipes"
                isAuthenticated={isAuthenticated}
                isOptionalCarousel
                modalReducer={modalReducer}
                showRatings
                styleConfig="primary"
            />
        </div>
    </div>
);

export default CulinarySearchRefinementGroups;
