import React from 'react';

import CulinaryFeatureGuideHeroMain from './main/culinary-feature-guide-hero-main';
import CulinaryFeatureGuideHeroSections from './sections/culinary-feature-guide-hero-sections';

class CulinaryFeatureGuideHero extends React.Component {
    constructor(props) {
        super(props);

        this.basicHero =
            this.props.contentReducer && this.props.contentReducer.urlContent.content.hero.displayAsBasicHero;
    }

    getHeroComponent() {
        const {content} = this.props.contentReducer.urlContent;
        const {dropLinks, router} = this.props;

        if (this.basicHero) {
            return <CulinaryFeatureGuideHeroMain content={content} metaData={content.metaData} router={router} />;
        }

        return (
            <CulinaryFeatureGuideHeroSections
                content={content}
                currentSectionIndex={this.props.currentSectionIndex}
                dropLinks={dropLinks}
                inPageBuidlerBody={this.props.inPageBuidlerBody}
                metaData={content.metaData}
                router={router}
            />
        );
    }

    render() {
        if (!this.props || !this.props.contentReducer) {
            return null;
        }

        return this.getHeroComponent();
    }
}

export default CulinaryFeatureGuideHero;
