import React from 'react';
import moment from 'moment';

class FeatureDate extends React.Component {
    componentWillMount() {
        if (this.props.publishDate) {
            this.dateFormat = moment(this.props.publishDate).format('MMMM D YYYY');
        }
    }

    render() {
        if (!this.dateFormat) {
            return null;
        }

        return <div className="feature-date">{this.dateFormat}</div>;
    }
}

export default FeatureDate;
