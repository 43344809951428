import React from 'react';
import {Footer, Header} from '@hy-vee/navigation';

import {featureToggleNames} from '../constants/feature-toggles';

import CulinaryFeatureMeta from './culinary/modules/feature/culinary-feature-meta';
import SkipLink from './global/skip-link';
import ModalContainer from './global/modal-container';
import CulinaryAlert from './culinary/modules/culinary-alert';

class PageLayout extends React.Component {
    constructor(props) {
        super(props);

        this.isLoadingSavedRecipeIds = false;
    }

    componentWillReceiveProps(nextProps) {
        const {urlContentPath} = this.props.contentReducer;
        const nextPropsUrlContentPath = nextProps.contentReducer.urlContentPath;

        const previousRoute = urlContentPath !== '/' ? urlContentPath : this.props.routes[0].path;
        const newRoute = nextPropsUrlContentPath !== '/' ? nextPropsUrlContentPath : nextProps.routes[0].path;

        if (previousRoute !== newRoute && this.props.alertReducer.isActive === true) {
            this.props.actions.closeAlert();
        }

        if (
            !this.isLoadingSavedRecipeIds &&
            !this.props.favoritesReducer.recipeIdsRequested &&
            !this.props.favoritesReducer.recipeFavorites.error &&
            this.props.isAuthenticated
        ) {
            this.props.actions.setSavedRecipeIds();
            this.isLoadingSavedRecipeIds = true;
        }

        if (this.props.favoritesReducer.recipeIdsRequested) {
            this.isLoadingSavedRecipeIds = false;
        }
    }

    getIsPrinting() {
        const serverSideQuery = this.props.serverSideLocation.query ? this.props.serverSideLocation.query : {};
        const clientSideQuery = this.props.location.query ? this.props.location.query : {};

        return serverSideQuery.print === 'true' || clientSideQuery.print === 'true';
    }

    getPathName() {
        return global.window?.location?.pathname || this.props.serverSideLocation.pathname;
    }

    getSearch() {
        return global.window?.location?.search || this.props.serverSideLocation.search || '';
    }

    getListOverrideUrl() {
        const newListsEnabled = this.props.environmentConfigReducer?.activeFeatures?.[
            featureToggleNames.culinaryAOAddToList
        ];

        if (newListsEnabled) {
            return {
                listHref: '/aisles-online/lists'
            };
        }

        return {};
    }

    getHeaderData(pathname, search) {
        const headerData = {
            location: {
                pathname: pathname + search
            },
            showCartIcon: this.props.isAuthenticated,
            showListIcon: true,
            showSearchOption: false,
            ...this.getListOverrideUrl()
        };

        const user = this.props.userReducer.user;
        const preferredStore = this.props.preferredStoreReducer.preferredStore;

        if (user) {
            headerData.user = {
                ...user,
                firstName: user.first_name ? user.first_name : ''
            };

            if (preferredStore && Object.keys(preferredStore).length) {
                headerData.selectedStore = {
                    name: preferredStore.nickname || preferredStore.name,
                    storeCode: preferredStore.storeId
                };
            }
        }

        return headerData;
    }

    render() {
        const isPrinting = this.getIsPrinting();
        const {urlContent} = this.props.contentReducer;

        const landmarkAccessibilityAttributes = {
            'aria-hidden': this.props.modalReducer.isActive
        };

        let title;

        if (urlContent && urlContent.content && urlContent.content.metaData) {
            title = urlContent.content.metaData.title;
        }

        const pathname = this.getPathName();
        const search = this.getSearch();

        const headerData = this.getHeaderData(pathname, search);

        return (
            <div className={isPrinting ? 'culinary-wrapper print-template' : 'culinary-wrapper'}>
                <CulinaryAlert actions={this.props.actions} alertReducer={this.props.alertReducer} />
                <div className="header">
                    <SkipLink pathname={pathname ? pathname : 'default'} title={title} />
                    <h1 className="heading">{'Hy-Vee Recipes and Ideas'}</h1>
                    <h2 className="secondary-heading">{'Header and Navigation'}</h2>
                    <Header enableFeatureToggle {...headerData} />
                </div>
                <div className="content-container">
                    <div id="main-content" tabIndex="-1" />
                    <div className="content" {...landmarkAccessibilityAttributes}>
                        {React.cloneElement(this.props.children, this.props)}
                    </div>
                    <ModalContainer {...this.props} />
                    <div {...landmarkAccessibilityAttributes} className="footer">
                        <h2 className="footer-heading">{'Footer'}</h2>
                        <Footer />
                    </div>
                </div>
                {urlContent && urlContent.content && urlContent.content.metaData && (
                    <CulinaryFeatureMeta metaData={urlContent.content.metaData} />
                )}
            </div>
        );
    }
}

export default PageLayout;
