import React from 'react';
import {Link} from 'react-router';

const CulinaryHomeTemplate = (props) => (
    <main className="culinary-home-template">
        <ul>
            <li>
                <Link className="link" to="/recipes-ideas">
                    {'Recipe Ideas'}
                </Link>
            </li>
            <li>
                <Link className="link" to="/recipes-ideas/recipes">
                    {'Recipes/Recipe Search'}
                </Link>
            </li>
            <li>
                <Link className="link" to="/recipes-ideas/advice-how-tos">
                    {'Editorial Landing (howTos/Advice)'}
                </Link>
            </li>
            <li>
                <Link className="link" to="/recipes-ideas/advice-how-tos/food-love">
                    {'Editorial Category (Food Love)'}
                </Link>
            </li>
            <li>
                <Link className="link" to="/recipes-ideas/advice-how-tos/wellness/health-fitness">
                    {'Editorial Category Sub (Health and Fitness)'}
                </Link>
            </li>
            <li>
                <Link className="link" to="/recipes-ideas/advice-how-tos/search">
                    {'Editorial Search'}
                </Link>
            </li>
            <li>
                <Link className="link" to="/recipes-ideas/advice-how-tos/food-love/example-guide-1">
                    {'Food Guide, Food Love Example 1'}
                </Link>
            </li>
            <li>
                <Link className="link" to="/recipes-ideas/advice-how-tos/food-love/example-how-to-article">
                    {'How-to Detail'}
                </Link>
            </li>
            <li>
                <Link className="link" to="/recipes-ideas/advice-how-tos/food-love/strawberries-in-season">
                    {'Article Detail - Strawberries'}
                </Link>
            </li>
            <li>
                <Link className="link" to="/recipes-ideas/advice-how-tos/food-love/example-listicle">
                    {'Listicle Detail'}
                </Link>
            </li>
            <li>
                <Link className="link" to="/recipes-ideas/recipes/cherry-wild-rice-and-quinoa-salad">
                    {'Cherry, Wild Rice and Quinoa Salad'}
                </Link>
            </li>
            <li>
                <Link className="link" to="/recipes-ideas/recipes/creamy-chicken-noodle-soup">
                    {'Creamy Chicken Noodle Soup'}
                </Link>
            </li>
            <li>
                <Link className="link" to="/recipes-ideas/favorites">
                    {'Favorites'}
                </Link>
            </li>
            <li>
                <Link className="link" to="/recipes-ideas/meal-ideas">
                    {'Meal Ideas'}
                </Link>
            </li>
            <li>
                <Link
                    className="link"
                    target="_blank"
                    to="/recipes-ideas/recipes/cherry-wild-rice-and-quinoa-salad?print=true"
                >
                    {'Print Test'}
                </Link>
            </li>
            <li>
                <Link className="link" target="_blank" to={props.isAuthenticated ? '/logout' : '/login'}>
                    {props.isAuthenticated ? 'Logout' : 'Login'}
                </Link>
            </li>
        </ul>
    </main>
);

export default CulinaryHomeTemplate;
