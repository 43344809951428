import React from 'react';
import {Link} from 'react-router';

import {getVideoMetadata} from '../../../../services/youtube-data-api-service';
import CulinaryFavoriteBtn from '../favorite/culinary-favorite-btn';

class CulinaryGuideRecipeHighlight extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            mediaUrl: null
        };
    }

    componentDidMount() {
        const {rowId, columnId, data} = this.props;

        if (rowId !== undefined && columnId !== undefined) {
            let isValid = false;

            if (data && data.recipe) {
                isValid = true;
            }

            this.props.actions.pageBuilderSetValid({
                columnId,
                isValid,
                rowId
            });
        }

        this.setImage();
    }

    setImage() {
        if (this.props.data.recipe) {
            const {media} = this.props.data.recipe;
            const {imageOverride} = this.props.data;

            if (media.type === 'Video' && !media.previewImageUrl) {
                getVideoMetadata(media.videoId).then((mediaUrl) => {
                    this.setState({
                        mediaUrl: mediaUrl.fallbackThumbnailUrl
                    });
                });
            } else if (media.type === 'Image') {
                this.setState({
                    mediaUrl: (imageOverride && imageOverride.url) || media.url
                });
            } else {
                this.setState({
                    mediaUrl: media.previewImageUrl
                });
            }
        }
    }

    render() {
        if (!this.props.data.recipe) {
            return null;
        }

        const ingredientsList = this.props.data.keyIngredients.split(', ');

        const styles = {
            backgroundImage: `url('${this.state.mediaUrl}')`
        };

        const {title, description, url, contentId} = this.props.data.recipe;

        return (
            <section className="culinary-guide-recipe-highlight">
                <div
                    className={`background ${this.props.data.displayMediaAsBackground ? 'background-full' : ''}`}
                    style={styles}
                />
                <div className="container">
                    <div className="content">
                        <h4 className="title">{title}</h4>
                        <div
                            className="description"
                            /* eslint-disable-next-line react/no-danger */
                            dangerouslySetInnerHTML={{__html: description ? description : ''}}
                        />
                        <h5 className="sub-title">{'Main Ingredients'}</h5>
                        <ul className="ingredients">
                            {ingredientsList.map((ingredient) => (
                                <li className="ingredient" key={ingredient}>
                                    {ingredient}
                                </li>
                            ))}
                        </ul>
                        <div className="cta">
                            <Link className="button" to={url}>
                                {'View Recipe'}
                            </Link>
                            <CulinaryFavoriteBtn
                                actions={this.props.actions}
                                alertReducer={this.props.alertReducer}
                                favoritesReducer={this.props.favoritesReducer}
                                id={contentId}
                                isAuthenticated={this.props.isAuthenticated}
                                layoutType={'inline'}
                                modalReducer={this.props.modalReducer}
                                modalTriggerId={`recipe-highlight-favorite-button-${contentId}`}
                            />
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default CulinaryGuideRecipeHighlight;
