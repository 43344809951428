import React from 'react';

import CulinaryListicleListItem from './culinary-listicle-list-item';

const CulinaryListicleList = ({
    actions,
    alertReducer,
    listItems,
    isAuthenticated,
    modalReducer,
    favoritesReducer,
    videoPlayerReducer
}) => {
    if (!listItems) {
        return null;
    }

    return (
        <ol className="culinary-listicle-list">
            {listItems.map((listItem, index) => (
                <CulinaryListicleListItem
                    actions={actions}
                    alertReducer={alertReducer}
                    favoritesReducer={favoritesReducer}
                    index={index}
                    isAuthenticated={isAuthenticated}
                    key={index}
                    listItem={listItem}
                    modalReducer={modalReducer}
                    videoPlayerReducer={videoPlayerReducer}
                />
            ))}
        </ol>
    );
};

export default CulinaryListicleList;
