import React from 'react';

import eventKeys from '../../../../../../constants/event-keys';

import CulinaryRecipeDetailServingsTooltipContent from './culinary-recipe-detail-servings-tooltip-content';

class CulinaryRecipeDetailServingsTooltip extends React.Component {
    constructor(props) {
        super(props);

        this.state = {isVisible: false};
    }

    toggleTooltip = () => {
        this.setState((prevState) => ({isVisible: !prevState.isVisible}));
    };

    onKeyDown = (event) => {
        if (event.keyCode === eventKeys.ESC && this.state.isVisible) {
            event.preventDefault();
            this.toggleTooltip();
        }
    };

    render() {
        return (
            <CulinaryRecipeDetailServingsTooltipContent
                clickHandler={this.toggleTooltip}
                isVisible={this.state.isVisible}
                keyEventHandler={this.onKeyDown}
                {...this.props}
            />
        );
    }
}

export default CulinaryRecipeDetailServingsTooltip;
