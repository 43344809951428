import React from 'react';

class FeaturePrimaryImage extends React.Component {
    componentDidMount() {
        const {rowId, columnId, data} = this.props;

        if (rowId !== undefined && columnId !== undefined) {
            let isValid = false;

            if (data && data.cropUrl) {
                isValid = true;
            }

            this.props.actions.pageBuilderSetValid({
                columnId,
                isValid,
                rowId
            });
        }
    }

    render() {
        const {data, layoutType, cropWidth, hideCaption} = this.props;

        if (!data) {
            return null;
        }

        const imageWidth = cropWidth || 1400;

        return (
            <figure className="feature-primary-image">
                {layoutType === 'primary' ? (
                    <img alt={data.alt} src={`${data.cropUrl}&width=800&height=640&upscale=false`} />
                ) : (
                    <img alt={data.alt} src={`${data.cropUrl}&width=${imageWidth}&upscale=false`} />
                )}
                {data.caption && !hideCaption && <figcaption className="caption">{data.caption}</figcaption>}
            </figure>
        );
    }
}

export default FeaturePrimaryImage;
