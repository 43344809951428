import React from 'react';

import {slugify} from '../../../../../../services/string-services';

class CulinaryRecipeDetailIngredientsSelect extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isChecked: this.setIsChecked(this.props)
        };
    }

    componentWillReceiveProps(nextProps) {
        const isChecked = this.setIsChecked(nextProps);

        if (this.state.isChecked !== isChecked) {
            this.setState({
                isChecked
            });
        }
    }

    getAllVariationIngredients(props) {
        const selectedVariationIndex = `${props.ingredientsVariationReducer.selectedVariationIndex}`;
        const allIngredients = [];

        props.ingredientGroups.forEach((group, groupIndex) => {
            group.ingredients.forEach((ingredient, index) => {
                const checkboxId = slugify(`${selectedVariationIndex}-${index}-${groupIndex}-${ingredient.ingredient}`);

                allIngredients.push(checkboxId);
            });
        });

        return allIngredients;
    }

    setIsChecked(props) {
        const allVariationIngredients = this.getAllVariationIngredients(props);
        const selectedVariationIndex = props.ingredientsVariationReducer.selectedVariationIndex;
        const selectedVariationIngredients = props.ingredientsReducer.ingredientSelections[selectedVariationIndex];

        let isChecked = false;

        if (selectedVariationIngredients) {
            isChecked = allVariationIngredients.length === selectedVariationIngredients.length;
        }

        return isChecked;
    }

    render() {
        const selectVerb = this.state.isChecked ? 'Deselect' : 'Select';

        return (
            <div className="culinary-recipe-detail-ingredients-select">
                <input
                    checked={this.state.isChecked}
                    className="checkbox-function"
                    id="checkbox-add-all"
                    onChange={this.props.onSelectAll}
                    type="checkbox"
                />
                <label className="checkbox-presentation" htmlFor="checkbox-add-all">
                    <span className="checkbox-icon" />
                    <span className="checkbox-label">{`${selectVerb} all`}</span>
                </label>
            </div>
        );
    }
}

export default CulinaryRecipeDetailIngredientsSelect;
