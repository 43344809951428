import React from 'react';

import CulinaryDirectionsListItem from './culinary-directions-list-item';

const CulinaryDirectionsList = (props) => (
    <ol className="culinary-directions-list">
        {props.directions.map((direction, index) => (
            <CulinaryDirectionsListItem
                {...props}
                alertReducer={props.alertReducer}
                direction={direction}
                favoritesReducer={props.favoritesReducer}
                index={index}
                ingredientsVariationReducer={props.ingredientsVariationReducer}
                isAuthenticated={props.isAuthenticated}
                isPrinting={props.isPrinting}
                key={index}
                modalReducer={props.modalReducer}
            />
        ))}
    </ol>
);

export default CulinaryDirectionsList;
