import {FORM_INPUT_CLEAR, FORM_INPUT_UPDATE} from '../action-types';

const getInitialState = () => ({
    inputs: {}
});

const formInputUpdate = (state, inputData) => ({
    ...state,
    inputs: {
        ...state.inputs,
        ...inputData
    }
});

const formInputClear = (state) => ({
    ...state,
    inputs: {}
});

const reducerMap = {
    [FORM_INPUT_CLEAR]: formInputClear,
    [FORM_INPUT_UPDATE]: formInputUpdate
};

export default (state = getInitialState(), {data, type}) => {
    if (reducerMap[type]) {
        return reducerMap[type](state, data);
    }

    return state;
};

export {getInitialState};
