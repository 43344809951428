import React from 'react';
import {Lazy} from 'react-lazy';

class CulinaryFeaturePromoBlock extends React.Component {
    constructor(props) {
        super(props);

        this.pinterestIsRendered = false;

        this.onLoadSeasonsMediaHandler = () => this.onLoadSeasonsMedia();
        this.onLoadPinterestMediaHandler = () => this.onLoadPinterestMedia();

        this.state = {
            isMounted: false,
            pinterestMediaLoaded: false,
            seasonsMediaLoaded: false
        };
    }

    componentDidMount() {
        /* eslint-disable react/no-did-mount-set-state */
        this.setState({isMounted: true});

        if (this.seasonsMedia && this.seasonsMedia.complete) {
            this.onLoadSeasonsMedia();
        }

        if (this.pinterestMedia && this.pinterestMedia.complete) {
            this.onLoadPinterestMedia();
        }
        /* eslint-enable react/no-did-mount-set-state */
    }

    componentDidUpdate() {
        if (!this.pinterestIsRendered && global.parsePinBtns) {
            global.parsePinBtns();
            this.pinterestIsRendered = true;
        }
    }

    onLoadSeasonsMedia() {
        this.setState({
            seasonsMediaLoaded: true
        });
    }

    onLoadPinterestMedia() {
        this.setState({
            pinterestMediaLoaded: true
        });
    }

    render() {
        const {data, hasDivider, condensedLayout} = this.props;

        if (!data) {
            return null;
        }

        const hasDividerClass = hasDivider ? 'culinary-feature-promo-block-has-divider' : '';
        const condensedLayoutClass = condensedLayout ? 'culinary-feature-promo-block-condensed' : '';

        return (
            <aside className={`culinary-feature-promo-block ${hasDividerClass} ${condensedLayoutClass}`}>
                <h3 className="heading">{'Subscribe'}</h3>
                <div className="item">
                    <div className="content">
                        <h4 className="heading-container">
                            <span className="label">{`${data.seasonsHeading} `}</span>
                            <span className="heading">{data.seasonsSubHeading}</span>
                        </h4>
                        <p className="body">{data.seasonsDescription}</p>
                        <a className="link" href={data.seasonsLink.url} target={data.seasonsLink.target}>
                            {data.seasonsLink.title}
                        </a>
                    </div>
                    {data.seasonsImage && (
                        <Lazy className="media" component="div" cushion={500}>
                            <img
                                alt={data.seasonsImage.alt}
                                className={`${this.state.seasonsMediaLoaded ? 'image image-loaded' : 'image'}`}
                                onLoad={this.onLoadSeasonsMediaHandler}
                                ref={(node) => {
                                    this.seasonsMedia = node;
                                }}
                                src={`${data.seasonsImage.cropUrl}&width=260`}
                            />
                        </Lazy>
                    )}
                </div>
                <div className="item">
                    <div className="content">
                        <h4 className="heading-container">
                            <span className="label">{`${data.pinterestHeading} `}</span>
                            <span className="heading">{data.pinterestSubHeading}</span>
                        </h4>
                        <p className="body">{data.pinterestDescription}</p>
                        <div>
                            {this.state.isMounted && (
                                <a
                                    data-pin-do="buttonFollow"
                                    data-pin-tall="true"
                                    href={this.props.environmentConfigReducer.sharing.pinterestUrl}
                                >
                                    {'Follow Hy-Vee'}
                                </a>
                            )}
                        </div>
                    </div>
                    {data.pinterestImage && (
                        <Lazy className="media" component="div" cushion={500}>
                            <img
                                alt={data.pinterestImage.alt}
                                className={`${this.state.pinterestMediaLoaded ? 'image image-loaded' : 'image'}`}
                                onLoad={this.onLoadPinterestMediaHandler}
                                ref={(node) => {
                                    this.pinterestMedia = node;
                                }}
                                src={`${data.pinterestImage.cropUrl}&width=260`}
                            />
                        </Lazy>
                    )}
                </div>
            </aside>
        );
    }
}

export default CulinaryFeaturePromoBlock;
