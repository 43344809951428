import React from 'react';
import debounce from 'lodash.debounce';
import throttle from 'lodash.throttle';

import {sectionSlug} from '../../../services/string-services';
import clamp from '../../../services/math-service';
import CulinaryFeatureGuideHero from '../modules/feature/guide-hero/culinary-feature-guide-hero';
import CulinaryFeatureRelatedFooter from '../modules/feature/related-content/culinary-feature-related-footer';
import CulinaryFeatureRelatedGuide from '../modules/feature/related-content/culinary-feature-related-guide';
import PageBuilder from '../../page-builder/page-builder';

class CulinaryGuideTemplate extends React.Component {
    constructor(props) {
        super(props);

        this.content = this.props.contentReducer.urlContent.content;
        this.sections = [];
        this.dropLinks = this.content.hero && !this.content.hero.displayAsBasicHero ? this.getDropLinks() : [];
        this.pageBuilderBody = null;

        this.viewHeight = 0;
        this.offset = 0;

        this.onScrollHandler = throttle(this.onScroll.bind(this), 100);
        this.onResizeHandler = debounce(this.onResize.bind(this), 100);

        this.state = {
            currentSectionIndex: 0,
            inPageBuidlerBody: false
        };
    }

    componentDidMount() {
        if (this.content.hero && !this.content.hero.displayAsBasicHero) {
            this.initSections();
        }
    }

    componentWillUnmount() {
        global.window.removeEventListener('scroll', this.onScrollHandler);
        global.window.removeEventListener('resize', this.onResizeHandler);
    }

    initSections() {
        this.dropLinks.forEach((dropLink) => {
            const section = global.document.querySelector(`#${dropLink.id}`);

            if (section) {
                this.sections.push(section);
            }
        });

        this.pageBuilderBody = global.document.querySelector('.page-builder');

        this.getViewMeasurements();
        this.getElementMeasurements();

        global.window.addEventListener('scroll', this.onScrollHandler);
        global.window.addEventListener('resize', this.onResizeHandler);
    }

    onScroll() {
        this.getElementMeasurements();
    }

    onResize() {
        this.getViewMeasurements();
        this.getElementMeasurements();
    }

    getViewMeasurements() {
        this.pageBuilderBody = global.document.querySelector('.page-builder');
        this.viewHeight = Math.max(document.documentElement.clientHeight, global.window.innerHeight);
        this.navBar = global.document.querySelector('.culinary-feature-guide-hero-sections-navbar');
        this.offset = this.navBar ? this.navBar.getBoundingClientRect().height : 0;
    }

    getElementMeasurements() {
        this.getSectionsProps();
        this.setBodyState();
    }

    getSectionsProps() {
        const sectionViewPropsArray = [];

        this.sections.forEach((section) => {
            sectionViewPropsArray.push(this.getSectionViewProps(section));
        });

        this.setCurrentSection(sectionViewPropsArray);
    }

    setBodyState() {
        const rect = this.pageBuilderBody.getBoundingClientRect();
        const inPageBuidlerBody = rect.top - this.offset * 2 < 0 && rect.bottom - this.offset > 0;

        if (this.state.inPageBuidlerBody !== inPageBuidlerBody) {
            this.setState({
                inPageBuidlerBody
            });
        }
    }

    setCurrentSection(sectionViewPropsArray) {
        const percentsArray = sectionViewPropsArray.map((object) => object.percentInView);
        const inViewArray = sectionViewPropsArray.map((object) => object.inView);

        const primarySectionIndex = percentsArray.indexOf(Math.max.apply(this, percentsArray));

        if (inViewArray[primarySectionIndex] && primarySectionIndex !== this.state.currentSectionIndex) {
            this.setState({
                currentSectionIndex: primarySectionIndex
            });
        }
    }

    getDropLinks() {
        const dropLinks = [];
        const rows = this.content.grid.rows;

        rows.forEach((row) => {
            if (row.dropLinkText) {
                dropLinks.push({
                    id: sectionSlug(row.dropLinkText),
                    label: row.dropLinkText
                });
            }
        });

        return dropLinks;
    }

    getSectionViewProps(node) {
        const rect = node.getBoundingClientRect();

        const heightInView =
            this.viewHeight - clamp(rect.top, 0) - clamp(this.viewHeight - (rect.top + rect.height), 0);
        const percentInView = heightInView / rect.height;
        const inView = !(rect.bottom < 0 || rect.top + this.offset - this.viewHeight >= 0);

        return {
            inView,
            percentInView
        };
    }

    render() {
        return (
            <main className="culinary-guide-template">
                <h2 className="page-title">{this.content.title}</h2>
                {this.content.hero && (
                    <CulinaryFeatureGuideHero
                        {...this.props}
                        currentSectionIndex={this.state.currentSectionIndex}
                        dropLinks={this.dropLinks}
                        inPageBuidlerBody={this.state.inPageBuidlerBody}
                    />
                )}
                <PageBuilder {...this.props} grid={this.content.grid} />
                <CulinaryFeatureRelatedFooter
                    environmentConfigReducer={this.props.environmentConfigReducer}
                    noChildren={!this.content.relatedGuides}
                    promoContent={this.content.promoContent}
                >
                    <CulinaryFeatureRelatedGuide
                        actions={this.props.actions}
                        alertReducer={this.props.alertReducer}
                        carouselState={this.props.carouselReducer.carousels}
                        favoritesReducer={this.props.favoritesReducer}
                        isAuthenticated={this.props.isAuthenticated}
                        modalReducer={this.props.modalReducer}
                        recipeSaveReducer={this.props.recipeSaveReducer}
                        relatedGuideContent={this.content.relatedGuides}
                    />
                </CulinaryFeatureRelatedFooter>
            </main>
        );
    }
}

export default CulinaryGuideTemplate;
