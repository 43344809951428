import React from 'react';
import * as qs from 'qs';

class CulinaryAddToCartBtn extends React.Component {
    getAddToCartUrl = () => {
        if (this.props.disabled) {
            return '';
        }

        const afterLastSlash = this.props.recipeUrl.lastIndexOf('/') + 1;
        const recipeQueryParam = this.props.recipeUrl.slice(afterLastSlash, this.props.recipeUrl.length);
        const stringifiedArray = JSON.stringify(this.props.selectedIngredientsArray);
        const selectedIngredientsQueryParam = qs.stringify({selectedItems: stringifiedArray});

        const AORecipeAddToCartUrl = this.props.environmentConfigReducer.url.aoCulinaryAddToCart;

        return `${AORecipeAddToCartUrl}?recipe=${recipeQueryParam}&${selectedIngredientsQueryParam}`;
    };

    getComponentClass = () => {
        const baseClass = 'culinary-add-to-cart-btn';

        return this.props.layoutType === 'block' ? `${baseClass} ${baseClass}-block` : baseClass;
    };

    render() {
        return (
            <a
                data-testid="add-to-cart"
                href={this.getAddToCartUrl()}
                style={
                    this.props.disabled
                        ? {
                              pointerEvents: 'none'
                          }
                        : null
                }
            >
                <button
                    aria-label="Add to cart"
                    className={this.getComponentClass()}
                    disabled={this.props.disabled}
                    type="button"
                >
                    <span className="label">
                        <span className="text">{'Shop Ingredients'}</span>
                        <span className="btn-icon">
                            <svg
                                className="icon-inner"
                                focusable="false"
                                viewBox="0 0 21 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M14.8,18.8c0-0.6,0.5-1.2,1.2-1.2c0.7,0,1.2,0.5,1.2,1.2 c0,0.6-0.5,1.2-1.2,1.2C15.3,20,14.8,19.5,14.8,18.8z M3.2,20c0.7,0,1.2-0.5,1.2-1.2c0-0.6-0.5-1.2-1.2-1.2c-0.7,0-1.2,0.5-1.2,1.2 C2,19.5,2.5,20,3.2,20z M21,4.7l-1.4,7c-0.1,0.7-0.6,1.1-1.2,1.1l-12,0.6L5,15.7h13c0.7,0,1.2,0.6,1.2,1.3c0,0.2-0.2,0.5-0.4,0.5 s-0.4-0.2-0.4-0.5c0-0.2-0.1-0.4-0.4-0.4H5.1c-0.5,0-0.9-0.1-1-0.4c-0.2-0.4,0.1-0.8,0.1-0.9l1.5-2.4L2.2,1.3 C2.1,1.1,1.6,0.8,0.3,0.8c0,0-0.5,0-0.3-0.5c0,0,0-0.4,0.8-0.3C2.6,0.1,3,0.8,3.1,1.1l0.4,1.5l16.3,1C20.4,3.5,21,4.1,21,4.7z M20.1,4.6c0-0.1-0.2-0.3-0.4-0.3l-16-0.9l2.8,9.2L18.4,12c0.3,0,0.4-0.3,0.4-0.4L20.1,4.6z" />
                            </svg>
                        </span>
                    </span>
                </button>
            </a>
        );
    }
}

export default CulinaryAddToCartBtn;
