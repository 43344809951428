import {getVideoMetadata} from './youtube-data-api-service';

export const getPreviewImageState = async (media) => {
    let previewImageState = {};

    if (media && media.type === 'Video' && !media.previewImageUrl) {
        const mediaUrl = await getVideoMetadata(media.videoId);

        previewImageState = {
            mediaUrl: mediaUrl.fallbackThumbnailUrl,
            shouldCrop: false
        };
    } else if (media && media.type === 'Image') {
        previewImageState = {
            mediaAlt: media.alt,
            mediaUrl: media.cropUrl
        };
    } else if (media && media.previewImageUrl) {
        previewImageState = {
            mediaAlt: media.alt,
            mediaUrl: media.previewImageUrl
        };
    } else {
        previewImageState = {
            mediaAlt: '',
            mediaUrl: ''
        };
    }

    return previewImageState;
};
