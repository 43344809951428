import React from 'react';

const CulinarySearchFilterCheckbox = ({fieldName, filterId, isActive, onChangeRefinementFilterHandler, title}) => (
    <div className="checkbox">
        <input
            checked={isActive}
            className="checkbox-function"
            data-filter-id={filterId}
            id={`checkbox-filter-${fieldName}-${filterId}`}
            onChange={(event) => onChangeRefinementFilterHandler(event, title)}
            type="checkbox"
            value={fieldName}
        />
        <label className="checkbox-presentation" htmlFor={`checkbox-filter-${fieldName}-${filterId}`}>
            <span className="checkbox-label">{title}</span>
        </label>
        <label className="checkbox-visual" htmlFor={`checkbox-filter-${fieldName}-${filterId}`}>
            {title}
        </label>
    </div>
);

export default CulinarySearchFilterCheckbox;
