import React from 'react';

class CulinaryRecipeDetailNutritionChart extends React.Component {
    constructor(props) {
        super(props);

        this.animProps = {
            percent: 0,
            stop: false,
            time: {
                duration: 0,
                elapsed: 0,
                end: 0,
                iteration: 0,
                now: 0,
                progress: 0,
                progressPercent: 0,
                remaining: 0,
                start: 0,
                then: 0
            }
        };

        this.progressStyle = {};
        this.requestId = null;

        this.state = {
            percent: 0
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.waypointInView) {
            this.runCharts();
        }
    }

    componentWillUnmount() {
        this.stopAnimation();
    }

    getIterationValue(options) {
        const {duration, percent} = options;

        return Math.floor(duration / percent);
    }

    setupValues(options) {
        this.animProps.percent = options.percent;

        this.animProps.time.iteration = this.getIterationValue(options);
        this.animProps.time.then = Date.now();
        this.animProps.time.start = this.animProps.time.then;
        this.animProps.time.end = this.animProps.time.start + options.duration;
        this.animProps.time.duration = options.duration;
    }

    updateNow() {
        this.animProps.time.now = Date.now();
        this.animProps.time.elapsed = this.animProps.time.now - this.animProps.time.then;
        this.animProps.time.remaining = this.animProps.time.end - this.animProps.time.now;
        this.animProps.time.progress = this.animProps.time.duration - this.animProps.time.remaining;
        this.animProps.time.progressPercent = this.animProps.time.progress / this.animProps.time.duration;
    }

    stepAnimation() {
        if (this.animProps.stop) {
            return;
        }

        this.startAnimation();

        this.updateNow();

        if (this.animProps.time.elapsed > this.animProps.time.iteration) {
            this.animProps.time.then = this.animProps.time.now % this.animProps.time.iteration;

            this.setState({
                percent: Math.floor(this.animProps.percent * this.animProps.time.progressPercent)
            });
        }

        if (this.animProps.time.now >= this.animProps.time.end) {
            this.animProps.stop = true;

            this.setState({
                percent: this.animProps.percent
            });
        }
    }

    nextStepAnimation = () => {
        this.stepAnimation();
    };

    startAnimation() {
        this.requestId = global.requestAnimationFrame(this.nextStepAnimation);
    }

    stopAnimation() {
        this.animProps.stop = true;
        global.cancelAnimationFrame(this.requestId);
    }

    countUp(options) {
        this.setupValues(options);
        this.startAnimation();
    }

    runCharts() {
        const radius = 29.5;
        const percentValue = this.props.dailyValue.value;
        const circumference = 2 * Math.PI * radius;
        const offsetVal = circumference - (circumference * percentValue) / 100;
        const dashoffset = percentValue > 100 ? 0 : offsetVal;

        this.progressStyle = {
            strokeDashoffset: dashoffset
        };

        this.countUp({
            duration: 2000,
            percent: percentValue
        });
    }

    render() {
        if (this.props.dailyValue.value === null) {
            return null;
        }

        return (
            <div
                className="culinary-recipe-detail-nutrition-chart"
                data-percent={this.props.dailyValue.value}
                ref={(circle) => {
                    this.circle = circle;
                }}
            >
                <div className="chart">
                    <svg className="svg" focusable={false} height="64" viewBox="0 0 64 64" width="64">
                        <circle className="track" cx="32" cy="32" r="29.5" />
                        <circle
                            className="progress"
                            cx="32"
                            cy="32"
                            r="29.5"
                            ref={(progressBar) => {
                                this.progressBar = progressBar;
                            }}
                            style={this.progressStyle}
                            transform="rotate(-90 32 32)"
                        />
                    </svg>
                    <div
                        className="display"
                        ref={(display) => {
                            this.display = display;
                        }}
                    >
                        {`${this.state.percent}%`}
                    </div>
                </div>
                <div className="key">
                    <span className="label">{this.props.dailyValue.key}</span>
                    <span className="value"> {`${this.props.dailyValue.value}%`}</span>
                </div>
            </div>
        );
    }
}

export default CulinaryRecipeDetailNutritionChart;
