import {ADD_VIDEO, SET_ACTIVE_VIDEO_ID, SET_VIDEO_PROP} from '../action-types';

export const addVideo = (videoId) => ({
    data: videoId,
    type: ADD_VIDEO
});

export const setActiveVideoId = (videoId) => ({
    data: videoId,
    type: SET_ACTIVE_VIDEO_ID
});

export const setVideoProp = (prop) => ({
    data: prop,
    type: SET_VIDEO_PROP
});
