import React from 'react';

class DropdownMenu extends React.Component {
    constructor(props) {
        super(props);

        this.dropdownOptions = null;
        this.dropdownButton = null;
        this.dropdownContainer = null;
        this.isRecentlyActive = false;

        this.onClickToggleHandler = (event) => this.onClickToggle(event);
        /* istanbul ignore next */
        this.onFocusDocumentHandler = (event) => this.onFocusDocument(event);
        /* istanbul ignore next */
        this.onFocusMenuEndHandler = (event) => this.onFocusMenuEnd(event);

        this.state = {
            isActive: false
        };
    }

    componentDidMount() {
        global.document.addEventListener('focus', this.onFocusDocumentHandler, true);
    }

    /* istanbul ignore next */
    componentWillReceiveProps(nextProps) {
        if (nextProps.activeDropdownIndex !== this.props.activeDropdownIndex) {
            this.setState({isActive: nextProps.activeDropdownIndex === nextProps.dropdownId});
        }
    }

    /* istanbul ignore next */
    componentDidUpdate() {
        if (this.isRecentlyActive && this.dropdownOptions) {
            this.dropdownOptions.focus();
            this.isRecentlyActive = false;
        }
    }

    onClickToggle() {
        /* istanbul ignore next */
        if (!this.state.isActive) {
            this.isRecentlyActive = true;
        }

        this.props.onToggleDropdown(this.dropdownButton, this.props.dropdownId);
    }

    /* istanbul ignore next */
    onFocusMenuEnd() {
        if (this.state.isActive) {
            this.props.onToggleDropdown(this.dropdownButton, this.props.dropdownId);
            this.dropdownButton.focus();
        }
    }

    /* istanbul ignore next */
    onFocusDocument(event) {
        if (this.dropdownContainer && this.dropdownButton) {
            if (this.state.isActive && !this.dropdownContainer.contains(event.target)) {
                this.props.onToggleDropdown(this.dropdownButton, this.props.dropdownId);
                this.dropdownButton.focus();
            }
        }
    }

    render() {
        const {activeDropdownIndex, dropdownCount, dropdownId, title, type, errorStyle} = this.props;

        /* istanbul ignore next */
        const ariaState = this.state.isActive ? 'true' : 'false';
        const typeClass = type ? `dropdown-menu-${type}` : '';

        return (
            <div
                className={`dropdown-menu ${typeClass} ${activeDropdownIndex === dropdownId ? 'active' : ''}`}
                ref={
                    /* istanbul ignore next */
                    (node) => {
                        /* istanbul ignore next */
                        this.dropdownContainer = node;
                    }
                }
            >
                <button
                    aria-controls={`panel-${dropdownId}`}
                    aria-expanded={ariaState}
                    aria-label={title}
                    className={`toggle-tab ${errorStyle ? errorStyle : ''}`}
                    data-errorId={dropdownId}
                    id={`tab-${dropdownId}`}
                    onClick={this.onClickToggleHandler}
                    ref={
                        /* istanbul ignore next */
                        (node) => {
                            /* istanbul ignore next */
                            this.dropdownButton = node;
                        }
                    }
                    type="button"
                >
                    <div className="tab-label">
                        {title}
                        {dropdownCount > 0 && <span> {`(${dropdownCount})`}</span>}
                    </div>
                </button>
                <div aria-labelledby={`tab-${dropdownId}`} className="toggle-panel" id={`panel-${dropdownId}`}>
                    <button className="menu-start" onFocus={this.onFocusMenuEndHandler} type="button">
                        {'menu Start'}
                    </button>
                    <div
                        aria-label="Dropdown options"
                        className="dropdown-options"
                        ref={
                            /* istanbul ignore next */
                            (node) => {
                                /* istanbul ignore next */
                                this.dropdownOptions = node;
                            }
                        }
                        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                        tabIndex="0"
                    >
                        {this.props.children}
                    </div>
                    <button className="menu-end" onFocus={this.onFocusMenuEndHandler} type="button">
                        {'menu End'}
                    </button>
                </div>
            </div>
        );
    }
}

export default DropdownMenu;
