import ContentApiService from '../services/api/content-api-service';
import EnterpriseApiService from '../services/api/enterprise-api-service';
import {
    CLEAR_RECIPE_FAVORITES,
    RECIPE_FAVORITES_IS_LOADING,
    LOAD_RECIPE_FAVORITES,
    SET_ACTIVE_COURSE_TYPE,
    SET_PAGED_RECIPE_FAVORITES,
    SORT_RECIPE_FAVORITES,
    RECIPE_FAVORITES_ERROR,
    SET_SAVED_RECIPE_IDS,
    RECIPE_SAVE_SUCCESSFUL,
    RECIPE_SAVE_ERROR,
    RECIPE_DELETE_SUCCESSFUL,
    RECIPE_DELETE_ON_FAVORITES_PAGE_SUCCESSFUL,
    RECIPE_DELETE_ERROR,
    SET_DELETE_DATA,
    CLEAR_FAVORITING_ERRORS,
    RECIPE_DELETE_ON_FAVORITES_PAGE_ERROR
} from '../action-types';

const getLoadingTime = (requestStartTime) => {
    let loadingTimeout = 2100;
    const requestTime = Date.now() - requestStartTime;

    if (requestTime > loadingTimeout) {
        loadingTimeout = 0;
    } else {
        loadingTimeout = loadingTimeout - requestTime;
    }

    return loadingTimeout;
};

const joinResponseData = (responseArr) => {
    const courseMap = {};

    return responseArr.reduce(
        (obj, response) => {
            const responseDataData = response.data.data;
            const responseRecipes = responseDataData.recipes ? responseDataData.recipes : [];

            return {
                courseTypes: responseDataData.courseTypes.map((courseType) => {
                    if (courseMap[courseType.title]) {
                        courseMap[courseType.title] = {
                            ...courseMap[courseType.title],
                            count: courseMap[courseType.title].count + courseType.count
                        };
                    } else {
                        courseMap[courseType.title] = courseType;
                    }

                    return courseMap[courseType.title];
                }),
                recipes: [...obj.recipes, ...responseRecipes],
                recipeSearchLanding:
                    obj.recipeSearchLanding !== '' ? obj.recipeSearchLanding : responseDataData.recipeSearchLanding,
                totalResults: obj.totalResults + responseDataData.totalResults
            };
        },
        {
            courseTypes: [],
            recipes: [],
            recipeSearchLanding: '',
            totalResults: 0
        }
    );
};

const isResponseSuccessful = (responseArr) => {
    let success = true;

    responseArr.forEach((response) => {
        if (!response.success) {
            success = false;
        }
    });

    return success;
};

const getRecipesFromIds = async (recipeIds, favoritesApiUrl) => {
    const responseArr = [];

    /* eslint-disable no-await-in-loop */
    for (let i = 0; i <= recipeIds.length; i += 100) {
        responseArr.push(
            await ContentApiService.getRecipeFavorites(
                recipeIds.slice(i, i + 100 > recipeIds.length ? recipeIds.length : i + 100),
                favoritesApiUrl
            )
        );
    }
    /* eslint-enable no-await-in-loop */

    return responseArr;
};

export const loadRecipeFavorites = (favoritesApiUrl) => async (dispatch, getState) => {
    const requestStartTime = Date.now();

    dispatch({
        data: true,
        type: RECIPE_FAVORITES_IS_LOADING
    });

    const endpoint = getState().environmentConfigReducer.enterprise.userRecipes;
    const favoriteIdsResponseModel = await EnterpriseApiService.fetchUserRecipes(endpoint);

    if (favoriteIdsResponseModel.success && favoriteIdsResponseModel.data.data) {
        const recipeIds = favoriteIdsResponseModel.data.data.map((id) => id.recipe_id);

        const responseArr = await getRecipesFromIds(recipeIds, favoritesApiUrl);

        const success = isResponseSuccessful(responseArr);

        const responseData = joinResponseData(responseArr);

        dispatch({
            data: favoriteIdsResponseModel.data.data,
            type: SET_SAVED_RECIPE_IDS
        });

        if (success) {
            const loadingTimeout = getLoadingTime(requestStartTime);

            setTimeout(() => {
                dispatch({
                    data: false,
                    type: RECIPE_FAVORITES_IS_LOADING
                });

                dispatch({
                    data: responseData,
                    type: LOAD_RECIPE_FAVORITES
                });
            }, loadingTimeout);
        } else {
            dispatch({
                data: true,
                type: RECIPE_FAVORITES_ERROR
            });
        }
    } else if (!favoriteIdsResponseModel.success) {
        dispatch({
            data: true,
            type: RECIPE_FAVORITES_ERROR
        });
    } else {
        dispatch({
            data: false,
            type: RECIPE_FAVORITES_IS_LOADING
        });

        dispatch({
            data: null,
            type: LOAD_RECIPE_FAVORITES
        });
    }
};

export const setActiveCourseType = (courseType) => ({
    data: courseType,
    type: SET_ACTIVE_COURSE_TYPE
});

export const setPagedRecipeFavorites = (pageNumber) => ({
    data: pageNumber,
    type: SET_PAGED_RECIPE_FAVORITES
});

export const clearRecipeFavorites = () => ({
    type: CLEAR_RECIPE_FAVORITES
});

export const clearFavoritingErrors = () => ({
    type: CLEAR_FAVORITING_ERRORS
});

export const sortRecipeFavorites = (sortKey) => ({
    data: sortKey,
    type: SORT_RECIPE_FAVORITES
});

export const setDeleteData = (title, index, id) => ({
    data: {
        id,
        index,
        title
    },
    type: SET_DELETE_DATA
});

export const saveRecipe = (id) => async (dispatch, getState) => {
    const endpoint = getState().environmentConfigReducer.enterprise.saveRecipe;
    const responseModel = await EnterpriseApiService.saveRecipeById(endpoint, id);
    const type = responseModel.success ? RECIPE_SAVE_SUCCESSFUL : RECIPE_SAVE_ERROR;

    dispatch({
        data: id,
        type
    });
};

export const deleteRecipe = (id, favoritesPage) => async (dispatch, getState) => {
    const endpoint = getState().environmentConfigReducer.enterprise.deleteRecipe;
    const responseModel = await EnterpriseApiService.deleteRecipeById(endpoint, id);
    let type;

    if (favoritesPage) {
        type = responseModel.success
            ? RECIPE_DELETE_ON_FAVORITES_PAGE_SUCCESSFUL
            : RECIPE_DELETE_ON_FAVORITES_PAGE_ERROR;
    } else {
        type = responseModel.success ? RECIPE_DELETE_SUCCESSFUL : RECIPE_DELETE_ERROR;
    }

    dispatch({
        data: id,
        type
    });
};

export const setSavedRecipeIds = () => async (dispatch, getState) => {
    const endpoint = getState().environmentConfigReducer.enterprise.userRecipes;
    const favoriteIdsResponseModel = await EnterpriseApiService.fetchUserRecipes(endpoint);

    if (favoriteIdsResponseModel.success && favoriteIdsResponseModel.data) {
        dispatch({
            data: favoriteIdsResponseModel.data.data,
            type: SET_SAVED_RECIPE_IDS
        });
    } else {
        dispatch({
            data: true,
            type: RECIPE_FAVORITES_ERROR
        });
    }
};
