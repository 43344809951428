import React from 'react';

import CulinarySearchRefinementListItem from './culinary-search-refinement-list-item';

class CulinarySearchRefinementList extends React.Component {
    buildRefinementItem(featuredRefinement, featuredRefinementIndex) {
        if (featuredRefinement === null || featuredRefinement.refinement === null) {
            return null;
        }

        return (
            <CulinarySearchRefinementListItem
                addFilter={this.props.addFilter}
                dropdowns={this.props.dropdowns}
                featuredRefinement={featuredRefinement}
                featuredRefinementId={featuredRefinement.refinement.id.toString()}
                filters={this.props.filters}
                key={featuredRefinementIndex}
                removeFilter={this.props.removeFilter}
                setLinks={this.props.setLinks}
                sortBy={this.props.sortBy}
            />
        );
    }

    render() {
        return (
            <section className="culinary-search-refinement-list culinary-search-refinement-list-expanded">
                <h3 className="heading">{'Refinements'}</h3>
                <ul className="list">
                    {this.props.data.map((featuredRefinement, featuredRefinementIndex) =>
                        this.buildRefinementItem(featuredRefinement, featuredRefinementIndex)
                    )}
                </ul>
            </section>
        );
    }
}

export default CulinarySearchRefinementList;
