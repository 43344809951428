import React from 'react';

import CulinaryRecipeDetailIngredientsRow from './culinary-recipe-detail-ingredients-row';

const CulinaryRecipeDetailIngredientsSection = ({
    ingredients,
    ingredientsReducer,
    ingredientsVariationReducer,
    title,
    actions,
    groupIndex,
    isPrinting
}) => {
    const hasTitle = Boolean(title);
    const noCategoryClass = hasTitle ? '' : ' culinary-recipe-detail-ingredients-section-no-category';

    return (
        <tbody className={`culinary-recipe-detail-ingredients-section${noCategoryClass}`}>
            {hasTitle && (
                <tr className="group">
                    <td className="category" colSpan="3">
                        {title}
                    </td>
                </tr>
            )}
            {ingredients &&
                ingredients.map((ingredient, ingredientIndex) => (
                    <CulinaryRecipeDetailIngredientsRow
                        actions={actions}
                        groupIndex={groupIndex}
                        ingredient={ingredient}
                        ingredientIndex={ingredientIndex}
                        ingredientsReducer={ingredientsReducer}
                        ingredientsVariationReducer={ingredientsVariationReducer}
                        isPrinting={isPrinting}
                        key={ingredientIndex}
                    />
                ))}
        </tbody>
    );
};

export default CulinaryRecipeDetailIngredientsSection;
