import {CLOSE_ALERT, CREATE_ALERT} from '../action-types';

const getInitialState = () => ({
    isActive: false,
    message: null,
    type: 'error'
});

const createAlert = (state, alertData) => ({
    ...state,
    ...alertData
});

const closeAlert = (state) => ({
    ...state,
    isActive: false,
    message: null
});

const reducerMap = {
    [CLOSE_ALERT]: closeAlert,
    [CREATE_ALERT]: createAlert
};

export default (state = getInitialState(), {data, type}) => {
    if (reducerMap[type]) {
        return reducerMap[type](state, data);
    }

    return state;
};

export {getInitialState};
