export const UI_UPDATE_CHECKED_DIRECTION = 'UI_UPDATE_CHECKED_DIRECTION';

export const CAROUSEL_SET_BLOCK_WIDTH = 'CAROUSEL_SET_BLOCK_WIDTH';

export const PAGE_BUILDER_SET_VALID = 'PAGE_BUILDER_SET_VALID';
export const PAGE_BUILDER_CLEAR_VALID = 'PAGE_BUILDER_CLEAR_VALID';

export const FORM_INPUT_CLEAR = 'FORM_INPUT_CLEAR';
export const FORM_INPUT_UPDATE = 'FORM_INPUT_UPDATE';

export const ADD_TO_CART_CLEAR_STATUS = 'ADD_TO_CART_CLEAR_STATUS';
export const ADD_TO_CART_INGREDIENTS_TO_ADD = 'ADD_TO_CART_INGREDIENTS_TO_ADD';
export const ADD_TO_CART_RECIPE = 'ADD_TO_CART_RECIPE';
export const ADD_TO_CART_SET_FAIL = 'ADD_TO_CART_SET_FAIL';
export const ADD_TO_CART_SET_LOADING = 'ADD_TO_CART_SET_LOADING';
export const ADD_TO_CART_SET_SUCCESS = 'ADD_TO_CART_SET_SUCCESS';

export const ADD_TO_LIST_ADD_ERRORS = 'ADD_TO_LIST_ADD_ERRORS';
export const ADD_TO_LIST_CLEAR_ERRORS = 'ADD_TO_LIST_CLEAR_ERRORS';
export const ADD_TO_LIST_CLEAR_STATUS = 'ADD_TO_LIST_CLEAR_STATUS';
export const ADD_TO_LIST_INGREDIENTS_TO_ADD = 'ADD_TO_LIST_INGREDIENTS_TO_ADD';
export const ADD_TO_LIST_RECENTLY_VALIDATED = 'ADD_TO_LIST_RECENTLY_VALIDATED';
export const ADD_TO_LIST_SET_FAIL = 'ADD_TO_LIST_SET_FAIL';
export const ADD_TO_LIST_SET_BUSY_ADDING = 'ADD_TO_LIST_SET_BUSY_ADDING';
export const ADD_TO_LIST_SET_BUSY_LOADING = 'ADD_TO_LIST_SET_BUSY_LOADING';
export const ADD_TO_LIST_SET_SUCCESS_ADDED = 'ADD_TO_LIST_SET_SUCCESS_ADDED';
export const ADD_TO_LIST_SET_SUCCESS_LIST_CREATED = 'ADD_TO_LIST_SET_SUCCESS_LIST_CREATED';
export const ADD_TO_LIST_SET_SUCCESS_LOADED = 'ADD_TO_LIST_SET_SUCCESS_LOADED';
export const ADD_TO_LIST_SET_SUCCESS_RELOADED = 'ADD_TO_LIST_SET_SUCCESS_RELOADED';
export const ADD_TO_LIST_SET_LIST_TITLE = 'ADD_TO_LIST_SET_LIST_TITLE';

export const MODAL_CLOSE = 'MODAL_CLOSE';
export const MODAL_UPDATE = 'MODAL_UPDATE';

export const SET_SELECTED_VARIATION_INDEX = 'SET_SELECTED_VARIATION_INDEX';

export const SET_RECIPE_RATING = 'SET_RECIPE_RATING';

export const RESET_SELECTED_INGREDIENTS = 'RESET_SELECTED_INGREDIENTS';
export const UPDATE_CHECKED_ALL_INGREDIENTS = 'UPDATE_CHECKED_ALL_INGREDIENTS';
export const UPDATE_CHECKED_INGREDIENT = 'UPDATE_CHECKED_INGREDIENT';

export const SET_FEEDBACK_RESPONSE_SELECTION = 'SET_FEEDBACK_RESPONSE_SELECTION';
export const CLEAR_FEEDBACK_RESPONSE = 'CLEAR_FEEDBACK_RESPONSE';

export const LOAD_URL_CONTENT = 'LOAD_URL_CONTENT';
export const LOAD_URL_CONTENT_SUCCESS = 'LOAD_URL_CONTENT_SUCCESS';
export const LOAD_STORE_CONTENT = 'LOAD_STORE_CONTENT';
export const CLEAR_URL_CONTENT = 'CLEAR_URL_CONTENT';
export const CONTENT_SET_LOADING = 'CONTENT_SET_LOADING';

export const SET_LOADING = 'SET_LOADING';

export const ADD_VIDEO = 'ADD_VIDEO';
export const SET_ACTIVE_VIDEO_ID = 'SET_ACTIVE_VIDEO_ID';
export const SET_VIDEO_PROP = 'SET_VIDEO_PROP';

export const ADD_FILTER = 'ADD_FILTER';
export const ADD_FILTER_GROUP = 'ADD_FILTER_GROUP';
export const REMOVE_FILTER = 'REMOVE_FILTER';
export const REMOVE_ALL_FILTERS = 'REMOVE_ALL_FILTERS';
export const SET_QUERY_STRING = 'SET_QUERY_STRING';
export const SET_SORT_BY = 'SET_SORT_BY';
export const SYNC_SEARCH_QUERY_WITH_STORE = 'SYNC_SEARCH_QUERY_WITH_STORE';
export const SET_SEARCH_IS_LOADING = 'SET_SEARCH_IS_LOADING';
export const SET_SEARCH_IS_LOADING_MORE = 'SET_SEARCH_IS_LOADING_MORE';

export const SET_AUTOCOMPLETE_RESULTS = 'SET_AUTOCOMPLETE_RESULTS';
export const CLEAR_AUTOCOMPLETE_RESULTS = 'CLEAR_AUTOCOMPLETE_RESULTS';
export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';
export const CLEAR_SEARCH_RESULTS = 'CLEAR_SEARCH_RESULTS';
export const RESET_SEARCH = 'RESET_SEARCH';
export const INCREMENT_PAGE = 'INCREMENT_PAGE';
export const SET_EDITORIAL_LIST_IN_STORE = 'SET_EDITORIAL_LIST_IN_STORE';
export const GET_PAGED_RESULTS = 'GET_PAGED_RESULTS';

export const ADD_MEAL_IDEA_TO_PRINT = 'ADD_MEAL_IDEA_TO_PRINT';
export const REMOVE_MEAL_IDEA_TO_PRINT = 'REMOVE_MEAL_IDEA_TO_PRINT';
export const ADD_ALL_MEAL_IDEAS_TO_PRINT = 'ADD_ALL_MEAL_IDEA_TO_PRINT';
export const SET_PRINT_PREVIEW_ERROR = 'SET_PRINT_PREVIEW_ERROR';

export const MEAL_IDEAS_KEY_UPDATE = 'MEAL_IDEAS_KEY_UPDATE';

export const LOAD_RECIPE_FAVORITES = 'LOAD_RECIPE_FAVORITES';
export const RECIPE_FAVORITES_IS_LOADING = 'RECIPE_FAVORITES_IS_LOADING';
export const SET_ACTIVE_COURSE_TYPE = 'SET_ACTIVE_COURSE_TYPE';
export const SET_PAGED_RECIPE_FAVORITES = 'SET_PAGED_RECIPE_FAVORITES';
export const CLEAR_RECIPE_FAVORITES = 'CLEAR_RECIPE_FAVORITES';
export const SORT_RECIPE_FAVORITES = 'SORT_RECIPE_FAVORITES';
export const RECIPE_FAVORITES_ERROR = 'RECIPE_FAVORITES_ERROR';
export const RECIPE_SAVE_SUCCESSFUL = 'RECIPE_SAVE_SUCCESSFUL';
export const RECIPE_SAVE_ERROR = 'RECIPE_SAVE_ERROR';
export const SET_SAVED_RECIPE_IDS = 'SET_SAVED_RECIPE_IDS';
export const RECIPE_DELETE_SUCCESSFUL = 'RECIPE_DELETE_SUCCESSFUL';
export const RECIPE_DELETE_ERROR = 'RECIPE_DELETE_ERROR';
export const SET_DELETE_DATA = 'SET_DELETE_DATA';
export const RECIPE_DELETE_ON_FAVORITES_PAGE_SUCCESSFUL = 'RECIPE_DELETE_ON_FAVORITES_PAGE_SUCCESSFUL';
export const RECIPE_DELETE_ON_FAVORITES_PAGE_ERROR = 'RECIPE_DELETE_ON_FAVORITES_PAGE_ERROR';
export const CLEAR_FAVORITING_ERRORS = 'CLEAR_FAVORITING_ERRORS';

export const CLOSE_ALERT = 'CLOSE_ALERT';
export const CREATE_ALERT = 'CREATE_ALERT';
