import React from 'react';

import withMountAnimation from '../../../global/hoc/with-mount-animation';
import CulinaryCardBlocks from '../culinary-card-blocks';

const CulinaryFavoritesRecipesMountAnimation = withMountAnimation(CulinaryCardBlocks);

class CulinaryFavoritesRecipes extends React.Component {
    constructor(props) {
        super(props);

        this.onClickHandler = () => this.getNextPage();
    }

    componentWillReceiveProps(nextProps) {
        this.shouldReanimate = false;

        if (
            this.props.favoritesReducer.recipeFavorites.activeCourseType !==
            nextProps.favoritesReducer.recipeFavorites.activeCourseType
        ) {
            this.shouldReanimate = true;
        }
    }

    getNextPage() {
        this.props.actions.setPagedRecipeFavorites(this.props.currentPage + 1);
    }

    render() {
        if (!this.props.visibleRecipes) {
            return null;
        }

        const {totalResultsInActiveCategory, visibleResultsLength} = this.props;
        const isEnabled = totalResultsInActiveCategory !== visibleResultsLength;
        const animationDelayOffset = 140;
        const animationTransitionDuration = 700;
        const animationTransitionTimeout = animationDelayOffset * visibleResultsLength + animationTransitionDuration;
        const shouldReanimate = this.shouldReanimate;
        const {activeCourseType} = this.props.favoritesReducer.recipeFavorites;

        return (
            <div className="culinary-favorites-content-loader" key={activeCourseType}>
                <CulinaryFavoritesRecipesMountAnimation
                    actions={this.props.actions}
                    alertReducer={this.props.alertReducer}
                    cards={this.props.visibleRecipes}
                    favoritesReducer={this.props.favoritesReducer}
                    focusFirstAllowed={this.props.focusFirstAllowed}
                    focusIndex={this.props.focusIndex}
                    isAuthenticated={this.props.isAuthenticated}
                    modalReducer={this.props.modalReducer}
                    shouldReanimate={shouldReanimate}
                    showConfirmation
                    showRatings={this.props.activeSortKey === 'rating'}
                    withMountAnimationProps={{
                        offset: animationDelayOffset,
                        pageSize: this.props.paginationAmount,
                        transitionGroup: {
                            appear: true,
                            className: 'culinary-card-blocks culinary-card-blocks-search',
                            classNames: {
                                appear: 'kf-slide-in'
                            },
                            component: 'ul',
                            enter: false,
                            exit: false,
                            timeout: animationTransitionTimeout
                        }
                    }}
                />
                {isEnabled && (
                    <button className="button" onClick={this.onClickHandler} type="button">
                        {'View More recipes'}
                    </button>
                )}
            </div>
        );
    }
}

export default CulinaryFavoritesRecipes;
