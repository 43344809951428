import React from 'react';
import {Link} from 'react-router';

import IconHyVeeLogo from '../../../../global/icon/icon-hy-vee-logo';
import CulinaryFeatureContentSharing from '../../feature/culinary-feature-content-sharing';

class CulinaryMealIdeasHero extends React.Component {
    constructor(props) {
        super(props);

        this.previousMarkup = this.getPreviousMarkup();
        this.nextMarkup = this.getNextMarkup();
    }

    getPreviousMarkup() {
        const {previousUrl} = this.props.content;

        if (previousUrl) {
            return (
                <Link aria-label="Previous" className="button button-previous" to={previousUrl}>
                    <span className="label">{'Previous'}</span>
                </Link>
            );
        }

        return null;
    }

    getNextMarkup() {
        const {nextUrl} = this.props.content;

        if (nextUrl) {
            return (
                <Link aria-label="Next" className="button button-next" to={nextUrl}>
                    <span className="label">{'Next'}</span>
                </Link>
            );
        }

        return null;
    }

    getPaginationClass() {
        let paginationClass = '';
        const {nextUrl, previousUrl} = this.props.content;

        if (nextUrl && !previousUrl) {
            paginationClass = 'pagination-next-only';
        }

        if (previousUrl && !nextUrl) {
            paginationClass = 'pagination-previous-only';
        }

        if (previousUrl && nextUrl) {
            paginationClass = 'pagination-all-buttons';
        }

        return paginationClass;
    }

    render() {
        const {router} = this.props;
        const {
            heading,
            leftBackgroundImage,
            metaData,
            rightBackgroundImage,
            subHeading,
            subTitle,
            title,
            shareUrl
        } = this.props.content;
        const leftBackgroundImageCropUrl = leftBackgroundImage ? leftBackgroundImage.cropUrl : '';
        const rightBackgroundImageCropUrl = rightBackgroundImage ? rightBackgroundImage.cropUrl : '';
        const leftBackgroundStyle = {backgroundImage: `url(${leftBackgroundImageCropUrl})`};
        const rightBackgroundStyle = {backgroundImage: `url(${rightBackgroundImageCropUrl})`};
        const paginationClass = this.getPaginationClass();

        return (
            <div className="culinary-meal-ideas-hero">
                <div className="container">
                    <div className="image" style={leftBackgroundStyle} />
                    <div className="header">
                        <div className="print-logo">
                            <IconHyVeeLogo />
                        </div>
                        <CulinaryFeatureContentSharing
                            metaData={metaData}
                            onClickPrintHandler={this.props.onClickPrintHandler}
                            router={router}
                            shareUrl={shareUrl}
                            subTitle={subTitle}
                        />
                        <h2 className="title">{heading}</h2>
                        <p className="body">{subHeading}</p>
                    </div>
                    <div className="image" style={rightBackgroundStyle} />
                </div>
                <div className={`pagination ${paginationClass}`}>
                    <div className="week-title">
                        <h3 className="main">{title}</h3>
                        <h4 className="sub">{subTitle}</h4>
                    </div>
                    {this.previousMarkup}
                    {this.nextMarkup}
                </div>
                <div className="lattice" />
            </div>
        );
    }
}

export default CulinaryMealIdeasHero;
