import React from 'react';

const IconHeartSmall = () => (
    <svg
        className="icon-heart icon-heart-small"
        focusable={false}
        height="19.7"
        viewBox="0 0 22 19.7"
        width="22"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            className="svg-shape"
            d="M19.1 10.3c.8-.9 1.4-2.2 1.4-3.6 0-2.7-2.5-5.2-5.2-5.2-1.8.1-3.4 1-4.4 2.4-.9-1.4-2.6-2.2-4.4-2.2-2.8 0-5 2.3-5 5.2 0 1.4.5 2.5 1.4 3.5l6.8 7.3c.2.2.7.5 1.1.5s.9-.2 1.1-.5l7.2-7.4"
        />
    </svg>
);

export default IconHeartSmall;
