import {post} from './api/fetch-service';

export const postFeedback = async(body) => {
    const response = await post('','/recipes-ideas/cms/feedback', {
        body: JSON.stringify(body),
        headers: {
            'Content-Type': 'application/json'
        }
    });

    return response;
}
