import React from 'react';
import {Link} from 'react-router';

import {isValidString} from '../../../utilities/prop-utilities';

const hasTarget = ({target}) => isValidString(target);

const hrefIsExternalToCulinary = ({href}) => {
    if (!isValidString(href)) {
        return false;
    }

    return !href.includes('recipes-ideas');
};

const isAnchor = (props) => hasTarget(props) || hrefIsExternalToCulinary(props);

const SwapLink = (props) =>
    isAnchor(props) ? (
        <a
            className={props.className}
            href={props.href}
            onClick={props.onClick}
            style={props.style}
            target={props.target}
        >
            {props.children}
        </a>
    ) : (
        <Link className={props.className} style={props.style} to={props.href}>
            {props.children}
        </Link>
    );

export default SwapLink;
