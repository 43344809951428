import React from 'react';

class CulinaryRecipeDetailNutritionAmountItem extends React.Component {
    validateValue(value) {
        return value === '0' ? '0g' : value;
    }

    getSubNutrientMinorMarkup(subNutrientMinor, indexMinor) {
        if (subNutrientMinor.value === '' || subNutrientMinor.value === null || subNutrientMinor.value === undefined) {
            return null;
        }

        return <li key={indexMinor}>{`${subNutrientMinor.key}: ${this.validateValue(subNutrientMinor.value)}`}</li>;
    }

    render() {
        const {subNutrient, index} = this.props;

        return (
            <li className="culinary-recipe-detail-nutrition-amount-item" key={index}>
                {`${subNutrient.key}: ${this.validateValue(subNutrient.value)}`}
                {subNutrient.subNutrients && subNutrient.subNutrients.length > 0 && (
                    <ul className="related">
                        {subNutrient.subNutrients.map((subNutrientMinor, indexMinor) =>
                            this.getSubNutrientMinorMarkup(subNutrientMinor, indexMinor)
                        )}
                    </ul>
                )}
            </li>
        );
    }
}

export default CulinaryRecipeDetailNutritionAmountItem;
