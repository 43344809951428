import React from 'react';

import CulinaryFeatureIntro from '../../modules/feature/culinary-feature-intro';
import FeatureWysiwygMixed from '../../../feature/feature-wysiwyg-mixed';

import CulinaryArticleListicleTemplateBase from './culinary-article-listicle-template-base';

const CulinaryArticleTemplateBody = (props) => {
    const {content} = props.contentReducer.urlContent;

    return (
        <CulinaryArticleListicleTemplateBase {...props}>
            <CulinaryFeatureIntro description={content.description} layout={'divided'} />
            <FeatureWysiwygMixed
                actions={props.actions}
                alertReducer={props.alertReducer}
                content={content.content}
                favoritesReducer={props.favoritesReducer}
                isAuthenticated={props.isAuthenticated}
                modalReducer={props.modalReducer}
            />
        </CulinaryArticleListicleTemplateBase>
    );
};

export default CulinaryArticleTemplateBody;
