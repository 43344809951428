import React from 'react';
import debounce from 'lodash.debounce';

import CulinaryFeatureRecipeCarouselItem from './culinary-feature-recipe-carousel-item';

class CulinaryFeatureRecipeCarouselList extends React.Component {
    constructor(props) {
        super(props);

        this.blocks = [];

        this.setupHandlers();
    }

    componentDidMount() {
        global.window.addEventListener('resize', this.onResizeWindowHandler);

        this.setBlockWidth();
    }

    componentWillUnmount() {
        global.window.removeEventListener('resize', this.onResizeWindowHandler);
    }

    setupHandlers() {
        this.onResizeWindowHandler = debounce((event) => this.onResizeWindow(event), 250);
    }

    onResizeWindow() {
        this.setBlockWidth();
    }

    setBlockWidth = () => {
        if (this.blocks[0]) {
            this.props.actions.carouselSetBlockWidth({
                blockWidth: this.blocks[0].getBoundingClientRect().width,
                id: this.props.id
            });
        }
    };

    setIsActive(activeIndex, index) {
        return activeIndex === index;
    }

    buildRecipeItem(recipe, index, isRepeated) {
        if (recipe.recipe && recipe.recipe !== null) {
            return (
                <li
                    key={index}
                    ref={(block) => {
                        this.blocks[index] = block;
                    }}
                >
                    <CulinaryFeatureRecipeCarouselItem
                        actions={this.props.actions}
                        alertReducer={this.props.alertReducer}
                        favoritesReducer={this.props.favoritesReducer}
                        id={this.props.id}
                        index={index}
                        isActive={this.setIsActive(this.props.activeIndex, index)}
                        isAuthenticated={this.props.isAuthenticated}
                        isRepeat={isRepeated}
                        key={recipe.title}
                        modalReducer={this.props.modalReducer}
                        onClickRecipe={this.props.onClickRecipe}
                        recipe={recipe}
                    />
                </li>
            );
        }

        return null;
    }

    render() {
        let repeatedList = null;

        if (this.props.isLooping) {
            repeatedList = this.props.recipes.map((recipe, index) => this.buildRecipeItem(recipe, index, true));
        }

        return (
            <ul className={`culinary-feature-recipe-carousel-list ${this.isLooping ? 'is-looping' : 'is-not-looping'}`}>
                {repeatedList}
                {this.props.recipes.map((recipe, index) => this.buildRecipeItem(recipe, index, false))}
                {repeatedList}
            </ul>
        );
    }
}

export default CulinaryFeatureRecipeCarouselList;
