import React from 'react';
import {Link} from 'react-router';

const CulinaryFeatureTags = ({badges, description, tags}) => {
    const displayCount = 3;

    if ((!tags && (!badges || badges.length <= displayCount)) || !description) {
        return null;
    }

    const leftoverBadgesToDisplay = badges.slice(displayCount, badges.length);
    const tagsAndBadges = leftoverBadgesToDisplay.concat(tags);

    return (
        <section className="culinary-feature-tags">
            <h4 className="header">{'Recipe Tags'}</h4>
            <ul className="list">
                {tagsAndBadges.map((tagOrBadge) => (
                    <li key={tagOrBadge.title}>
                        <Link className="tag-link" to={tagOrBadge.url}>
                            {tagOrBadge.title}
                        </Link>
                    </li>
                ))}
            </ul>
        </section>
    );
};

export default CulinaryFeatureTags;
