import React from 'react';

const CulinaryFeatureArticleAuthor = ({author, stackAuthorOnMobile}) => {
    if (!author) {
        return null;
    }

    const authorNameTitle = author.description ? `${author.name}, ${author.description}` : author.name;
    const layoutClass = stackAuthorOnMobile ? 'culinary-feature-article-author-stack-small' : '';

    return (
        <div className={`culinary-feature-article-author ${layoutClass}`}>
            {author.profileImage && (
                <img
                    alt={author.profileImage.alt}
                    className="image"
                    src={`${author.profileImage.cropUrl}&width=80&height=80`}
                />
            )}
            <div className="name">{authorNameTitle}</div>
        </div>
    );
};

export default CulinaryFeatureArticleAuthor;
