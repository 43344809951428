import React from 'react';

const FeatureFlexibleListItem = ({listItem, layoutType, classes, listItemType}) => {
    if (!listItem) {
        return null;
    }

    return (
        <li className={`feature-flexible-list-item-${listItemType} ${layoutType} ${classes}`}>
            {listItem.image && <img alt={listItem.image.alt} src={`${listItem.image.cropUrl}&width=320`} />}
            <div className="feature-flexible-list-item-body">
                <h4 className="title">{listItem.heading}</h4>
                <p className="description">{listItem.description}</p>
            </div>
        </li>
    );
};

export default FeatureFlexibleListItem;
