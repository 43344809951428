import React from 'react';

class CulinaryFeatureGuideHeroSectionsListItem extends React.Component {
    constructor(props) {
        super(props);

        this.label = this.props.dropLink.label;
        this.target = this.props.dropLink.id;
    }

    getComponentClass() {
        const baseClass = 'culinary-feature-guide-hero-sections-list-item';

        return this.props.itemIndex === this.props.selectedIndex ? `${baseClass} is-active` : baseClass;
    }

    render() {
        const data = {
            index: this.props.itemIndex,
            target: this.target
        };

        return (
            <li className={this.getComponentClass()}>
                <a
                    className="section-link"
                    data-target={this.target}
                    href={`#${this.target}`}
                    onClick={(event) => this.props.onClickHandler(event, data)}
                    ref={(sectionLink) => {
                        this.sectionLink = sectionLink;
                    }}
                >
                    <div className="label">{this.label}</div>
                </a>
            </li>
        );
    }
}

export default CulinaryFeatureGuideHeroSectionsListItem;
