import React from 'react';

import SwapLink from '../../../global/hoc/swap-link';

const positionClass = (alignment) => {
    switch (alignment) {
        case 'Center':
            return 'culinary-feature-hero-banner-middle';
        case 'Right':
            return 'culinary-feature-hero-banner-end';
        default:
            return '';
    }
};

const CulinaryFeatureHeroBanner = ({hero}) => {
    if (!hero) {
        return null;
    }

    const cropUrl = hero.backgroundImage ? hero.backgroundImage.cropUrl : null;
    const backgroundImageStyle = {
        backgroundImage: cropUrl ? `url(${cropUrl}&width=1800)` : 'none'
    };
    const invertedColorClass = !hero.invertedText ? 'overlay-content-color-dark' : '';
    const textContrastClass = hero.textContrast ? 'overlay-content-color-contrast' : '';
    const buttonColor = hero.linkButtonColor ? {color: `#${hero.linkButtonColor}`} : null;

    return (
        <section
            className={`culinary-feature-hero-banner ${positionClass(hero.alignment)}`}
            style={backgroundImageStyle}
        >
            <div className="bg-image" style={backgroundImageStyle} />
            <h3 className="section-header">{'Featured recipes and ideas'}</h3>
            <div className="inner-wrap">
                <div className={`overlay-content ${invertedColorClass} ${textContrastClass}`}>
                    <div className="title">{hero.heading}</div>
                    {hero.subHeading && <p className="description">{hero.subHeading}</p>}
                    <SwapLink className="action-btn" href={hero.link.url} style={buttonColor} target={hero.link.target}>
                        <span className="btn-label">{hero.link.title}</span>
                    </SwapLink>
                </div>
            </div>
        </section>
    );
};

export default CulinaryFeatureHeroBanner;
