import {SET_EDITORIAL_LIST_IN_STORE, GET_PAGED_RESULTS} from '../action-types';

const getInitialState = () => ({
    allResults: null,
    currentPage: 0,
    focusIndex: 0,
    paginationAmount: 10,
    subsetResults: null,
    totalResults: null
});

const setEditorialListInStore = (state, editorialList) => ({
    ...state,
    allResults: editorialList,
    subsetResults: editorialList.slice(0, state.paginationAmount),
    totalResults: editorialList.length
});

const getPagedResults = (state, pageNumber) => {
    const startIndex = pageNumber * state.paginationAmount;
    const endIndex = startIndex + state.paginationAmount;
    const results = state.allResults.slice(startIndex, endIndex);

    return {
        ...state,
        currentPage: pageNumber,
        focusIndex: startIndex,
        subsetResults: [...state.subsetResults, ...results]
    };
};

const reducerMap = {
    [GET_PAGED_RESULTS]: getPagedResults,
    [SET_EDITORIAL_LIST_IN_STORE]: setEditorialListInStore
};

export default (state = getInitialState(), {data, type}) => {
    if (reducerMap[type]) {
        return reducerMap[type](state, data);
    }

    return state;
};

export {getInitialState};
