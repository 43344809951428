import React from 'react';
import DocumentMeta from 'react-document-meta';

const CulinaryFeatureMeta = ({metaData}) => {
    if (!metaData) {
        return null;
    }

    const pageTitle = metaData.title ? `${metaData.title} | ` : '';

    const metaProps = {
        auto: {
            ograph: true
        },
        description: metaData.description || '',
        meta: {
            property: {
                'og:description': metaData.description || '',
                'og:image': metaData.media ? metaData.media.cropUrl : '',
                'og:site_name': 'Hy-Vee Recipes and Ideas',
                'og:title': metaData.title || '',
                'og:type': 'article',
                'og:url': metaData.url || '',
                'twitter:card': 'summary_large_image',
                'twitter:description': metaData.description || '',
                'twitter:image': metaData.media ? metaData.media.cropUrl : '',
                'twitter:site': '@hyvee',
                'twitter:title': metaData.title || ''
            }
        },
        title: `${pageTitle}Hy-Vee`
    };

    Object.keys(metaProps.meta.property).forEach((key) => {
        if (metaProps.meta.property[key] === '') {
            delete metaProps.meta.property[key];
        }
    });

    return (
        <div>
            <DocumentMeta {...metaProps} />
            {metaData.jsonLd && <script type="application/ld+json">{JSON.stringify(metaData.jsonLd)}</script>}
        </div>
    );
};

export default CulinaryFeatureMeta;
