import React from 'react';

import DropdownSelect from '../../../global/dropdown/dropdown-select';

class CulinaryAddToListFormFields extends React.Component {
    constructor(props) {
        super(props);

        this.fieldsContainer = null;

        this.onChangeTextInputHandler = (event) => this.onChangeTextInput(event);
    }

    componentDidUpdate() {
        this.focusFirstError();
    }

    onChangeTextInput(event) {
        this.props.actions.formInputUpdate({
            [event.currentTarget.getAttribute('id')]: {
                value: event.currentTarget.value
            }
        });

        // If the user has started adding a new list, the current list selection needs to clear
        if (event.currentTarget.value.length === 1) {
            this.props.actions.formInputUpdate({
                [this.props.existingListInputId]: {
                    id: '',
                    value: ''
                }
            });
        }
    }

    focusFirstError() {
        if (this.props.addToListReducer.isRecentlyValidated) {
            const errorIds = Object.keys(this.props.addToListReducer.errors);

            if (errorIds.length > 0) {
                const firstErrorInput = this.fieldsContainer.querySelector(`[data-errorId="${errorIds[0]}"]`);

                firstErrorInput.focus();
                this.props.actions.addToListRecentlyValidated(false);
            }
        }
    }

    getError(id, forVisualUsers) {
        if (this.props.addToListReducer.errors[id]) {
            let accessibilityAttributes = {};

            if (forVisualUsers) {
                accessibilityAttributes = {
                    'aria-hidden': true
                };
            }

            return (
                <div {...accessibilityAttributes} className="error">
                    {this.props.addToListReducer.errors[id]}
                </div>
            );
        }

        return null;
    }

    getErrorStyle(id) {
        return this.props.addToListReducer.errors[id] ? 'has-error' : '';
    }

    getCheckExistingValue() {
        return this.props.formInputReducer.inputs[this.props.existingListInputId]
            ? this.props.formInputReducer.inputs[this.props.existingListInputId].value
            : '';
    }

    getCheckNewValue() {
        return this.props.formInputReducer.inputs[this.props.newListInputId]
            ? this.props.formInputReducer.inputs[this.props.newListInputId].value
            : '';
    }

    getNewListInput() {
        return (
            <div className={'text-input-container'}>
                <input
                    className={`text-input ${this.getErrorStyle(this.props.newListInputId)}`}
                    data-errorId={this.props.newListInputId}
                    id={this.props.newListInputId}
                    onChange={this.onChangeTextInputHandler}
                    type="text"
                    value={this.getCheckNewValue()}
                />
                {this.getError(this.props.newListInputId, true)}
            </div>
        );
    }

    prepareLists(lists) {
        return lists.map((list) => ({
            id: list.shopping_list_id,
            name: list.name
        }));
    }

    isInvalid() {
        return this.props.addToListReducer.errors[this.props.existingListInputId] &&
            this.props.addToListReducer.errors[this.props.existingListInputId].value
            ? 'true'
            : 'false';
    }

    render() {
        if (this.props.userLists.length > 0) {
            return (
                <div
                    className="inner-content"
                    ref={(element) => {
                        this.fieldsContainer = element;
                    }}
                >
                    <span className="label" htmlFor={this.props.existingListInputId}>
                        {'Select List'}
                        {this.getError(this.props.existingListInputId, false)}
                    </span>
                    <div className={`select-input-container ${this.getErrorStyle(this.props.existingListInputId)}`}>
                        <DropdownSelect
                            actions={this.props.actions}
                            checkedValue={this.getCheckExistingValue()}
                            dropdownId={this.props.existingListInputId}
                            errorStyle={this.getErrorStyle(this.props.existingListInputId)}
                            invalid={this.isInvalid()}
                            options={this.prepareLists(this.props.userLists)}
                        />
                        {this.getError(this.props.existingListInputId, true)}
                    </div>
                    <div className="instruction">{'or'}</div>
                    <label className="label" htmlFor={this.props.newListInputId}>
                        {'Create New List'}
                        {this.getError(this.props.newListInputId, false)}
                    </label>
                    {this.getNewListInput()}
                </div>
            );
        }

        return (
            <div
                className="inner-content"
                ref={(element) => {
                    this.fieldsContainer = element;
                }}
            >
                <label className="label" htmlFor={this.props.newListInputId}>
                    {'List Name'}
                    {this.getError(this.props.newListInputId, false)}
                </label>
                {this.getNewListInput()}
            </div>
        );
    }
}

export default CulinaryAddToListFormFields;
