import React from 'react';
import {Link} from 'react-router';

const CulinaryFeatureHeaderBlock = ({contentLabel, linkUrl, heading, constrained}) => {
    const constrainedClass = constrained ? 'culinary-feature-header-block-constrained' : '';

    return (
        <div className={`culinary-feature-header-block ${constrainedClass}`}>
            <h3 className="heading">{heading}</h3>
            {linkUrl && (
                <Link className="link" to={linkUrl}>
                    {`View more ${contentLabel}`}
                </Link>
            )}
        </div>
    );
};

export default CulinaryFeatureHeaderBlock;
