export const getContentCategorizationData = (componentData) => {
    let label, url;

    // Articles/Listicles
    if (componentData.category) {
        label = componentData.category;
        url = componentData.categoryUrl;
    }

    // Howtos/Guides
    if (componentData.contentType) {
        label = componentData.contentType;
        url = componentData.contentTypeUrl;
    }

    // Recipes
    if (componentData.courseType) {
        label = componentData.courseType;
        url = componentData.courseTypeUrl;
    }

    return {
        label,
        url
    };
};
