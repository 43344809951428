import React from 'react';

import CulinaryAddToListBtn from '../../../add-to-list/culinary-add-to-list-btn';

class CulinaryRecipeDetailIngredientsAddToList extends React.Component {
    constructor(props) {
        super(props);

        this.modalTriggerId = 'addToListButton';

        this.onClickAddToListHandler = (event) => this.onClickAddToList(event);
    }

    getDisabledState = (props) => {
        const selectedVariationIndex = props.ingredientsVariationReducer.selectedVariationIndex;
        const ingredientSelections = props.ingredientsReducer.ingredientSelections[selectedVariationIndex];

        if (ingredientSelections) {
            return ingredientSelections.length === 0;
        }

        return true;
    };

    render() {
        const isDisabled = this.getDisabledState(this.props);

        return (
            <CulinaryAddToListBtn
                actions={this.props.actions}
                disabled={isDisabled}
                environmentConfigReducer={this.props.environmentConfigReducer}
                ingredientsToAdd={this.props.ingredientsToAdd}
                isAuthenticated={this.props.isAuthenticated}
                layoutType="block"
                modalReducer={this.props.modalReducer}
                modalTriggerId={this.modalTriggerId}
                recipeUrl={this.props.recipeUrl}
            />
        );
    }
}

export default CulinaryRecipeDetailIngredientsAddToList;
