import React from 'react';
import {Link} from 'react-router';

import SwapLink from '../../../global/hoc/swap-link';

class CulinaryFeatureStory extends React.Component {
    componentDidMount() {
        const {rowId, columnId, data} = this.props;

        if (rowId !== undefined && columnId !== undefined) {
            let isValid = false;

            if (data && data.featuredContentItem) {
                isValid = true;
            }

            this.props.actions.pageBuilderSetValid({
                columnId,
                isValid,
                rowId
            });
        }
    }

    render() {
        const data = this.props.data;

        let styles = {},
            categoryMarkup = null,
            ctaMarkup = null;

        const {backgroundImage, featuredContentItem, link} = data;

        if (!featuredContentItem) {
            return null;
        }

        if (backgroundImage && backgroundImage.cropUrl) {
            styles = {
                backgroundImage: `url(${backgroundImage.cropUrl})`
            };
        }

        if (featuredContentItem.courseType && featuredContentItem.courseTypeUrl) {
            categoryMarkup = (
                <Link className="content-category" to={featuredContentItem.courseTypeUrl}>
                    {featuredContentItem.courseType}
                </Link>
            );
        }

        if (featuredContentItem.category && featuredContentItem.categoryUrl) {
            categoryMarkup = (
                <Link className="content-category" to={featuredContentItem.categoryUrl}>
                    {featuredContentItem.category}
                </Link>
            );
        }

        if (link && link.url) {
            ctaMarkup = (
                <SwapLink className={'content-link'} href={link.url} target={link.target}>
                    {link.title}
                </SwapLink>
            );
        }

        return (
            <div className="culinary-feature-story">
                <div className="background" style={styles} />
                <div className="intro-content">
                    <h4 className="heading">
                        {categoryMarkup}
                        <SwapLink
                            className="content-link"
                            href={featuredContentItem.url}
                            target={featuredContentItem.target}
                        >
                            <span className="link-text">{featuredContentItem.title}</span>
                        </SwapLink>
                    </h4>
                    <p className="content-body">{featuredContentItem.description}</p>
                    {ctaMarkup}
                </div>
            </div>
        );
    }
}

export default CulinaryFeatureStory;
