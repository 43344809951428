import React from 'react';
import {Link} from 'react-router';

import {backgroundStyle, textCustomizationClasses} from '../../../../services/style-helpers-service';

const CulinaryFeatureEditorialCategoryCard = ({
    title,
    cardBackgroundImage,
    cardBackgroundColor,
    cardInverseText,
    categoryUrl
}) => {
    const typeClass = textCustomizationClasses(null, cardInverseText);
    const elementStyle = backgroundStyle(cardBackgroundColor, cardBackgroundImage);

    return (
        <Link className={`culinary-feature-editorial-category-card ${typeClass}`} style={elementStyle} to={categoryUrl}>
            <div className="content">
                <span className="label">{'View everything'}</span>
                <span className="action">
                    <span>{title}</span>
                </span>
            </div>
        </Link>
    );
};

export default CulinaryFeatureEditorialCategoryCard;
