import nutritionKeyMap from '../constants/nutrition-key-map';
import dietTagMap from '../constants/diet-tag-map';

function getNutritionInformation(nutrients) {
    if (nutrients) {
        const nutritionInformation = {};

        nutrients.forEach((nutrient) => {
            nutritionInformation[nutrient.key] = nutrient.value;

            if (nutrient.subNutrients) {
                nutrient.subNutrients.forEach((subNutrient) => {
                    nutritionInformation[subNutrient.key] = subNutrient.value;
                });
            }
        });

        const mappedNutritionInformation = {};

        Object.keys(nutritionInformation).forEach(
            // eslint-disable-next-line no-return-assign
            (key) => (mappedNutritionInformation[nutritionKeyMap[key]] = nutritionInformation[key])
        );

        return mappedNutritionInformation;
    }

    return {};
}

function getIngredientsForVariation(ingredientGroups) {
    if (ingredientGroups) {
        return ingredientGroups
            .reduce((ingredientGroupList, ingredientGroup) => ingredientGroupList.concat(ingredientGroup), [])
            .reduce((ingredientItemList, ingredientItem) => ingredientItemList.concat(ingredientItem.ingredients), [])
            .map(
                (ingredientItem) =>
                    `${ingredientItem.quantity} ${ingredientItem.unit ? `${ingredientItem.unit} of` : ''} ${
                        ingredientItem.ingredient
                    }`
            );
    }

    return '';
}

function getInstructionsForVariation(directions) {
    if (directions) {
        return directions
            .reduce((directionList, direction) => directionList.concat(direction.content), [])
            .map((direction, index) => `${index + 1}. ${direction}`);
    }

    return '';
}

function getDietTag(tags) {
    if (tags) {
        return tags
            .map((tag) => tag.title)
            .map((title) => dietTagMap[title])
            .filter(Boolean);
    }

    return [];
}

export function createRecipeJsonLd(content, variationIndex) {
    const nutritionInformation = getNutritionInformation(content.nutritionalInfo.nutrients);
    const ingredients = getIngredientsForVariation(content.variations[variationIndex].ingredientGroups);
    const recipeInstructions = getInstructionsForVariation(content.variations[variationIndex].directions);
    const recipeYield = `${content.variations[variationIndex].servings} ${content.variations[variationIndex].servingsInfo}`;
    const suitableForDiet = getDietTag(content.tags);
    const prepTime = content.variations[variationIndex].prepTime;
    const totalTime = content.variations[variationIndex].totalTime;
    const authorUrl = content.author.profileImage ? content.author.profileImage.url : '';

    return {
        '@context': 'http://schema.org',
        '@type': 'Recipe',
        aggregateRating: {
            '@type': 'AggregateRating',
            ratingValue: content.rating.value.toFixed(2),
            reviewCount: content.rating.count
        },
        author: {
            '@type': 'Person',
            description: content.author.description,
            image: authorUrl,
            name: content.author.name
        },
        cookTime: totalTime,
        description: content.description,
        image: content.primaryMedia ? content.primaryMedia.url : '',
        mainEntityOfPage: 'true',
        name: content.title,
        nutrition: {
            '@type': 'NutritionInformation',
            ...nutritionInformation
        },
        prepTime,
        recipeIngredient: [...ingredients],
        recipeInstructions: [...recipeInstructions],
        recipeYield,
        suitableForDiet
    };
}
