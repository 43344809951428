import {CAROUSEL_SET_BLOCK_WIDTH} from '../action-types';

const getInitialState = () => ({
    carousels: {}
});

const carouselSetBlockWidth = (state, carouselData) => ({
    ...state,
    carousels: {
        ...state.carousels,
        [carouselData.id]: {
            blockWidth: carouselData.blockWidth
        }
    }
});

const reducerMap = {
    [CAROUSEL_SET_BLOCK_WIDTH]: carouselSetBlockWidth
};

export default (state = getInitialState(), {data, type}) => {
    if (reducerMap[type]) {
        return reducerMap[type](state, data);
    }

    return state;
};

export {getInitialState};
