import {combineReducers} from 'redux';
import {routerReducer} from 'react-router-redux';

import addToCartReducer from './add-to-cart-reducer';
import addToListReducer from './add-to-list-reducer';
import alertReducer from './alert-reducer';
import autocompleteReducer from './autocomplete-reducer';
import carouselReducer from './carousel-reducer';
import contentLoaderReducer from './content-loader-reducer';
import contentReducer from './content-reducer';
import environmentConfigReducer from './environment-config-reducer';
import favoritesReducer from './favorites-reducer';
import feedbackReducer from './feedback-reducer';
import formInputReducer from './form-input-reducer';
import ingredientsReducer from './ingredients-reducer';
import ingredientsVariationReducer from './ingredients-variation-reducer';
import isAuthenticated from './is-authenticated-reducer';
import mealIdeasNutritionReducer from './meal-ideas-nutrition-reducer';
import mealIdeasToPrintReducer from './meal-ideas-to-print-reducer';
import modalReducer from './modal-reducer';
import pageBuilderReducer from './page-builder-reducer';
import recipeRatingReducer from './recipe-rating-reducer';
import searchReducer from './search-reducer';
import preferredStoreReducer from './preferred-store-reducer';
import uiReducer from './ui-reducer';
import videoPlayerReducer from './video-player-reducer';
import serverSideLocation from './server-side-location-reducer';
import userReducer from './user-reducer';
import renderReducer from './render-reducer';

export function combine() {
    return combineReducers({
        addToCartReducer,
        addToListReducer,
        alertReducer,
        autocompleteReducer,
        carouselReducer,
        contentLoaderReducer,
        contentReducer,
        environmentConfigReducer,
        favoritesReducer,
        feedbackReducer,
        formInputReducer,
        ingredientsReducer,
        ingredientsVariationReducer,
        isAuthenticated,
        mealIdeasNutritionReducer,
        mealIdeasToPrintReducer,
        modalReducer,
        pageBuilderReducer,
        preferredStoreReducer,
        recipeRatingReducer,
        renderReducer,
        routing: routerReducer,
        searchReducer,
        serverSideLocation,
        uiReducer,
        userReducer,
        videoPlayerReducer
    });
}
