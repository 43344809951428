import React from 'react';

import {createRecipeJsonLd} from '../../../../../factories/recipe-json-ld-factory';

const RecipeJsonLd = ({content, selectedVariationIndex}) => {
    const recipeJsonld = createRecipeJsonLd(content, selectedVariationIndex);
    const innerHtml = {
        __html: JSON.stringify(recipeJsonld)
    };

    /* eslint-disable-next-line react/no-danger */
    return <script dangerouslySetInnerHTML={innerHtml} type="application/ld+json" />;
};

export default RecipeJsonLd;
