import React from 'react';

import CulinaryFeatureCard from '../feature/cards/culinary-feature-card';
import CulinaryFeatureExpertTip from '../feature/culinary-feature-expert-tip';
import FeaturePrimaryVideo from '../feature/feature-primary-video';
import FeaturePrimaryImage from '../../../feature/feature-primary-image';

import CulinaryDirectionsCheckbox from './culinary-directions-checkbox';

function getCheckedState(selections, checkboxId) {
    let checked = false;

    selections.forEach((key) => {
        if (key === checkboxId) {
            checked = true;
        }
    });

    return checked;
}

const CulinaryDirectionsListItem = (props) => {
    let directionBodyClasses = 'item-body',
        expertTipMarkup = null,
        inlineCardMarkup = null,
        mediaMarkup = null,
        checkboxId = '',
        isChecked = false,
        completedStateClass = '';
    const {direction, index, reducers, id, actions} = props;
    const newIndex = index + 1;

    if (!props.isPrinting) {
        checkboxId = `${id}-directions-checkbox-${newIndex}-${props.ingredientsVariationReducer.selectedVariationIndex}`;
        isChecked = getCheckedState(reducers.uiReducer.directionSelections, checkboxId);
        completedStateClass = isChecked ? 'completed' : '';
    }

    if (!props.isPrinting && direction.cardReference) {
        inlineCardMarkup = (
            <div className="inline-card">
                <CulinaryFeatureCard
                    actions={props.actions}
                    alertReducer={props.alertReducer}
                    card={direction.cardReference}
                    favoritesReducer={props.favoritesReducer}
                    id="directions-list-item"
                    isAuthenticated={props.isAuthenticated}
                    layoutType="inline"
                    modalReducer={props.modalReducer}
                />
            </div>
        );
    }

    if (direction.expertTip) {
        expertTipMarkup = (
            <div className="expert-tip">
                <CulinaryFeatureExpertTip data={direction.expertTip} layoutType="direction" />
            </div>
        );
        directionBodyClasses = `${directionBodyClasses} has-expert-tip`;
    }

    if (!props.isPrinting && direction.media && direction.media.type === 'Video') {
        mediaMarkup = (
            <FeaturePrimaryVideo
                activeVideoId={reducers.videoPlayerReducer.activeVideoId}
                addVideo={actions.addVideo}
                media={direction.media}
                setActiveVideoId={actions.setActiveVideoId}
                setVideoProp={actions.setVideoProp}
                videoPlayerReducer={reducers.videoPlayerReducer}
            />
        );
    }

    if (!props.isPrinting && direction.media && direction.media.type === 'Image') {
        const image = {...direction.media};

        mediaMarkup = <FeaturePrimaryImage cropWidth={'1000'} data={image} />;
    }

    return (
        <li className={`culinary-directions-list-item ${completedStateClass}`}>
            <div className="item-action">
                {!props.isPrinting && (
                    <CulinaryDirectionsCheckbox {...props} checkboxId={checkboxId} index={`${newIndex}`} />
                )}
            </div>
            <div aria-hidden={isChecked} className={directionBodyClasses}>
                {mediaMarkup}
                <div
                    className="text-content"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{__html: direction.content}}
                />
                {expertTipMarkup}
                {inlineCardMarkup}
            </div>
        </li>
    );
};

export default CulinaryDirectionsListItem;
