import React from 'react';

import withMountAnimation from '../../../global/hoc/with-mount-animation';

import CulinaryFeaturePreviewList from './culinary-feature-preview-list';

const CulinaryFeaturePreviewListWithMountAnimation = withMountAnimation(CulinaryFeaturePreviewList);

class CulinaryFeatureEditorialContentLoader extends React.Component {
    constructor(props) {
        super(props);

        this.onClickHandler = () => this.getNextPage();
    }

    getNextPage() {
        this.props.getPagedResults(this.props.currentPage + 1);
    }

    render() {
        if (!this.props.editorialList) {
            return null;
        }

        const editorialListLength = this.props.editorialList.length;
        const isEnabled = this.props.totalResults !== editorialListLength;
        const animationDelayOffset = 140;
        const animationTransitionDuration = 700;
        const animationTransitionTimeout =
            animationDelayOffset * this.props.editorialList.length + animationTransitionDuration;

        return (
            <div className="culinary-feature-editorial-content-loader">
                <CulinaryFeaturePreviewListWithMountAnimation
                    featuredContentList={this.props.editorialList}
                    focusIndex={this.props.focusIndex}
                    layout="small"
                    listStyle="column"
                    withMountAnimationProps={{
                        offset: animationDelayOffset,
                        pageSize: this.props.paginationAmount,
                        transitionGroup: {
                            appear: true,
                            className: 'culinary-feature-preview-list culinary-feature-preview-list-column',
                            classNames: {
                                appear: 'kf-fade-in',
                                enter: 'kf-slide-in'
                            },
                            component: 'ul',
                            enter: true,
                            exit: false,
                            timeout: animationTransitionTimeout
                        }
                    }}
                />
                {isEnabled && (
                    <button className="button" onClick={this.onClickHandler} type="button">
                        {'View More'}
                    </button>
                )}
            </div>
        );
    }
}

export default CulinaryFeatureEditorialContentLoader;
