import React from 'react';

import IconPlus from '../../../global/icon/icon-plus';
import IconMinus from '../../../global/icon/icon-minus';

class CulinarySearchFilters extends React.Component {
    constructor(props) {
        super(props);

        this.state = {isExpanded: false};
        this.togglePanelAttributesHandler = () => this.togglePanelAttributes();
    }

    componentDidMount() {
        require('velocity-animate');
    }

    togglePanelAttributes() {
        /* eslint-disable-next-line no-undef, new-cap */
        Velocity(this.filterPanel, this.state.isExpanded ? 'slideUp' : 'slideDown', {
            duration: 300,
            easing: 'easeOutSine'
        });

        this.setState((prevState) => ({isExpanded: !prevState.isExpanded}));
    }

    render() {
        const {isExpanded} = this.state;

        return (
            <section className="culinary-search-filters">
                <h2 className="heading">{'Search Filters'}</h2>
                <div className="filter-container">
                    <button
                        aria-controls="filter-panel"
                        aria-expanded={isExpanded}
                        aria-label="More Filters"
                        className="toggle-tab"
                        id="filter-tab"
                        onClick={this.togglePanelAttributesHandler}
                        ref={(element) => {
                            this.mobileMoreFiltersButton = element;
                        }}
                        role="tab"
                        type="button"
                    >
                        <span className="tab-label">{'More Filters'}</span>
                        <span className="tab-icon">
                            {!isExpanded && (
                                <span className="icon-open">
                                    <IconPlus />
                                </span>
                            )}
                            {isExpanded && (
                                <span className="icon-close">
                                    <IconMinus />
                                </span>
                            )}
                        </span>
                    </button>
                    <div
                        aria-labelledby="filter-tab"
                        className="toggle-panel"
                        id="filter-panel"
                        ref={(element) => {
                            this.filterPanel = element;
                        }}
                        role="tabpanel"
                    >
                        <div className="toggle-panel-body">{this.props.children}</div>
                        <button
                            className="toggle-panel-close"
                            onClick={this.togglePanelAttributesHandler}
                            type="button"
                        >
                            {'Close'}
                        </button>
                    </div>
                </div>
            </section>
        );
    }
}

export default CulinarySearchFilters;
