import {CLOSE_ALERT, CREATE_ALERT} from '../action-types';

export const closeAlert = () => ({
    type: CLOSE_ALERT
});

export const createAlert = (alertData) => ({
    data: alertData,
    type: CREATE_ALERT
});
