import React from 'react';

import {convertTimingData} from '../../../../../../services/timing-service';

import CulinaryRecipeDetailTimingSummaryServings from './culinary-recipe-detail-timing-summary-servings';
import CulinaryRecipeDetailTimingSummaryDetails from './culinary-recipe-detail-timing-summary-details';

const CulinaryRecipeDetailTimingSummary = (props) => {
    const templateData = props.contentReducer.urlContent.content;
    const selectedVariationIndex = props.ingredientsVariationReducer.selectedVariationIndex;
    const activeVariation = templateData.variations[selectedVariationIndex];
    const convertedPrepTime = convertTimingData(activeVariation.prepTime);
    const convertedTotalTime = convertTimingData(activeVariation.totalTime);

    return (
        <section className="culinary-recipe-detail-timing-summary">
            <h4 className="header">{'Recipe Data'}</h4>
            <div className="list">
                {activeVariation.servings > 0 && <CulinaryRecipeDetailTimingSummaryServings {...props} />}
                {convertedPrepTime && (
                    <CulinaryRecipeDetailTimingSummaryDetails label={'Prep'} time={convertedPrepTime} />
                )}

                {convertedTotalTime && (
                    <CulinaryRecipeDetailTimingSummaryDetails label={'Total'} time={convertedTotalTime} />
                )}
            </div>
        </section>
    );
};

export default CulinaryRecipeDetailTimingSummary;
