import React from 'react';

import {slugify} from '../../../../../../services/string-services';

import CulinaryRecipeDetailIngredientsAddToList from './culinary-recipe-detail-ingredients-add-to-list';
import CulinaryRecipeDetailIngredientsAddToCart from './culinary-recipe-detail-ingredients-add-to-cart';
import CulinaryRecipeDetailIngredientsSection from './culinary-recipe-detail-ingredients-section';
import CulinaryRecipeDetailIngredientsSelect from './culinary-recipe-detail-ingredients-select';

class CulinaryRecipeDetailIngredients extends React.Component {
    constructor(props) {
        super(props);

        this.ingredientsToAdd = [];
        this.ingredientGroupsMarkup = null;
        this.selectedVariationIndex = this.props.isPrinting
            ? 0
            : this.props.ingredientsVariationReducer.selectedVariationIndex;

        this.onSelectAllHandler = (event) => this.onSelectAll(event);
    }

    componentWillReceiveProps(nextProps) {
        const selectedVariation = this.props.ingredientsVariationReducer.selectedVariationIndex;
        const selectedIngredients = this.props.ingredientsReducer.ingredientSelections[selectedVariation];
        const selectedIngredientsString = selectedIngredients ? selectedIngredients.toString() : '';

        const nextSelectedVariation = nextProps.ingredientsVariationReducer.selectedVariationIndex;
        const nextSelectedIngredients = nextProps.ingredientsReducer.ingredientSelections[nextSelectedVariation];
        const nextSelectedIngredientsString = nextSelectedIngredients ? nextSelectedIngredients.toString() : '';

        if (nextSelectedIngredientsString && selectedIngredientsString !== nextSelectedIngredientsString) {
            this.ingredientsToAdd = nextSelectedIngredients.map((ingredient) => ingredient);
        }
    }

    onSelectAll(event) {
        const isChecked = !event.currentTarget.checked;
        const selectedVariationIndex = this.props.ingredientsVariationReducer.selectedVariationIndex;
        const allIngredients = this.getAllVariationIngredients(isChecked);

        this.props.actions.updateCheckedAllIngredients({
            allIngredients,
            selectedVariationIndex
        });
    }

    getAllVariationIngredients(isChecked) {
        const selectedVariationIndex = `${this.props.ingredientsVariationReducer.selectedVariationIndex}`;
        const allIngredients = [];

        if (!isChecked) {
            this.props.ingredientGroups.forEach((group, groupIndex) => {
                group.ingredients.forEach((ingredient, index) => {
                    const checkboxId = slugify(
                        `${selectedVariationIndex}-${index}-${groupIndex}-${ingredient.ingredient}`
                    );

                    allIngredients.push({
                        displayName: `${ingredient.quantity ? ingredient.quantity : ''}${
                            ingredient.unit ? ingredient.unit : ''
                        } ${ingredient.ingredient}`,
                        id: checkboxId,
                        ingredientIndices: {
                            groupIndex,
                            index
                        },
                        title: ingredient.ingredient
                    });
                });
            });
        }

        return allIngredients;
    }

    getCheckedIngredientsArrayQueryParam() {
        if (this.props.ingredientGroups) {
            const allIngredients = this.getAllVariationIngredients();
            const selectedIngredients = this.props.ingredientsReducer.ingredientSelections;
            const mappedSelectedIngredients = Object.keys(selectedIngredients).length
                ? Object.keys(selectedIngredients).reduce(
                      (accum, groupKey) => [...accum, ...selectedIngredients[groupKey].map((ingredient) => ingredient)],
                      []
                  )
                : [];
            const selectedIngredientsArray = this.props.ingredientGroups.map(() => new Array(0));

            mappedSelectedIngredients.map((ingredient) => {
                const newSelected = allIngredients.find((allIngredient) => allIngredient.id === ingredient.id);
                const indices = newSelected.ingredientIndices;

                selectedIngredientsArray[indices.groupIndex].push(indices.index);
            });

            return selectedIngredientsArray;
        }

        return null;
    }

    render() {
        const {isPrinting} = this.props;

        let ingredientGroupsMarkup;

        if (this.props.ingredientGroups) {
            ingredientGroupsMarkup = this.props.ingredientGroups.map((group, index) => (
                <CulinaryRecipeDetailIngredientsSection
                    actions={this.props.actions}
                    groupIndex={index}
                    ingredients={group.ingredients}
                    ingredientsReducer={this.props.ingredientsReducer}
                    ingredientsVariationReducer={this.props.ingredientsVariationReducer}
                    isPrinting={isPrinting}
                    key={index}
                    recipeId={this.props.id}
                    title={group.title}
                />
            ));
        }

        const selectedVariationIndex = this.props.isPrinting
            ? 0
            : this.props.ingredientsVariationReducer.selectedVariationIndex;

        return (
            <section
                className={`culinary-recipe-detail-ingredients ${
                    !this.props.description ? 'culinary-recipe-detail-ingredients-no-divider' : ''
                } `}
            >
                <h4 className="heading" id="recipe-ingredients">
                    {'Servings and Ingredients'}
                </h4>
                <section className="ingredients">
                    {this.props.servings > 0 && (
                        <div className="split-heading">
                            <h5 className="heading">{'Ingredients'}</h5>
                            <div className="servings">{`Serves ${this.props.servings}`}</div>
                        </div>
                    )}
                    {!isPrinting && (
                        <CulinaryRecipeDetailIngredientsSelect
                            ingredientGroups={this.props.ingredientGroups}
                            ingredientsReducer={this.props.ingredientsReducer}
                            ingredientsVariationReducer={this.props.ingredientsVariationReducer}
                            isPrinting={isPrinting}
                            key={`selectall-${selectedVariationIndex}`}
                            onSelectAll={this.onSelectAllHandler}
                        />
                    )}
                    <table className="table" key={selectedVariationIndex}>
                        <thead className="header">
                            <tr>
                                <th scope="col">{'Quantity'}</th>
                                <th scope="col">{'Ingredient'}</th>
                                <th scope="col">{'Add'}</th>
                            </tr>
                        </thead>
                        {ingredientGroupsMarkup}
                    </table>
                </section>
                {!isPrinting && (
                    <div className="actions">
                        <CulinaryRecipeDetailIngredientsAddToCart
                            environmentConfigReducer={this.props.environmentConfigReducer}
                            ingredientsReducer={this.props.ingredientsReducer}
                            ingredientsVariationReducer={this.props.ingredientsVariationReducer}
                            recipeUrl={this.props.recipeUrl}
                            selectedIngredientsArray={this.getCheckedIngredientsArrayQueryParam()}
                        />
                        <CulinaryRecipeDetailIngredientsAddToList
                            actions={this.props.actions}
                            environmentConfigReducer={this.props.environmentConfigReducer}
                            ingredientsReducer={this.props.ingredientsReducer}
                            ingredientsToAdd={this.ingredientsToAdd}
                            ingredientsVariationReducer={this.props.ingredientsVariationReducer}
                            isAuthenticated={this.props.isAuthenticated}
                            modalReducer={this.props.modalReducer}
                            recipeUrl={this.props.recipeUrl}
                        />
                    </div>
                )}
            </section>
        );
    }
}

export default CulinaryRecipeDetailIngredients;
