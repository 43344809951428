class RequestModel {
    static ANTI_FORGERY_TOKEN = null;

    _headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'hv-culinary-af-token': RequestModel.ANTI_FORGERY_TOKEN
    };

    _endpoint = '';

    data = {};

    get headers() {
        return this._headers;
    }

    set headers(requestHeaders) {
        this._headers = {
            ...this._headers,
            ...requestHeaders
        };
    }

    get endpoint() {
        return this._stripTrailingSlash(this._endpoint);
    }

    constructor(endpoint) {
        this._endpoint = endpoint;
    }

    _stripTrailingSlash(str) {
        return str.replace(/\/$/u, '');
    }
}

export default RequestModel;
