import React from 'react';

const CulinaryFeatureIntro = ({description, layout}) => {
    if (!description) {
        return null;
    }

    return (
        <div className={`culinary-feature-intro culinary-feature-intro-${layout}`}>
            <h4 className="heading">{'Description'}</h4>
            <div
                /* eslint-disable-next-line react/no-danger */
                dangerouslySetInnerHTML={{__html: description}}
            />
        </div>
    );
};

export default CulinaryFeatureIntro;
