import React from 'react';

const IconEmail = ({height, width}) => (
    <svg className="icon-email" focusable={false} height={height} viewBox="0 0 31 31" width={width}>
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
            <g transform="translate(-858.000000, -607.000000)">
                <g transform="translate(720.000000, 607.000000)">
                    <g transform="translate(138.000000, 0.000000)">
                        <path
                            d="M31,15.75 C31,24.172 24.172,31 15.75,31 C7.328,31 0.5,24.172 0.5,15.75 C0.5,7.328 7.328,0.5 15.75,0.5 C24.172,0.5 31,7.328 31,15.75"
                            fill="#43BF96"
                        />
                        <path
                            d="M8.6572,9.5703 L22.8432,9.5703 C23.5832,9.5703 24.1842,10.1713 24.1842,10.9113 L24.1842,20.5893 C24.1842,21.3293 23.5832,21.9293 22.8432,21.9293 L8.6572,21.9293 C7.9162,21.9293 7.3162,21.3293 7.3162,20.5893 L7.3162,10.9113 C7.3162,10.1713 7.9162,9.5703 8.6572,9.5703"
                            fill="#FFFFFF"
                        />
                        <path
                            d="M9.7676,12.0762 L14.9886,16.5442 C15.4926,16.9752 16.2376,16.9722 16.7386,16.5382 L21.6296,12.2972"
                            stroke="#43BF96"
                            strokeLinecap="round"
                        />
                        <path d="M13.8267,15.5498 L9.7677,19.4578" stroke="#43BF96" strokeLinecap="round" />
                        <path d="M17.9912,15.5498 L21.6302,19.4578" stroke="#43BF96" strokeLinecap="round" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default IconEmail;
