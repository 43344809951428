import React from 'react';

const IconInfo = () => (
    <svg className="icon-info" focusable={false} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
        <path
            className="shape-1"
            d="M30.545 16.246c0 7.897-6.402 14.299-14.299 14.299s-14.299-6.402-14.299-14.299c0-7.897 6.402-14.299 14.299-14.299s14.299 6.402 14.299 14.299z"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeWidth="2.1818"
        />

        <path
            className="shape-2"
            d="M16 24.239v-9.603"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeWidth="2.1818"
        />

        <path
            className="shape-3"
            d="M17.497 10.203c0 0.815-0.661 1.476-1.476 1.476s-1.476-0.661-1.476-1.476c0-0.815 0.661-1.476 1.476-1.476s1.476 0.661 1.476 1.476z"
        />
    </svg>
);

export default IconInfo;
