import React from 'react';
import debounce from 'lodash.debounce';

import {isMaxBreakpoint} from '../../services/breakpoint-service';

import FeatureCategorySection from './feature-category-section';

class FeatureCategories extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isMobileBreakpoint: null,
            visibleCategoryId: this.getVisibleCatgoryId()
        };

        this.updateVisibleCategoryIdHandler = (event) => this.updateVisibleCategoryId(event);
        this.onResizeWindowHandler = debounce((event) => this.onResizeWindow(event), 250);
    }

    componentDidMount() {
        global.window.addEventListener('resize', this.onResizeWindowHandler);
    }

    componentWillReceiveProps() {
        this.checkBreakpoint();
    }

    componentWillUnmount() {
        global.window.removeEventListener('resize', this.onResizeWindowHandler);
    }

    onResizeWindow() {
        this.checkBreakpoint();
    }

    updateVisibleCategoryId(categoryId) {
        this.setState({visibleCategoryId: categoryId});
    }

    checkBreakpoint() {
        const isMobileBreakpoint = isMaxBreakpoint('base');

        if (isMobileBreakpoint) {
            this.setState({isMobileBreakpoint: true});
        } else {
            this.setState({isMobileBreakpoint: false});
        }
    }

    getVisibleCatgoryId() {
        let categoryId = null;

        const {categories, urlContentPath} = this.props;
        const urlPathWithoutTrailingSlash = urlContentPath && urlContentPath.replace(/\/$/u, '');

        categories.forEach((category, categoryIndex) => {
            const categoryUrlMatch = urlPathWithoutTrailingSlash === category.url;

            if (categoryUrlMatch) {
                categoryId = `category-${categoryIndex}`;
            } else {
                const subCategoryIndex = category.subLinks.findIndex(
                    (subLink) => urlPathWithoutTrailingSlash === subLink.url
                );

                if (subCategoryIndex >= 0) {
                    categoryId = `category-${categoryIndex}`;
                }
            }
        });

        return categoryId;
    }

    render() {
        const {categories, className, urlContentPath} = this.props;

        return (
            <nav className={className} role="navigation">
                <h3 className="heading">{'Editorial Categories'}</h3>
                {categories.map((category, index) => (
                    <FeatureCategorySection
                        category={category}
                        className={className}
                        index={index}
                        isMobileBreakpoint={this.state.isMobileBreakpoint}
                        key={index}
                        showPageState={this.props.showPageState}
                        updateVisibleCategoryIdHandler={this.updateVisibleCategoryIdHandler}
                        urlContentPath={urlContentPath}
                        visibleCategoryId={this.state.visibleCategoryId}
                    />
                ))}
            </nav>
        );
    }
}

export default FeatureCategories;
