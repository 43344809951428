import React from 'react';

import SwapLink from '../global/hoc/swap-link';

import FeatureInfoBlock from './feature-info-block';

const FeaturePromotional = ({promotional}) => {
    if (!promotional || (promotional.promoItems && promotional.promoItems.length === 0)) {
        return null;
    }

    const backgroundImage = {
        backgroundImage:
            promotional.backgroundImage && promotional.backgroundImage.url
                ? `url(${promotional.backgroundImage.url})`
                : 'none'
    };

    return (
        <section className="feature-promotional" style={backgroundImage}>
            <div className="content">
                <h3 className="header">
                    {promotional.headingImage && (
                        <img
                            alt={promotional.headingImage.alt}
                            className="title"
                            src={promotional.headingImage.url}
                            width="156"
                        />
                    )}
                    <span className="subtitle">{promotional.heading}</span>
                </h3>
                <ul className={`list list-${promotional.promoItems.length}up`}>
                    {promotional.promoItems.map((block, index) => (
                        <li key={index}>
                            <FeatureInfoBlock
                                description={block.description}
                                heading={block.heading}
                                image={block.image}
                            />
                        </li>
                    ))}
                </ul>
                {promotional.link && (
                    <div className="actions">
                        <SwapLink className="actions-btn" href={promotional.link.url} target={promotional.link.target}>
                            {promotional.link.title}
                        </SwapLink>
                    </div>
                )}
            </div>
        </section>
    );
};

export default FeaturePromotional;
