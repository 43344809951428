import React from 'react';
import uuid from 'uuid';

const CulinaryFeatureThingsToGrab = ({thingsToGrab, displayTypeDouble}) => {
    if (thingsToGrab === null) {
        return null;
    }

    const layoutClass = displayTypeDouble === true ? 'list list-double' : 'list';

    return (
        <div className="culinary-feature-things-to-grab">
            <h4 className="header">{'Things To Grab'}</h4>
            <ul className={layoutClass}>
                {thingsToGrab.map((item) => (
                    <li key={uuid.v4()}>{item}</li>
                ))}
            </ul>
        </div>
    );
};

export default CulinaryFeatureThingsToGrab;
