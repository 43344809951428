const getVideoInfo = async (videoId) => {
    const youtubeApiKey =
        process.env.NODE_ENV === 'prod'
            ? 'AIzaSyDJk01jzDZ231pJm1a7-C4Y8cdRZ5AQekE'
            : 'AIzaSyDV_wOqwaDlPFcNJNwkblfiGMGSFWyCeJY';

    const videoInfoResponse = await fetch(
        `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&part=contentDetails,snippet&key=${youtubeApiKey}`
    );
    const videoInfoJson = await videoInfoResponse.json();

    return videoInfoJson;
};

const getHighestResolutionThumbnailUrl = (thumbnails) => {
    const thumbnailsValues = Object.keys(thumbnails).map((key) => thumbnails[key]);
    const highResThumbnail = thumbnailsValues.reduce((thumbnail, currentValue) =>
        thumbnail.width > currentValue.width ? thumbnail : currentValue
    );

    return highResThumbnail.url;
};

export const getVideoMetadata = async (videoId) => {
    const videoInfoJson = await getVideoInfo(videoId);
    const firstVideo = videoInfoJson.items[0];

    if (firstVideo) {
        const duration = firstVideo.contentDetails.duration;
        const title = firstVideo.snippet.title;
        const thumbnails = firstVideo.snippet.thumbnails;
        const highestResolutionThumbnailUrl = getHighestResolutionThumbnailUrl(thumbnails);

        return {
            duration,
            fallbackThumbnailUrl: highestResolutionThumbnailUrl,
            title
        };
    }

    return {
        duration: '',
        fallbackThumbnailUrl: '',
        title: ''
    };
};
