import EnterpriseApiService from '../services/api/enterprise-api-service';
import {
    ADD_TO_CART_CLEAR_STATUS,
    ADD_TO_CART_INGREDIENTS_TO_ADD,
    ADD_TO_CART_RECIPE,
    ADD_TO_CART_SET_FAIL,
    ADD_TO_CART_SET_LOADING,
    ADD_TO_CART_SET_SUCCESS
} from '../action-types';

export const addToCartAdd = (data) => async (dispatch, getState) => {
    const ingredientsToSend = data.ingredients.map((ingredient) => ({
        displayName: ingredient.displayName,
        quantity: 1,
        searchTerm: ingredient.title,
        sku: '',
        source: '',
        sourceUrl: ''
    }));

    const dataToSend = {
        items: ingredientsToSend,
        source: data.recipeTitle,
        sourceUrl: data.recipeUrl
    };

    dispatch({
        type: ADD_TO_CART_SET_LOADING
    });

    const endpoint = getState().environmentConfigReducer.enterprise.addToCart;
    const responseModel = await EnterpriseApiService.addToCartText(endpoint, dataToSend);

    if (responseModel.data && responseModel.data.data !== null && responseModel.data.data !== undefined) {
        dispatch({
            data: responseModel.data.data,
            type: ADD_TO_CART_SET_SUCCESS
        });
    } else {
        dispatch({
            type: ADD_TO_CART_SET_FAIL
        });
    }
};

export const addToCartIngredientsToAdd = (ingredients) => ({
    data: ingredients,
    type: ADD_TO_CART_INGREDIENTS_TO_ADD
});

export const addToCartRecipe = (recipeData) => ({
    data: recipeData,
    type: ADD_TO_CART_RECIPE
});

export const addToCartClearStatus = () => ({
    type: ADD_TO_CART_CLEAR_STATUS
});
