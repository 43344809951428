import React from 'react';

const CulinaryFeatureRecommendedPairing = ({heading, image, description}) => {
    if (!heading && !description) {
        return null;
    }

    return (
        <div className="culinary-feature-recommended-pairing">
            <h3 className="heading">{'Recommended Pairing'}</h3>
            {image && <img alt={image.alt} src={`${image.cropUrl}&width=240&height=328`} />}
            <div className="body">
                <div className="label">{'Recommended Pairing'}</div>
                {heading && <div className="name">{heading}</div>}
                {description && (
                    <div
                        className="description"
                        /* eslint-disable-next-line react/no-danger */
                        dangerouslySetInnerHTML={{__html: description}}
                    />
                )}
            </div>
        </div>
    );
};

export default CulinaryFeatureRecommendedPairing;
