import React from 'react';

import FeatureCategories from '../../../feature/feature-categories';

const CulinaryEditorialFooter = ({editorialCategories}) => (
    <footer className="culinary-editorial-footer">
        <h3 className="header">{'Explore Advice and How-Tos'}</h3>
        <div className="inner">
            <FeatureCategories
                categories={editorialCategories}
                className={'culinary-editorial-footer-categories'}
                showPageState={false}
            />
        </div>
    </footer>
);

export default CulinaryEditorialFooter;
