import React from 'react';

import CulinaryRecipeDetailServingsTooltip from '../tooltip/culinary-recipe-detail-servings-tooltip';

function getTooltipView(templateData, activeVariation) {
    let tooltipShouldCenterSmall = false;

    if (activeVariation.totalTime === null && activeVariation.prepTime === null) {
        tooltipShouldCenterSmall = true;
    }

    return tooltipShouldCenterSmall;
}

function getServingInfoClass(servingInfo) {
    let classes = 'culinary-recipe-detail-timing-summary-item';

    if (servingInfo) {
        classes = `${classes} has-tooltip`;
    }

    return classes;
}

const CulinaryRecipeDetailTimingSummaryServings = (props) => {
    const templateData = props.contentReducer.urlContent.content;
    const selectedVariationIndex = props.ingredientsVariationReducer.selectedVariationIndex;
    const activeVariation = templateData.variations[selectedVariationIndex];
    const singletonView = getTooltipView(templateData, activeVariation);
    const key = activeVariation.servings > 1 ? 'Servings' : 'Serving';
    const elementClasses = getServingInfoClass(activeVariation.servingsInfo);

    return (
        <div className={elementClasses}>
            <div className="value">
                <span>{activeVariation.servings}</span>
                {activeVariation.servingsInfo && (
                    <div className="tooltip-wrap">
                        <CulinaryRecipeDetailServingsTooltip
                            content={activeVariation.servingsInfo}
                            singletonView={singletonView}
                            title={'Servings:'}
                            {...props}
                        />
                    </div>
                )}
            </div>
            <div className="key">{key}</div>
        </div>
    );
};

export default CulinaryRecipeDetailTimingSummaryServings;
