import React from 'react';

const DropdownOptions = ({onClickOption, options}) => (
    <ul>
        {Object.keys(options).map((option, optionIndex) => (
            <li key={optionIndex}>
                <button className="dropdown-options-button" data-value={option} onClick={onClickOption} type="button">
                    {options[option]}
                </button>
            </li>
        ))}
    </ul>
);

export default DropdownOptions;
