import React from 'react';

import FeaturePrimaryVideo from '../culinary/modules/feature/feature-primary-video';

class FeatureVideoBlock extends React.Component {
    componentDidMount() {
        const {rowId, columnId, data} = this.props;

        if (rowId !== undefined && columnId !== undefined) {
            let isValid = false;

            if (data) {
                isValid = true;
            }

            this.props.actions.pageBuilderSetValid({
                columnId,
                isValid,
                rowId
            });
        }
    }

    render() {
        const {data, layoutType, actions, videoPlayerReducer} = this.props;
        const centeredLayoutClass = layoutType === 'full' ? 'feature-video-block-centered' : '';

        if (!data) {
            return null;
        }

        return (
            <div className={`feature-video-block ${centeredLayoutClass}`}>
                <FeaturePrimaryVideo
                    activeVideoId={videoPlayerReducer.activeVideoId}
                    addVideo={actions.addVideo}
                    media={data}
                    setActiveVideoId={actions.setActiveVideoId}
                    setVideoProp={actions.setVideoProp}
                    videoPlayerReducer={videoPlayerReducer}
                />
                {data.title && <div className="title">{data.title}</div>}
            </div>
        );
    }
}

export default FeatureVideoBlock;
