import React from 'react';

import CulinaryFeaturePreviewListItem from './culinary-feature-preview-list-item';

/* eslint-disable react/prefer-stateless-function */
class CulinaryFeaturePreviewList extends React.Component {
    render() {
        const {featuredContentList, layout, listStyle = 'row', focusIndex} = this.props;

        if (!featuredContentList) {
            return null;
        }

        return (
            <ul className={`culinary-feature-preview-list culinary-feature-preview-list-${listStyle}`}>
                {featuredContentList.map((preview, index) => (
                    <CulinaryFeaturePreviewListItem
                        content={preview}
                        key={index}
                        layout={layout}
                        shouldFocus={focusIndex === index && focusIndex > 0}
                    />
                ))}
            </ul>
        );
    }
}
/* eslint-enable react/prefer-stateless-function */

export default CulinaryFeaturePreviewList;
