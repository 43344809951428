import React from 'react';

import SwapLink from '../global/hoc/swap-link';

const FeatureInfoBlock = ({description, heading, image, layoutType, link}) => {
    const layoutClass = layoutType === 'fourth' ? ' feature-info-block-small' : '';

    return (
        <div className={`feature-info-block${layoutClass}`}>
            <div className="display-icon">
                {image && <img alt={image.alt} src={`${image.cropUrl}&width=180&height=180&upscale=false`} />}
            </div>
            <h4 className="heading">{heading}</h4>
            {description && (
                <div
                    className="description"
                    /* eslint-disable-next-line react/no-danger */
                    dangerouslySetInnerHTML={{__html: description}}
                />
            )}
            {link && (
                <SwapLink className="link" href={link.url} target={link.target}>
                    <span className="text">{link.title}</span>
                </SwapLink>
            )}
        </div>
    );
};

export default FeatureInfoBlock;
