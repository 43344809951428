import {
    CLEAR_URL_CONTENT,
    CONTENT_SET_LOADING,
    LOAD_URL_CONTENT,
    LOAD_URL_CONTENT_SUCCESS,
    LOAD_STORE_CONTENT
} from '../action-types';

const getInitialState = () => ({
    isLoading: false,
    storeExperts: null,
    urlContent: null,
    urlContentPath: null
});

const setUrlContentPath = (state, data) => ({
    ...state,
    urlContentPath: data
});

const contentSetLoading = (state, isLoading) => ({
    ...state,
    isLoading,
    urlContent: null
});

const loadContentForUrl = (state, data) => ({
    ...state,
    isLoading: false,
    urlContent: data
});

const clearContent = (state) => ({
    ...state,
    isLoading: false,
    urlContent: null
});

const loadStoreContent = (state, data) => ({
    ...state,
    storeContent: data
});

const reducerMap = {
    [CLEAR_URL_CONTENT]: clearContent,
    [CONTENT_SET_LOADING]: contentSetLoading,
    [LOAD_STORE_CONTENT]: loadStoreContent,
    [LOAD_URL_CONTENT]: setUrlContentPath,
    [LOAD_URL_CONTENT_SUCCESS]: loadContentForUrl
};

export default (initialState = getInitialState(), {data, type}) => {
    if (reducerMap[type]) {
        return reducerMap[type](initialState, data);
    }

    return initialState;
};

export {getInitialState};
