import React from 'react';
import {Router} from 'react-router';
import {Provider} from 'react-redux';

import {getRoutes} from './routes';

const ProviderContainer = ({store, history}) => (
    <Provider store={store}>
        <Router history={history} routes={getRoutes(store)} />
    </Provider>
);

export default ProviderContainer;
