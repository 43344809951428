import {getYouTubeIframeApi} from '../utilities/youtube-iframe-api';

export const getYouTubePlayer = async (videoId, uuid, callback) => {
    const youTubeIframeApi = await getYouTubeIframeApi();

    const options = {
        events: {
            onReady: callback
        },
        playerVars: {
            rel: 0
        },
        videoId
    };

    return new youTubeIframeApi.Player(`${videoId}${uuid}`, options);
}
