import React from 'react';

const IconHeartLarge = () => (
    <svg
        className="icon-heart icon-heart-large"
        focusable={false}
        height="24"
        viewBox="0 0 27 24"
        width="27"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            className="svg-shape"
            d="M23.7 12.6c1-1.1 1.8-2.9 1.8-4.5 0-3.5-3.2-6.6-6.6-6.6-2.4 0-4.4 1.2-5.6 2.9-1.1-1.7-3.2-2.7-5.5-2.7-3.5 0-6.3 2.9-6.3 6.5 0 1.7.6 3.1 1.8 4.4l8.6 9.3c.3.3.9.6 1.4.6s1.1-.2 1.4-.6l9-9.3"
        />
    </svg>
);

export default IconHeartLarge;
