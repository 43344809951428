import React from 'react';

import {slugify} from '../../../../../services/string-services';
import IconInfo from '../../../../global/icon/icon-info';
import CulinaryMealIdeasNutritionModal from '../modals/culinary-meal-ideas-nutrition-modal';

class CulinaryMealIdeasCardNutritionBtn extends React.Component {
    constructor(props) {
        super(props);

        this.nutritionModalId = CulinaryMealIdeasNutritionModal.ID;
        this.modalTriggerId = `nutritionInfoButton_${slugify(this.props.mealId)}`;

        this.onClickNutritionInfoHandler = (event) => this.onClickNutritionInfo(event);
    }

    componentDidMount() {
        this.updateModal();
    }

    componentDidUpdate() {
        this.updateModal();
    }

    updateModal() {
        const modalReducer = this.props.modalReducer;

        if (
            modalReducer.isActive === false &&
            modalReducer.triggerId === this.modalTriggerId &&
            modalReducer.isRecentlyInactive === true
        ) {
            this[this.modalTriggerId].focus();

            this.props.actions.modalUpdate({
                isActive: false,
                isRecentlyInactive: false,
                modalId: '',
                triggerId: ''
            });
        }
    }

    onClickNutritionInfo() {
        this.props.actions.mealIdeasModalUpdate({
            mealIdeaKey: this.props.mealId
        });

        this.props.actions.modalUpdate({
            isActive: true,
            modalId: this.nutritionModalId,
            triggerId: this.modalTriggerId
        });
    }

    render() {
        return (
            <button
                className="culinary-meal-ideas-card-nutrition-btn"
                onClick={this.onClickNutritionInfoHandler}
                ref={(node) => {
                    this[this.modalTriggerId] = node;
                }}
                type="button"
            >
                <span className="label">{'Nutrition'}</span>
                <span className="icon">
                    <IconInfo />
                </span>
            </button>
        );
    }
}

export default CulinaryMealIdeasCardNutritionBtn;
