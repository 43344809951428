const dietTagMap = {
    'Dairy-Free': 'http://schema.org/LowLactoseDiet',
    'Gluten-Free': 'http://schema.org/GlutenFreeDiet',
    'Low Calorie': 'http://schema.org/LowCalorieDiet',
    'Low Fat': 'http://schema.org/LowFatDiet',
    'Low Glycemic Index': 'http://schema.org/DiabeticDiet',
    'Low Sodium': 'http://schema.org/LowSaltDiet',
    Vegan: 'http://schema.org/VeganDiet',
    Vegetarian: 'http://schema.org/VegetarianDiet'
};

export default dietTagMap;
