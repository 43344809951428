import React from 'react';

const CulinaryFeatureRecipeCarouselNavigation = ({onClickNext, onClickPrevious}) => (
    <div className="culinary-feature-recipe-carousel-navigation">
        <button
            aria-label={'Scroll Carousel Backward'}
            className={'previous-click-area'}
            onClick={onClickPrevious}
            type="button"
        >
            <span className="button">
                <span className="label">{'Scroll Carousel Backward'}</span>
            </span>
        </button>
        <button
            aria-label={'Scroll Carousel Forward'}
            className={'next-click-area'}
            onClick={onClickNext}
            type="button"
        >
            <span className="button">
                <span className="label">{'Scroll Carousel Forward'}</span>
            </span>
        </button>
    </div>
);

export default CulinaryFeatureRecipeCarouselNavigation;
