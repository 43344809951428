import moment from 'moment';

function checkValue(value) {
    return value > 0 ? value : null;
}

export const convertToMilliseconds = (timingString) => {
    if (timingString !== null) {
        const timingMoment = moment.duration(timingString);

        return timingMoment._milliseconds;
    }

    return null;
};

export const convertTimingData = (timingString) => {
    if (timingString !== null) {
        const timingMoment = moment.duration(timingString);

        return {
            hours: {
                unit: 'hr',
                value: checkValue(timingMoment.hours())
            },
            mins: {
                unit: 'min',
                value: checkValue(timingMoment.minutes())
            }
        };
    }

    return null;
};

export const createReadableTimingString = (timingString) => {
    let timingDisplay = timingString;

    if (timingString !== null) {
        const timingObject = convertTimingData(timingString);
        const hours = timingObject.hours.value ? `${timingObject.hours.value} ${timingObject.hours.unit}` : '';
        const mins = timingObject.mins.value ? `${timingObject.mins.value} ${timingObject.mins.unit}` : '';

        timingDisplay = `${hours} ${mins}`.trim();
    }

    return timingDisplay;
};
