import {
    ADD_MEAL_IDEA_TO_PRINT,
    REMOVE_MEAL_IDEA_TO_PRINT,
    ADD_ALL_MEAL_IDEAS_TO_PRINT,
    SET_PRINT_PREVIEW_ERROR
} from '../action-types';

export const addAllMealIdeasToPrint = (meals) => ({
    data: meals,
    type: ADD_ALL_MEAL_IDEAS_TO_PRINT
});

export const addMealIdeaToPrint = (mealIdeaIndex) => ({
    data: mealIdeaIndex,
    type: ADD_MEAL_IDEA_TO_PRINT
});

export const removeMealIdeaToPrint = (mealIdeaIndex) => ({
    data: mealIdeaIndex,
    type: REMOVE_MEAL_IDEA_TO_PRINT
});

export const setPrintPreviewError = (hasError) => ({
    data: hasError,
    type: SET_PRINT_PREVIEW_ERROR
});
