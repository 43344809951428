import {RESET_SELECTED_INGREDIENTS, UPDATE_CHECKED_ALL_INGREDIENTS, UPDATE_CHECKED_INGREDIENT} from '../action-types';

export const updateCheckedAllIngredients = (ingredientData) => ({
    data: ingredientData,
    type: UPDATE_CHECKED_ALL_INGREDIENTS
});

export const updateCheckedIngredient = (ingredientData) => ({
    data: ingredientData,
    type: UPDATE_CHECKED_INGREDIENT
});

export const resetSelectedIngredients = () => ({
    type: RESET_SELECTED_INGREDIENTS
});
