import React from 'react';
import {Link} from 'react-router';

import CulinaryFavoriteBtn from '../../favorite/culinary-favorite-btn';
import CulinaryTotalTime from '../culinary-feature-total-time';

const CulinaryFeatureRecipeCarouselItemDetails = (props) => (
    <div className="culinary-feature-recipe-carousel-item-details">
        <div className="header">
            <h4 className="heading">{props.recipe.title}</h4>
            <div className="split">
                <CulinaryTotalTime card={props.recipe} />
                <CulinaryFavoriteBtn
                    actions={props.actions}
                    alertReducer={props.alertReducer}
                    favoritesReducer={props.favoritesReducer}
                    id={props.recipe.contentId}
                    isAuthenticated={props.isAuthenticated}
                    modalReducer={props.modalReducer}
                    modalTriggerId={`featured-recipe-carousel-${props.id}-${props.recipe.contentId}`}
                    preventFocus={props.isRepeat}
                />
            </div>
        </div>
        <div className="link-container">
            <Link className="link" tabIndex={props.isRepeat ? -1 : 0} to={props.recipe.url}>
                {'View Recipe'}
            </Link>
        </div>
    </div>
);

export default CulinaryFeatureRecipeCarouselItemDetails;
