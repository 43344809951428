import React from 'react';

import IconHyVeeLogo from '../../../global/icon/icon-hy-vee-logo';

const EXPERT_TIP_LABEL = 'Expert Tip';

class CulinaryFeatureExpertTip extends React.Component {
    componentDidMount() {
        const {rowId, columnId, data} = this.props;

        if (rowId !== undefined && columnId !== undefined) {
            let isValid = false;

            if (data && data.author) {
                isValid = true;
            }

            this.props.actions.pageBuilderSetValid({
                columnId,
                isValid,
                rowId
            });
        }
    }

    getLayoutClass(layoutType = '') {
        return layoutType === '' ? '' : ` culinary-feature-expert-tip-${layoutType}`;
    }

    render() {
        const {data, layoutType} = this.props;

        if (!data) {
            return null;
        }

        const layoutClass = this.getLayoutClass(layoutType);

        return (
            <blockquote className={`culinary-feature-expert-tip${layoutClass}`}>
                <div className="quote-bd">
                    <h5 className="heading">
                        <span aria-hidden="true" className="heading-logo">
                            <IconHyVeeLogo />
                        </span>
                        <span className="heading-prefix">{'Hyvee '}</span>
                        <span className="heading-text">{`${data.type} ${EXPERT_TIP_LABEL}`}</span>
                    </h5>
                    <span
                        className="quote-text"
                        dangerouslySetInnerHTML={{__html: data.content}} // eslint-disable-line react/no-danger
                    />
                </div>
                <cite className="quote-author">
                    {data.author.profileImage && (
                        <span className="author-image">
                            <img
                                alt={data.author.profileImage.alt}
                                className="thumbnail"
                                src={`${data.author.profileImage.cropUrl}&width=300&height=300`}
                            />
                        </span>
                    )}
                    <span className="author-name">{data.author.name}</span>
                    <span className="author-title">{data.author.description}</span>
                </cite>
            </blockquote>
        );
    }
}

export default CulinaryFeatureExpertTip;
