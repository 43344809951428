import React from 'react';

const CulinaryRecipeDetailTimingSummaryDetails = ({time, label}) => (
    <div className="culinary-recipe-detail-timing-summary-item">
        <div className="value">
            {time.hours.value && (
                <span>
                    {time.hours.value}
                    <span className="unit">{time.hours.unit}</span>
                </span>
            )}

            {time.mins.value && (
                <span>
                    {time.mins.value}
                    <span className="unit">{time.mins.unit}</span>
                </span>
            )}
        </div>
        <div className="key">{label}</div>
    </div>
);

export default CulinaryRecipeDetailTimingSummaryDetails;
