import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {browserHistory} from 'react-router';
import debounce from 'lodash.debounce';

import * as ActionCreators from '../../action-creators/root-actions';
import clamp from '../../services/math-service';
import eventKeys from '../../constants/event-keys';

import FeatureSearchAutocomplete from './feature-search-autocomplete';

const mapStateToProps = (state) => ({
    environmentConfigReducer: state.environmentConfigReducer,
    isServerSide: state.isServerSide,
    sortBy: state.searchReducer.sortBy
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(ActionCreators, dispatch)
});

export class FeatureSearchBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hasInputAndFocus: false,
            isActive: this.props.hasSearchQuery || props.isServerSide,
            selectedAutocompleteResultIndex: null
        };

        this.onSubmitSearchHandler = (event) => this.onSubmitSearch(event);
        this.onChangeSearchInputHandler = debounce((event) => this.onChangeSearchInput(event), 500);
        this.onBlurSearchInputHandler = (event) => this.onBlurSearchInput(event);
        this.onFocusSearchInputHandler = (event) => this.onFocusSearchInput(event);
        this.onClickWindowHandler = (event) => this.onClickWindow(event);
        this.onKeyDownHandler = (event) => this.onKeyDown(event);
    }

    componentDidMount() {
        this.clearAutoComplete();

        global.addEventListener('click', this.onClickWindowHandler);
    }

    componentWillUnmount() {
        global.removeEventListener('click', this.onClickWindowHandler);
    }

    onChangeSearchInput() {
        if (this.searchInput) {
            this.setSearchInputStatus();
            const searchTerm = this.searchInput.value;
            const minimumSearchValueLengthForAutocomplete = 3;

            if (searchTerm.length >= minimumSearchValueLengthForAutocomplete) {
                if (this.props.searchType === 'editorial') {
                    this.props.actions.setAutocompleteEditorialSearchTerm(
                        searchTerm,
                        this.props.environmentConfigReducer.cms.editorialSearchAutoCompleteApiUrl
                    );
                } else {
                    this.props.actions.setAutocompleteSearchTerm(
                        searchTerm,
                        this.props.environmentConfigReducer.cms.autocompleteApiUrl
                    );
                }
            } else {
                this.clearAutoComplete();
            }

            const location = browserHistory.getCurrentLocation();

            if (searchTerm === '' && location.query.q) {
                this.props.actions.setQueryString('');
            }
        }
    }

    onFocusSearchInput() {
        this.setSearchInputStatus();
    }

    onBlurSearchInput() {
        this.setSearchInputStatus();
    }

    onClickWindow(event) {
        if (
            !this.featureSearchBar.contains(event.target) &&
            this.props.autocompleteResults &&
            this.props.autocompleteResults.length > 0
        ) {
            this.clearAutoComplete();
        }
    }

    onSubmitSearch(event) {
        this.clearAutoComplete();

        if (!this.props.formAction && this.searchInput) {
            event.preventDefault();

            if (this.searchInput.value !== this.props.searchTerm) {
                this.props.actions.setQueryString(this.searchInput.value);

                this.props.submitSearchHandler();
                this.setState({isActive: true});
            }
        }
    }

    onKeyDown(event) {
        const resultsLength = this.props.autocompleteResults.length;
        const keyCode = event.which;

        if (resultsLength > 0) {
            if (keyCode === eventKeys.UP || keyCode === eventKeys.DOWN) {
                event.preventDefault();

                this.setSelectedAutocompleteIndex(resultsLength, keyCode);
            }

            if (keyCode === eventKeys.ENTER && this.state.selectedAutocompleteResultIndex !== null) {
                event.preventDefault();

                this.navigateToSelectedAutocompleteIndex(this.state.selectedAutocompleteResultIndex);
            }

            if (keyCode === eventKeys.ESC) {
                this.clearAutoComplete();
            }
        }
    }

    setSearchInputStatus() {
        if (this.searchInput) {
            const searchTerm = this.searchInput.value;
            const hasInputAndFocus = searchTerm.length > 0;

            this.setState({hasInputAndFocus});
        }
    }

    setSelectedAutocompleteIndex(resultsLength, keyCode) {
        let index;

        if (keyCode === eventKeys.DOWN) {
            index =
                this.state.selectedAutocompleteResultIndex === null
                    ? 0
                    : this.state.selectedAutocompleteResultIndex + 1;
        } else {
            // keyCode is UP This function is only called when key is UP or DOWN
            index =
                this.state.selectedAutocompleteResultIndex === null
                    ? 0
                    : this.state.selectedAutocompleteResultIndex - 1;
        }

        const clampedIndex = clamp(index, 0, resultsLength - 1);

        this.setState({selectedAutocompleteResultIndex: clampedIndex});
    }

    navigateToSelectedAutocompleteIndex(selectedAutocompleteResultIndex) {
        this.clearAutoComplete();

        const url = this.props.autocompleteResults[selectedAutocompleteResultIndex].url;

        browserHistory.push(url);
    }

    clearAutoComplete() {
        this.props.actions.clearAutocompleteResults();
        this.setState({selectedAutocompleteResultIndex: null});
    }

    render() {
        const visibleLabelClass = this.props.showLabel ? ' feature-search-bar-has-visible-label' : '';
        const classExt = this.props.classExt ? ` feature-search-bar-${this.props.classExt}` : '';
        const labelStyle = this.props.labelStyle ? this.props.labelStyle : '';

        return (
            <form
                action={this.props.formAction || ''}
                autoComplete="off"
                className={`feature-search-bar${classExt}${visibleLabelClass}${this.state.isActive ? ' active' : ''}`}
                onSubmit={this.onSubmitSearchHandler}
                ref={(element) => {
                    this.featureSearchBar = element;
                }}
            >
                <label className={`search-label ${labelStyle}`} htmlFor="recipeSearch">
                    {this.props.label}
                </label>
                <input
                    className="search-input"
                    defaultValue={this.props.searchTerm}
                    id="recipeSearch"
                    maxLength={30}
                    name="q"
                    onBlur={this.onBlurSearchInputHandler}
                    onChange={this.onChangeSearchInputHandler}
                    onFocus={this.onFocusSearchInputHandler}
                    onKeyDown={this.onKeyDownHandler}
                    placeholder={this.props.placeholder}
                    ref={(input) => {
                        this.searchInput = input;
                    }}
                    type="text"
                />
                <div className="search-submit">
                    <button className={`submit-button ${this.state.hasInputAndFocus ? 'active' : ''}`} type="submit">
                        {'Search'}
                    </button>
                </div>
                {this.props.autocompleteResults.length > 0 && this.searchInput && (
                    <FeatureSearchAutocomplete
                        autocompleteResults={this.props.autocompleteResults}
                        isActive={this.state.isActive}
                        searchTerm={this.searchInput.value}
                        selectedAutocompleteResultIndex={this.state.selectedAutocompleteResultIndex}
                        showLabel={this.props.showLabel}
                    />
                )}
            </form>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FeatureSearchBar);
