import moment from 'moment';
import {browserHistory} from 'react-router';

import ContentApiService from '../services/api/content-api-service';
import {
    ADD_FILTER,
    ADD_FILTER_GROUP,
    CLEAR_SEARCH_RESULTS,
    INCREMENT_PAGE,
    REMOVE_ALL_FILTERS,
    REMOVE_FILTER,
    RESET_SEARCH,
    SET_QUERY_STRING,
    SET_SEARCH_IS_LOADING,
    SET_SEARCH_RESULTS,
    SET_SORT_BY,
    SYNC_SEARCH_QUERY_WITH_STORE,
    SET_SEARCH_IS_LOADING_MORE
} from '../action-types';

const newContentApiServiceInstance = ContentApiService.create();

const setUrl = (searchReducer) => {
    const currentLocation = browserHistory.getCurrentLocation();
    const filters = searchReducer.filters;
    const queryString = searchReducer.queryString;
    const sortBy = searchReducer.sortBy;

    const filterBy = Object.keys(filters).reduce((hashObj, fieldName) => {
        hashObj[fieldName] = filters[fieldName].join(','); // eslint-disable-line no-param-reassign

        return hashObj;
    }, {});

    const newLocation = {
        ...currentLocation,
        query: {
            ...filterBy,
            q: queryString,
            sortBy
        }
    };

    Object.keys(newLocation.query).forEach((key) => {
        if (!newLocation.query[key]) {
            delete newLocation.query[key];
        }
    });

    const noneFilters = ['pageSize', 'sortBy', 'currentPage'];
    const shouldClearSearchQueries =
        Object.keys(newLocation.query).filter((key) => !noneFilters.includes(key)).length === 0;

    if (shouldClearSearchQueries) {
        delete newLocation.query.sortBy;
    }

    browserHistory.replace(newLocation);
};

export const setSearchQuery = (searchQuery, endpoint, isViewMore) => async (dispatch) => {
    dispatch({
        type: CLEAR_SEARCH_RESULTS
    });

    dispatch({
        data: true,
        type: isViewMore ? SET_SEARCH_IS_LOADING_MORE : SET_SEARCH_IS_LOADING
    });

    await newContentApiServiceInstance.abort();
    const responseModel = await newContentApiServiceInstance.getSearchResults(searchQuery, endpoint);

    if (responseModel.success) {
        const searchData = responseModel.data.data;

        if (searchData && searchData.results && searchData.results.length) {
            searchData.results.forEach((result) => {
                result.dateCreatedFormated = moment(result.dateCreated).format('MMMM D YYYY'); // eslint-disable-line no-param-reassign
            });
        }

        if (searchData) {
            dispatch({
                data: searchData,
                type: SET_SEARCH_RESULTS
            });
        }

        dispatch({
            data: false,
            type: isViewMore ? SET_SEARCH_IS_LOADING_MORE : SET_SEARCH_IS_LOADING
        });
    }
};

export const incrementPage = () => (dispatch, getState) => {
    dispatch({
        type: INCREMENT_PAGE
    });

    setUrl(getState().searchReducer);
};

export const syncSearchQueryWithStore = (query) => ({
    data: query,
    type: SYNC_SEARCH_QUERY_WITH_STORE
});

export const addFilterGroup = (categoryId) => ({
    data: categoryId,
    type: ADD_FILTER_GROUP
});

export const addFilter = (filter) => (dispatch, getState) => {
    dispatch({
        data: filter,
        type: ADD_FILTER
    });

    setUrl(getState().searchReducer);
};

export const removeFilter = (filter) => (dispatch, getState) => {
    dispatch({
        data: filter,
        type: REMOVE_FILTER
    });

    setUrl(getState().searchReducer);
};

export const removeAllFilters = () => (dispatch, getState) => {
    dispatch({
        type: REMOVE_ALL_FILTERS
    });

    setUrl(getState().searchReducer);
};

export const setSortBy = (sortBy) => (dispatch, getState) => {
    dispatch({
        data: sortBy,
        type: SET_SORT_BY
    });

    setUrl(getState().searchReducer);
};

export const setQueryString = (queryString) => (dispatch, getState) => {
    dispatch({
        data: queryString,
        type: SET_QUERY_STRING
    });

    setUrl(getState().searchReducer);
};

export const clearSearchResults = () => ({
    type: CLEAR_SEARCH_RESULTS
});

export const resetSearch = () => ({
    type: RESET_SEARCH
});
