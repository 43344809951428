import {SET_FEEDBACK_RESPONSE_SELECTION, CLEAR_FEEDBACK_RESPONSE} from '../action-types';

const getInitialState = () => ({
    howToFeedbackApiResponse: null,
    selectedOptionValue: undefined
});

const setFeedbackResponseSelection = (state, data) => {
    const selectedOptionValue = data;

    return {
        ...state,
        selectedOptionValue
    };
};

const clearFeedbackResponse = () => ({
    howToFeedbackApiResponse: null,
    selectedOptionValue: undefined
});

const reducerMap = {
    [CLEAR_FEEDBACK_RESPONSE]: clearFeedbackResponse,
    [SET_FEEDBACK_RESPONSE_SELECTION]: setFeedbackResponseSelection
};

export default (state = getInitialState(), {data, type}) => {
    if (reducerMap[type]) {
        return reducerMap[type](state, data);
    }

    return state;
};

export {getInitialState};
