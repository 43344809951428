import React from 'react';

import IconClose from '../../../global/icon/icon-close';

class CulinarySearchActiveRefinementList extends React.Component {
    constructor(props) {
        super(props);

        this.onClickFilterItemHandler = (event, {fieldName, filterId}) =>
            this.onClickFilterItem(event, {
                fieldName,
                filterId
            });

        this.onClickClearAllHandler = () => this.props.removeAllFilters();
    }

    onClickFilterItem(event, {fieldName, filterId}) {
        const filterIndex = this.props.filters[fieldName].indexOf(filterId);

        this.props.removeFilter({
            fieldName,
            filterIndex
        });
    }

    render() {
        const filters = this.props.filters;
        let hasFilters = false;

        Object.keys(filters).forEach((key) => {
            if (filters[key].length > 0) {
                hasFilters = true;
            }
        });

        if (!hasFilters) {
            return null;
        }

        const dropdowns = this.props.dropdowns || [];

        return (
            <div className="culinary-search-active-refinement-list">
                <ul className="list">
                    {dropdowns.map((dropdown) => {
                        const fieldName = dropdown.fieldName;
                        const hasFilter = Boolean(filters[fieldName]);
                        const activeFilter = dropdown.options.map((refinement, refinementIndex) => {
                            let filterIsActive = false,
                                filterItem = null;

                            if (hasFilter) {
                                filterIsActive = filters[fieldName].includes(refinement.id.toString());
                            }

                            if (filterIsActive) {
                                filterItem = (
                                    <li className="list-item" key={refinementIndex}>
                                        <button
                                            className="button-remove"
                                            onClick={(event) =>
                                                this.onClickFilterItemHandler(event, {
                                                    fieldName,
                                                    filterId: refinement.id.toString()
                                                })
                                            }
                                            type="button"
                                        >
                                            <span className="button-label">{refinement.title}</span>
                                            <span className="button-icon">
                                                <IconClose />
                                            </span>
                                        </button>
                                    </li>
                                );
                            }

                            return filterItem;
                        });

                        return activeFilter;
                    })}
                    <li className="list-item">
                        <button className="button-clear" onClick={this.onClickClearAllHandler} type="button">
                            <span className="button-label">{'Clear All'}</span>
                        </button>
                    </li>
                </ul>
            </div>
        );
    }
}

export default CulinarySearchActiveRefinementList;
