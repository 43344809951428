import React from 'react';

import CulinaryLanding from './culinary/templates/culinary-recipes-ideas-template';
import Home from './culinary/templates/culinary-home-template';
import Favorites from './culinary/templates/culinary-favorites-template';
import Culinary404 from './culinary/templates/culinary-404';
import Culinary500 from './culinary/templates/culinary-500';
import RecipeDetail from './culinary/templates/culinary-recipe-detail-template';
import RecipesLanding from './culinary/templates/culinary-recipes-template';
import CulinaryHowTo from './culinary/templates/culinary-how-to-detail-template';
import CulinaryGuide from './culinary/templates/culinary-guide-template';
import CulinaryArticle from './culinary/templates/article-listicle/culinary-article-template';
import CulinaryListicle from './culinary/templates/article-listicle/culinary-listicle-template';
import CulinaryMealIdeasWeek from './culinary/templates/culinary-meal-ideas-template';
import CulinaryLoadingCart from './culinary/modules/loading/culinary-loading-cart';
import CulinaryEditorialLanding from './culinary/templates/culinary-editorial-landing-template';
import CulinaryEditorialCategory from './culinary/templates/culinary-editorial-category-template';
import CulinaryEditorialSearch from './culinary/templates/culinary-editorial-search-template';

class TemplateRenderer extends React.Component {
    componentWillReceiveProps(nextProps) {
        const previousContentUrl = nextProps.contentReducer.urlContentPath;
        const newContentUrl = nextProps.router.location.pathname;

        if (previousContentUrl !== newContentUrl) {
            nextProps.actions.loadContentForUrl(
                nextProps.router.location.pathname,
                nextProps.environmentConfigReducer.cms.contentApiUrl
            );
        }
    }

    componentHash = {
        CulinaryArticle,
        CulinaryEditorialCategory,
        CulinaryEditorialLanding,
        CulinaryEditorialSearch,
        CulinaryGuide,
        CulinaryHowTo,
        CulinaryLanding,
        CulinaryListicle,
        CulinaryMealIdeasWeek,
        Favorites,
        Home,
        RecipeDetail,
        RecipesLanding
    };

    render() {
        if (this.props.renderReducer.is500Error) {
            return <Culinary500 {...this.props} />;
        }

        if (this.props.contentReducer.isLoading) {
            return <CulinaryLoadingCart label="Loading..." />;
        }

        if (this.props.contentReducer.urlContent) {
            const templateName = this.props.contentReducer.urlContent.template;
            const TemplateComponent = this.componentHash[templateName] || Culinary404;

            return <TemplateComponent {...this.props} />;
        }

        return <Culinary404 {...this.props} />;
    }
}

export default TemplateRenderer;
