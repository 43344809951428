import {PAGE_BUILDER_SET_VALID, PAGE_BUILDER_CLEAR_VALID} from '../action-types';

export const pageBuilderSetValid = (componentData) => ({
    data: componentData,
    type: PAGE_BUILDER_SET_VALID
});

export const pageBuilderClearValid = () => ({
    type: PAGE_BUILDER_CLEAR_VALID
});
