import React from 'react';

import {createReadableTimingString} from '../../../../services/timing-service';

const CulinaryFeatureTotalTime = ({card}) => {
    if (!card.totalTime) {
        return null;
    }

    const timeDisplay = createReadableTimingString(card.totalTime);

    return (
        <div className="duration">
            <div className="duration-text">{timeDisplay}</div>
        </div>
    );
};

export default CulinaryFeatureTotalTime;
