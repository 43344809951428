import React from 'react';

import CulinaryAddToListForm from './culinary-add-to-list-form';
import CulinaryAddToListFail from './culinary-add-to-list-fail';
import CulinaryAddToListSuccess from './culinary-add-to-list-success';
import CulinaryAddToListLoading from './culinary-add-to-list-loading';

class CulinaryAddToListModal extends React.Component {
    static ID = 'culinary-add-to-list-modal';

    constructor(props) {
        super(props);

        this.onClickCancelHandler = (event) => this.onClickCancel(event);
    }

    componentWillMount() {
        this.props.actions.addToListGetLists();
        this.props.actions.formInputClear();
        this.props.actions.addToListClearErrors();
        this.props.actions.addToListClearStatus();
    }

    componentWillReceiveProps(nextProps) {
        const newListInput = nextProps.formInputReducer.inputs[CulinaryAddToListForm.NEW_LIST_INPUT_ID];

        if (newListInput) {
            /*
             * If the new list input has a value and the user has just successfully created a new list,
             * set the list title and retrieve the new lists
             */
            if (nextProps.addToListReducer.isSuccessListCreated && newListInput.value) {
                this.props.actions.addToListSetListTitle(newListInput.value);
                this.props.actions.addToListGetNewLists();
            }

            /*
             * If the new list input has a value and the user has just successfully reloaded the lists,
             * match the new list to the users lists to get the id and add the ingredients to that list
             */
            if (nextProps.addToListReducer.isSuccessReloaded && newListInput.value) {
                const matchedLists = nextProps.addToListReducer.userLists.filter(
                    (list) => list.name === newListInput.value
                );
                const matchedListId = matchedLists[0].shopping_list_id;

                this.props.addToListReducer.ingredientsToAdd.forEach((ingredient) => {
                    this.props.actions.addToListAddIngredient(matchedListId, ingredient);
                });
            }
        }
    }

    onClickCancel() {
        this.props.actions.modalClose();
    }

    render() {
        if (this.props.addToListReducer.isSuccessLoaded) {
            return (
                <CulinaryAddToListForm
                    {...this.props}
                    ingredientsToAdd={this.props.addToListReducer.ingredientsToAdd}
                    onClickCancel={this.onClickCancelHandler}
                    userLists={this.props.addToListReducer.userLists}
                />
            );
        } else if (this.props.addToListReducer.isSuccessAdded) {
            return (
                <CulinaryAddToListSuccess
                    listTitle={this.props.addToListReducer.listTitle}
                    onClickCancel={this.onClickCancelHandler}
                />
            );
        } else if (this.props.addToListReducer.isFail) {
            return (
                <CulinaryAddToListFail
                    contentReducer={this.props.contentReducer}
                    onClickCancel={this.onClickCancelHandler}
                />
            );
        } else if (this.props.addToListReducer.isBusyAdding) {
            return <CulinaryAddToListLoading label="Adding items..." />;
        }

        return <CulinaryAddToListLoading label="Loading your lists..." />;
    }
}

export default CulinaryAddToListModal;
