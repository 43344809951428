import React from 'react';

import CulinaryFeatureCard from '../feature/cards/culinary-feature-card';
import CulinaryFeatureCardGuide from '../feature/cards/culinary-feature-card-guide';
import CulinaryFeatureExpertTip from '../feature/culinary-feature-expert-tip';
import CulinaryFeaturePrimaryMedia from '../feature/culinary-feature-primary-media';
import FeatureWysiwyg from '../../../feature/feature-wysiwyg';

const CulinaryListicleListItem = ({
    actions,
    alertReducer,
    favoritesReducer,
    isAuthenticated,
    index,
    listItem,
    modalReducer,
    videoPlayerReducer
}) => {
    if (!listItem) {
        return null;
    }

    return (
        <li>
            <h3 className="title">{listItem.heading}</h3>

            {listItem.media && (
                <CulinaryFeaturePrimaryMedia
                    activeVideoId={videoPlayerReducer.activeVideoId}
                    addVideo={actions.addVideo}
                    media={listItem.media}
                    setActiveVideoId={actions.setActiveVideoId}
                    setVideoProp={actions.setVideoProp}
                    videoPlayerReducer={videoPlayerReducer}
                />
            )}

            <div className="description">
                <FeatureWysiwyg data={listItem.content} />
            </div>

            <div className="components">
                {listItem.compactReference && (
                    <CulinaryFeatureCard
                        actions={actions}
                        alertReducer={alertReducer}
                        card={listItem.compactReference}
                        favoritesReducer={favoritesReducer}
                        id={`listicle-list-inline-${index}`}
                        isAuthenticated={isAuthenticated}
                        layoutType="inline"
                        modalReducer={modalReducer}
                    />
                )}

                {listItem.largeReference && listItem.largeReference.type === 'Guide' && (
                    <CulinaryFeatureCardGuide cardGuide={listItem.largeReference} isLargeCard isWide />
                )}

                {listItem.largeReference && listItem.largeReference.type !== 'Guide' && (
                    <CulinaryFeatureCard
                        actions={actions}
                        alertReducer={alertReducer}
                        card={listItem.largeReference}
                        favoritesReducer={favoritesReducer}
                        id={`listicle-list-${index}`}
                        isAuthenticated={isAuthenticated}
                        layoutType="big"
                        modalReducer={modalReducer}
                    />
                )}

                {listItem.expertTip && <CulinaryFeatureExpertTip data={listItem.expertTip} layoutType="direction" />}
            </div>
        </li>
    );
};

export default CulinaryListicleListItem;
