import React from 'react';

import SwapLink from '../../../../global/hoc/swap-link';

const CulinaryMealIdeasActionCta = ({content}) => {
    if (!content) {
        return null;
    }

    const hasLink1 = content.link1 && content.link1.title && content.link1.url;

    const hasLink2 = content.link2 && content.link2.title && content.link2.url;

    const hasTwoLinks = hasLink1 && hasLink2;

    return (
        <section className="culinary-meal-ideas-action-cta">
            {content.heading && <h3 className="header">{content.heading}</h3>}
            <div className="actions">
                {hasLink1 && (
                    <SwapLink className={'link'} href={content.link1.url} target={content.link1.target}>
                        {content.link1.title}
                    </SwapLink>
                )}
                {hasTwoLinks && <div className="delimiter">{'or'}</div>}
                {hasLink2 && (
                    <SwapLink className={'link'} href={content.link2.url} target={content.link2.target}>
                        {content.link2.title}
                    </SwapLink>
                )}
            </div>
        </section>
    );
};

export default CulinaryMealIdeasActionCta;
