import React from 'react';

import CulinaryFeatureGuideHeroSectionsSubnav from './culinary-feature-guide-hero-sections-subnav';

const CulinaryFeatureGuideHeroSectionsList = (props) => {
    if (!props.dropLinks.length) {
        return null;
    }

    return (
        <div className="culinary-feature-guide-hero-sections-list">
            <h3 className="heading">{'in this guide...'}</h3>
            <CulinaryFeatureGuideHeroSectionsSubnav dropLinks={props.dropLinks} isScrolled={props.isScrolled} />
        </div>
    );
};

export default CulinaryFeatureGuideHeroSectionsList;
