import React from 'react';
import {Link} from 'react-router';

const CulinaryFeatureBadgesLink = ({badge}) => {
    const badgeStyles = {
        color: `#${badge.color}`
    };

    return (
        <Link className="culinary-feature-badges-link" style={badgeStyles} to={badge.url}>
            <span className="label">{badge.title}</span>
        </Link>
    );
};

export default CulinaryFeatureBadgesLink;
