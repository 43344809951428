import React from 'react';

const CulinaryAddToListFormError = ({addToListReducer}) => {
    const errorCount = Object.keys(addToListReducer.errors).length;
    const errorMessageStart = errorCount > 1 ? 'There are ' : 'There is ';
    const errorCountMessage = errorCount > 1 ? `${errorCount} errors` : `${errorCount} error`;

    if (errorCount > 0) {
        return (
            <div className="status-banner">
                {errorMessageStart}
                <span className="detail">{errorCountMessage}</span>
                {' in the form below.'}
            </div>
        );
    }

    return null;
};

export default CulinaryAddToListFormError;
