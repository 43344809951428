import React from 'react';

import CulinaryFeatureActionBar from '../../feature/culinary-feature-action-bar';
import CulinaryFeatureIntro from '../../feature/culinary-feature-intro';
import CulinaryRecipeDetailIngredients from '../../recipe/detail/ingredients/culinary-recipe-detail-ingredients';
import CulinaryRecipeDetailNutritionInfo from '../../recipe/detail/nutrition/culinary-recipe-detail-nutrition-info';
import CulinaryDirectionsList from '../../directions/culinary-directions-list';
import CulinaryRecipeDetailPreStep from '../../recipe/detail/culinary-recipe-detail-pre-step';
import {convertTimingData} from '../../../../../services/timing-service';
import CulinaryRecipeDetailTimingSummaryDetails from '../../recipe/detail/timing/culinary-recipe-detail-timing-summary-details';
import FeatureArticleSource from '../../../../feature/feature-article-source';
import IconHyVeeLogo from '../../../../global/icon/icon-hy-vee-logo';

import CulinaryMealIdeasPrintViewListItem from './culinary-meal-ideas-print-view-list-item';

class CulinaryMealIdeasPrintView extends React.Component {
    getMealIngredientGroups() {
        const ingredientsGroupList = this.props.selectedMeals
            .reduce((departments, meal) => departments.concat(meal.ingredients), [])
            .reduce((ingredients, department) => {
                const departmentName = department.department;
                const found = ingredients.find((group) => group.department === departmentName);

                if (found) {
                    found.ingredients = found.ingredients.concat(department.ingredients);
                } else {
                    ingredients.push({
                        department: departmentName,
                        ingredients: [...department.ingredients]
                    });
                }

                return ingredients;
            }, []);

        return ingredientsGroupList;
    }

    getMealIngredientsItemMarkup(ingredients) {
        return ingredients.map((ingredient, ingredientIndex) => (
            <CulinaryMealIdeasPrintViewListItem ingredient={ingredient} key={ingredientIndex} />
        ));
    }

    getMealIngredientsGroupMarkup(mealIngredientGroups) {
        return mealIngredientGroups.map((group) => (
            <div className="meal-ingredients-group" key={group.department}>
                <h4 className="department">{group.department}</h4>
                <ul className="list">{this.getMealIngredientsItemMarkup(group.ingredients)}</ul>
            </div>
        ));
    }

    getMealsMarkup() {
        const recipesToPrint = this.props.selectedMeals
            .reduce((mealItems, meal) => mealItems.concat(meal.mealItems), [])
            .filter((mealItem) => mealItem.recipe)
            .map((mealItem) => mealItem.recipe);

        return (
            <div className="recipes">
                {recipesToPrint.map((recipe) => (
                    <article className="container" key={recipe.id}>
                        <div className="header">
                            <div className="title">
                                <div className="print-logo">
                                    <IconHyVeeLogo />
                                </div>
                                <div className="hdg">{recipe.title}</div>
                            </div>
                            {recipe.primaryMedia && recipe.primaryMedia.type === 'Image' && (
                                <img
                                    alt={recipe.primaryMedia.alt}
                                    className="image"
                                    src={`${recipe.primaryMedia.cropUrl}&width=300&height=200&upscale=false`}
                                />
                            )}

                            <section className="culinary-recipe-detail-timing-summary">
                                <h4 className="header">{'Recipe Data'}</h4>
                                <div className="list">
                                    {recipe.defaultVariation.servings && (
                                        <div className="culinary-recipe-detail-timing-summary-item">
                                            <div className="key">{'Servings'}</div>
                                            <div className="value">{recipe.defaultVariation.servings}</div>
                                        </div>
                                    )}
                                    {recipe.defaultVariation.prepTime && (
                                        <CulinaryRecipeDetailTimingSummaryDetails
                                            label={'Prep'}
                                            time={convertTimingData(recipe.defaultVariation.prepTime)}
                                        />
                                    )}

                                    {recipe.defaultVariation.totalTime && (
                                        <CulinaryRecipeDetailTimingSummaryDetails
                                            label={'Total'}
                                            time={convertTimingData(recipe.defaultVariation.totalTime)}
                                        />
                                    )}
                                </div>
                            </section>
                        </div>

                        {recipe.author && <CulinaryFeatureActionBar author={recipe.author} id={this.id} isPrinting />}
                        {recipe.description && (
                            <CulinaryFeatureIntro description={recipe.description} layout={'full'} />
                        )}
                        <div className="culinary-detail-template-bd">
                            <div className="info-supplies">
                                {recipe.defaultVariation.ingredientGroups && (
                                    <CulinaryRecipeDetailIngredients
                                        actions={this.props.actions}
                                        ingredientGroups={recipe.defaultVariation.ingredientGroups}
                                        ingredientsReducer={this.props.ingredientsReducer}
                                        ingredientsVariationReducer={this.props.ingredientsVariationReducer}
                                        isPrinting
                                        servings={recipe.defaultVariation.servings}
                                    />
                                )}
                            </div>
                            <div className="directions">
                                <h4 className="header">{'Directions'}</h4>
                                {recipe.defaultVariation.prepStep && (
                                    <CulinaryRecipeDetailPreStep preStep={recipe.defaultVariation.prepStep} />
                                )}
                                {recipe.defaultVariation.directions && (
                                    <CulinaryDirectionsList
                                        directions={recipe.defaultVariation.directions}
                                        id={this.id}
                                        isPrinting
                                    />
                                )}
                                {recipe.nutritionalInfo && (
                                    <CulinaryRecipeDetailNutritionInfo nutritionInfo={recipe.nutritionalInfo} />
                                )}
                            </div>
                            <div className="supporting-content">
                                {recipe.source && <FeatureArticleSource articleSource={recipe.source} />}
                            </div>
                        </div>
                    </article>
                ))}
            </div>
        );
    }

    render() {
        const mealIngredientGroups = this.getMealIngredientGroups();

        return (
            <div className="culinary-meal-ideas-print-view">
                {this.props.shouldPrintShoppingList && (
                    <div className="container container-shopping-list">
                        <p className="note">
                            {'Get menus like this delivered to your email inbox every week by signing up at hy-vee.com'}
                        </p>
                        <h3 className="header">{'Shopping List'}</h3>
                        {this.getMealIngredientsGroupMarkup(mealIngredientGroups)}
                    </div>
                )}
                {this.props.shouldPrintRecipes && this.getMealsMarkup()}
            </div>
        );
    }
}

export default CulinaryMealIdeasPrintView;
