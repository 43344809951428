import React from 'react';

class CulinarySearchViewMoreBtn extends React.Component {
    constructor(props) {
        super(props);

        this.onClickViewMoreHandler = () => this.onClickViewMore();
    }

    onClickViewMore() {
        this.props.actions.clearSearchResults();
        const isViewMore = true;
        const {location, endpoint} = this.props;
        const locationQueryWithPagination = {
            currentPage: this.props.searchReducer.currentPage + 1,
            pageSize: this.props.searchReducer.pageSize,
            sortBy: this.props.searchReducer.sortBy,
            ...location.query
        };

        this.props.actions.incrementPage();
        this.props.actions.setSearchQuery(locationQueryWithPagination, endpoint, isViewMore);
    }

    render() {
        const {label, searchReducer} = this.props;
        const {searchIsLoadingMore} = searchReducer;
        const loadingClass = searchIsLoadingMore ? ' culinary-search-view-more-btn-is-loading' : '';
        const labelText = `View More ${label}`;

        return (
            <div className={`culinary-search-view-more-btn${loadingClass}`}>
                <button className="cta-button" onClick={this.onClickViewMoreHandler} type="button">
                    <span aria-live="polite" className="label">
                        {labelText}
                    </span>
                </button>
            </div>
        );
    }
}

export default CulinarySearchViewMoreBtn;
