import React from 'react';

import FeaturePrimaryImage from '../../../../feature/feature-primary-image';
import CulinaryMealIdeasPrintModal from '../modals/culinary-meal-ideas-print-modal';
import SwapLink from '../../../../global/hoc/swap-link';

class CulinaryMealIdeasActionItem extends React.Component {
    constructor(props) {
        super(props);
        this.onClickHandler = (event) => this.onClick(event);
    }

    onClick(event) {
        const urlPrintString = event.target.href.split('?')[1];

        if (urlPrintString) {
            event.preventDefault();

            this.props.modalUpdate({
                isActive: true,
                modalId: CulinaryMealIdeasPrintModal.ID
            });
        }
    }

    linkMarkup() {
        return (
            <SwapLink
                className={'link'}
                href={this.props.link.url}
                onClick={this.onClickHandler}
                target={this.props.link.target}
            >
                {this.props.link.title}
            </SwapLink>
        );
    }

    render() {
        return (
            <div className="culinary-meal-ideas-action-item">
                <div className="media">
                    <FeaturePrimaryImage cropWidth={'80'} data={this.props.image} hideCaption />
                </div>
                <div className="body">
                    <div className="message">{this.props.message}</div>
                    {this.linkMarkup()}
                </div>
            </div>
        );
    }
}

export default CulinaryMealIdeasActionItem;
