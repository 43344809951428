class ResponseModel {
    data = null;

    statusCode = null;

    statusText = null;

    success = null;
}

export default ResponseModel;
