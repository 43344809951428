import React from 'react';

import CulinaryFavoritesNav from '../modules/favorites/nav/culinary-favorites-nav';
import CulinaryLoadingList from '../modules/loading/culinary-loading-list';
import CulinaryFavoritesSortBar from '../modules/favorites/culinary-favorites-sort-bar';
import CulinaryFavoritesRecipes from '../modules/favorites/culinary-favorites-recipes';
import CulinaryFavoritesNoResults from '../modules/favorites/culinary-favorites-no-results';

class CulinaryFavoritesTemplate extends React.Component {
    componentWillMount() {
        this.props.actions.clearRecipeFavorites();
        this.props.actions.loadRecipeFavorites(this.props.environmentConfigReducer.cms.favoritesApiUrl);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.favoritesReducer.recipeFavorites.error && !this.props.favoritesReducer.recipeFavorites.error) {
            this.props.actions.createAlert({
                isActive: true,
                message: 'There was an issue loading your favorites. Please try again.',
                type: 'error'
            });
        }
    }

    render() {
        const {favoritesReducer} = this.props;
        const {recipeFavorites} = favoritesReducer;
        const visibleResultsLength = recipeFavorites.visibleRecipes && recipeFavorites.visibleRecipes.length;

        return (
            <div className="culinary-favorites-template">
                <div className="inner">
                    <div className="sidebar">
                        <div className="content">
                            {!recipeFavorites.loading && (
                                <CulinaryFavoritesNav
                                    actions={this.props.actions}
                                    activeSortKey={recipeFavorites.activeSortKey}
                                    courseTypes={recipeFavorites.courseTypes}
                                    setPagedRecipeFavorites={this.props.actions.setPagedRecipeFavorites}
                                    totalResults={recipeFavorites.totalResults}
                                />
                            )}
                        </div>
                    </div>

                    <div className="container">
                        <div className="content">
                            <CulinaryFavoritesSortBar
                                actions={this.props.actions}
                                pageTitle={recipeFavorites.activeCourseType}
                                showDefaultTitle={recipeFavorites.loading}
                                totalResultsInActiveCategory={recipeFavorites.totalResultsInActiveCategory}
                                visibleResultsLength={visibleResultsLength}
                            />

                            {visibleResultsLength > 0 && !recipeFavorites.loading && (
                                <CulinaryFavoritesRecipes
                                    actions={this.props.actions}
                                    activeSortKey={recipeFavorites.activeSortKey}
                                    alertReducer={this.props.alertReducer}
                                    currentPage={recipeFavorites.currentPage}
                                    favoritesReducer={this.props.favoritesReducer}
                                    focusFirstAllowed={recipeFavorites.focusFirstAllowed}
                                    focusIndex={recipeFavorites.focusIndex}
                                    isAuthenticated={this.props.isAuthenticated}
                                    modalReducer={this.props.modalReducer}
                                    paginationAmount={recipeFavorites.paginationAmount}
                                    totalResultsInActiveCategory={recipeFavorites.totalResultsInActiveCategory}
                                    visibleRecipes={recipeFavorites.visibleRecipes}
                                    visibleResultsLength={visibleResultsLength}
                                />
                            )}

                            {visibleResultsLength === 0 && !recipeFavorites.loading && (
                                <CulinaryFavoritesNoResults recipeFavorites={recipeFavorites} />
                            )}

                            {recipeFavorites.loading && <CulinaryLoadingList />}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CulinaryFavoritesTemplate;
