import React from 'react';

const DISABLED_CLASS = 'is-disabled';

const CulinaryFeatureCardCarouselNavigation = ({
    disableClickNext,
    disableClickPrevious,
    onClickNext,
    onClickPrevious
}) => {
    let nextBtnClasses = 'next-click-area',
        prevBtnClasses = 'previous-click-area';

    if (disableClickNext) {
        nextBtnClasses = `${nextBtnClasses} ${DISABLED_CLASS}`;
    }

    if (disableClickPrevious) {
        prevBtnClasses = `${prevBtnClasses} ${DISABLED_CLASS}`;
    }

    return (
        <div className="culinary-feature-card-carousel-navigation">
            <button
                aria-hidden={disableClickPrevious}
                aria-label={'Scroll Carousel Backward'}
                className={prevBtnClasses}
                disabled={disableClickPrevious}
                onClick={onClickPrevious}
                type="button"
            >
                <span className="button">
                    <span className="label">{'Scroll Carousel Backward'}</span>
                </span>
            </button>
            <button
                aria-hidden={disableClickNext}
                aria-label={'Scroll Carousel Forward'}
                className={nextBtnClasses}
                disabled={disableClickNext}
                onClick={onClickNext}
                type="button"
            >
                <span className="button">
                    <span className="label">{'Scroll Carousel Forward'}</span>
                </span>
            </button>
        </div>
    );
};

export default CulinaryFeatureCardCarouselNavigation;
