import React from 'react';

import FeatureWysiwyg from './feature-wysiwyg';

class FeatureIntroBlock extends React.Component {
    componentDidMount() {
        const {rowId, columnId, data} = this.props;

        if (rowId !== undefined && columnId !== undefined) {
            let isValid = false;

            if (data && (data.content || data.heading)) {
                isValid = true;
            }

            this.props.actions.pageBuilderSetValid({
                columnId,
                isValid,
                rowId
            });
        }
    }

    render() {
        const data = this.props.data;

        if (!data) {
            return null;
        }

        const centeredClass = data.alignCenter ? 'centered' : '';

        return (
            <div className={`feature-intro-block ${centeredClass}`}>
                {data.heading && <h3 className="heading">{data.heading}</h3>}
                <FeatureWysiwyg data={data.content} />
            </div>
        );
    }
}

export default FeatureIntroBlock;
