import React from 'react';

const Culinary500 = () => (
    <div className="culinary-error">
        <div className="content">
            <h3 className="heading">{'500'}</h3>
            <h4 className="subheading">
                {'This is '} <span>{'nacho'}</span>
                {' problem.'}
            </h4>
            <p className="message">
                {
                    "The problem is on our end. There seems to be a problem displaying this page. Please try again later, when we'll have it fixed!"
                }
            </p>
        </div>
        <div className="media">
            <img alt="Unexpected Error" className="image" src="/recipes-ideas/static/images/nacho-500-2x.jpg" />
        </div>
    </div>
);

export default Culinary500;
