import React from 'react';

const CulinaryFeatureFeaturedIngredientsTab = (props) => {
    const {onClickHandler, index, ingredient, selectedTabIndex} = props;
    const {referenceImage} = ingredient;
    const selected = selectedTabIndex === index;
    const activeClass = selected ? ' active' : '';

    return (
        <button
            aria-controls={`panel${index}`}
            aria-label={ingredient.title}
            aria-selected={selected}
            className={`culinary-feature-featured-ingredients-tab${activeClass}`}
            data-tab-index={index}
            id={`panel${index}-tab`}
            onClick={onClickHandler}
            role="tab"
            type="button"
        >
            <span className="container">
                {referenceImage && (
                    <img
                        alt={referenceImage.alt}
                        className="image"
                        src={`${referenceImage.cropUrl}&width=300&height=300`}
                    />
                )}
            </span>
            <span className="title">{ingredient.title}</span>
        </button>
    );
};

export default CulinaryFeatureFeaturedIngredientsTab;
