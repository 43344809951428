import React from 'react';

import CulinaryFavoritesModalConfirmDeleteFail from './culinary-favorites-modal-confirm-delete-fail';
import CulinaryFavoritesModalConfirmDeleteDefault from './culinary-favorites-modal-confirm-delete-default';

class CulinaryFavoritesModalConfirmDelete extends React.Component {
    static ID = 'culinary-favorites-modal-confirm-delete';

    constructor(props) {
        super(props);

        this.onClickCancelHandler = (event) => this.onClickCancel(event);
        this.onClickConfirmHandler = (event) => this.onClickConfirm(event);
    }

    componentDidUpdate() {
        if (!this.props.favoritesReducer.recipeFavorites.deleteError) {
            this.props.actions.modalClose(); // wait to close until successful
        }
    }

    onClickConfirm() {
        this.props.actions.deleteRecipe(this.props.favoritesReducer.activeFavoriteId, true);
    }

    onClickCancel() {
        this.props.actions.modalClose();
    }

    render() {
        if (this.props.favoritesReducer.recipeFavorites.deleteError === false) {
            return (
                <CulinaryFavoritesModalConfirmDeleteDefault
                    onClickCancelHandler={this.onClickCancelHandler}
                    onClickConfirmHandler={this.onClickConfirmHandler}
                    recipeName={this.props.favoritesReducer.recipeFavorites.activeDeletion.title}
                />
            );
        }

        return <CulinaryFavoritesModalConfirmDeleteFail onClickCancelHandler={this.onClickCancelHandler} />;
    }
}

export default CulinaryFavoritesModalConfirmDelete;
