import React from 'react';
import {Link} from 'react-router';

import {textCustomizationClasses} from '../../../../../services/style-helpers-service';

import CulinaryFeatureCardGuideBackground from './culinary-feature-card-guide-background';

class CulinaryFeatureCardGuide extends React.Component {
    getAdditionalLayoutClasses() {
        const wideClass = this.props.isWide ? 'culinary-feature-card-guide-wide' : '';
        const largeCardClass = this.props.isLargeCard ? 'culinary-feature-card-guide-large-card' : '';
        const noAspectRatioClass = this.props.noAspectRatio ? 'culinary-feature-card-guide-no-aspect-ratio' : '';
        const isWideSmall = this.props.isWideXSmall ? 'culinary-feature-card-guide-wide-xsmall' : '';

        return `${wideClass} ${largeCardClass} ${noAspectRatioClass} ${isWideSmall}`;
    }

    render() {
        const {cardGuide} = this.props;
        const {displayContrast, textInverse} = this.props.cardGuide;
        const typeClass = textCustomizationClasses(displayContrast, textInverse);
        const layoutClasses = this.getAdditionalLayoutClasses();
        const styles = this.props.hasStyles ? this.props.styles : {};

        return (
            <Link
                aria-label={`${cardGuide.contentType}, ${cardGuide.title}, view`}
                className={`culinary-feature-card-guide${typeClass} ${layoutClasses}`}
                style={styles}
                to={cardGuide.url}
            >
                <CulinaryFeatureCardGuideBackground cardGuide={this.props.cardGuide} isWide={this.props.isWide} />
                <div className="border">
                    <div className="top-left" />
                    <div className="bottom-right" />
                </div>
                <div className="container">
                    <div className="main-content">
                        <div className="topic">{cardGuide.contentType}</div>
                        <div className="title">{cardGuide.title}</div>
                        <div className="action">{'View'}</div>
                    </div>
                </div>
            </Link>
        );
    }
}

export default CulinaryFeatureCardGuide;
