import {MODAL_CLOSE, MODAL_UPDATE} from '../action-types';

export const modalClose = () => ({
    type: MODAL_CLOSE
});

export const modalUpdate = (modalData) => ({
    data: modalData,
    type: MODAL_UPDATE
});
