const getInitialState = () => ({
    is500Error: false,
    isServerSide: true
});

const reducerMap = {};

export default (state = getInitialState(), {data, type}) => {
    /* istanbul ignore next */
    if (reducerMap[type]) {
        return reducerMap[type](state, data);
    }

    return state;
};

export {getInitialState};
