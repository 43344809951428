import React from 'react';

import CulinaryAddToCartBtn from '../../../add-to-cart/culinary-add-to-cart-btn';

class CulinaryRecipeDetailIngredientsAddToCart extends React.Component {
    constructor(props) {
        super(props);

        this.modalTriggerId = 'addToCartButton';

        this.onClickAddToListHandler = (event) => this.onClickAddToList(event);
    }

    getDisabledState = (props) => {
        const selectedVariationIndex = props.ingredientsVariationReducer.selectedVariationIndex;
        const ingredientSelections = props.ingredientsReducer.ingredientSelections[selectedVariationIndex];

        if (ingredientSelections) {
            return ingredientSelections.length === 0;
        }

        return true;
    };

    render() {
        const isDisabled = this.getDisabledState(this.props);

        return (
            <CulinaryAddToCartBtn
                disabled={isDisabled}
                environmentConfigReducer={this.props.environmentConfigReducer}
                layoutType="block"
                recipeUrl={this.props.recipeUrl}
                selectedIngredientsArray={this.props.selectedIngredientsArray}
            />
        );
    }
}

export default CulinaryRecipeDetailIngredientsAddToCart;
