import React from 'react';

import IconInfo from '../../../../../global/icon/icon-info';
import IconClose from '../../../../../global/icon/icon-close';

class CulinaryRecipeDetailServingsTooltipContent extends React.Component {
    componentWillReceiveProps(nextProps) {
        if (nextProps.isVisible === false && this.props.isVisible === true) {
            this.shouldFocus = true;
        } else {
            this.shouldFocus = false;
        }
    }

    componentDidUpdate() {
        if (this.shouldFocus) {
            this.toggleButton.focus();
        }
    }

    getTooltipClasses(isVisible, singletonView) {
        let classes = 'culinary-recipe-detail-servings-tooltip-content';

        if (isVisible === false) {
            classes = `${classes} is-hidden`;
        }

        if (singletonView === true) {
            classes = `${classes} centered-small`;
        }

        return classes;
    }

    render() {
        const {title, content, isVisible, singletonView, clickHandler, keyEventHandler} = this.props;
        const tooltipClasses = this.getTooltipClasses(isVisible, singletonView);

        return (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div className={tooltipClasses} onKeyDown={keyEventHandler}>
                <button
                    aria-expanded={isVisible}
                    className="toggle-button"
                    onClick={clickHandler}
                    ref={(toggleButton) => {
                        this.toggleButton = toggleButton;
                    }}
                    type="button"
                >
                    <IconInfo />
                    <span className="label">{'Toggle More Information'}</span>
                </button>
                <div aria-hidden={!isVisible} className="body">
                    <div className="header">
                        <h5 className="title">{title}</h5>
                        <button
                            className="culinary-recipe-detail-servings-tooltip-content-close"
                            onClick={clickHandler}
                            type="button"
                        >
                            <IconClose />
                            <span className="label">{'Close Tooltip'}</span>
                        </button>
                    </div>
                    <p className="content">{content}</p>
                </div>
                <button className="cover-close-button" onClick={clickHandler} type="button">
                    <span className="label">{'Close Tooltip'}</span>
                </button>
            </div>
        );
    }
}

export default CulinaryRecipeDetailServingsTooltipContent;
