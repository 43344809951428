const authRequiredRoutes = ['/recipes-ideas/favorites'];

export const isAuthRequiredRoute = (pathname) => {
    // eslint-disable-next-line require-unicode-regexp
    const pattern = new RegExp(`^${pathname}$`, 'i');

    return authRequiredRoutes.some((authPathname) => authPathname.match(pattern));
};

export const getLoginRedirectUrl = (pathname, search, accountLoginUrl) => {
    const redirectUrl = encodeURIComponent(`${pathname}${search ? search : ''}`);

    return `${accountLoginUrl}${redirectUrl}`;
};
