import React from 'react';

import CulinaryFeatureActionBar from '../../modules/feature/culinary-feature-action-bar';
import CulinaryFeaturePreviewList from '../../modules/feature/culinary-feature-preview-list';
import CulinaryDetailTemplateBase from '../culinary-detail-template/culinary-detail-template-base';
import CulinaryDetailTemplateHd from '../culinary-detail-template/culinary-detail-template-hd';
import CulinaryFeatureEditorialBlock from '../../modules/feature/culinary-feature-editorial-block';
import CulinaryFeatureRelatedGuide from '../../modules/feature/related-content/culinary-feature-related-guide';
import CulinaryFeatureRelatedFooter from '../../modules/feature/related-content/culinary-feature-related-footer';

const CulinaryArticleListicleTemplate = (props) => {
    const {content, id} = props.contentReducer.urlContent;

    return (
        <CulinaryDetailTemplateBase {...props}>
            <article className="container">
                <CulinaryDetailTemplateHd ignoreChildren template="article-listicle" {...props} />
                <CulinaryFeatureActionBar
                    actions={props.actions}
                    alertReducer={props.alertReducer}
                    author={content.author}
                    id={id}
                    locationQuery={props.location.query}
                    metaData={content.metaData}
                    publishDate={content.updateDate}
                    router={props.router}
                    simpleLayout
                    stackAuthorOnMobile
                />
                <div className="culinary-detail-template-bd">
                    <div className="primary primary-tight">{props.children}</div>
                    <div className="supporting-content supporting-content-tight">
                        <div className="related">
                            <h3 className="heading">{'Related Ideas'}</h3>
                            <CulinaryFeaturePreviewList
                                featuredContentList={content.relatedEditorialContent}
                                layout="small"
                                listStyle="column"
                            />
                        </div>
                    </div>
                </div>
            </article>

            <CulinaryFeatureRelatedFooter
                environmentConfigReducer={props.environmentConfigReducer}
                noChildren={!content.featuredGuideContent && !content.latestCategoryContent}
                promoContent={content.promoContent}
            >
                <CulinaryFeatureEditorialBlock data={content.latestCategoryContent} />
                <CulinaryFeatureRelatedGuide
                    actions={props.actions}
                    alertReducer={props.alertReducer}
                    carouselState={props.carouselReducer.carousels}
                    favoritesReducer={props.favoritesReducer}
                    isAuthenticated={props.isAuthenticated}
                    modalReducer={props.modalReducer}
                    relatedGuideContent={content.featuredGuideContent}
                />
            </CulinaryFeatureRelatedFooter>
        </CulinaryDetailTemplateBase>
    );
};

export default CulinaryArticleListicleTemplate;
