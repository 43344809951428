import React from 'react';

import {reportListEvent} from '../../../../utilities/gtm-utilities';

import CulinaryAddToListFormFields from './culinary-add-to-list-form-fields';
import CulinaryAddToListFormError from './culinary-add-to-list-form-error';


const NEW_LIST_INPUT_ID = 'new-list';
const EXISTING_LIST_INPUT_ID = 'existing-user-list';

class CulinaryAddToListForm extends React.Component {
    static NEW_LIST_INPUT_ID = NEW_LIST_INPUT_ID;

    static EXISTING_LIST_INPUT_ID = EXISTING_LIST_INPUT_ID;

    constructor(props) {
        super(props);

        this.onClickAddHandler = (event) => this.onClickAdd(event);
    }

    componentDidMount() {
        this.props.actions.formInputUpdate({
            [EXISTING_LIST_INPUT_ID]: {
                id: '',
                value: ''
            }
        });
        this.props.actions.formInputUpdate({
            [NEW_LIST_INPUT_ID]: {
                id: '',
                value: ''
            }
        });
    }

    componentWillReceiveProps(nextProps) {
        const nextExistingListInput = nextProps.formInputReducer.inputs[EXISTING_LIST_INPUT_ID];
        const currentExistingListInput = this.props.formInputReducer.inputs[EXISTING_LIST_INPUT_ID];

        const nextExistingListInputValue = nextExistingListInput ? nextExistingListInput.value : '';
        const currentExistingListInputValue = currentExistingListInput ? currentExistingListInput.value : '';

        // If a new existing list has been selected, clear the new list input
        if (nextExistingListInputValue !== '' && nextExistingListInputValue !== currentExistingListInputValue) {
            this.props.actions.formInputUpdate({
                [NEW_LIST_INPUT_ID]: {
                    value: ''
                }
            });
        }
    }

    onClickAdd(event) {
        event.preventDefault();
        this.validateForm();
    }

    checkIfNewListExists() {
        let newListExists = false;

        this.props.userLists.forEach((list) => {
            if (list.name === this.props.formInputReducer.inputs[NEW_LIST_INPUT_ID].value) {
                newListExists = true;
            }
        });

        return newListExists;
    }

    validateForm() {
        if (
            !this.props.formInputReducer.inputs[NEW_LIST_INPUT_ID].value &&
            !this.props.formInputReducer.inputs[EXISTING_LIST_INPUT_ID].value &&
            this.props.userLists.length > 0
        ) {
            this.props.actions.addToListAddErrors({
                [EXISTING_LIST_INPUT_ID]: 'Select a list to add ingredients to'
            });
            this.props.actions.addToListRecentlyValidated(true);
        } else if (!this.props.formInputReducer.inputs[NEW_LIST_INPUT_ID].value && this.props.userLists.length === 0) {
            this.props.actions.addToListAddErrors({
                [NEW_LIST_INPUT_ID]: 'Create a list to add ingredients to'
            });
            this.props.actions.addToListRecentlyValidated(true);
        } else if (this.checkIfNewListExists()) {
            this.props.actions.addToListAddErrors({
                [NEW_LIST_INPUT_ID]: 'Oops, a list with that name already exists. Please create a new list name'
            });
            this.props.actions.addToListRecentlyValidated(true);
        } else {
            this.continueSubmittingForm();
            reportListEvent('Add to List');
        }
    }

    continueSubmittingForm() {
        const selectedIngredients = this.props.ingredientsToAdd;

        if (this.props.formInputReducer.inputs[NEW_LIST_INPUT_ID].value) {
            const selectedList = this.props.formInputReducer.inputs[NEW_LIST_INPUT_ID];

            this.props.actions.addToListCreateList(selectedList.value);
        } else {
            const selectedList = this.props.formInputReducer.inputs[EXISTING_LIST_INPUT_ID];

            this.props.actions.addToListSetListTitle(selectedList.value);

            selectedIngredients.forEach((ingredient) => {
                this.props.actions.addToListAddIngredient(selectedList.id, ingredient);
            });
        }
    }

    getIntroText() {
        return this.props.userLists.length > 0
            ? 'Select a shopping list or create a new one.'
            : 'Create your first shopping list.';
    }

    render() {
        return (
            <form className="culinary-add-to-list-form" noValidate>
                <div className="content">
                    <h3 className="heading">{'Add To List'}</h3>
                    <p className="intro">{this.getIntroText()}</p>
                    <CulinaryAddToListFormError addToListReducer={this.props.addToListReducer} />
                    <CulinaryAddToListFormFields
                        actions={this.props.actions}
                        addToListReducer={this.props.addToListReducer}
                        existingListInputId={EXISTING_LIST_INPUT_ID}
                        formInputReducer={this.props.formInputReducer}
                        newListInputId={NEW_LIST_INPUT_ID}
                        userLists={this.props.userLists}
                    />
                </div>
                <div className="actions">
                    <button className="secondary-button" onClick={this.props.onClickCancel} type="button">
                        {'Cancel'}
                    </button>
                    <button className="primary-button" onClick={this.onClickAddHandler} type="submit">
                        {'Add to List'}
                    </button>
                </div>
            </form>
        );
    }
}

export default CulinaryAddToListForm;
