import React from 'react';

import {setGtmFilterSort} from '../../../../utilities/gtm-utilities';
import DropdownMenu from '../../../global/dropdown/dropdown-menu';

import CulinarySearchFilterCheckbox from './culinary-search-filter-checkbox';

class CulinarySearchFilterBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeDropdownIndex: null
        };

        this.onClickWindowHandler = (event) => this.onClickWindow(event);
        this.onChangeRefinementFilterHandler = (event, title) => this.onChangeRefinementFilter(event, title);
        this.onToggleDropdownHandler = (toggle, refinementIndex) => this.onToggleDropdown(toggle, refinementIndex);
    }

    componentDidMount() {
        global.addEventListener('click', this.onClickWindowHandler);
    }

    componentWillUnmount() {
        global.removeEventListener('click', this.onClickWindowHandler);
    }

    onChangeRefinementFilter(event, title) {
        const filterId = event.target.getAttribute('data-filter-id');
        const filters = this.props.filters;
        const fieldName = event.target.value;
        const filterIndex = filters[fieldName].indexOf(filterId);

        if (event.target.checked) {
            this.props.addFilter({
                fieldName,
                filterId
            });

            setGtmFilterSort(title);
        } else {
            this.props.removeFilter({
                fieldName,
                filterIndex
            });
        }
    }

    onToggleDropdown(toggle, refinementIndex) {
        const refinementButton = toggle;
        const shouldExpand = refinementButton.getAttribute('aria-expanded') === 'false';

        refinementButton.setAttribute('aria-expanded', shouldExpand);
        refinementButton.setAttribute('aria-selected', shouldExpand);

        const activeDropdownIndex = shouldExpand ? refinementIndex : null;

        this.setState({activeDropdownIndex});
    }

    onClickWindow(event) {
        if (!this.culinarySearchFilterBar.contains(event.target)) {
            this.setState({activeDropdownIndex: null});
        }
    }

    render() {
        const dropdowns = this.props.dropdowns || [];
        const filters = this.props.filters;

        return (
            <div
                className="culinary-search-filter-bar"
                ref={(element) => {
                    this.culinarySearchFilterBar = element;
                }}
            >
                <div className="filter-label">{'Filter By'}</div>
                <div className="filter-list">
                    {dropdowns.map((dropdown) => {
                        const fieldName = dropdown.fieldName;
                        const hasFilter = Boolean(filters[fieldName]);
                        const dropdownCount = dropdown.options.reduce((count, filter) => {
                            if (hasFilter && filters[fieldName].includes(filter.id.toString())) {
                                return count + 1;
                            }

                            return count;
                        }, 0);

                        return (
                            <div key={fieldName}>
                                <DropdownMenu
                                    activeDropdownIndex={this.state.activeDropdownIndex}
                                    dropdownCount={dropdownCount}
                                    dropdownId={fieldName}
                                    onToggleDropdown={this.onToggleDropdownHandler}
                                    title={dropdown.title}
                                >
                                    <ul className="filter-list-options">
                                        {dropdown.options.map((refinement, refinementIndex) => {
                                            let filterIsActive = false;

                                            if (hasFilter) {
                                                filterIsActive = filters[fieldName].includes(refinement.id.toString());
                                            }

                                            return (
                                                <li key={`${fieldName}-${refinementIndex}`}>
                                                    <CulinarySearchFilterCheckbox
                                                        fieldName={fieldName}
                                                        filterId={refinement.id}
                                                        isActive={filterIsActive}
                                                        onChangeRefinementFilterHandler={
                                                            this.onChangeRefinementFilterHandler
                                                        }
                                                        title={refinement.title}
                                                    />
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </DropdownMenu>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default CulinarySearchFilterBar;
