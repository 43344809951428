const eventKeys = {
    DOWN: 40,
    ENTER: 13,
    ESC: 27,
    LEFT: 37,
    RIGHT: 39,
    TAB: 9,
    UP: 38
};

export default eventKeys;
