import {SET_SELECTED_VARIATION_INDEX} from '../action-types';

const getInitialState = () => ({
    selectedVariationIndex: 0
});

const setSelectedVariationIndex = (state, data) => {
    const selectedVariationIndex = data;

    return {
        ...state,
        selectedVariationIndex
    };
};

const reducerMap = {
    [SET_SELECTED_VARIATION_INDEX]: setSelectedVariationIndex
};

export default (state = getInitialState(), {data, type}) => {
    if (reducerMap[type]) {
        return reducerMap[type](state, data);
    }

    return state;
};

export {getInitialState};
