import React from 'react';
import {connect} from 'react-redux';

import IconEmail from '../../../global/icon/icon-email';
import {setGtmSharingPlatform} from '../../../../utilities/gtm-utilities';
import Platforms from '../../../../constants/platforms';

const mapStateToProps = (state) => ({
    serverSideLocation: state.serverSideLocation
});

export const CulinaryFeatureEmail = ({metaData, serverSideLocation, shareUrl, subTitle}) => {
    const titleEncoded = metaData.title ? encodeURIComponent(metaData.title) : '';
    const subTitleEncoded = subTitle ? encodeURIComponent(subTitle) : '';

    let link = global.window ? global.window.location.href : serverSideLocation.href;

    if (shareUrl) {
        const origin = global.window ? global.window.location.origin : serverSideLocation.origin;

        link = `${origin}${shareUrl}`;
    }

    const newLine = encodeURIComponent('\n\n');
    const emailMessageEncoded = metaData.emailMessage ? encodeURIComponent(metaData.emailMessage) : '';
    const bodyEncoded = subTitle ? `${titleEncoded}%20-%20${subTitleEncoded}` : titleEncoded;

    return (
        <a
            className="culinary-feature-email"
            href={`mailto:?subject=${titleEncoded}&body=${emailMessageEncoded}${newLine}${link}${newLine}${bodyEncoded}`}
            onClick={() => setGtmSharingPlatform(Platforms.Email)}
            rel="noopener noreferrer"
        >
            <span className="sharing-label">{'Email This Recipe'}</span>
            <IconEmail height="31" width="31" />
        </a>
    );
};

export default connect(mapStateToProps)(CulinaryFeatureEmail);
