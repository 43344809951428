import React from 'react';

import DropdownMenu from '../../../global/dropdown/dropdown-menu';
import DropdownOptions from '../../../global/dropdown/dropdown-options';

class CulinaryFavoritesSortBar extends React.Component {
    constructor(props) {
        super(props);

        this.sortFilters = {
            defaultOrder: 'Recently added',
            rating: 'Highest rated',
            totalTime: 'Total time',
            title: 'Title' // eslint-disable-line sort-keys
        };

        this.state = {
            activeDropdownIndex: null,
            optionLabel: 'Recently Added'
        };

        this.onClickWindowHandler = (event) => this.onClickWindow(event);
        this.onToggleDropdownHandler = (toggle, refinementIndex) => this.onToggleDropdown(toggle, refinementIndex);
    }

    componentDidMount() {
        global.addEventListener('click', this.onClickWindow);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.sortBy !== nextProps.sortBy) {
            const activeOption = this.sortFilters.find(
                (sortFilter) => sortFilter.replace(/\s/gu, '').toLowerCase() === nextProps.sortBy
            );

            this.setState({optionLabel: activeOption});
        }
    }

    componentWillUnmount() {
        global.removeEventListener('click', this.onClickWindow);
        this.props.actions.clearRecipeFavorites();
    }

    onToggleDropdown(toggle, refinementIndex) {
        const refinementButton = toggle;
        const shouldExpand = refinementButton.getAttribute('aria-expanded') === 'false';

        refinementButton.setAttribute('aria-expanded', shouldExpand);
        refinementButton.setAttribute('aria-selected', shouldExpand);

        const activeDropdownIndex = shouldExpand ? refinementIndex : null;

        this.setState({activeDropdownIndex});
    }

    onClickWindow = (event) => {
        if (this.culinaryFavoritesSortBar && !this.culinaryFavoritesSortBar.contains(event.target)) {
            this.setState({activeDropdownIndex: null});
        }
    };

    onClickOption = (event) => {
        this.setState({
            activeDropdownIndex: null,
            optionLabel: event.target.textContent
        });

        const sortKey = event.target.getAttribute('data-value');

        this.props.actions.sortRecipeFavorites(sortKey);
    };

    getHiddenTitleClass() {
        return this.props.totalResultsInActiveCategory <= 0 && !this.props.showDefaultTitle
            ? 'page-title-is-visually-hidden'
            : '';
    }

    render() {
        const hasResults = this.props.totalResultsInActiveCategory > 0;
        const hiddenTitleClass = this.getHiddenTitleClass();

        return (
            <div className="culinary-favorites-sort-bar">
                <div className="info">
                    <h2 className={`page-title ${hiddenTitleClass}`}>{this.props.pageTitle}</h2>
                    {hasResults && (
                        <div aria-live="polite" className={'result-count'}>
                            {'Displaying '}
                            <mark className="highlight">{this.props.visibleResultsLength}</mark>
                            {' of '}
                            <mark className="highlight">{this.props.totalResultsInActiveCategory}</mark>
                            {' results'}
                        </div>
                    )}
                </div>
                {hasResults && (
                    <div
                        className="sorter"
                        ref={(element) => {
                            this.culinaryFavoritesSortBar = element;
                        }}
                    >
                        <div className="label">{'Sort By'}</div>
                        <DropdownMenu
                            activeDropdownIndex={this.state.activeDropdownIndex}
                            dropdownId={'sortBy'}
                            onToggleDropdown={this.onToggleDropdownHandler}
                            title={this.state.optionLabel || Object.values(this.sortFilters)[0]}
                            type={'favorites'}
                        >
                            <DropdownOptions onClickOption={this.onClickOption} options={this.sortFilters} />
                        </DropdownMenu>
                    </div>
                )}
            </div>
        );
    }
}

export default CulinaryFavoritesSortBar;
