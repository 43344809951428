import React from 'react';

import IconFlame from '../../../../global/icon/icon-flame';

const CulinaryRecipeDetailPreStep = ({preStep}) => (
    <div className="culinary-recipe-detail-pre-step">
        <IconFlame />
        <div
            className="body"
            /* eslint-disable-next-line react/no-danger */
            dangerouslySetInnerHTML={{__html: preStep}}
        />
    </div>
);

export default CulinaryRecipeDetailPreStep;
