import React from 'react';

class CulinaryRecipeDetailRatingsIcon extends React.Component {
    constructor(props) {
        super(props);

        this.starNumber = props.index + 1;
        this.pluralizeStar = this.starNumber > 1 ? 'stars' : 'star';
        this.ratingNumberString = `Rate ${this.starNumber} ${this.pluralizeStar}`;
        this.showGradient = '';
        this.svgHoverClass = '';
        this.handlers = this.props.handlers;
        this.ratedClass = '';
        this.hoveredClass = '';
        this.classSwitch = '';
    }

    componentWillReceiveProps(nextProps) {
        this.svgHoverClass = this.stateClass(nextProps);
    }

    setRatedClass(userRating) {
        return userRating.rating >= this.starNumber ? ' activeRated' : ' inactiveRated';
    }

    setUnratedClass(ratingValue) {
        return ratingValue >= this.starNumber ? ' active' : ' inactive';
    }

    setHoveredClass(props) {
        return props.hoveredStarNum >= this.starNumber ? ' hovered' : ' unhovered';
    }

    setClassSwitch(props) {
        return props.isHovered ? this.hoveredClass : this.ratedClass;
    }

    getPressedState() {
        const recipeId = this.props.rating.recipeId;
        const userRating =
            this.props.userRating && this.props.userRating[`${recipeId}`]
                ? this.props.userRating[`${recipeId}`].rating
                : 0;

        if (userRating > this.props.index || (!userRating && this.props.value > 0)) {
            return true;
        }

        return false;
    }

    stateClass(props) {
        const hasStoredRating = props.userRating !== null;
        const hasUserRating = hasStoredRating && props.userRating[props.rating.recipeId];

        this.ratedClass = hasUserRating
            ? this.setRatedClass(props.userRating[props.rating.recipeId])
            : this.setUnratedClass(props.rating.value);
        this.hoveredClass = this.setHoveredClass(props);
        this.classSwitch = this.setClassSwitch(props);

        return this.classSwitch;
    }

    render() {
        return (
            <button
                aria-pressed={this.getPressedState()}
                className={`culinary-recipe-detail-ratings-icon${this.svgHoverClass}`}
                data-star-num={this.starNumber}
                onClick={this.props.clickRatingHandler}
                onMouseEnter={this.props.mouseEnterRatingHandler}
                onMouseLeave={this.props.mouseLeaveRatingHandler}
                ref={(ratingsIcon) => {
                    this.ratingsIcon = ratingsIcon;
                }}
                type="button"
            >
                <span className="rating-number">{this.ratingNumberString}</span>
                <svg focusable={false} viewBox="0 0 36.5 36.7" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                        <linearGradient id={`gradient-${this.starNumber}`}>
                            <stop offset={`${this.props.value}%`} stopColor="#e2231a" />
                            <stop offset={`${this.props.value}%`} stopColor="#ffffff" />
                        </linearGradient>
                    </defs>
                    <path
                        className="svg-outline"
                        d="M8.8 33.9l1.8-11-7.5-7.7 10.4-1.6 4.7-10.1L23 13.6l10.4 1.6-7.5 7.7 1.8 11-9.4-5.2-9.5 5.2z"
                    />
                    <path
                        className="svg-gradient"
                        d="M10.8 31.1l1.4-8.7-6-6.1 8.3-1.3 3.7-7.9L22 15l8.3 1.3-6 6.1 1.4 8.7-7.5-4.1-7.4 4.1z"
                        fill={`url(#gradient-${this.starNumber})`}
                    />
                    <path
                        className="svg-shape"
                        d="M10.8 31.1l1.4-8.7-6-6.1 8.3-1.3 3.7-7.9L22 15l8.3 1.3-6 6.1 1.4 8.7-7.5-4.1-7.4 4.1z"
                    />
                </svg>
            </button>
        );
    }
}

export default CulinaryRecipeDetailRatingsIcon;
