import React from 'react';

class DropdownRadio extends React.Component {
    constructor(props) {
        super(props);

        this.onChangeOptionHandler = (event) => this.onChangeOption(event);
    }

    shouldComponentUpdate(nextProps) {
        return !(nextProps.checkedValue === this.props.checkedValue);
    }

    onChangeOption(event) {
        if (event.currentTarget.checked) {
            this.props.actions.formInputUpdate({
                [this.props.dropdownId]: {
                    id: event.currentTarget.id,
                    value: event.currentTarget.value
                }
            });
        }
    }

    render() {
        return (
            <ul className="dropdown-radio">
                {this.props.options.map((option, optionIndex) => (
                    <li className="list-item" key={optionIndex}>
                        <input
                            aria-invalid={this.props.invalid}
                            checked={option.name === this.props.checkedValue}
                            className="radio-input"
                            id={option.id ? option.id : `${this.props.dropdownId}-${optionIndex}`}
                            name={this.props.dropdownId}
                            onChange={this.onChangeOptionHandler}
                            type="radio"
                            value={option.name}
                        />
                        <label
                            className="label"
                            htmlFor={option.id ? option.id : `${this.props.dropdownId}-${optionIndex}`}
                        >
                            {option.name}
                        </label>
                    </li>
                ))}
            </ul>
        );
    }
}

export default DropdownRadio;
