import React from 'react';

import CulinaryRecipeDetailVariationsTab from './culinary-recipe-detail-variations-tab';

const CulinaryRecipeDetailVariations = ({contentReducer, location, actions, selectedVariationIndex}) => {
    const variations = contentReducer.urlContent.content.variations;

    if (variations && variations.length > 1) {
        return (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
            <ul className="culinary-recipe-detail-variations" role="tablist">
                {variations.map((variation, index) => (
                    <CulinaryRecipeDetailVariationsTab
                        actions={actions}
                        key={variation.title}
                        location={location}
                        selectedVariationIndex={selectedVariationIndex}
                        tabIndex={index}
                        variation={variation}
                    />
                ))}
            </ul>
        );
    }

    return null;
};

export default CulinaryRecipeDetailVariations;
