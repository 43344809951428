import React from 'react';

class FeatureWysiwyg extends React.Component {
    componentDidMount() {
        const {rowId, columnId, data} = this.props;

        if (rowId !== undefined && columnId !== undefined) {
            let isValid = false;

            if (data) {
                isValid = true;
            }

            this.props.actions.pageBuilderSetValid({
                columnId,
                isValid,
                rowId
            });
        }
    }

    render() {
        return (
            <div
                className="feature-wysiwyg"
                /* eslint-disable-next-line react/no-danger */
                dangerouslySetInnerHTML={{__html: this.props.data}}
            />
        );
    }
}

export default FeatureWysiwyg;
