import {RESET_SELECTED_INGREDIENTS, UPDATE_CHECKED_ALL_INGREDIENTS, UPDATE_CHECKED_INGREDIENT} from '../action-types';

const getInitialState = () => ({
    ingredientSelections: {}
});

const getCheckedSelections = (ingredientSelection = [], data) => {
    const found = ingredientSelection.find((selection) => selection.id === data.id);

    if (found) {
        return ingredientSelection.filter((checkedItem) => checkedItem.id !== data.id);
    }

    return ingredientSelection.concat(data);
};

const updateCheckedIngredient = (state, ingredientData) => {
    const [variationIndex] = ingredientData.id.split('-');

    return {
        ...state,
        ingredientSelections: {
            ...state.ingredientSelections,
            [variationIndex]: getCheckedSelections(state.ingredientSelections[variationIndex], ingredientData)
        }
    };
};

const updateCheckedAllIngredients = (state, data) => {
    const {allIngredients, selectedVariationIndex} = data;

    return {
        ...state,
        ingredientSelections: {
            ...state.ingredientSelections,
            [selectedVariationIndex]: allIngredients
        }
    };
};

const reducerMap = {
    [RESET_SELECTED_INGREDIENTS]: getInitialState,
    [UPDATE_CHECKED_ALL_INGREDIENTS]: updateCheckedAllIngredients,
    [UPDATE_CHECKED_INGREDIENT]: updateCheckedIngredient
};

export default (state = getInitialState(), {data, type}) => {
    if (reducerMap[type]) {
        return reducerMap[type](state, data);
    }

    return state;
};

export {getInitialState};
