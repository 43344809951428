import React from 'react';

class CulinarySearchHeroBackground extends React.Component {
    constructor(props) {
        super(props);

        this.onLoadImageHandler = () => this.onLoadImage();

        this.state = {
            heroImageLoaded: false
        };
    }

    componentDidMount() {
        if (this.heroImage && this.heroImage.complete) {
            this.onLoadImage();
        }
    }

    onLoadImage() {
        this.setState({
            heroImageLoaded: true
        });
    }

    render() {
        const bgImage = {
            backgroundImage: `url('${this.props.media.url}')`
        };

        return (
            <div className="culinary-search-hero-background">
                <div
                    className={`hero-background${this.state.heroImageLoaded ? ' hero-background-loaded' : ''}`}
                    style={bgImage}
                />
                {this.props.media && (
                    <img
                        alt=""
                        className="hero-img"
                        onLoad={this.onLoadImageHandler}
                        ref={(node) => {
                            this.heroImage = node;
                        }}
                        src={this.props.media.url}
                    />
                )}
            </div>
        );
    }
}

export default CulinarySearchHeroBackground;
