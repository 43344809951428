import React from 'react';

import IconAlert from '../../global/icon/icon-dismiss';

class CulinaryAlert extends React.Component {
    constructor(props) {
        super(props);
        this.onClickDismissHandler = () => this.onClickDismiss();
    }

    onClickDismiss() {
        this.props.actions.closeAlert();
    }

    render() {
        const {alertReducer} = this.props;
        const activeClass = alertReducer.isActive ? 'is-active' : '';

        return (
            <div className={`culinary-alert ${activeClass}`}>
                <span aria-live="polite">{alertReducer.message}</span>
                <button className="button" onClick={this.onClickDismissHandler} type="button">
                    <IconAlert />
                    <span className="label">{'Dismiss alert'}</span>
                </button>
            </div>
        );
    }
}

export default CulinaryAlert;
