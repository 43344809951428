import React, {useRef, useState, useEffect} from 'react';
import IframeResizer from 'iframe-resizer-react';
import * as qs from 'qs';

import {featureToggleNames} from '../../../../constants/feature-toggles';

import CulinaryAddToListModalLogin from './culinary-add-to-list-modal-login';
import CulinaryAddToListModal from './culinary-add-to-list-modal';

const iFrameStyle = {
    bottom: 0,
    left: 0,
    minHeight: '100%',
    position: 'fixed',
    right: 0,
    top: 0,
    width: '100%',
    zIndex: 10
};

const getIframeUrl = (ingredientsToAdd, listModalUrl) => {
    const ingredientsArray = (ingredientsToAdd.map((ingredient) => ingredient.displayName)).filter(ingredient => ingredient);
    const stringifiedArray = JSON.stringify(ingredientsArray);
    const queryParam = qs.stringify({listItemsToAdd: stringifiedArray});

    return `${listModalUrl}?${queryParam}`;
};

const CulinaryAddToListBtn = ({
    actions,
    disabled,
    environmentConfigReducer,
    ingredientsToAdd,
    isAuthenticated,
    layoutType,
    modalTriggerId,
    modalReducer,
    recipeUrl
}) => {
    const iframeRef = useRef(null);
    const buttonRef = useRef(null);
    const [showIframe, setShowIframe] = useState(false);
    const [iframeInDom, setIframeInDom] = useState(true);
    const featureEnabled = environmentConfigReducer.activeFeatures[featureToggleNames.culinaryAOAddToList];
    const getComponentClass = () => {
        const baseClass = 'culinary-add-to-list-btn';

        return layoutType === 'block' ? `${baseClass} ${baseClass}-block` : baseClass;
    };
    const buttonOnPress = () => {
        if (featureEnabled) {
            document.documentElement.style.overflow = 'hidden';
            setShowIframe(true);
        } else {
            actions.addToListIngredientsToAdd(ingredientsToAdd);

            actions.modalUpdate({
                isActive: true,
                modalId: isAuthenticated ? CulinaryAddToListModal.ID : CulinaryAddToListModalLogin.ID,
                triggerId: modalTriggerId
            });
        }
    };

    useEffect(() => {
        if (
            modalReducer.isActive === false &&
            modalReducer.triggerId === modalTriggerId &&
            modalReducer.isRecentlyInactive === true
        ) {
            buttonRef.current.focus();

            actions.modalUpdate({
                isRecentlyInactive: false,
                modalId: '',
                triggerId: ''
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalReducer]);

    const addToListButton = () => {
        return featureEnabled && !isAuthenticated ?
        <a
            as='button'
            href={`${environmentConfigReducer.url.accountLogin}${recipeUrl}`}
        >
            <button
                aria-label={'Log in to Add to List'}
                className={getComponentClass()}
                data-testid="login-to-add-to-list-btn"
                disabled={disabled}
                ref={buttonRef}
                type='button'
            >
                <span className="label" style={{whiteSpace:'nowrap'}}>
                    <span className="text">{ 'Login to Add to List'}</span>
                    <span className="btn-icon">
                        <svg
                            className="icon-inner"
                            focusable="false"
                            viewBox="0 0 25 32.6"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M21.4 2.1h-2V.8c0-.2 0-.3-.1-.5S19 0 18.8 0c-.3 0-.7.3-.7.7v1.4h-4.9V.7c0-.4-.3-.7-.7-.7s-.7.3-.7.7v1.4h-5V.7c0-.4-.3-.7-.7-.7-.4 0-.7.3-.7.7v1.4H3.5C1.6 2.1 0 3.7 0 5.6v23.5c0 1.9 1.6 3.5 3.5 3.5h18.1c1.9 0 3.5-1.6 3.5-3.5V5.6c-.1-1.9-1.7-3.5-3.7-3.5zM6.2 5.8c.4 0 .7-.3.7-.7V3.6h4.8V5c0 .4.3.7.7.7s.7-.3.7-.7V3.6H18V5c0 .4.3.7.7.7.4 0 .7-.3.7-.7V3.6h2c1.1 0 2 .9 2 2v23.5c0 1.1-.9 2-2 2H3.5c-1.1 0-2-.9-2-2V5.6c0-1.1.9-2 2-2h2V5c0 .5.3.8.7.8z"
                            />
                            <path
                                d="M5.4 17.8c0 .4.3.7.7.7h11c.4 0 .7-.3.7-.7 0-.4-.3-.7-.7-.7h-11c-.4 0-.7.3-.7.7zM6.2 12.3h8.2c.4 0 .7-.3.7-.7 0-.4-.3-.7-.7-.7H6.2c-.4 0-.7.3-.7.7s.3.7.7.7zM14.4 23.3H6.2c-.4 0-.7.3-.7.7 0 .4.3.7.7.7h8.2c.4 0 .7-.3.7-.7 0-.4-.3-.7-.7-.7z"
                            />
                        </svg>
                    </span>
                </span>
            </button>
        </a>
        :
        <button
            aria-label={'Add to List'}
            className={getComponentClass()}
            data-testid="add-to-list-btn"
            disabled={disabled}
            onClick={buttonOnPress}
            ref={buttonRef}
            type="button"
        >
            <>
                <span className="label">
                    <span className="text">{'Add to List'}</span>
                    <span className="btn-icon">
                        <svg
                            className="icon-inner"
                            focusable="false"
                            viewBox="0 0 25 32.6"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M21.4 2.1h-2V.8c0-.2 0-.3-.1-.5S19 0 18.8 0c-.3 0-.7.3-.7.7v1.4h-4.9V.7c0-.4-.3-.7-.7-.7s-.7.3-.7.7v1.4h-5V.7c0-.4-.3-.7-.7-.7-.4 0-.7.3-.7.7v1.4H3.5C1.6 2.1 0 3.7 0 5.6v23.5c0 1.9 1.6 3.5 3.5 3.5h18.1c1.9 0 3.5-1.6 3.5-3.5V5.6c-.1-1.9-1.7-3.5-3.7-3.5zM6.2 5.8c.4 0 .7-.3.7-.7V3.6h4.8V5c0 .4.3.7.7.7s.7-.3.7-.7V3.6H18V5c0 .4.3.7.7.7.4 0 .7-.3.7-.7V3.6h2c1.1 0 2 .9 2 2v23.5c0 1.1-.9 2-2 2H3.5c-1.1 0-2-.9-2-2V5.6c0-1.1.9-2 2-2h2V5c0 .5.3.8.7.8z"
                            />
                            <path
                                d="M5.4 17.8c0 .4.3.7.7.7h11c.4 0 .7-.3.7-.7 0-.4-.3-.7-.7-.7h-11c-.4 0-.7.3-.7.7zM6.2 12.3h8.2c.4 0 .7-.3.7-.7 0-.4-.3-.7-.7-.7H6.2c-.4 0-.7.3-.7.7s.3.7.7.7zM14.4 23.3H6.2c-.4 0-.7.3-.7.7 0 .4.3.7.7.7h8.2c.4 0 .7-.3.7-.7 0-.4-.3-.7-.7-.7z"
                            />
                        </svg>
                    </span>
                </span>
                {iframeInDom  ? (
                    <IframeResizer
                        aria-label={'Add or Remove Items from List'}
                        data-testid="add-to-list-iframe"
                        forwardRef={iframeRef}
                        heightCalculationMethod="max"
                        inPageLinks
                        onMessage={(messageData) => {
                            const message = messageData.message;

                            if (message === 'close-lists-modal') {
                                document.documentElement.style.overflow = 'visible';
                                setShowIframe(false);
                                setIframeInDom(false);

                                setTimeout(() => {
                                    setIframeInDom(true);
                                }, 10);
                            }
                        }}
                        src={getIframeUrl(ingredientsToAdd, environmentConfigReducer.url.aoAddToList)}
                        style={{
                            ...iFrameStyle,
                            ...showIframe ? {display: 'block'} : {display: 'none'}
                        }}
                    />
                ) : null}
            </>
        </button>
    };

    return addToListButton();
};

export default CulinaryAddToListBtn;
