import {
    ADD_ALL_MEAL_IDEAS_TO_PRINT,
    ADD_MEAL_IDEA_TO_PRINT,
    MODAL_CLOSE,
    REMOVE_MEAL_IDEA_TO_PRINT,
    SET_PRINT_PREVIEW_ERROR
} from '../action-types';

const getInitialState = () => ({
    hasError: false,
    mealIdeasToPrint: []
});

const addAllMealIdeasToPrint = (state, mealIdeas) => {
    const mealIds = [];

    mealIdeas.forEach((meal) => {
        mealIds.push(meal.id);
    });

    return {
        ...state,
        mealIdeasToPrint: mealIds
    };
};

const addMealIdeaToPrint = (state, mealIdeaIdToAdd) => ({
    ...state,
    hasError: false,
    mealIdeasToPrint: [...state.mealIdeasToPrint, mealIdeaIdToAdd]
});

const removeMealIdeaToPrint = (state, mealIdeaIdToRemove) => {
    const newMealIdeasToPrint = state.mealIdeasToPrint.filter((id) => id !== mealIdeaIdToRemove);

    return {
        ...state,
        mealIdeasToPrint: newMealIdeasToPrint
    };
};

const setPrintPreviewError = (state, hasError) => ({
    ...state,
    hasError
});

const reducerMap = {
    [ADD_ALL_MEAL_IDEAS_TO_PRINT]: addAllMealIdeasToPrint,
    [ADD_MEAL_IDEA_TO_PRINT]: addMealIdeaToPrint,
    [MODAL_CLOSE]: setPrintPreviewError,
    [REMOVE_MEAL_IDEA_TO_PRINT]: removeMealIdeaToPrint,
    [SET_PRINT_PREVIEW_ERROR]: setPrintPreviewError
};

export default (initialState = getInitialState(), {data, type}) => {
    if (reducerMap[type]) {
        return reducerMap[type](initialState, data);
    }

    return initialState;
};

export {getInitialState};
