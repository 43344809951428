import clamp from '../services/math-service';
import {
    ADD_FILTER_GROUP,
    ADD_FILTER,
    CLEAR_SEARCH_RESULTS,
    INCREMENT_PAGE,
    REMOVE_ALL_FILTERS,
    REMOVE_FILTER,
    RESET_SEARCH,
    SET_QUERY_STRING,
    SET_SEARCH_IS_LOADING,
    SET_SEARCH_IS_LOADING_MORE,
    SET_SEARCH_RESULTS,
    SET_SORT_BY,
    SYNC_SEARCH_QUERY_WITH_STORE
} from '../action-types';

const getInitialState = () => ({
    currentPage: 0,
    displayResults: [],
    filters: {},
    metaData: {},
    pageSize: 12,
    queryString: '',
    relatedContent: [],
    results: [],
    searchIsLoading: true,
    searchIsLoadingMore: false,
    sortBy: '',
    totalPages: 0,
    totalResultCount: 0
});

const addFilterGroup = (state, {fieldName}) => ({
    ...state,
    filters: {
        ...state.filters,
        [fieldName]: []
    }
});

const addFilter = (state, {fieldName, filterId}) => ({
    ...state,
    currentPage: 0,
    displayResults: [],
    filters: {
        ...state.filters,
        [fieldName]: [...state.filters[fieldName], filterId]
    }
});

const removeFilter = (state, {filterIndex, fieldName}) => ({
    ...state,
    currentPage: 0,
    displayResults: [],
    filters: {
        ...state.filters,
        [fieldName]: [
            ...state.filters[fieldName].slice(0, filterIndex),
            ...state.filters[fieldName].slice(filterIndex + 1)
        ]
    }
});

const removeAllFilters = (state) => ({
    ...state,
    currentPage: 0,
    displayResults: [],
    filters: {}
});

const setQueryString = (state, queryString) => ({
    ...state,
    currentPage: 0,
    displayResults: [],
    queryString,
    sortBy: 'relevance'
});

const setSortBy = (state, sortBy) => ({
    ...state,
    currentPage: 0,
    displayResults: [],
    sortBy
});

const setSearchIsLoading = (state, searchIsLoading) => ({
    ...state,
    searchIsLoading
});

const setSearchIsLoadingMore = (state, searchIsLoadingMore) => ({
    ...state,
    searchIsLoadingMore
});

function getQueryFilters(query) {
    const queryNamesToIgnore = ['pageSize', 'q', 'sortBy', 'currentPage'];

    return Object.keys(query).reduce((hashObj, queryName) => {
        if (!queryNamesToIgnore.includes(queryName)) {
            hashObj[queryName] = query[queryName].split(','); // eslint-disable-line no-param-reassign
        }

        return hashObj;
    }, {});
}

const syncSearchQueryWithStore = (state, query) => {
    let sortBy = query.q ? 'relevance' : 'newest';

    if (query.sortBy) {
        sortBy = query.sortBy;
    }

    const emptyFilters = Object.keys(state.filters).reduce((obj, key) => {
        obj[key] = []; // eslint-disable-line no-param-reassign

        return obj;
    }, {});

    return {
        ...state,
        filters: {
            ...emptyFilters,
            ...getQueryFilters(query)
        },
        queryString: !query.q ? '' : query.q,
        sortBy
    };
};

const setSearchResults = (state, searchData) => ({
    ...state,
    ...searchData,
    displayResults: [...state.displayResults, ...searchData.results]
});

const clearSearchResults = (state) => ({
    ...state,
    results: []
});

const resetSearch = () => ({
    ...getInitialState()
});

const incrementPage = (state) => ({
    ...state,
    currentPage: clamp(state.currentPage + 1, 0, state.totalPages - 1)
});

const reducerMap = {
    [ADD_FILTER]: addFilter,
    [ADD_FILTER_GROUP]: addFilterGroup,
    [CLEAR_SEARCH_RESULTS]: clearSearchResults,
    [INCREMENT_PAGE]: incrementPage,
    [REMOVE_ALL_FILTERS]: removeAllFilters,
    [REMOVE_FILTER]: removeFilter,
    [RESET_SEARCH]: resetSearch,
    [SET_QUERY_STRING]: setQueryString,
    [SET_SEARCH_IS_LOADING]: setSearchIsLoading,
    [SET_SEARCH_IS_LOADING_MORE]: setSearchIsLoadingMore,
    [SET_SEARCH_RESULTS]: setSearchResults,
    [SET_SORT_BY]: setSortBy,
    [SYNC_SEARCH_QUERY_WITH_STORE]: syncSearchQueryWithStore
};

export default (state = getInitialState(), {data, type}) => {
    if (reducerMap[type]) {
        return reducerMap[type](state, data);
    }

    return state;
};

export {getInitialState};
