import React from 'react';

import SwapLink from '../../../global/hoc/swap-link';

/* eslint-disable react/no-danger */
class CulinaryFeatureBlockWithLinks extends React.Component {
    componentDidMount() {
        const {rowId, columnId, data} = this.props;

        if (rowId !== undefined && columnId !== undefined) {
            let isValid = false;

            if (data && (data.heading || data.description)) {
                isValid = true;
            }

            this.props.actions.pageBuilderSetValid({
                columnId,
                isValid,
                rowId
            });
        }
    }

    render() {
        const data = this.props.data;

        if (!data) {
            return null;
        }

        const styles = {
            backgroundImage: data.backgroundImage ? `url(${data.backgroundImage.url})` : 'none'
        };

        let labelMarkup = null;

        if (data.navLabel) {
            labelMarkup = <span className="label">{data.navLabel}</span>;
        }

        return (
            <div className="culinary-feature-block-with-links" style={styles}>
                <div className="content">
                    <h2 className="heading">{data.heading}</h2>

                    <div className="description" dangerouslySetInnerHTML={{__html: data.description}} />
                    {data.ctaLink && (
                        <SwapLink className={'button'} href={data.ctaLink.url} target={data.ctaLink.target}>
                            {data.ctaLink.title}
                        </SwapLink>
                    )}
                    {data.navLinks && (
                        <div className="links">
                            {labelMarkup}
                            <ul className="feature-link-list">
                                {data.navLinks.map((link, index) => (
                                    <li key={index}>
                                        <SwapLink className="link" href={link.url} target={link.target}>
                                            {link.title}
                                        </SwapLink>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
/* eslint-enable react/no-danger */

export default CulinaryFeatureBlockWithLinks;
