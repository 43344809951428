import React from 'react';

import CulinaryPrintButton from '../../modules/culinary-print-button';

class CulinaryDetailTemplateBase extends React.Component {
    constructor(props) {
        super(props);
        this.isPrinting = this.props.location.query.print === 'true';
    }

    render() {
        const {content} = this.props.contentReducer.urlContent;

        return (
            <main className="culinary-detail-template">
                {this.isPrinting && <CulinaryPrintButton />}
                {content.metaData && content.metaData.title && <h2 className="header">{content.metaData.title}</h2>}
                {this.props.children}
            </main>
        );
    }
}
export default CulinaryDetailTemplateBase;
