import throttle from 'lodash.throttle';

const createArrayWithElements = function() {
    const nodes = document.querySelectorAll('[data-wpa]');

    return [...nodes];
};

const initializeElements = function(nodes) {
    if (nodes && nodes.length > 0) {
        nodes.forEach((node) => {
            const animation = node.dataset.wpa;
            const animationDuration = node.dataset.wpaDuration;

            node.classList.add(`kf-${animation}`);

            if (animationDuration) {
                const element = node;

                element.style.animationDuration = `${animationDuration}ms`;
            }
        });
    }
};

const componentIsVisible = function(element, offset = 80) {
    const rect = element.getBoundingClientRect();
    const viewHeight = Math.max(document.documentElement.clientHeight, global.window.innerHeight);

    return !(rect.bottom < 0 || rect.top + offset - viewHeight >= 0);
};

const checkIfComponentIsVisible = function(nodes) {
    if (nodes && nodes.length > 0) {
        nodes.forEach((node) => {
            const wpaOffset = node.dataset.wpaOffset ? parseInt(node.dataset.wpaOffset) : undefined;

            if (componentIsVisible(node, wpaOffset)) {
                node.classList.add('kf-animated');
            }
        });
    }
};

export const isComponentVisible = (element, offset = 80) => componentIsVisible(element, offset);

export function initWaypoints() {
    const nodes = createArrayWithElements();

    initializeElements(nodes);

    global.window.addEventListener(
        'scroll',
        throttle(() => {
            checkIfComponentIsVisible(nodes);
        }, 100)
    );
}

export function removeWaypoints() {
    global.window.removeEventListener('scroll', throttle);
}
