import React from 'react';

import CulinaryEditorialCategoryCount from '../modules/editorial/culinary-editorial-category-count';
import CulinaryFeature2up from '../modules/feature/culinary-feature-2up';
import CulinaryFeatureCardMedia from '../modules/feature/culinary-feature-card-media';
import FeatureCategories from '../../feature/feature-categories';
import CulinaryFeatureEditorialContentLoader from '../modules/feature/culinary-feature-editorial-content-loader';
import CulinaryFeatureRelatedContent from '../modules/feature/related-content/culinary-feature-related-content';
import CulinaryFeatureRelatedFooter from '../modules/feature/related-content/culinary-feature-related-footer';
import FeatureSearchBar from '../../feature/feature-search-bar';
import CulinaryFeatureCardGuide from '../modules/feature/cards/culinary-feature-card-guide';
import ApiQueryService from '../../../services/api/api-query-service';

class CulinaryEditorialCategoryTemplate extends React.Component {
    constructor(props) {
        super(props);

        this.hasSearchQuery = ApiQueryService.hasSearchQuery(this.props);
    }

    componentWillMount() {
        if (this.hasSearchQuery) {
            this.props.actions.syncSearchQueryWithStore(this.props.router.location.query);
        }
    }

    componentWillReceiveProps(nextProps) {
        this.hasSearchQuery = ApiQueryService.hasSearchQuery(nextProps);
    }

    render() {
        const {content} = this.props.contentReducer.urlContent;

        let secondaryFeatures;

        if (content.featuredContent.length > 1) {
            secondaryFeatures = {
                featureOne: content.featuredContent[1],
                featureTwo: content.featuredContent[2]
            };
        }

        return (
            <main className="culinary-editorial-category-template">
                <div className="intro">
                    <h2 className="page-title">{content.heading}</h2>
                    <div className="search">
                        <h3 className="heading">{'Search Editorial Content'}</h3>
                        <FeatureSearchBar
                            autocompleteResults={this.props.autocompleteReducer.results}
                            classExt={'editorial'}
                            formAction="/recipes-ideas/advice-how-tos/search"
                            hasSearchQuery={this.hasSearchQuery}
                            placeholder={'I want to learn about...'}
                            searchType={'editorial'}
                        />
                    </div>
                </div>
                <div className="container">
                    <div className="main">
                        <div className="primary">
                            <CulinaryEditorialCategoryCount
                                allResults={this.props.contentLoaderReducer.allResults}
                                featuredContentCount={content.featuredContent.length}
                                subsetResults={this.props.contentLoaderReducer.subsetResults}
                            />
                            <h3 className="heading">{`${content.heading} content`}</h3>
                            {content.featuredContent && (
                                <div className="primary-feature">
                                    {content.featuredContent[0] && content.featuredContent[0].type === 'Guide' && (
                                        <CulinaryFeatureCardGuide cardGuide={content.featuredContent[0]} isWide />
                                    )}
                                    {content.featuredContent[0] && content.featuredContent[0].type !== 'Guide' && (
                                        <CulinaryFeatureCardMedia
                                            content={content.featuredContent[0]}
                                            imageHeight={'825'}
                                            imageWidth={'1100'}
                                            isStacked
                                            layout={'feature-primary'}
                                            showDescription
                                        />
                                    )}
                                </div>
                            )}
                            {secondaryFeatures && (
                                <div className="editorial-blocks">
                                    <CulinaryFeature2up feature2up={secondaryFeatures} />
                                </div>
                            )}
                            <CulinaryFeatureEditorialContentLoader
                                currentPage={this.props.contentLoaderReducer.currentPage}
                                editorialList={this.props.contentLoaderReducer.subsetResults}
                                focusIndex={this.props.contentLoaderReducer.focusIndex}
                                getPagedResults={this.props.actions.getPagedResults}
                                paginationAmount={this.props.contentLoaderReducer.paginationAmount}
                                totalResults={this.props.contentLoaderReducer.totalResults}
                            />
                        </div>
                        <div className="supporting-content">
                            <FeatureCategories
                                categories={content.editorialNavigationLinks}
                                className={'culinary-editorial-category-sidebar'}
                                showPageState
                                urlContentPath={this.props.contentReducer.urlContentPath}
                            />
                        </div>
                    </div>
                </div>
                <CulinaryFeatureRelatedFooter
                    environmentConfigReducer={this.props.environmentConfigReducer}
                    noChildren={!content.relatedRecipes || content.relatedRecipes.length === 0}
                    promoContent={content.promoContent}
                >
                    {content.relatedRecipes && content.relatedRecipes.length > 0 && (
                        <CulinaryFeatureRelatedContent
                            actions={this.props.actions}
                            alertReducer={this.props.alertReducer}
                            carouselState={this.props.carouselReducer.carousels}
                            favoritesReducer={this.props.favoritesReducer}
                            isAuthenticated={this.props.isAuthenticated}
                            modalReducer={this.props.modalReducer}
                            relatedContent={content.relatedRecipes}
                            sectionTitle={'Recipes you might like'}
                        />
                    )}
                </CulinaryFeatureRelatedFooter>
            </main>
        );
    }
}

export default CulinaryEditorialCategoryTemplate;
