import React from 'react';

import CulinaryFavoritesModalConfirmDelete from '../favorites/culinary-favorites-modal-confirm-delete/culinary-favorites-modal-confirm-delete';
import IconHeartSmall from '../../../global/icon/icon-heart-small';
import IconHeartLarge from '../../../global/icon/icon-heart-large';

import CulinaryFavoriteModalLogin from './culinary-favorite-modal-login';

class CulinaryFavoriteBtn extends React.Component {
    constructor(props) {
        super(props);

        this.modalTriggerId = this.props.modalTriggerId;

        this.timeoutHandler = null;
        this.modalTriggerButton = null;

        this.state = {
            isSaved: false,
            saving: false,
            unsaving: false
        };

        this.onClickSaveHandler = (event) => this.onClickSave(event);
    }

    componentDidMount() {
        this.updateModal();
    }

    componentWillReceiveProps(nextProps) {
        const nextFavoritesReducer = nextProps.favoritesReducer;
        const isSaved =
            nextFavoritesReducer.savedRecipeIds && nextFavoritesReducer.savedRecipeIds.includes(this.props.id);

        if (
            nextFavoritesReducer.saveRecipeError &&
            !this.props.favoritesReducer.saveRecipeError &&
            this.props.id === nextFavoritesReducer.activeFavoriteId
        ) {
            this.props.actions.createAlert({
                isActive: true,
                message: 'There was an issue saving this recipe. Please try again.',
                type: 'error'
            });
            this.props.actions.clearFavoritingErrors();
        } else if (
            nextFavoritesReducer.deleteRecipeError &&
            !this.props.favoritesReducer.deleteRecipeError &&
            this.props.id === nextFavoritesReducer.activeFavoriteId
        ) {
            this.props.actions.createAlert({
                isActive: true,
                message: 'There was an issue removing this recipe from favorites. Please try again.',
                type: 'error'
            });
            this.props.actions.clearFavoritingErrors();
        }

        this.setState({
            isSaved
        });
    }

    componentDidUpdate() {
        this.updateModal();
    }

    componentWillUnmount() {
        clearTimeout(this.timeoutHandler);
    }

    updateModal() {
        const modalReducer = this.props.modalReducer;

        if (
            modalReducer &&
            modalReducer.isActive === false &&
            modalReducer.triggerId === this.modalTriggerId &&
            modalReducer.isRecentlyInactive === true
        ) {
            this.modalTriggerButton.focus();

            this.props.actions.modalUpdate({
                isRecentlyInactive: false,
                modalId: '',
                triggerId: ''
            });
        }
    }

    getMessageStrings() {
        const animating = this.state.saving || this.state.unsaving;
        const inProgressMessages = animating && this.state.saving ? 'Saving' : 'Unsaving';
        const completeMessages = this.state.isSaved ? 'Saved!' : 'Unsaved';

        return animating ? inProgressMessages : completeMessages;
    }

    getStateStrings() {
        return {
            actionLabel: this.state.isSaved ? 'Remove from' : 'Add to',
            message: this.getMessageStrings()
        };
    }

    getStateClasses() {
        return {
            activeClass: this.state.isSaved ? ' culinary-favorite-btn-active' : '',
            savingClass: this.state.saving ? ' culinary-favorite-btn-saving' : '',
            unsavingClass: this.state.unsaving ? ' culinary-favorite-btn-unsaving' : ''
        };
    }

    onClickSave() {
        if (!this.props.isAuthenticated) {
            this.promptLogin();
        } else if (this.props.showConfirmation) {
            this.props.actions.setDeleteData(this.props.recipeTitle, this.props.cardIndex, this.props.id);
            this.props.actions.modalUpdate({
                isActive: true,
                modalId: CulinaryFavoritesModalConfirmDelete.ID,
                triggerId: this.modalTriggerId
            });
        } else {
            if (this.props.alertReducer.isActive) {
                this.props.actions.closeAlert();
            }

            this.updateSaveState();
        }
    }

    promptLogin() {
        this.props.actions.modalUpdate({
            isActive: true,
            modalId: CulinaryFavoriteModalLogin.ID,
            triggerId: this.modalTriggerId
        });
    }

    updateSaveState() {
        if (this.state.isSaved) {
            this.props.actions.deleteRecipe(this.props.id);
            this.setState({
                isSaved: false,
                saving: false,
                unsaving: true
            });
        } else {
            this.props.actions.saveRecipe(this.props.id);
            this.setState({
                isSaved: true,
                saving: true,
                unsaving: false
            });
        }

        clearTimeout(this.timeoutHandler);

        if (this.state.saving) {
            this.timeoutHandler = setTimeout(() => {
                this.setState({
                    saving: false
                });
            }, 1000);
        }

        if (this.state.unsaving) {
            this.timeoutHandler = setTimeout(() => {
                this.setState({
                    unsaving: false
                });
            }, 1000);
        }
    }

    getMessageMarkup(message) {
        return (
            <span aria-live="polite" className="message">
                {message}
            </span>
        );
    }

    getPreventFocus() {
        return this.props.preventFocus ? -1 : 0;
    }

    getHeartIconLayout() {
        return this.props.layoutType === 'large' ? <IconHeartLarge /> : <IconHeartSmall />;
    }

    render() {
        const {actionLabel, message} = this.getStateStrings();
        const {activeClass, savingClass, unsavingClass} = this.getStateClasses();

        return (
            <button
                aria-label={`${actionLabel} Favorites`}
                aria-pressed={this.state.isSaved}
                className={`culinary-favorite-btn ${activeClass}${savingClass}${unsavingClass}`}
                data-id={this.modalTriggerId}
                onClick={this.onClickSaveHandler}
                ref={(node) => {
                    this.modalTriggerButton = node;
                }}
                tabIndex={this.getPreventFocus()}
                type="button"
            >
                <span className="icon icon_heart">{this.getHeartIconLayout()}</span>
                {this.getMessageMarkup(message)}
            </button>
        );
    }
}

export default CulinaryFavoriteBtn;
