import {
    ADD_TO_LIST_ADD_ERRORS,
    ADD_TO_LIST_CLEAR_ERRORS,
    ADD_TO_LIST_CLEAR_STATUS,
    ADD_TO_LIST_INGREDIENTS_TO_ADD,
    ADD_TO_LIST_RECENTLY_VALIDATED,
    ADD_TO_LIST_SET_BUSY_ADDING,
    ADD_TO_LIST_SET_BUSY_LOADING,
    ADD_TO_LIST_SET_FAIL,
    ADD_TO_LIST_SET_LIST_TITLE,
    ADD_TO_LIST_SET_SUCCESS_ADDED,
    ADD_TO_LIST_SET_SUCCESS_LIST_CREATED,
    ADD_TO_LIST_SET_SUCCESS_LOADED,
    ADD_TO_LIST_SET_SUCCESS_RELOADED
} from '../action-types';

const INITIAL_STATUS = {
    isBusyAdding: false,
    isBusyLoading: false,
    isFail: false,
    isRecentlyValidated: false,
    isSuccessAdded: false,
    isSuccessListCreated: false,
    isSuccessLoaded: false,
    isSuccessReloaded: false
};

const getInitialState = () => ({
    ...INITIAL_STATUS,
    errors: {},
    ingredientsToAdd: [],
    listTitle: '',
    userLists: {}
});

const addToListAddErrors = (state, errorData) => ({
    ...state,
    errors: {
        ...errorData
    }
});

const addToListClearErrors = (state) => ({
    ...state,
    errors: {}
});

const addToListRecentlyValidated = (state, status) => ({
    ...state,
    isRecentlyValidated: status
});

const addToListSetFail = (state) => ({
    ...state,
    ...INITIAL_STATUS,
    isFail: true
});

const addToListSetBusyAdding = (state) => ({
    ...state,
    ...INITIAL_STATUS,
    isBusyAdding: true
});

const addToListSetBusyLoading = (state) => ({
    ...state,
    ...INITIAL_STATUS,
    isBusyLoading: true
});

const addToListSetSuccessAdded = (state) => ({
    ...state,
    ...INITIAL_STATUS,
    isSuccessAdded: true
});

const addToListSetSuccessListCreated = (state) => ({
    ...state,
    ...INITIAL_STATUS,
    isSuccessListCreated: true
});

const addToListSetSuccessLoaded = (state, userLists) => ({
    ...state,
    ...INITIAL_STATUS,
    isSuccessLoaded: true,
    userLists
});

const addToListSetSuccessReloaded = (state, userLists) => ({
    ...state,
    ...INITIAL_STATUS,
    isSuccessReloaded: true,
    userLists
});

const addToListIngredientsToAdd = (state, ingredientsToAdd) => ({
    ...state,
    ingredientsToAdd
});

const addToListClearStatus = (state) => ({
    ...state,
    ...INITIAL_STATUS,
    listTitle: '',
    userLists: {}
});

const addToListSetListTitle = (state, listTitle) => ({
    ...state,
    listTitle
});

const reducerMap = {
    [ADD_TO_LIST_ADD_ERRORS]: addToListAddErrors,
    [ADD_TO_LIST_CLEAR_ERRORS]: addToListClearErrors,
    [ADD_TO_LIST_CLEAR_STATUS]: addToListClearStatus,
    [ADD_TO_LIST_INGREDIENTS_TO_ADD]: addToListIngredientsToAdd,
    [ADD_TO_LIST_RECENTLY_VALIDATED]: addToListRecentlyValidated,
    [ADD_TO_LIST_SET_BUSY_ADDING]: addToListSetBusyAdding,
    [ADD_TO_LIST_SET_BUSY_LOADING]: addToListSetBusyLoading,
    [ADD_TO_LIST_SET_FAIL]: addToListSetFail,
    [ADD_TO_LIST_SET_LIST_TITLE]: addToListSetListTitle,
    [ADD_TO_LIST_SET_SUCCESS_ADDED]: addToListSetSuccessAdded,
    [ADD_TO_LIST_SET_SUCCESS_LIST_CREATED]: addToListSetSuccessListCreated,
    [ADD_TO_LIST_SET_SUCCESS_LOADED]: addToListSetSuccessLoaded,
    [ADD_TO_LIST_SET_SUCCESS_RELOADED]: addToListSetSuccessReloaded
};

export default (state = getInitialState(), {data, type}) => {
    if (reducerMap[type]) {
        return reducerMap[type](state, data);
    }

    return state;
};

export {getInitialState, INITIAL_STATUS};
