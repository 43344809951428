import React from 'react';
import {checkElementsInViewport} from 'react-lazy';

import CulinaryFeatureFeaturedIngredientsTab from './culinary-feature-featured-ingredients-tab';
import CulinaryFeatureFeaturedIngredientsPanel from './culinary-feature-featured-ingredients-panel';

class CulinaryFeatureFeaturedIngredients extends React.Component {
    constructor(props) {
        super(props);

        this.ingredientsNavMarkup = null;
        this.ingredientsPanelMarkup = null;

        this.state = {
            selectedTabIndex: 0
        };
    }

    componentDidMount() {
        const {rowId, columnId, data} = this.props;

        if (rowId !== undefined && columnId !== undefined) {
            let isValid = false;

            if (data && data.ingredients && data.ingredients.length > 0) {
                isValid = true;
            }

            this.props.actions.pageBuilderSetValid({
                columnId,
                isValid,
                rowId
            });
        }
    }

    selectTab = (event) => {
        const tabIndex = Number.parseInt(event.currentTarget.dataset.tabIndex);

        checkElementsInViewport();

        this.setState({
            selectedTabIndex: tabIndex
        });
    };

    getPanelStyle() {
        const {selectedTabIndex} = this.state;

        if (selectedTabIndex > 0) {
            return {
                transform: `translateX(-${selectedTabIndex}00%)`
            };
        }

        return {};
    }

    render() {
        if (this.props.data.ingredients) {
            this.ingredientsNavMarkup = this.props.data.ingredients.map((ingredient, index) => (
                <li key={index}>
                    <CulinaryFeatureFeaturedIngredientsTab
                        index={index}
                        ingredient={ingredient}
                        onClickHandler={this.selectTab}
                        selectedTabIndex={this.state.selectedTabIndex}
                    />
                </li>
            ));

            this.ingredientsPanelMarkup = this.props.data.ingredients.map((ingredient, index) => (
                <CulinaryFeatureFeaturedIngredientsPanel
                    actions={this.props.actions}
                    alertReducer={this.props.alertReducer}
                    favoritesReducer={this.props.favoritesReducer}
                    index={index}
                    ingredient={ingredient}
                    isAuthenticated={this.props.isAuthenticated}
                    key={index}
                    modalReducer={this.props.modalReducer}
                    selectedTabIndex={this.state.selectedTabIndex}
                />
            ));
        }

        return (
            <section className="culinary-feature-featured-ingredients">
                <h3 className="title">{this.props.data.heading}</h3>
                <h4 className="tab-navigation-title">{'Tab Navigation'}</h4>

                <ul
                    className="tab-navigation"
                    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                    role="tablist"
                >
                    {this.ingredientsNavMarkup}
                </ul>
                <div className="tab-panels">
                    <div className="panels" style={this.getPanelStyle()}>
                        {this.ingredientsPanelMarkup}
                    </div>
                </div>
            </section>
        );
    }
}

export default CulinaryFeatureFeaturedIngredients;
