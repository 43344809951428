const fractionType = {
    '0.125': '\u215B',
    '0.250': '&frac14;',
    '0.333': '\u2153',
    '0.375': '\u215C',
    '0.500': '&frac12;',
    '0.625': '\u215D',
    '0.666': '\u2154',
    '0.750': '&frac34;',
    '0.875': '\u215E'
};

export default fractionType;
