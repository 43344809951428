import React from 'react';

import CulinaryLoadingList from '../loading/culinary-loading-list';

const CulinaryAddToListLoading = ({label}) => (
    <div className="culinary-add-to-list-loading">
        <div className="content">
            <h3 className="heading">{'Add To List'}</h3>
            <CulinaryLoadingList label={label} />
        </div>
    </div>
);

export default CulinaryAddToListLoading;
