import React from 'react';

import {isMaxBreakpoint} from '../../../../../services/breakpoint-service';

import CulinaryFavoritesNavItem from './culinary-favorites-nav-item';

class CulinaryFavoritesNav extends React.Component {
    constructor(props) {
        super(props);

        this.onClickToggleHandler = (event) => this.onClickToggle(event);

        this.state = {
            categoryIndex: 'all',
            isActive: false
        };

        this.sectionStyle = {
            display: 'none'
        };
    }

    componentDidMount() {
        require('velocity-animate');
    }

    onClickToggle() {
        this.animateSection();

        this.setState((prevState) => ({
            isActive: !prevState.isActive
        }));
    }

    onClickSection = (event, courseType) => {
        const targetIndex = event.target.dataset.index;

        this.props.actions.setActiveCourseType(courseType);
        this.props.actions.sortRecipeFavorites(this.props.activeSortKey);

        this.setState({
            categoryIndex: targetIndex
        });
    };

    animateSection() {
        const isMobile = isMaxBreakpoint('sm');
        const action = this.state.isActive ? 'slideUp' : 'slideDown';

        if (isMobile) {
            /* eslint-disable-next-line no-undef, new-cap */
            Velocity(this.section, action, {
                duration: 300,
                easing: 'easeInOutQuad'
            });
        }
    }

    getToggleStateClass() {
        return this.state.isActive ? 'toggle is-active' : 'toggle';
    }

    render() {
        if (!this.props.courseTypes) {
            return null;
        }

        return (
            <nav className="culinary-favorites-nav">
                <h3 className="heading">
                    {'My Recipes'}
                    <span className="screenreader">{'By course type navigation'}</span>
                </h3>
                <button
                    className={this.getToggleStateClass()}
                    onClick={this.onClickToggleHandler}
                    ref={(node) => {
                        this.toggle = node;
                    }}
                    type="button"
                >
                    <span className="label">{'My Recipes'}</span>
                    <svg focusable={false} height="10" viewBox="0 0 10 10" width="10">
                        <path className="plus" d="M5.7 0H4.3v10h1.3V0z" />
                        <path className="minus" d="M10 4.3H0v1.3h10V4.3z" />
                    </svg>
                </button>
                <ul
                    className="section"
                    ref={(node) => {
                        this.section = node;
                    }}
                    style={this.sectionStyle}
                >
                    <CulinaryFavoritesNavItem
                        categoryIndex={this.state.categoryIndex}
                        count={this.props.totalResults}
                        itemIndex="all"
                        layoutType="primary"
                        onClickSectionHandler={this.onClickSection}
                        title={'Saved Recipes'}
                    />
                    <ul className="list">
                        {this.props.courseTypes.map((courseType, index) => (
                            <li className="item" key={index}>
                                <CulinaryFavoritesNavItem
                                    {...courseType}
                                    categoryIndex={this.state.categoryIndex}
                                    itemIndex={index}
                                    onClickSectionHandler={this.onClickSection}
                                />
                            </li>
                        ))}
                    </ul>
                </ul>
            </nav>
        );
    }
}

export default CulinaryFavoritesNav;
