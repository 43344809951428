import React from 'react';

import CulinaryFeatureFeaturedIngredients from '../views/culinary/modules/feature/featured-ingredients/culinary-feature-featured-ingredients';
import CulinaryFeatureExpertTip from '../views/culinary/modules/feature/culinary-feature-expert-tip';
import FeatureFlexibleList from '../views/feature/flexible-lists/feature-flexible-list';
import FeatureWysiwyg from '../views/feature/feature-wysiwyg';
import CulinaryFeatureFullWidthIntro from '../views/culinary/modules/feature/culinary-feature-full-width-intro';
import CulinaryFeatureTabs from '../views/culinary/modules/feature/tabs/culinary-feature-tabs';
import FeatureIntroBlock from '../views/feature/feature-intro-block';
import FeaturePrimaryImage from '../views/feature/feature-primary-image';
import FeatureMultiColumnLeadIn from '../views/feature/feature-multi-column-lead-in';
import FeatureVideoBlock from '../views/feature/feature-video-block';
import CulinaryFeaturedRecipeCarousel from '../views/culinary/modules/feature/recipe-carousel/culinary-feature-recipe-carousel';
import CulinaryFeatureRelatedStandalone from '../views/culinary/modules/feature/culinary-feature-related-standalone';
import CulinaryFeatureBlockWithLinks from '../views/culinary/modules/feature/culinary-feature-block-with-links';
import CulinaryGuideRecipeHighlight from '../views/culinary/modules/feature/culinary-guide-recipe-highlight';
import CulinaryFeatureSplitContent from '../views/culinary/modules/feature/culinary-feature-split-content';
import CulinaryFeatureStory from '../views/culinary/modules/feature/culinary-feature-story';
import CulinaryFeatureBasicCardListing from '../views/culinary/modules/feature/culinary-feature-basic-card-listing';

/**
 * Mapping of aliases to their corresponding components. Additionally map
 * "layout types" based on the container width
 */
const map = {
    culinaryBasicCardListing: {
        component: CulinaryFeatureBasicCardListing,
        layouts: {
            column: [3, 4],
            full: [12],
            half: [6],
            'three-quarters': [8, 9]
        }
    },
    culinaryExpertTip: {
        component: CulinaryFeatureExpertTip,
        layouts: {
            'feature-row': [7, 8, 9, 10, 11, 12],
            'feature-stacked': [1, 2, 3, 4, 5, 6]
        }
    },
    culinaryFeaturedEditorialContent: {
        component: CulinaryFeatureRelatedStandalone
    },
    culinaryFeaturedIngredients: {
        component: CulinaryFeatureFeaturedIngredients
    },
    culinaryFeaturedRecipeCarousel: {
        component: CulinaryFeaturedRecipeCarousel,
        layouts: null
    },
    culinaryFeaturedWithSubNav: {
        component: CulinaryFeatureBlockWithLinks,
        layouts: null
    },
    culinaryFlexibleListTextOnly: {
        component: FeatureFlexibleList,
        layouts: {
            column: [3, 4],
            row: [6, 8, 9],
            'row full': [12]
        }
    },
    culinaryFlexibleListWithImages: {
        component: FeatureFlexibleList,
        layouts: {
            column: [3, 4],
            row: [6, 8, 9],
            'row full': [12]
        }
    },
    culinaryFullWidthContentStory: {
        component: CulinaryFeatureStory,
        layout: null
    },
    culinaryFullWidthStory: {
        component: CulinaryFeatureFullWidthIntro,
        layouts: null
    },
    culinaryIntroText: {
        component: FeatureIntroBlock,
        layouts: null
    },
    culinaryMultiColumnLeadIn: {
        component: FeatureMultiColumnLeadIn,
        layouts: {
            fourth: [3],
            full: [12],
            half: [6]
        }
    },
    culinaryRecipeHighlight: {
        component: CulinaryGuideRecipeHighlight,
        layouts: null
    },
    culinarySplitFeaturedRecipes: {
        component: CulinaryFeatureSplitContent,
        layouts: {
            full: [12],
            half: [6]
        }
    },
    culinaryTabbedContent: {
        component: CulinaryFeatureTabs,
        layouts: {
            full: [12],
            half: [6]
        }
    },
    culinaryWYSIWYG: {
        component: FeatureWysiwyg,
        layouts: {
            row: [3, 4, 6, 9, 12]
        }
    },
    imageBlock: {
        component: FeaturePrimaryImage,
        layouts: {
            'page-builder': [3, 4, 6, 12]
        }
    },
    videoBlock: {
        component: FeatureVideoBlock,
        layouts: {
            full: [12],
            regular: [3, 4, 6]
        }
    }
};

class ComponentFactory extends React.Component {
    getType(alias) {
        return map[alias].layouts ? Object.keys(map[alias].layouts)[0] : null;
    }

    /**
     * Responsible for determining the layout type based on the width of
     * the components container. This is determined by a previous defined
     * mapping.
     *
     * @param {string} alias The CMS based alias for the component (mapping key)
     * @param {number} width The width of the modules container
     * @returns {string}
     */
    getLayoutType(alias, width) {
        let type = this.getType(alias);

        // eslint-disable-next-line no-unused-vars
        for (const layout in map[alias].layouts) {
            if (map[alias].layouts[layout].includes(parseInt(width))) {
                type = layout;
            }
        }

        return type;
    }

    /**
     * Responsible for dynamically chosing a component to render based on
     * the CMS data supplied for the component.
     */
    render() {
        const {columnId, component, rowId, width, templateData} = this.props;

        if (!component) {
            return null;
        }

        const alias = component.alias;

        if (!map.hasOwnProperty(alias)) {
            return null;
        }

        const content = component.content;
        const layout = this.getLayoutType(alias, width);
        const Component = map[alias].component;

        return (
            <Component
                columnId={columnId}
                data={content}
                dropLinkText={component.dropLinkText}
                layoutType={layout}
                rowId={rowId}
                {...templateData}
            />
        );
    }
}

export default ComponentFactory;
