import React from 'react';

import {isMaxBreakpoint} from '../../../../../../services/breakpoint-service';

import CulinaryFeatureGuideHeroSectionsListItem from './culinary-feature-guide-hero-sections-list-item';

class CulinaryFeatureGuideHeroSectionsSubnav extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            navOpen: false,
            selectedIndex: 0
        };
    }

    componentDidMount() {
        require('velocity-animate');
    }

    onClickHandler = (event, data) => {
        event.preventDefault();

        const isMobile = isMaxBreakpoint('xs');
        const navOpen = this.state.navOpen;
        const id = data.target;
        const index = data.index;

        if (isMobile) {
            this.setState({
                navOpen: !navOpen,
                selectedIndex: index
            });
        }

        if (navOpen === false && isMobile) {
            return;
        }

        this.scrollToTarget(id);
    };

    scrollToTarget(id) {
        const scrollTarget = global.document.querySelector(`#${id}`);

        if (scrollTarget) {
            /* eslint-disable-next-line no-undef, new-cap */
            Velocity(scrollTarget, 'scroll', {
                complete: () => {
                    const pageYOffset = global.window.pageYOffset;

                    scrollTarget.setAttribute('tabindex', '-1');
                    scrollTarget.focus();

                    global.window.scrollTo(0, pageYOffset);
                },
                duration: 1000,
                offset: this.props.isScrolled ? -64 : -164
            });
        }
    }

    getComponentClass() {
        const baseClass = 'culinary-feature-guide-hero-sections-subnav';

        return this.state.navOpen ? `${baseClass} is-active` : baseClass;
    }

    render() {
        return (
            <div
                className={this.getComponentClass()}
                ref={(subnav) => {
                    this.subnav = subnav;
                }}
            >
                <ul className="list">
                    {this.props.dropLinks.map((dropLink, index) => (
                        <CulinaryFeatureGuideHeroSectionsListItem
                            dropLink={dropLink}
                            itemIndex={index}
                            key={index}
                            onClickHandler={this.onClickHandler}
                            selectedIndex={this.state.selectedIndex}
                        />
                    ))}
                </ul>
            </div>
        );
    }
}

export default CulinaryFeatureGuideHeroSectionsSubnav;
