import React from 'react';

const CulinaryAddToListFail = (props) => {
    const urls = props.environmentConfigReducer.url;

    return (
        <div className="culinary-add-to-list-fail">
            <div className="content">
                <h3 className="heading">{'Add To List'}</h3>
                <p>{'Oops, something went wrong. Please make sure you are logged in.'}</p>
            </div>
            <div className="actions">
                <button className="secondary-button" onClick={props.onClickCancel} type="button">
                    {'Close'}
                </button>
                <a
                    className="primary-button"
                    href={`${urls.accountLogin}${urls.base}${props.contentReducer.urlContentPath}`}
                >
                    {'Log in'}
                </a>
            </div>
        </div>
    );
};

export default CulinaryAddToListFail;
