import React from 'react';

const IconPrint = ({height, width}) => (
    <svg className="icon-print" focusable={false} height={height} viewBox="0 0 31 31" width={width}>
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
            <g transform="translate(-904.000000, -607.000000)">
                <g transform="translate(720.000000, 607.000000)">
                    <g transform="translate(184.000000, 0.000000)">
                        <path
                            d="M31,15.75 C31,24.172 24.172,31 15.75,31 C7.328,31 0.5,24.172 0.5,15.75 C0.5,7.328 7.328,0.5 15.75,0.5 C24.172,0.5 31,7.328 31,15.75"
                            fill="#ED7F26"
                        />
                        <path
                            d="M22.3945,21.5977 L9.1055,21.5977 C7.7305,21.5977 6.6055,20.4727 6.6055,19.0977 L6.6055,13.5367 C6.6055,12.1617 7.7305,11.0367 9.1055,11.0367 L22.3945,11.0367 C23.7695,11.0367 24.8945,12.1617 24.8945,13.5367 L24.8945,19.0977 C24.8945,20.4727 23.7695,21.5977 22.3945,21.5977"
                            fill="#FFFFFF"
                        />
                        <path
                            d="M21.5752,10.5244 L21.5752,8.7704 C21.5752,7.5324 20.5622,6.5194 19.3242,6.5194 L12.1662,6.5194 C10.9292,6.5194 9.9162,7.5324 9.9162,8.7704 L9.9162,10.5244 L21.5752,10.5244 Z"
                            fill="#FFFFFF"
                        />
                        <path
                            d="M21.5752,10.5244 L21.5752,8.7704 C21.5752,7.5324 20.5622,6.5194 19.3242,6.5194 L12.1662,6.5194 C10.9292,6.5194 9.9162,7.5324 9.9162,8.7704 L9.9162,10.5244 L21.5752,10.5244 Z"
                            stroke="#ED7F26"
                        />
                        <path
                            d="M19.1045,24.4111 L12.4445,24.4111 C11.0645,24.4111 9.9445,23.2921 9.9445,21.9111 L9.9445,17.3891 L21.6045,17.3891 L21.6045,21.9111 C21.6045,23.2921 20.4855,24.4111 19.1045,24.4111"
                            fill="#FFFFFF"
                        />
                        <path
                            d="M19.1045,24.4111 L12.4445,24.4111 C11.0645,24.4111 9.9445,23.2921 9.9445,21.9111 L9.9445,17.3891 L21.6045,17.3891 L21.6045,21.9111 C21.6045,23.2921 20.4855,24.4111 19.1045,24.4111 Z"
                            stroke="#ED7F26"
                        />
                        <path d="M19.8252,19.8301 L11.7242,19.8301" stroke="#ED7F26" strokeLinecap="round" />
                        <path d="M19.8252,21.9697 L11.7242,21.9697" stroke="#ED7F26" strokeLinecap="round" />
                        <path
                            d="M21.8906,13.7686 C21.8906,14.0836 21.6346,14.3396 21.3186,14.3396 C21.0026,14.3396 20.7466,14.0836 20.7466,13.7686 C20.7466,13.4536 21.0026,13.1976 21.3186,13.1976 C21.6346,13.1976 21.8906,13.4536 21.8906,13.7686"
                            fill="#ED7F26"
                        />
                        <path
                            d="M19.6797,13.7686 C19.6797,14.0836 19.4237,14.3396 19.1077,14.3396 C18.7917,14.3396 18.5357,14.0836 18.5357,13.7686 C18.5357,13.4536 18.7917,13.1976 19.1077,13.1976 C19.4237,13.1976 19.6797,13.4536 19.6797,13.7686"
                            fill="#ED7F26"
                        />
                        <path
                            d="M10.5166,13.7686 C10.5166,14.0836 10.2606,14.3396 9.9446,14.3396 C9.6286,14.3396 9.3726,14.0836 9.3726,13.7686 C9.3726,13.4536 9.6286,13.1976 9.9446,13.1976 C10.2606,13.1976 10.5166,13.4536 10.5166,13.7686"
                            fill="#ED7F26"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default IconPrint;
