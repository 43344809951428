import React from 'react';

import ApiQueryService from '../../../../../services/api/api-query-service';

import CulinaryMealIdeasPrintModalMealItem from './culinary-meal-ideas-print-modal-meal-item';

class CulinaryMealIdeasPrintModal extends React.Component {
    static ID = 'culinary-meal-ideas-print-modal';

    constructor(props) {
        super(props);

        this.meals = this.props.contentReducer.urlContent.content.meals;
        this.checkedMealIdeas = [];

        this.onClickCancelHandler = (event) => this.onClickCancel(event);
        this.onChangeInputHandler = (id, isChecked) => this.onChangeInput(id, isChecked);
        this.onClickShoppingListHandler = () => this.onClickShoppingList();
        this.onClickRecipesHandler = () => this.onClickRecipes();
        this.onClickSubmitHandler = (event) => this.onClickSubmit(event);

        this.state = {
            includeRecipes: true,
            includeShoppingList: true,
            shouldScrollToError: true
        };
    }

    componentDidMount() {
        this.props.actions.addAllMealIdeasToPrint(this.meals);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.mealIdeasToPrintReducer.hasError && !this.props.mealIdeasToPrintReducer.hasError) {
            this.setState({
                shouldScrollToError: true
            });
        }
    }

    componentDidUpdate() {
        if (this.props.mealIdeasToPrintReducer.hasError && this.state.shouldScrollToError) {
            const scrollTarget = this.errorMessage;

            scrollTarget.scrollIntoView();
        }
    }

    buildPreviewUrlParams() {
        const printIngredients = this.includeShoppingListInput.checked;
        const printRecipes = this.includeRecipesInput.checked;
        const location = this.props.router.location.pathname;

        const queryString = ApiQueryService.toQueryString({
            meals: this.props.mealIdeasToPrintReducer.mealIdeasToPrint.join(','),
            print: true,
            printIngredients,
            printRecipes
        });

        return global.window.open(`${location}?${queryString}`, '_blank');
    }

    getFormStatus() {
        const noMealIdeasToPrint = this.props.mealIdeasToPrintReducer.mealIdeasToPrint.length === 0;
        const {includeShoppingList, includeRecipes} = this.state;
        const formEmpty = noMealIdeasToPrint && !includeShoppingList && !includeRecipes;

        return {
            formEmpty,
            hasError: (noMealIdeasToPrint && (includeShoppingList || includeRecipes)) || formEmpty
        };
    }

    onClickSubmit(event) {
        event.preventDefault();

        const {hasError} = this.getFormStatus();

        if (hasError) {
            this.props.actions.setPrintPreviewError(true);
        } else {
            this.buildPreviewUrlParams();
        }
    }

    onClickCancel() {
        this.props.actions.modalClose();
    }

    onChangeInput(id, isChecked) {
        if (isChecked) {
            this.props.actions.removeMealIdeaToPrint(id);
        } else {
            this.props.actions.addMealIdeaToPrint(id);
        }
    }

    onClickShoppingList() {
        this.setState((prevState) => ({
            includeShoppingList: !prevState.includeShoppingList,
            shouldScrollToError: false
        }));
    }

    onClickRecipes() {
        this.setState((prevState) => ({
            includeRecipes: !prevState.includeRecipes,
            shouldScrollToError: false
        }));
    }

    render() {
        const {formEmpty} = this.getFormStatus();

        return (
            <form className="culinary-meal-ideas-print-modal" noValidate onSubmit={this.onClickSubmitHandler}>
                <div className="content">
                    <h1 className="header">{'Print Meal Ideas'}</h1>
                    <p className="instructions">
                        {'Select from the options below. A print-friendly version will be created for you.'}
                    </p>
                    <fieldset className="meal-ideas">
                        <legend className="label">{'Choose at least 1 meal idea'}</legend>
                        {this.props.mealIdeasToPrintReducer.hasError && (
                            <div
                                aria-live="polite"
                                className="status-banner"
                                ref={(node) => {
                                    this.errorMessage = node;
                                }}
                            >
                                {`${
                                    formEmpty
                                        ? 'Please select at least one recipe and if you want to print the shopping list and/or recipes.'
                                        : 'Please select at least one recipe to view a shopping list or recipes.'
                                }`}
                            </div>
                        )}
                        <ul className="meal-list">
                            {this.meals.map((meal, mealIdeaIndex) => (
                                <CulinaryMealIdeasPrintModalMealItem
                                    key={mealIdeaIndex}
                                    mealIdea={meal}
                                    mealIdeaIndex={mealIdeaIndex}
                                    mealIdeasToPrintReducer={this.props.mealIdeasToPrintReducer}
                                    onChangeInputHandler={this.onChangeInputHandler}
                                    shouldFocus={this.props.mealIdeasToPrintReducer.hasError && mealIdeaIndex === 0}
                                />
                            ))}
                        </ul>
                    </fieldset>
                    <fieldset className="additional-options">
                        <legend className="label">{'Additional Print Options'}</legend>
                        <ul className="include-list">
                            <li className="item">
                                <div className="input">
                                    <input
                                        checked={this.state.includeShoppingList}
                                        className="checkbox-function"
                                        id="mealPrint-include-shopping-list"
                                        onChange={this.onClickShoppingListHandler}
                                        ref={(node) => {
                                            this.includeShoppingListInput = node;
                                        }}
                                        type="checkbox"
                                    />
                                    <label className="checkbox-presentation" htmlFor="mealPrint-include-shopping-list">
                                        {'Include Shopping List'}
                                    </label>
                                </div>
                                <p className="description">
                                    {
                                        "We'll generate a shopping list of all ingredients needed for the selected Meal Ideas"
                                    }
                                </p>
                            </li>
                            <li className="item">
                                <div className="input">
                                    <input
                                        checked={this.state.includeRecipes}
                                        className="checkbox-function"
                                        id="mealPrint-include-recipes"
                                        onChange={this.onClickRecipesHandler}
                                        ref={(node) => {
                                            this.includeRecipesInput = node;
                                        }}
                                        type="checkbox"
                                    />
                                    <label className="checkbox-presentation" htmlFor="mealPrint-include-recipes">
                                        {'Include Recipes'}
                                    </label>
                                </div>
                                <p className="description">
                                    {"We'll bundle all the recipes for the selected Meal Ideas"}
                                </p>
                            </li>
                        </ul>
                    </fieldset>
                </div>
                <fieldset className="actions">
                    <button className="secondary-button" onClick={this.onClickCancelHandler} type="button">
                        {'Cancel'}
                    </button>
                    <button className="primary-button" type="submit">
                        {'Preview'}
                    </button>
                </fieldset>
            </form>
        );
    }
}

export default CulinaryMealIdeasPrintModal;
