import React from 'react';
import debounce from 'lodash.debounce';

import CulinaryFeatureCard from './culinary-feature-card';
import CulinaryFeatureCardGuide from './culinary-feature-card-guide';

class CulinaryFeatureCardList extends React.Component {
    constructor(props) {
        super(props);

        this.blocks = [];

        this.setupHandlers();
    }

    componentDidMount() {
        global.window.addEventListener('resize', this.onResizeWindowHandler);

        this.setBlockWidth();
    }

    componentWillUnmount() {
        global.window.removeEventListener('resize', this.onResizeWindowHandler);
    }

    setupHandlers() {
        this.onResizeWindowHandler = debounce((event) => this.onResizeWindow(event), 250);
    }

    onResizeWindow() {
        this.setBlockWidth();
    }

    setBlockWidth = () => {
        if (this.blocks[0]) {
            this.props.actions.carouselSetBlockWidth({
                blockWidth: this.blocks[0].getBoundingClientRect().width,
                id: this.props.id
            });
        }
    };

    getCards() {
        if (this.props.cardList && this.props.cardList.length) {
            return this.props.cardList;
        } else if (this.props.cardList && this.props.cardList.cards.length) {
            return this.props.cardList.cards;
        }

        return null;
    }

    render() {
        const cards = this.getCards();

        if (!cards) {
            return null;
        }

        return (
            <ul
                className={`culinary-feature-card-list ${
                    this.props.isMobileOnlyCarousel ? 'is-mobile-only-carousel' : ''
                }`}
            >
                {cards.map((card, index) => (
                    <li
                        key={index}
                        ref={(block) => {
                            this.blocks[index] = block;
                        }}
                    >
                        {card.type === 'Guide' ? (
                            <CulinaryFeatureCardGuide cardGuide={card} />
                        ) : (
                            <CulinaryFeatureCard
                                actions={this.props.actions}
                                alertReducer={this.props.alertReducer}
                                card={card}
                                favoritesReducer={this.props.favoritesReducer}
                                id={this.props.id}
                                isAuthenticated={this.props.isAuthenticated}
                                modalReducer={this.props.modalReducer}
                            />
                        )}
                    </li>
                ))}
            </ul>
        );
    }
}

export default CulinaryFeatureCardList;
