import React from 'react';
import {Link} from 'react-router';

import CulinaryFeatureCardCarousel from '../cards/culinary-feature-card-carousel';

class CulinaryFeatureRelatedContent extends React.Component {
    shouldComponentUpdate() {
        if (this.props.searchReducer) {
            return this.props.searchReducer.currentPage === 0;
        }

        return true;
    }

    getCardList = () => {
        return this.props.relatedContent && this.props.relatedContent.references
            ? this.props.relatedContent.references
            : this.props.relatedContent;
    };

    render() {
        const {
            actions,
            alertReducer,
            carouselState,
            favoritesReducer,
            isAuthenticated,
            label,
            modalReducer,
            relatedContent,
            sectionTitle
        } = this.props;

        const cardList = this.getCardList();

        if (!cardList || !cardList.length) {
            return null;
        }

        return (
            <section className="culinary-feature-related-content">
                {sectionTitle ? (
                    <h4 className="section-header">{sectionTitle}</h4>
                ) : (
                    <h4 className="section-header">
                        {'More '}
                        <Link to={relatedContent.url}>{label}</Link>
                        {' you might like'}
                    </h4>
                )}

                <CulinaryFeatureCardCarousel
                    actions={actions}
                    alertReducer={alertReducer}
                    cardList={cardList}
                    carouselState={carouselState}
                    favoritesReducer={favoritesReducer}
                    id="carousel-feature-related-content"
                    isAuthenticated={isAuthenticated}
                    modalReducer={modalReducer}
                    styleConfig="secondary"
                />
            </section>
        );
    }
}

export default CulinaryFeatureRelatedContent;
