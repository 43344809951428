import 'fetch-everywhere';

import React from 'react';
import ReactDOM from 'react-dom';
import {browserHistory} from 'react-router';
import {persistStore} from 'redux-persist';

import {createProviderStore} from './factories/provider-factory';
import ProviderWrapper from './views/provider-wrapper';
import RequestModel from './models/request-model';
import {registerClientSideHistoryChangesWithGoogle} from './services/google-analytics-service';

import './sass/index.scss';

const serverState = global.window.__STATE__;
const initialState = {
    ...serverState,
    renderReducer: {
        ...serverState.renderReducer,
        isServerSide: false
    }
};
const store = createProviderStore(initialState, browserHistory);

RequestModel.ANTI_FORGERY_TOKEN = initialState.renderReducer.hvCulinaryAntiForgeryValue;

persistStore(store, {
    whitelist: ['recipeRatingReducer', 'uiReducer']
});

registerClientSideHistoryChangesWithGoogle(browserHistory, document.title);

ReactDOM.render(<ProviderWrapper history={browserHistory} store={store} />, global.document.querySelector('#app'));
