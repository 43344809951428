import React from 'react';

const IconFacebook = ({height, width}) => (
    <svg className="icon-facebook" focusable={false} height={height} viewBox="0 0 31 31" width={width}>
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
            <g transform="translate(-720.000000, -607.000000)">
                <g transform="translate(720.000000, 607.000000)">
                    <path
                        d="M31,15.75 C31,24.172 24.172,31 15.75,31 C7.328,31 0.5,24.172 0.5,15.75 C0.5,7.328 7.328,0.5 15.75,0.5 C24.172,0.5 31,7.328 31,15.75"
                        fill="#3B5998"
                    />
                    <path
                        d="M19.7393,10.3955 L18.3383,10.3955 C17.2393,10.3955 17.0343,10.9215 17.0343,11.6805 L17.0343,13.3675 L19.6493,13.3675 L19.3023,16.0095 L17.0343,16.0095 L17.0343,22.7855 L14.3023,22.7855 L14.3023,16.0095 L12.0263,16.0095 L12.0263,13.3675 L14.3023,13.3675 L14.3023,11.4215 C14.3023,9.1635 15.6863,7.9315 17.7043,7.9315 C18.6683,7.9315 19.4983,8.0025 19.7393,8.0385 L19.7393,10.3955 Z"
                        fill="#FFFFFF"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export default IconFacebook;
